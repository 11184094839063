import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
const dateDifference = (
    firstDate,
    secondDate = new Date(),
    differenceType = 'day'
) => {
    const dateToCompare = dayjs(firstDate).utc()
    const dateToday = dayjs(secondDate).utc()
    const diffDays = dateToday.diff(dateToCompare, differenceType)
    return Math.abs(diffDays)
}

export default dateDifference
