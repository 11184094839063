import phoneFormatter from 'phone-formatter'
import PatternFormat from 'react-number-format'
import Cookies from 'js-cookie'
import PhoneOnboardingSVG from 'assets/onboarding_v3/phone_change.svg'

import React, { useState, useEffect, useContext, useRef, Fragment } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { AuthContext } from 'context/AuthContext'

import Button from 'components/Button'
import BackwardButton from 'components/Button/Backward'
import Emitter from 'utils/emitter'
import delay from 'delay'

export default function ChangePhoneNew() {
    const cellphoneInput = useRef()
    const navigate = useNavigate()

    const {
        checkChangePhone,
        sendCodeChangePhone,
        state: authState,
        setState: setAuthState
    } = useContext(AuthContext)

    const [loading, setLoading] = useState(false)
    const [cellphone, setCellphone] = useState('')
    const [error, setError] = useState('')

    const { tokenNewPhone } = useParams()

    useEffect(() => {
        checkPhoneToken()
    }, [])

    const checkPhoneToken = async () => {
        try {
            await checkChangePhone(tokenNewPhone)
            Cookies.set('tokenNewPhone', tokenNewPhone)
        } catch (error) {
            if (error.response.data.code !== 200) {
                Emitter.emit('onOpenNotification', {
                    type: 'error',
                    title: 'Token de acceso expiró',
                    message: `El token para actualizar tu número a expirado, intentalo nuevamente.`
                })

                navigate('/change-phone')
            }
        }
    }

    const onSendCellphoneCode = async (event) => {
        event.preventDefault()

        if (cellphone.length !== 10) {
            return setError('Ingresa un número de celular válido')
        }

        try {
            setLoading(true)

            const formattedCellphone = phoneFormatter.format(
                cellphone,
                '+1(NNN)NNN-NNNN'
            )

            await sendCodeChangePhone({
                cellphone: formattedCellphone,
                service: ''
            })

            setAuthState((prev) => ({
                ...prev,
                new_cellphone: formattedCellphone
            }))

            navigate('/change-phone/verify')
        } catch (error) {
            if (error.response.data.messages === 'Phone already exist') {
                return navigate('/change-phone/error')
            }

            setError(
                'Parece que hubo un problema. Inténtalo de nuevo por favor'
            )
        } finally {
            setLoading(false)
        }
    }

    const onSetCellphone = ({ value }) => {
        if (value.length === 10) {
            setCellphone(value)
            cellphoneInput.current.blur()
        } else {
            setCellphone('')
        }

        setError('')
    }

    const onBackward = () => {
        if (authState.logged) {
            return navigate('/personal-information')
        }

        navigate('/login')
    }

    return (
        <Fragment>
            {authState.logged && <BackwardButton onBackward={onBackward} />}
            <form onSubmit={onSendCellphoneCode} className="flex flex-col grow">
                <img className="mx-auto mt-24 mb-8" src={PhoneOnboardingSVG} />

                <div className="mb-10">
                    <h1 className="text-dark-kiwi text-center font-semibold mb-4 text-2xl">
                        Ingresa tu nuevo número de celular
                    </h1>

                    <p className="mb-10 text-center">
                        Te enviaremos un código de 6 dígitos para verificar tu
                        cuenta
                    </p>

                    <div className="relative">
                        <div
                            className={`absolute top-1/2 left-4 -translate-y-1/2 ${
                                error ? 'text-red-kiwi' : ''
                            }`}>
                            +1
                        </div>
                        <PatternFormat
                            className={`w-full rounded-lg py-2 px-4 pl-10 ${
                                error
                                    ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                    : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                            }`}
                            format="(###) ### ####"
                            placeholder="(000) 000 0000"
                            mask=""
                            getInputRef={cellphoneInput}
                            onValueChange={onSetCellphone}
                        />
                    </div>

                    {error !== '' && (
                        <small className="text-red-kiwi inline-block text-xs w-full mt-2">
                            {error}
                        </small>
                    )}
                </div>

                <Button
                    id="send_cellphone_code-gtm"
                    className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto"
                    loading={loading}
                    type="submit">
                    Cambiar número
                </Button>
            </form>
        </Fragment>
    )
}
