import React from 'react'
import { Route } from 'react-router-dom'

/**
 * Routes
 */

import CreditMonitoring from 'views/mobile/CreditMonitoring'
import CreditMonitoringRegister from 'views/mobile/CreditMonitoring/Register'
import CreditMonitoringReport from 'views/mobile/CreditMonitoring/Report'

/**
 * Provider
 */
import { CreditMonitoringProvider } from 'context/CreditMonitoringContext'

const CreditMonitoringRoutes = (
    <Route
        exact
        path="credit-monitoring"
        element={
            <CreditMonitoringProvider>
                <CreditMonitoring />
            </CreditMonitoringProvider>
        }>
        <Route exact path="register" element={<CreditMonitoringRegister />} />
        <Route exact path="report" element={<CreditMonitoringReport />} />
    </Route>
)

export default CreditMonitoringRoutes
