import React, { Fragment, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CreditBuilderContext } from 'context/CreditBuilderContext'
import { UserContext } from 'context/UserContext'
import { CheckCircleIcon } from '@heroicons/react/outline'

import MenCellphonePNG from 'assets/onboarding_v3/welcome_tour/men_cellphone.png'
import Advisement from 'components/Advisement'
import Button from 'components/Button'
import { ChevronLeftIcon } from 'assets/icons_v3/fonts'
import BackwardButton from 'components/Button/Backward'

export default function BuildScore() {
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const { state: creditState, saveState: saveCreditState } =
        useContext(CreditBuilderContext)
    const { state: userContext } = useContext(UserContext)

    const onBackward = () => {
        const {
            credit_builder: {
                courses: { total }
            }
        } = userContext.products

        if (total === 0) {
            return navigate('/home')
        }

        navigate('/credit-builder')
    }

    const onContinue = async () => {
        try {
            setLoading(true)

            await saveCreditState({
                ...creditState,
                build: {
                    completed: true
                }
            })

            navigate('/credit-builder/buy-course')
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Fragment>
            <BackwardButton onBackward={onBackward} />

            <div className="flex flex-col justify-center mb-10">
                <h1 className="text-dark-kiwi font-semibold text-2xl text-center">
                    ¡Comienza a crear tu historial ahora mismo!
                </h1>

                <img src={MenCellphonePNG} className="w-44 mx-auto my-8" />

                <div className="flex flex-col gap-4">
                    <div className="flex items-center">
                        <CheckCircleIcon className="bg-red w-6 text-blue-kiwi mr-4 shrink-0" />
                        <div className="font-semibold">
                            Cuotas con 0% de interés
                        </div>
                    </div>
                    <div className="flex items-center">
                        <CheckCircleIcon className="bg-red w-6 text-blue-kiwi mr-4 shrink-0" />
                        <div className="font-semibold">
                            Sin indagación de crédito
                        </div>
                    </div>
                    <div className="flex items-center">
                        <CheckCircleIcon className="bg-red w-6 text-blue-kiwi mr-4 shrink-0" />
                        <div className="font-semibold">
                            Acceso a cursos de educación financiera
                        </div>
                    </div>
                    <div className="flex items-center">
                        <CheckCircleIcon className="bg-red w-6 text-blue-kiwi mr-4 shrink-0" />
                        <div className="font-semibold">
                            Reportamos tus pagos mensuales a TransUnion,
                            Experian y Equifax
                        </div>
                    </div>
                </div>

                <Advisement className="mt-10" icon="bulb_money">
                    <strong className="block">
                        No es un préstamo de efectivo
                    </strong>
                    Accede a cursos financieros y construye crédito con tus
                    pagos mensuales
                </Advisement>
            </div>

            <Button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto"
                loading={loading}
                onClick={onContinue}>
                Continuar
            </Button>
        </Fragment>
    )
}
