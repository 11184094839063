import React from 'react'
import BaseModal from '../BaseModal'

export default function CourseLessonsModal({
    isVisible,
    closeModal,
    lessons,
    description
}) {
    return (
        <BaseModal
            title="Detalle de contenido"
            description={description}
            isVisible={isVisible}
            closeModal={closeModal}>
            {lessons?.map((lesson) => (
                <div
                    key={lesson.id}
                    className="flex justify-between items-center p-4 rounded-md border-l-4 border-blue-kiwi [&:not(:last-child)]:mb-4 text-sm">
                    <div>
                        <div>{lesson.name}</div>
                        <div className="text-gray-500-kiwi text-xs mt-1">
                            Duración {lesson.duration}
                        </div>
                    </div>
                </div>
            ))}
        </BaseModal>
    )
}
