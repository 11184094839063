import * as Yup from 'yup'
import NumberFormat from 'react-number-format'
import Button from 'components/Button'
import React, { Fragment, useRef, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Advisement from 'components/Advisement'
import { PaymentAPI } from 'api'
import moneyToNumber from 'utils/moneyToNumber'

export default function AddCreditCardValidation({
    validationStatus,
    onSuccess,
    onReject
}) {
    const form = useRef()
    const [loading, setLoading] = useState(false)

    const validationSchema = Yup.object().shape({
        value: Yup.string()
            .required('Este campo es requerido')
            .test('min-amount', 'El ingreso debe ser mayor a $0', (money) => {
                if (money) {
                    let amount = Number(
                        money.replaceAll(',', '').replace('$ ', '')
                    )

                    if (amount > 0) {
                        return true
                    }
                }
            })
    })

    const initialValues = {
        value: ''
    }

    const onContinue = async ({ value }) => {
        try {
            setLoading(true)

            await PaymentAPI.cardEvaluate({
                id: validationStatus.id,
                amount: moneyToNumber(value, true)
            })

            onSuccess()
        } catch (error) {
            const {
                response: { data }
            } = error

            if (data.messages === 'WRONG_VALUES') {
                return form.current.setFieldError(
                    'value',
                    'El monto ingresado no es correcto. Tienes un último intento'
                )
            }

            onReject()
        } finally {
            setLoading(false)
        }
    }

    return (
        <Formik
            innerRef={form}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onContinue}>
            {({ errors, touched }) => (
                <Fragment>
                    <Form className="flex flex-col grow" autoComplete="off">
                        <h1 className="text-dark-kiwi font-semibold text-2xl mb-4">
                            Verificación de tarjeta
                        </h1>
                        <p>
                            Para validar tu tarjeta terminada en{' '}
                            <span className="font-semibold">
                                *{validationStatus.last4.slice(-4)}
                            </span>
                            , hemos debitado un monto aleatorio en centavos. Por
                            favor, ingresa la cantidad exacta que fue debitada
                        </p>

                        <div className="my-10">
                            <div className="mb-6">
                                <label
                                    htmlFor="value"
                                    className="inline-block text-sm mb-2">
                                    Monto debitado
                                </label>

                                <Field name="value" id="value">
                                    {({ field, ...props }) => (
                                        <NumberFormat
                                            disabled={loading}
                                            {...field}
                                            {...props}
                                            id="value"
                                            thousandSeparator={true}
                                            prefix={'$ '}
                                            allowNegative={false}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            placeholder="$ 0.00"
                                            autoComplete="off"
                                            className={`focus:ring-0 outline-none w-full rounded-lg py-2 px-4 border border-solid ${
                                                errors.value && touched.value
                                                    ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                                    : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                            }`}
                                        />
                                    )}
                                </Field>

                                <ErrorMessage
                                    name="value"
                                    component="small"
                                    className="text-red-kiwi inline-block text-xs w-full mt-2"
                                />
                            </div>
                        </div>

                        <Advisement className="mt-auto" icon="shield_check">
                            <strong>El monto debitado será reembolsado</strong>
                            <p>
                                a tu cuenta. Solo es una medida de seguridad
                                para proteger tu información
                            </p>
                        </Advisement>

                        <Button
                            className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                            loading={loading}
                            type="submit">
                            Continuar
                        </Button>
                    </Form>
                </Fragment>
            )}
        </Formik>
    )
}
