import React from 'react'
import { Route } from 'react-router-dom'
import { RouletteProvider } from 'context/RouletteContext'
import RouletteLayout from 'views/mobile/Roulette'
import RouletteHowWorks from 'views/mobile/Roulette/HowWorks'
import RoulettePrizes from 'views/mobile/Roulette/Prizes'
import RouletteWheel from 'views/mobile/Roulette/Wheel'
import RouletteWinners from 'views/mobile/Roulette/Winners'

const RouletteRoutes = (
    <Route>
        <Route
            exact
            path="roulette"
            element={
                <RouletteProvider>
                    <RouletteLayout />
                </RouletteProvider>
            }>
            <Route exact path="" element={<RouletteWheel />} />
            <Route exact path="prizes" element={<RoulettePrizes />} />
            <Route exact path="winners" element={<RouletteWinners />} />
            <Route exact path="how-works" element={<RouletteHowWorks />} />
        </Route>
    </Route>
)

export default RouletteRoutes
