import React, { Fragment, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import CardWarningSVG from 'assets/onboarding_v3/card_warning.svg'
import { ApprovedLoanContext } from 'context/ApprovedLoanContext'

export default function ApprovedLoanMesalveError() {
    const { state: approvedState } = useContext(ApprovedLoanContext)
    const navigate = useNavigate()

    const onContinue = () => {
        navigate('/home')
    }

    return (
        <Fragment>
            <div className="flex flex-col justify-center min-h-full text-center m-auto">
                <img className="mx-auto mb-8" src={CardWarningSVG} />

                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    No pudimos verificar tu identidad
                </h1>
                <p>
                    Lamentablemente, no pudimos verificar tu identidad. Por favor, revisa tu correo electrónico para obtener más detalles.
                </p>
            </div>

            <button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                onClick={onContinue}>
                Aceptar
            </button>
        </Fragment>
    )
}
