import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { UserContext } from 'context/UserContext'

/**
 * Prevenir que el usuario regrese al flujo firma de contratos, si ya se
 * firmó el contrato y necesita una llamada de verificación
 */
export const NeedVerificationMiddleware = () => {
    const { state: userState } = useContext(UserContext)
    const { products } = userState
    const {
        personal_loan: { application }
    } = products

    const need_verification_call = application?.need_verification_call ? application?.need_verification_call : null;

    return need_verification_call === true ? (
        <Navigate to="/approved-loan/need-verification-call" />
    ) : (
        <Outlet />
    )
}
