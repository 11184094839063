export default ({ className, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={className}
        {...props}>
        <path
            fill="currentColor"
            d="M21 3a1 1 0 1 1 0 2v11a2 2 0 0 1-2 2h-5.055l2.293 2.293a1 1 0 0 1-1.414 1.414l-2.828-2.828-2.829 2.828a1 1 0 0 1-1.414-1.414L10.046 18H5a2 2 0 0 1-2-2V5a1 1 0 0 1 0-2Zm-2 2H5v11h14zm-1.343 2.172a1 1 0 0 1 0 1.414l-4.236 4.236a1.01 1.01 0 0 1-1.428 0L9.88 10.708 7.759 12.83a1 1 0 0 1-1.415-1.415l2.822-2.82a1.01 1.01 0 0 1 1.428 0l2.113 2.112 3.536-3.535a1 1 0 0 1 1.414 0"
        />
    </svg>
)
