import React, { useState } from 'react'
import BaseModal from '../BaseModal'
import warningIcon from 'assets/icons/notification/warning.svg'
import { SearchIcon } from '@heroicons/react/outline'

export default function SelectCity({
    isVisible,
    selectedCity,
    closeModal,
    selectCity,
    data
}) {
    const [cities, setCities] = useState(data)

    const onSelectCity = async (city) => {
        selectCity(city)
        onCloseModal()
    }

    const onSearchCity = (event) => {
        const query = event.currentTarget.value.toLowerCase()

        if (query === '') {
            return setCities(data)
        }

        const filteredCities = data.filter(({ name }) => {
            const city = name.toLowerCase()
            return city.indexOf(query) > -1
        })

        setCities(filteredCities)
    }

    const onCloseModal = () => {
        closeModal()
        setCities(data)
    }

    return (
        <BaseModal
            title="Seleccionar ciudad"
            isVisible={isVisible}
            closeModal={onCloseModal}>
            <div className="flex flex-col gap-6 mt-4">
                <div className="flex">
                    <input
                        className={`h-[42px] focus:ring-0 outline-none w-full rounded-lg py-2 pr-4 pl-14 border border-solid border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi`}
                        placeholder="Buscar ciudad..."
                        onChange={onSearchCity}
                    />
                    <SearchIcon className="absolute w-6 ml-4 mt-2 text-gray-500-kiwi" />
                </div>
                {cities.length > 0 ? (
                    cities.map(({ name: city }) => (
                        <div key={city} className="relative">
                            <input
                                className="peer hidden"
                                type="radio"
                                id={city}
                                name="city"
                                onChange={() => onSelectCity(city)}
                                checked={city === selectedCity}
                            />
                            <div className='pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:bg-[url("./assets/icons/check.svg")] after:bg-no-repeat after:bg-center after:bg-auto after:rounded-full absolute right-4 top-1/2 -translate-y-1/2 rounded-full w-5 h-5 peer-checked:border-blue-kiwi border border-solid border-gray-400-kiwi'></div>
                            <label
                                htmlFor={city}
                                className="cursor-pointer peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 block rounded-lg border border-solid border-gray-200-kiwi p-4 pr-12">
                                <div>{city}</div>
                            </label>
                        </div>
                    ))
                ) : (
                    <div className="flex p-4 bg-yellow-50-kiwi rounded-lg">
                        <div
                            className={`flex items-center justify-center rounded-full h-10 w-10 shrink-0 bg-[#FFE9C7] mr-4`}>
                            <img src={warningIcon} />
                        </div>
                        <small className="text-xs">
                            Parece que no se encontraron resultados relacionados
                            con tú búsqueda, si crees que es un error, contacta
                            a<strong> support@kiwicredito.com</strong>
                        </small>
                    </div>
                )}
            </div>
        </BaseModal>
    )
}
