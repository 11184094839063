import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

import NeedVerificationCallImg from 'assets/onboarding_v3/verification_call.svg'
import supportIcon from 'assets/icons/support.svg'
import { ChevronLeftIcon } from 'assets/icons_v3/fonts'
import { SUPPORT_PHONE_NUMBER } from 'constants'
import phoneFormatter from 'phone-formatter'

export default function NeedVerificationCall() {
    const navigate = useNavigate()

    const onContinue = () => {
        navigate('/home')
    }

    const getPhoneNumber = () => {
        return phoneFormatter.format(
            String(SUPPORT_PHONE_NUMBER),
            '+1 (NNN) NNN-NNNN'
        )
    }

    return (
        <Fragment>
            <ChevronLeftIcon
                className="cursor-pointer w-2 mb-12"
                onClick={onContinue}
            />
            <div className="flex flex-col justify-center min-h-full text-center m-auto">
                <img className="mx-auto mb-8" src={NeedVerificationCallImg} />
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    ¡Te llamaremos pronto!
                </h1>
                <p>
                    Te contactaremos para verificar tu información en las próximas 12 horas hábiles. Si lo prefieres, puedes llamarnos al <span className='font-bold'>{getPhoneNumber()}</span>
                </p>
            </div>

            <div className="mt-10">
                <a
                    href={`tel:+${SUPPORT_PHONE_NUMBER}`}
                    className="flex justify-center items-center rounded-xl p-3 mt-10 bg-blue-kiwi text-white">
                    <img className="mr-2" src={supportIcon} />
                    Llamar a soporte
                </a>
            </div>
        </Fragment>
    )
}
