export default ({ className, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={className}
        {...props}>
        <path
            fill="currentColor"
            d="M16 4a2 2 0 0 1 1.995 1.85L18 6v2.21l2.094-1.29a1.25 1.25 0 0 1 1.9.943l.006.12v8.034a1.25 1.25 0 0 1-1.8 1.122l-.106-.058L18 15.79V18a2 2 0 0 1-1.85 1.995L16 20H4a2 2 0 0 1-1.995-1.85L2 18V6a2 2 0 0 1 1.85-1.995L4 4h12Zm0 2H4v12h12V6Zm4 3.327-2 1.232v2.883l2 1.232V9.327Z"
        />
    </svg>
)
