import React, { useState, Fragment, useEffect } from 'react'

import Button from 'components/Button'
import { BankIcon } from 'assets/icons_v3/fonts'
import HTMLParser from 'html-react-parser'
import PaymentSkeleton from 'components/Skeleton/Payment'

export default function PaymentMethodsTemplate({
    loading,
    selectProfileTitle,
    selectProfileDescription,
    profiles,
    selectedProfileID,
    onSelectProfile
}) {
    const [selectedProfile, setSelectedProfile] = useState({})

    useEffect(() => {
        const profile = profiles.find((p) => p.id === selectedProfileID)
        if (profile) setSelectedProfile(profile)
    }, [loading])

    const onContinue = async () => {
        onSelectProfile({
            id: selectedProfile.id,
            bankName: selectedProfile.CheckingAccount.bankName,
            last4: selectedProfile.CheckingAccount.accountNumber.slice(-4)
        })
    }

    return (
        <Fragment>
            <div className="flex flex-col mb-10">
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    {selectProfileTitle}
                </h1>

                {selectProfileDescription && (
                    <div className="mb-8">
                        {HTMLParser(selectProfileDescription)}
                    </div>
                )}

                <div className="flex flex-col gap-6">
                    {loading
                        ? [...Array(5)].map((item, index) => (
                              // TODO: Hacer ProfileSkeleton
                              <PaymentSkeleton key={index} />
                          ))
                        : profiles.map((profile) => (
                              <div key={profile.id} className="relative">
                                  <input
                                      className="peer hidden"
                                      type="radio"
                                      id={profile.title}
                                      name={profile.title}
                                      onChange={() =>
                                          setSelectedProfile(profile)
                                      }
                                      checked={
                                          selectedProfile.id === profile.id
                                      }
                                  />
                                  <div className='pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:w-3 after:h-3 after:bg-[url("./assets/icons/check.svg")] after:bg-no-repeat after:bg-center after:bg-auto after:rounded-full absolute right-4 top-1/2 -translate-y-1/2 rounded-full w-5 h-5 peer-checked:border-blue-kiwi border border-solid border-gray-400-kiwi'></div>
                                  <label
                                      htmlFor={profile.title}
                                      className={`min-h-[78px] flex cursor-pointer  rounded-lg border border-solid border-gray-200-kiwi p-4 peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10`}>
                                      <div
                                          className={`h-10 w-10 mr-4 grow-0 shrink-0 rounded-full flex items-center justify-center ${
                                              selectedProfile.id === profile.id
                                                  ? 'bg-blue-50-kiwi'
                                                  : 'bg-slate-100-kiwi'
                                          }`}>
                                          <BankIcon
                                              className={`w-6  ${
                                                  selectedProfile.id ===
                                                  profile.id
                                                      ? 'text-blue-kiwi'
                                                      : 'text-regal-blue-kiwi'
                                              }`}
                                          />
                                      </div>
                                      <div className="my-auto">
                                          <div className="leading-5">
                                              Cuenta ****{' '}
                                              {profile.CheckingAccount.accountNumber.slice(
                                                  -4
                                              )}
                                          </div>
                                          {profile.isPrimary === 1 && (
                                              <p className="text-xs text-gray-500-kiwi mt-1">
                                                  Cuenta principal
                                              </p>
                                          )}
                                      </div>
                                  </label>
                              </div>
                          ))}
                    {}
                </div>
            </div>

            <p className="text-xs text-gray-500-kiwi mt-auto">
                Si quieres cambiar tu cuenta de banco puedes comunicarte con
                nosotros al correo <strong>support@kiwicredito.com</strong>
            </p>

            <Button
                className={`rounded-xl p-3 mt-10 ${
                    !selectedProfile.id
                        ? 'bg-gray-200-kiwi text-gray-500-kiwi'
                        : 'bg-blue-kiwi text-white '
                }`}
                disabled={!selectedProfile.id}
                onClick={onContinue}>
                Continuar
            </Button>
        </Fragment>
    )
}
