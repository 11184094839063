import { useRef, useEffect } from 'react'
import { useFormikContext } from 'formik'

const MapsAutocompleteField = ({ errors, touched, field }) => {
    const { setFieldValue } = useFormikContext()

    const mapsAutoCompleteRef = useRef()
    const mapsInputRef = useRef()
    const mapsOptions = {
        fields: ['formatted_address', 'name', 'address_components', 'geometry'],
        componentRestrictions: { country: ['us', 'pr'] }
    }

    useEffect(() => {
        mapsAutoCompleteRef.current =
            new window.google.maps.places.Autocomplete(
                mapsInputRef.current,
                mapsOptions
            )
        mapsAutoCompleteRef.current.addListener(
            'place_changed',
            async function () {
                const place = await mapsAutoCompleteRef.current.getPlace()
                let address = ''
                place.address_components.forEach((element) => {
                    switch (element.types[0]) {
                        case 'postal_code':
                            setFieldValue('zipcode', element.long_name)
                            break
                        case 'locality':
                            setFieldValue(
                                'city',
                                element.long_name
                                    .normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, '')
                            )
                            break
                        case 'route':
                            address = `${address} ${element.long_name}`
                            break
                        case 'street_number':
                            address = `${element.long_name} ${address}`
                            break
                    }
                })
                setFieldValue(
                    field.name,
                    address !== '' ? address : `${place.formatted_address}`
                )
            }
        )
    }, [])

    return (
        <input
            ref={mapsInputRef}
            {...field}
            onKeyDown={(e) => {
                e.key === 'Enter' && e.preventDefault()
            }}
            className={`focus:ring-0 outline-none w-full rounded-lg py-2 px-4 border border-solid ${
                errors.value && touched.value
                    ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                    : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
            }`}
        />
    )
}
export default MapsAutocompleteField
