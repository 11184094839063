import React, { useState, useContext, Fragment, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ApprovedLoanContext } from 'context/ApprovedLoanContext'
import ProgressBar from 'components/ProgressBar'
import { StarIcon } from '@heroicons/react/solid'
import PaperCheckConfirmation from 'components/Modals/PaperCheckConfirmation'
import { VerificationsAPI } from 'api'
import ACHModal from 'components/Modals/ACH'
import PaymentElectionAuthorizationModal from 'components/Modals/PaymentElectionAuthorization'
import Button from 'components/Button'
import {
    REPAYMENT_OPTIONS,
    DISBURSEMENT_METHODS,
    STATES_AVAILABLES
} from 'constants'
import { UserContext } from 'context/UserContext'

export default function ApprovedLoanRepaymentOptionsSelect() {
    const navigate = useNavigate()
    const ACHModalRef = useRef()
    const PaymentElectionAuthorizationModalRef = useRef()
    const PaperCheckModalRef = useRef()
    const PaymentAuthModalRef = useRef()

    const { state: approvedState, saveState: saveApprovedState } =
        useContext(ApprovedLoanContext)
    const { state: userState } = useContext(UserContext)
    const [repaymentOption, setRepaymentOption] = useState(
        approvedState.repaymentOption.value
    )

    useEffect(() => {
        if (
            approvedState.repaymentOption.value ===
                REPAYMENT_OPTIONS.paperCheck &&
            approvedState.repaymentOptionReviewing.completed === false
        ) {
            navigate('/approved-loan/repayment-options/reviewing')
        }
    }, [])

    const showPaperCheckOption = () => {
        const { state } = userState.user
        const isFromFlorida = state === STATES_AVAILABLES.florida
        return isFromFlorida
    }

    const onContinue = () => {
        if (repaymentOption === REPAYMENT_OPTIONS.ACH) {
            saveApprovedState({
                ...approvedState,
                repaymentOption: {
                    value: repaymentOption,
                    completed: true
                }
            })

            navigate('/approved-loan/repayment-profile')
        }

        if (repaymentOption === REPAYMENT_OPTIONS.paperCheck) {
            if (approvedState.repaymentOptionReviewing.completed === true) {
                return navigate('/approved-loan/persona')
            }

            PaperCheckModalRef.current.openModal()
        }
    }

    const onBackward = () => {
        const disbursementMethod = approvedState.disbursement.value

        if (disbursementMethod === DISBURSEMENT_METHODS.transfer)
            navigate('/approved-loan/disbursement-profile')

        if (disbursementMethod === DISBURSEMENT_METHODS.immediate)
            navigate('/approved-loan/stores')

        if (
            disbursementMethod === DISBURSEMENT_METHODS.paperCheck ||
            disbursementMethod === DISBURSEMENT_METHODS.ath
        )
            navigate('/approved-loan/disbursement')
    }

    const onContinuePaperCheck = async () => {
        await VerificationsAPI.postRepaymentCheck()

        saveApprovedState({
            ...approvedState,
            repaymentOption: {
                value: repaymentOption,
                completed: true
            }
        })

        navigate('/approved-loan/repayment-options/reviewing')
    }

    const onChangeACH = () => {
        setRepaymentOption('transfer')
    }

    return (
        <Fragment>
            <ProgressBar progress="7/10" onBackward={onBackward} />

            <ACHModal ref={ACHModalRef} />
            <PaymentElectionAuthorizationModal
                ref={PaymentElectionAuthorizationModalRef}
            />
            <PaperCheckConfirmation
                ref={PaperCheckModalRef}
                onChangeACH={onChangeACH}
                onContinuePaperCheck={onContinuePaperCheck}
            />

            <div className="mb-10">
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    ¿Cómo deseas hacer tus pagos?
                </h1>
                <p className="mb-5 text-gray-700-kiwi">
                    Cómo deseas realizar los pagos de tu préstamo
                </p>

                <div
                    className="text-blue-kiwi underline text-sm cursor-pointer"
                    onClick={() => ACHModalRef.current.openModal()}>
                    ¿Cómo funcionan los pagos automáticos?
                </div>
            </div>

            <div className="flex flex-col gap-6 mb-10">
                <div className="relative">
                    <input
                        className="peer hidden"
                        type="radio"
                        id="transfer"
                        name="bank"
                        onChange={() => setRepaymentOption('transfer')}
                        checked={repaymentOption === 'transfer'}
                    />
                    <div className='pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:bg-[url("./assets/icons/check.svg")] after:bg-no-repeat after:bg-center after:bg-auto after:rounded-full absolute right-4 top-1/2 -translate-y-1/2 rounded-full w-5 h-5 peer-checked:border-blue-kiwi border border-solid border-gray-400-kiwi'></div>
                    <label
                        htmlFor="transfer"
                        className="cursor-pointer peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 block rounded-lg border border-solid border-gray-200-kiwi p-4">
                        <div className="leading-5 mb-2 font-medium text-dark-kiwi">
                            Transferencias Electrónicas de Fondos (ACH)
                        </div>
                        <p className="flex text-xs mb-2">
                            <span className="flex my-2 bg-yellow-kiwi text-white px-4 py-1 rounded-xl">
                                <StarIcon className="w-4 mr-1" />
                                Recomendado
                            </span>
                        </p>
                        <ul className=" text-xs text-gray-600-kiwi list-disc pl-5">
                            <li>Preferido por los clientes</li>
                            <li>Rápido y sencillo</li>
                            <li>Amigable con el medio ambiente</li>
                            <li>Pagos automáticos</li>
                        </ul>
                    </label>
                </div>
                {showPaperCheckOption() && (
                    <div className="relative ">
                        <input
                            className="peer hidden"
                            type="radio"
                            id="immediate"
                            name="bank"
                            onChange={() => setRepaymentOption('paper_check')}
                            checked={repaymentOption === 'paper_check'}
                        />
                        <div className='pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:bg-[url("./assets/icons/check.svg")] after:bg-no-repeat after:bg-center after:bg-auto after:rounded-full absolute right-4 top-1/2 -translate-y-1/2 rounded-full w-5 h-5 peer-checked:border-blue-kiwi border border-solid border-gray-400-kiwi'></div>
                        <label
                            htmlFor="immediate"
                            className="cursor-pointer peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 block rounded-lg border border-solid border-gray-200-kiwi p-4">
                            <div className="leading-5 mb-3 font-medium text-dark-kiwi">
                                Pagos manuales con Cheque de Papel
                            </div>
                            <ul className=" text-xs text-gray-600-kiwi list-disc pl-5">
                                <li>Requiere la compra de sellos postales</li>
                                <li>Posibles retrasos en el correo postal</li>
                                <li>Proceso manual en cada cuota</li>
                            </ul>
                        </label>
                    </div>
                )}
            </div>

            <div className="mt-auto text-sm">
                <p className="text-xs text-gray-500-kiwi">
                    *Por favor, revise el{' '}
                    <span
                        className="text-blue-kiwi text-xs underline cursor-pointer"
                        onClick={() =>
                            PaymentElectionAuthorizationModalRef.current.openModal()
                        }>
                        {' '}
                        Acuerdo de Elección y Autorización de Pago
                    </span>{' '}
                    para más detalles en la sección de firma de contratos.
                </p>
            </div>

            <Button
                disabled={repaymentOption === ''}
                className={`rounded-xl p-3 mt-10 ${
                    repaymentOption === ''
                        ? 'bg-gray-200-kiwi text-gray-500-kiwi'
                        : 'bg-blue-kiwi text-white '
                }`}
                onClick={onContinue}>
                Continuar
            </Button>
        </Fragment>
    )
}
