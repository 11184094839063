import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import PaymentProfile from 'templates/PaymentProfile'
import BackwardButton from 'components/Button/Backward'
import { PaymentAPI } from 'api'
import { AutopayContext } from 'context/AutopayContext'
import PaymentMethodsTemplate from 'templates/PaymentMethods'
import LoadingModal from 'components/Modals/Loading'

export default function AutopayPaymentProfile() {
    const { state: autoState, setState: setAutoState } =
        useContext(AutopayContext)

    const navigate = useNavigate()

    const [profiles, setProfiles] = useState([])
    const [showLoader, setShowLoader] = useState(true)

    useEffect(() => {
        getProfiles()
    }, [])

    const getProfiles = async () => {
        try {
            const {
                data: { data }
            } = await PaymentAPI.getBankAccounts()

            if (data.length === 0) {
                return navigate('/autopay/error', {
                    state: 'noAccounts'
                })
            }

            setProfiles(data)
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoader(false)
        }
    }

    const onBackward = () => {
        navigate('/autopay')
    }

    const onSelectProfile = ({ id, last4, bankName }) => {
        setAutoState((state) => ({
            ...state,
            paymentProfile: {
                id,
                last4,
                bankName,
                completed: true
            }
        }))

        navigate('/autopay/contract')
    }

    return (
        <Fragment>
            <BackwardButton onBackward={onBackward} />
            <LoadingModal isVisible={showLoader} />

            <PaymentMethodsTemplate
                loading={showLoader}
                profiles={profiles}
                onSelectProfile={onSelectProfile}
                selectedProfileID={autoState.paymentProfile.id}
                selectProfileTitle="Selecciona tu perfil de pago"
                selectProfileDescription="Reactiva tus pagos automáticos para que evites olvidos y retrasos"
            />
        </Fragment>
    )
}
