import React, { Fragment } from 'react'

import MagnifyingSVG from 'assets/onboarding_v3/magnifying.svg'
import { useNavigate } from 'react-router-dom'

export default function AutopayReviewing() {
    const navigate = useNavigate()

    const onContinue = () => {
        navigate('/home')
    }

    return (
        <Fragment>
            <div className="flex flex-col justify-center min-h-full text-center m-auto">
                <img className="mx-auto mb-8" src={MagnifyingSVG} />
                <h1 className="text-dark-kiwi font-semibold text-2xl mb-4">
                    Verificando tu cuenta bancaria
                </h1>
                <p>
                    Estamos revisando que la información de tu cuenta bancaria
                    esté correcta, recibirás respuesta en minutos.
                </p>
            </div>

            <button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                onClick={onContinue}>
                Continuar
            </button>
        </Fragment>
    )
}
