import React, { useState, useRef, useContext } from 'react'

import Button from 'components/Button'
import { useEffect } from 'react'
import { ArrowsDownIcon } from 'assets/icons_v3/fonts'
import LoadingModal from 'components/Modals/Loading'
import BackwardButton from 'components/Button/Backward'
import { useNavigate } from 'react-router-dom'
import { AutopayContext } from 'context/AutopayContext'
import { UserContext } from 'context/UserContext'
import getUserIP from 'utils/getUserIP'
import ContractTemplate from 'templates/Contract'
import { LoanAPI } from 'api'
import Emitter from 'utils/emitter'

export default function AutopayContract({}) {
    const { state: autoState } = useContext(AutopayContext)
    const { state: userState } = useContext(UserContext)

    const {
        products: {
            personal_loan: {
                loan: { id: loanpro_tmp_loan_id }
            }
        }
    } = userState

    const {
        paymentProfile: { id: payment_profile_id }
    } = autoState

    const navigate = useNavigate()

    const [showLoader, setShowLoader] = useState(true)
    const [contractURL, setContractURL] = useState('')

    useEffect(() => {
        if (!payment_profile_id) {
            return navigate('/autopay/payment-profile')
        }

        getContract()
    }, [])

    const contractPayload = async () => {
        const ip = await getUserIP()

        return {
            loanpro_tmp_loan_id,
            ip,
            payment_profile_id
        }
    }

    const getContract = async () => {
        try {
            const payload = await contractPayload()
            const { data } = await LoanAPI.getACHContractAutopay(payload)

            if (data) {
                const reader = new window.FileReader()

                reader.readAsDataURL(data)
                reader.onload = () => {
                    setContractURL(reader.result)
                }
            }
        } catch (error) {
            console.error(error)
        } finally {
            setShowLoader(false)
        }
    }

    const onSignContract = async () => {
        try {
            setShowLoader(true)

            const payload = await contractPayload()
            await LoanAPI.signACHContractAutopay(payload)

            navigate('/autopay/success')
        } catch (error) {
            console.error(error)
            Emitter.emit('onOpenNotification', {
                type: 'error',
                title: 'Problemas al reactivar tus pagos automáticos',
                message:
                    'Parece que tuvimos problemas al reactivar tus pagos automáticos. Por favor inténtalo nuevamente, si el problema persiste contacta a support@kiwicredito.com'
            })
        } finally {
            setShowLoader(false)
        }
    }

    const onBackward = () => {
        navigate('/autopay/payment-profile')
    }

    return (
        <div className="flex flex-col h-[calc(100vh-80px)] overflow-hidden">
            <BackwardButton onBackward={onBackward} />
            <LoadingModal isVisible={showLoader} />

            <ContractTemplate
                signed={false}
                title="Autorización ACH"
                fileUrl={contractURL}
                signContract={onSignContract}
            />
        </div>
    )
}
