export default function User_icon() {
  return (
    <svg
      width="91"
      height="90"
      viewBox="0 0 91 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.7487 70.0007C87.6933 62.6004 90.3325 53.9002 90.3325 45C90.3325 43.6215 90.2693 42.2495 90.1444 40.8879L73.1216 56.236C67.6779 61.1477 58.8245 61.1531 53.3754 56.2632L37.5033 42.0069C36.4311 41.0387 35.1213 40.5709 33.8392 40.7178C32.5515 40.8538 31.3909 41.5772 30.5564 42.7521L21.8301 55.0883C19.6361 58.1778 16.4307 59.9728 13.043 60H12.9656C9.59995 60 6.39457 58.2594 4.17845 55.2189L0.667484 50.4805C0.802837 51.5836 0.97933 52.6838 1.19719 53.7791C2.93352 62.5082 7.21936 70.5264 13.5127 76.8198C19.8061 83.1132 27.8243 87.399 36.5535 89.1353C45.2826 90.8717 54.3306 89.9805 62.5533 86.5746C70.776 83.1686 77.804 77.4009 82.7487 70.0007ZM0.889741 37.9403L1.75231 39.0425L10.5229 50.8729C11.269 51.8955 12.1587 52.4775 12.9656 52.4775H12.9822C13.8001 52.4775 14.6843 51.8792 15.4138 50.8403L24.1402 38.5149C26.2458 35.545 29.4677 33.6304 32.9826 33.2551C36.4974 32.8581 40.0786 34.0601 42.8032 36.5078L58.6698 50.7587C61.1567 52.9888 65.3458 52.9888 67.8327 50.7478L88.46 32.1537C86.3443 25.0517 82.4873 18.5151 77.1523 13.1802C68.7132 4.74106 57.2673 0 45.3325 0C36.4324 0 27.7321 2.6392 20.3319 7.58387C12.9317 12.5285 7.16389 19.5566 3.75795 27.7792C2.39937 31.0591 1.44092 34.4704 0.889741 37.9403Z"
        fill="currentColor"
      />
    </svg>
  );
}
