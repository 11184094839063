import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from 'context/UserContext'
import mixpanel from 'utils/mixpanel'
import CreditBuilder from 'api/CreditBuilder'
import { CREDIT_BUILDER_COURSE_STATUS } from 'constants'
import { ChevronLeftIcon, GiftIcon } from 'assets/icons_v3/fonts'
import CreditBuilderPNG from 'assets/onboarding_v3/recommended/credit_builder.png'
import { CreditBuilderAPI } from 'api'
import PaidoutCourseComponent from 'components/PaidoutCourse'
import { CreditBuilderContext } from 'context/CreditBuilderContext'
import CourseComponent from 'components/Course'
import CourseSkeleton from 'components/Skeleton/Course'
import PaidoutCourseSkeleton from 'components/Skeleton/PaidoutCourse'

export default function CreditBuilderActiveCourses() {
    const navigate = useNavigate()

    const { state: userState } = useContext(UserContext)
    const {
        credit_builder: { tracking, courses: totalCourses }
    } = userState.products
    const context = tracking?.context
    const {
        state: creditState,
        saveState: saveCreditState,
        initialState: initialCreditState
    } = useContext(CreditBuilderContext)

    const [paidoutCourses, setPaidoutCourses] = useState([])
    const [courses, setCourses] = useState([])

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        /**
         * Si es la primera vez, redirigir al flujo
         * de solicitud...
         */
        if (totalCourses.total === 0) {
            return redirectTracking()
        }

        /**
         * Si hay un curso activo, redirigir al balance...
         */
        if (totalCourses.active > 0) {
            return navigate('/credit-builder/active')
        }

        /**
         * Si ya tiene cursos y ninguno esta activo, mostrar los cursos
         * que haya comprado y los cursos que están disponibles...
         */
        getUserCourses()
        getCourses()
    }, [])

    const onBackward = () => {
        navigate('/home')
    }

    const getCourses = async () => {
        try {
            setLoading(true)

            const { data } = await CreditBuilderAPI.getCourses(
                userState.user.id
            )

            setCourses(data)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const getUserCourses = async () => {
        try {
            setLoading(true)

            const { data } = await CreditBuilder.getUserCourses(
                userState.user.id
            )

            setPaidoutCourses(
                data
                    .filter(
                        (course) =>
                            course.status ===
                            CREDIT_BUILDER_COURSE_STATUS.paidout
                    )
                    .reverse()
            )
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const redirectTracking = () => {
        if (context === undefined) {
            return navigate('/credit-builder/build-score')
        }

        if (context.contract?.completed) {
            navigate('/credit-builder/contract')
        } else if (context.persona?.completed) {
            navigate('/credit-builder/contract')
        } else if (context.paymentProfile?.completed) {
            navigate('/credit-builder/persona')
        } else if (context.repaymentTerms?.completed) {
            navigate('/credit-builder/payment-profile')
        } else if (context.paymentDay?.completed) {
            navigate('/credit-builder/repayment-terms')
        } else if (context.ssn?.completed) {
            navigate('/credit-builder/payment-day')
        } else if (context.address?.completed) {
            navigate('/credit-builder/ssn')
        } else if (context.selectedCourse?.completed) {
            navigate('/credit-builder/address')
        } else if (context.course?.completed) {
            navigate('/credit-builder/selected-course')
        } else if (context.build?.completed) {
            navigate('/credit-builder/buy-course')
        }
    }

    const onCourseDetails = (course) => {
        mixpanel.track('Course Viewed - CB', {
            Course: course.product.name
        })

        navigate(`/credit-builder/courses/${course.product.id}`)
    }

    const getCoursePrice = (country, prices) => {
        const price = prices.find((p) => p.country_code === country)
        return price
    }

    const onViewAllCourses = () => {
        saveCreditState({
            ...creditState,
            build: {
                completed: true
            }
        })

        navigate('/credit-builder/buy-course')
    }

    const onSelectCourse = (course) => {
        if (creditState.course.id !== course.id) {
            const { id, financial_months, name, cb_prices } = course
            const price = getCoursePrice('us', cb_prices)

            saveCreditState({
                ...initialCreditState,
                build: {
                    completed: true
                },
                course: {
                    id,
                    financial_months,
                    name,
                    price_id: price.id,
                    price: price.value,
                    completed: true
                }
            })

            mixpanel.track('Select Course', {
                Course: name
            })
        }

        navigate('/credit-builder/selected-course')
    }

    return (
        <Fragment>
            <ChevronLeftIcon
                className="cursor-pointer w-2 mb-12"
                onClick={onBackward}
            />

            <div className="mb-8">
                <h1 className="text-dark-kiwi font-semibold text-2xl mb-6">
                    Credit Builder
                </h1>

                <div
                    className="px-4 py-4 bg-slate-100-kiwi rounded-2xl flex justify-between gap-4 cursor-pointer"
                    onClick={redirectTracking}>
                    <div className="mb-4">
                        <p className="text-blue-kiwi mb-2 text-xs font-medium flex items-center">
                            <GiftIcon className="w-4 mr-2" /> Producto
                        </p>
                        <p className="font-bold mb-1 text-sm">
                            Construye tu crédito
                        </p>
                        <p className="text-xs">
                            Te ayudamos a construir tu puntaje por $5 al mes.
                        </p>
                    </div>

                    <img src={CreditBuilderPNG} className="shrink-0 self-end" />
                </div>
            </div>

            <div className="mb-8">
                <div className="font-semibold mb-6">Créditos finalizados</div>
                <div className="flex flex-col gap-6">
                    {loading ? (
                        <Fragment>
                            {[...Array(3)].map((item, index) => (
                                <PaidoutCourseSkeleton key={index} />
                            ))}
                        </Fragment>
                    ) : (
                        <Fragment>
                            {paidoutCourses.map((course) => (
                                <PaidoutCourseComponent
                                    key={course.product.id}
                                    course={course}
                                    onCourseDetails={onCourseDetails}
                                />
                            ))}
                        </Fragment>
                    )}
                </div>
            </div>

            <div>
                <div className="flex items-center justify-between mb-6">
                    <div className="font-semibold">Explora más cursos</div>
                    <div
                        className="text-blue-kiwi font-semibold text-sm cursor-pointer"
                        onClick={onViewAllCourses}>
                        Ver todos
                    </div>
                </div>
                <div className="flex flex-col gap-6">
                    {loading ? (
                        <Fragment>
                            {[...Array(3)].map((item, index) => (
                                <CourseSkeleton key={index} />
                            ))}
                        </Fragment>
                    ) : (
                        <Fragment>
                            {courses?.map((course) => (
                                <CourseComponent
                                    key={course.id}
                                    course={course}
                                    onSelectCourse={onSelectCourse}
                                />
                            ))}
                        </Fragment>
                    )}
                </div>
            </div>
        </Fragment>
    )
}
