import React from 'react'

export default function PaymentSkeleton() {
    return (
        <div className="flex items-center">
            <div className="flex shrink-0 bg-gray-50-kiwi h-10 w-10 rounded-full mr-4"></div>
            <div className="grow">
                <div className="h-3 bg-gray-50-kiwi rounded-lg w-1/2 mb-2"></div>
                <div className="h-3 bg-gray-50-kiwi rounded-lg w-1/3"></div>
            </div>
            <div className="flex shrink-0 bg-gray-50-kiwi h-3 w-14 rounded-lg ml-4"></div>
        </div>
    )
}
