import React, { Fragment, useState } from 'react'

import LateFee from 'components/Modals/LateFee'
import NSFFee from 'components/Modals/NSFFee'
import Payment from 'components/Payment'

export default function PaymentList({ payments }) {
    const [lateFeeVisible, setLateFeeVisible] = useState(false)
    const [NSFFeeVisible, setNSFFeeVisible] = useState(false)

    const onToggleLateFeeModal = () => {
        setLateFeeVisible(!lateFeeVisible)
    }

    const onToggleNSFFeeModal = () => {
        setNSFFeeVisible(!NSFFeeVisible)
    }

    const onOpenDetails = (payment) => {
        const { type, category } = payment

        if (type === 'charges') {
            if (category === 'NSF_FEE') {
                onToggleNSFFeeModal()
            }

            if (category === 'LATE_FEE') {
                onToggleLateFeeModal()
            }
        }
    }

    return (
        <Fragment>
            <h1 className="text-dark-kiwi font-semibold mb-10 text-2xl">
                Historial de movimientos
            </h1>

            <div className="flex flex-col gap-8">
                {payments.map((payment, index) => (
                    <Payment
                        payment={payment}
                        key={index}
                        onClick={() => onOpenDetails(payment)}
                    />
                ))}
            </div>

            <LateFee
                isVisible={lateFeeVisible}
                closeModal={onToggleLateFeeModal}
            />

            <NSFFee
                isVisible={NSFFeeVisible}
                closeModal={onToggleNSFFeeModal}
            />
        </Fragment>
    )
}
