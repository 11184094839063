export default [
    {
        id: 40,
        store: 'Lajas',
        address: 'Centro Comercial Municipal'
    },
    {
        id: 73,
        store: 'Barranquitas',
        address: 'Carr. 156 km. 17.1 Plaza San Cristobal Local #108'
    },
    {
        id: 84,
        store: 'Centro Sur',
        address: 'Centro Del Sur Shopping Center'
    },
    {
        id: 99,
        store: 'Juana Diaz Mall',
        address: 'Plz. Juana Diaz Shop. Road 149'
    },
    {
        id: 90,
        store: 'Lares Mall',
        address: 'Lares Mall Shop. Road 111 KM. 3.1'
    },
    {
        id: 103,
        store: 'Fajardo Mall',
        address: 'Plz. Fajardo Shop. Rd. 3 Km. 44.6'
    },
    {
        id: 149,
        store: 'Cidra Shopping',
        address: 'Cidra Shopping Center'
    },
    {
        id: 156,
        store: 'Aibonito Shopping',
        address: 'Plaza Las Flores Carr. #14 KM. 52.7 Bo. Robles'
    },
    {
        id: 157,
        store: 'Naranjito Shopping',
        address: 'El Mercado Plaza,Carr #152 KM 16.00 Bo. Cedro Abajo'
    },
    {
        id: 190,
        store: 'Oulet Barcelo',
        address: 'Premium Outlets, Barceloneta'
    },
    {
        id: 69,
        store: 'Post',
        address: '10 Munoz Rivera & Post Street'
    },
    {
        id: 86,
        store: 'Aguada Mall',
        address: 'Aguada Shopping/Road 115 KM. 22.4'
    },
    {
        id: 102,
        store: 'Dorado',
        address: 'Dorado Shop. Road 693 Km. 8.0'
    },
    {
        id: 109,
        store: 'Plz Guayama',
        address: 'Carr. 3. km. 134.7 local #43 Ave. Los Veteranos'
    },
    {
        id: 171,
        store: 'Plaza Penuelas',
        address: 'Carr.385, Km 0.7, Local C-20 Bo. Cuevas'
    },

    {
        id: 61,
        store: 'Patillas',
        address: 'Calle Riefkol Carr. 3 km. 123.1'
    },
    {
        id: 66,
        store: 'Yauco Plaza',
        address: 'Yauco Plz/Ground Floor-Road 128'
    },
    {
        id: 68,
        store: 'Jayuya',
        address: 'Calle Guillermo Esteves'
    },
    {
        id: 82,
        store: 'Arecibo Norte',
        address: 'Arecibo Shop/Luis Llorens Torr   '
    },
    {
        id: 100,
        store: 'S. German Mall',
        address: 'San German Shop/L.5 Road 2 & 122, Km. 174.4'
    },
    {
        id: 129,
        store: 'Palma Real',
        address: 'Plaza Palma Real 350 Road 3'
    },
    {
        id: 133,
        store: 'Mayaguez Mall',
        address: 'Ave. Hostos 975 Suite 415 '
    },
    {
        id: 136,
        store: 'Plz S. Sebastian',
        address: 'Carr. 111 km. 17.6, Bo. Guatemala '
    },
    {
        id: 124,
        store: 'Plz Isabela',
        address: 'Plaza Isabela Shop. Road 2'
    },
    {
        id: 150,
        store: 'Coamo Shopping',
        address: 'Carr. #153, km. 13.7, local #9, Bo. San Idefonso'
    },
    {
        id: 87,
        store: 'Plaza Vega Baja',
        address: 'Carretera #2 Km 69.1, Vega Baja, PR 00693'
    },
    {
        id: 170,
        store: 'Morovis Plaza Shopping Center',
        address: 'PR-137 Int. PR-617 Morovis, PR 00687'
    },
    {
        id: 173,
        store: 'Norte Shopping Center Local #5',
        address:
            'Av Baldorioty de Castro, Esq. Calle Arcoiris, San Juan, PR 00999'
    },
    {
        id: 160,
        store: 'Río Piedras Local 1B',
        address: 'Carr. Est. #3 y Ave. Barbosa, San Juan, PR 00925'
    },
    {
        id: 10,
        store: 'The Outlets at Montehiedra Local A-10',
        address: 'Avenida Los Romeros, San Juan, PR 00926'
    },
    {
        id: 23,
        store: 'Reparto Metropolitano Shopping Center Local #23',
        address: 'Av. Américo Miranda, San Juan, PR 00921'
    },
    {
        id: 81,
        store: 'Corozal Shopping #81',
        address: 'Corozal, PR 00783'
    },
    {
        id: 8,
        store: 'Av. Los Dominicos, Levittown',
        address: 'Toa Baja, PR 00949'
    },
    {
        id: 185,
        store: 'Rexville Plaza # 185',
        address: 'Bayamón, PR 00957'
    },
    {
        id: 177,
        store: 'Centro Comercial Los Filtros Carretra Local 11/13',
        address: 'Km 2.0 Minillas Ward, Bayamón, PR 00959'
    },
    {
        id: 145,
        store: 'Plaza Guaynabo, Local 1A',
        address: 'Esq. Ave. Esmeralda, Guaynabo, PR 00969'
    },
    {
        id: 200,
        store: 'Plaza Centro Mall 200',
        address: 'Av. Rafael Cordero, Caguas, PR 00726'
    },
    {
        id: 9,
        store: 'Plaza Villa Blanca Shopping Center Local 8B/13D',
        address: 'Carr. #1 Km. 34.9, Caguas, PR 00725'
    },
    {
        id: 13,
        store: 'Plaza Canovanas Local 13',
        address: 'Carr. #3 Km. 17.8, Canóvanas, PR 00729'
    },
    {
        id: 14,
        store: 'Rio Grande Town Center PR-3',
        address: 'Km 24.5 Bo, Río Grande, 00745'
    },
    {
        id: 15,
        store: 'Juncos Plaza Shopping Center Local 6A - 6B',
        address: 'Carr. PR 31 km. 24 Barrio Ceiba, Juncos, 00777'
    },
    {
        id: 16,
        store: 'Centro Comercial Pérez Hermanos',
        address: 'Carr PR 1, km. 56.2, Cayey, 00736'
    },
    {
        id: 151,
        store: 'PR2 & PR10 Calle Baramaya',
        address: 'Ponce, 00715 y 00731'
    },
    {
        id: 54,
        store: 'Rio Piedras',
        address: '108 Calle José De Diego, Rio Piedras'
    },
    {
        id: 55,
        store: 'Utuado',
        address: 'Centro Comercial Santa Rosa (Plaza Utuado)'
    },
    {
        id: 56,
        store: 'San Patricio',
        address: 'San Patricio Shopping F-10'
    },
    {
        id: 57,
        store: 'Las Piedras Shopping',
        address: 'Caribbean Shopping Center,Carretera 198 Km. 20.1  '
    },
    {
        id: 58,
        store: 'El Monto Town C',
        address: 'Store #102 ST Road PR-14'
    },
    //news
    {
        id: 59,
        store: 'Ponce',
        address: 'Santa Maria Shopping Center'
    },
    {
        id: 60,
        store: 'Aguas Buenas',
        address: '30 Rafael Lasa Street'
    },
    {
        id: 61,
        store: 'Plaza Caribe Mall',
        address: 'Plaza Caribe Mall 140 Carr.679 Local S-005'
    },
    {
        id: 62,
        store: 'Orocovis',
        address: '5 4 de Julio Street'
    },
    {
        id: 63,
        store: 'Plaza Ferran',
        address: 'Road 2 Km 1.25 Plz. Ferran Shopping'
    },
    {
        id: 64,
        store: 'Comercio',
        address: 'Calle Georgetti #67'
    },
    {
        id: 65,
        store: 'Anasco Shopping',
        address: 'La Hacienda Town Center'
    },
    {
        id: 66,
        store: 'Hatillo Town Center',
        address: 'Int. State Roads PR-2 KM.89.9  '
    },
    {
        id: 67,
        store: 'Wetern Plaza',
        address: 'PR -2 KM 180 Mayagüez'
    },
    {
        id: 68,
        store: 'Barrio Obrero',
        address: '2270 Borinquen Ave, Bo. Obrero'
    },
    {
        id: 69,
        store: 'Quebradillas',
        address: '116 San Carlos Street'
    },
    {
        id: 70,
        store: 'Canton Mall',
        address: 'Canton Mall/Local F8 & F10'
    },
    {
        id: 71,
        store: 'Monte Real Plaza',
        address: 'Loc. #20 State Road #2, KM 45.6 '
    },
    {
        id: 72,
        store: 'Cabo Rojo Plaza',
        address: 'Cabo Rojo Plaza Shopping Center'
    },
    {
        id: 73,
        store: 'Sabana Grande',
        address: '4 Angel Gregorio Martinez Street'
    },
    {
        id: 74,
        store: 'Aguadilla Mall',
        address: 'Carr. #2 Km. 126.5 Ave. Corazones Local 5'
    },
    {
        id: 75,
        store: 'San Lorenzo Shopping',
        address: 'Carr. #183 Km. 1.0'
    },
    {
        id: 76,
        store: 'Plaza del Norte',
        address: '506 Calle Truncado, Hatillo'
    },
    {
        id: 78,
        store: 'Moca',
        address: '184 Don Chemary Street'
    },
    {
        id: 79,
        store: 'Europonce',
        address: 'Road 22 Km. 227.9 Loc. 129-130'
    },
    {
        id: 80,
        store: 'Plaza Atlantico',
        address: 'Plaza Atlantico Mall Loc. 27 A Road 2 Km. 80.0'
    },
    {
        id: 81,
        store: 'Plaza Rio Hondo',
        address: 'Ave. Comerio Local 55'
    },
    {
        id: 82,
        store: 'Rincon',
        address: 'Carr. #115 K.m. 11.4'
    },
    //news
    {
        id: 83,
        store: 'Yabucoa Plaza',
        address: 'Yabucoa Plaza Shopping Center'
    },
    {
        id: 84,
        store: 'Santa Rosa Mall',
        address: 'Santa Rosa Mall, Bayamón, 00956'
    },
    {
        id: 85,
        store: 'Montellanos Shopping',
        address: 'Plaza Cayey Centro,Carr. #1 KM 55.2'
    },
    {
        id: 86,
        store: 'Villalba',
        address: '30 Munoz Rivera Street'
    },
    {
        id: 87,
        store: 'Adjuntas Plaza',
        address: 'Adjuntas Plaza Shopping Center, Local 13'
    },
    {
        id: 88,
        store: 'Carolina Shopping Court',
        address: '10000 Av. 65 de Infantería, Carolina,'
    },
    {
        id: 89,
        store: 'Bayamon Oeste',
        address:
            'Bayamon Oeste Shopping Carr. 2 km. 14.7, Bo. Hato Tejas, Local 8A'
    },
    {
        id: 90,
        store: 'Las Catalinas',
        address: '#400 Calle Betances ID space #010025 Suite. 0025'
    },
    {
        id: 91,
        store: 'Oriental Plaza',
        address: 'Carretera Estatal #3 y Carretera #908'
    },
    {
        id: 92,
        store: 'Naguabo',
        address: 'Space #3 & #4 Plaza Naguabo'
    },
    {
        id: 93,
        store: 'Plaza Aquarium',
        address: 'Carretera #165 Int. PR -159, Bo. Quebrada Cruz, '
    },
    {
        id: 94,
        store: 'Isabela',
        address: '48 Barbosa Street'
    }
]
