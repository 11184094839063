import React, { useEffect, Fragment } from 'react'
import Cookies from 'js-cookie'
import LogRocket from 'logrocket';
import { initGA } from './utils/GA'
import Notification from './components/Modals/Notification'
import Routes from './routes'

const App = () => {
    zE('messenger', 'hide');

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const referral_code = queryParameters.get('referral_code')
        const kw_source_url = Cookies.get('kw_source_url')
        const kw_referral_code = Cookies.get('kw_referral_code')

        if (!kw_source_url) {
            Cookies.set('kw_source_url', window.location.href)
        }

        if (!kw_referral_code && referral_code) {
            Cookies.set('kw_referral_code', referral_code)
        }
        LogRocket.init('fqaolv/kiwi');
        initGA()
        window.addEventListener('resize', setRootWidth)
        setRootWidth()
    }, [])

    const setRootWidth = () => {
        const root = document.getElementById('root')

        if (window.innerWidth > 480) {
            root.classList.add('desktop')
        } else {
            root.classList.remove('desktop')
        }
    }

    return (
        <Fragment>
            <Notification />
            <Routes />
        </Fragment>
    )
}

export default App
