export default (text = '', allWords = false) => {
    if (allWords) {
        return text
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
    } else {
        return text.charAt(0).toUpperCase() + text.slice(1)
    }
}
