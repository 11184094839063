import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from 'context/AuthContext'
import { UserContext } from 'context/UserContext'
import mixpanel from 'utils/mixpanel'
import PrivacyTermsModal from 'components/Modals/PrivacyTerms'
import PaymentTermsModal from 'components/Modals/PaymentTerms'

import Subtract from 'assets/subtractDynamic'

import { LogoutIcon } from '@heroicons/react/outline'
import Menu from 'templates/Menu'
import {
    Document2Icon,
    GiftIcon,
    MailSendIcon,
    RightIcon,
    SafetyCertificateIcon,
    User3Icon,
    WhatsappIcon
} from 'assets/icons_v3/fonts'

export default function Settings() {
    const navigate = useNavigate()

    const { resetState: resetAuthState, closeTicket } = useContext(AuthContext)
    const { state: userState, resetState: resetUserState } =
        useContext(UserContext)
    const [privacyTermsVisible, setPrivacyTermsVisible] = useState(false)
    const [termsConditionsVisible, setTermsConditionsVisible] = useState(false)

    const MENU_LIST = [
        [
            {
                icon: <User3Icon />,
                title: 'Información personal',
                action: function () {
                    navigate('/personal-information')
                }
            },
            {
                icon: <GiftIcon />,
                title: 'Referir a un amigo',
                action: function () {
                    mixpanel.track('Referrals Page', {
                        Type: 'Profile'
                    })
                    navigate('/referrals')
                }
            },
            {
                icon: <MailSendIcon />,
                title: 'Contáctanos por correo',
                description: 'support@kiwicredito.com',
                action: function () {
                    const mail = document.createElement('a')
                    mail.href = 'mailto:support@kiwicredito.com'
                    mail.click()
                    mail.remove()
                }
            }
            // {
            //     icon: <WhatsappIcon />,
            //     title: 'Escríbenos por Whatsapp',
            //     action: function () {
            //         const mail = document.createElement('a')
            //         mail.href = `https://wa.me/+18341547895?text=Hola Kiwi, necesito ayuda`
            //         mail.target = `_blank`
            //         mail.click()
            //         mail.remove()
            //     }
            // }
        ],
        [
            {
                icon: <SafetyCertificateIcon />,
                title: 'Política de privacidad',
                action: function () {
                    setPrivacyTermsVisible(true)
                }
            },
            {
                icon: <Document2Icon />,
                title: 'Términos y condiciones',
                action: function () {
                    setTermsConditionsVisible(true)
                }
            }
        ]
        // [
        //     {
        //         icon: <Document2Icon />,
        //         title: 'Documentos y contratos',
        //         action: function () {}
        //     }
        // ]
    ]

    const onLogout = async () => {
        closeTicket()
        resetUserState()
        resetAuthState()
    }

    return (
        <div className="w-full min-h-full flex flex-col px-6 pt-24 pb-[136px]">
            <PrivacyTermsModal
                isVisible={privacyTermsVisible}
                closeModal={() => setPrivacyTermsVisible(false)}
            />
            <PaymentTermsModal
                isVisible={termsConditionsVisible}
                closeModal={() => setTermsConditionsVisible(false)}
            />

            <div className="flex flex-col justify-center items-center text-center mb-12">
                <div className="shrink-0 grow-0 w-15 h-15 text-blue-kiwi">
                    <Subtract />
                </div>
                <h2 className="font-semibold text-xl mt-4 capitalize">
                    {userState.user.first_name} {userState.user.first_surname}
                </h2>
                <p className="text-sm">{userState.user.email}</p>
            </div>

            <div className="flex flex-col gap-14">
                {MENU_LIST.map((submenu, index) => (
                    <div className="flex flex-col gap-6" key={index}>
                        {submenu.map((link) => (
                            <div
                                key={link.title}
                                className="flex items-center cursor-pointer gap-4"
                                onClick={() => link.action.call(link)}>
                                <div className="w-10 h-10 rounded-full flex justify-center items-center text-slate-900-kiwi bg-slate-100-kiwi">
                                    {link.icon}
                                </div>

                                <div className="mr-auto">
                                    <p className="font-medium">{link.title}</p>
                                    {link.description && (
                                        <p className="text-xs text-gray-500-kiwi">
                                            {link.description}
                                        </p>
                                    )}
                                </div>

                                <RightIcon className="shrink-0 grow-0 w-6 h-6 text-gray-500-kiwi" />
                            </div>
                        ))}
                    </div>
                ))}

                <div>
                    <div className="flex items-center cursor-pointer w-max gap-4">
                        <div className="bg-gray-100 w-10 h-10 rounded-full flex justify-center items-center">
                            <LogoutIcon className="w-6 text-gray-500-kiwi" />
                        </div>
                        <p className="font-medium" onClick={onLogout}>
                            Cerrar sesión
                        </p>
                    </div>
                </div>
            </div>

            <Menu selected="profile" />
        </div>
    )
}
