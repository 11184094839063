import React, {Fragment, useEffect, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import CardReviewingSVG from 'assets/onboarding_v3/card_reviewing.svg';
import {ApprovedLoanContext} from 'context/ApprovedLoanContext';

export default function ApprovedLoanMesalveReviewing() {
    const {state: approvedState} = useContext(ApprovedLoanContext);
    const navigate = useNavigate();

    const onContinue = () => {
        navigate('/home');
    };

    return (
        <Fragment>
            <div className="flex flex-col justify-center min-h-full text-center m-auto">
                <img className="mx-auto mb-8" src={CardReviewingSVG} />

                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    Estamos verificando tu identidad
                </h1>
                <p>
                    Estamos analizando tu documento, nos pondremos en contacto
                    contigo dentro de las próximas 24 horas.
                </p>
            </div>

            <button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                onClick={onContinue}>
                Aceptar
            </button>
        </Fragment>
    );
}
