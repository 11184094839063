import React from 'react'
import formatDate from 'utils/formatDate'
import {
    RefundDollarIcon,
    BankCardIcon,
    TimeIcon,
    GiftIcon,
    CertificateIcon
} from 'assets/icons_v3/fonts'

export default function Payment({ payment }) {
    const { status, type, category, date, amount } = payment

    const isReferral = status === 'Active' && type === 'referral'
    const isNormal = status === 'Active' && type === 'normal'
    const isNSFFee = type === 'charges' && category === 'NSF_FEE'
    const isLateFee = type === 'charges' && category === 'LATE_FEE'
    const isReversed = status === 'Reversed'

    const getPaymentTitle = () => {
        if (isNSFFee) {
            return 'Cargo falta de fondos'
        }

        if (isLateFee) {
            return 'Cargo por mora'
        }

        if (isNormal) {
            return 'Pago realizado'
        }

        if (isReferral) {
            return 'Pago referidos'
        }

        if (isReversed) {
            return 'Pago reversado'
        }
    }

    const getPaymentAmount = () => {
        let displayedAmount = '$' + Number(amount).toFixed(2)
        let className = ''

        if (isNormal || isReferral) {
            displayedAmount = '-' + displayedAmount
            className = '!text-green-kiwi'
        }

        if (isReversed) {
            className = 'line-through'
        }

        return (
            <span className={`text-slate-700-kiwi ${className}`}>
                {displayedAmount}
            </span>
        )
    }

    const PaymentIcon = () => {
        if (isReferral) {
            return <GiftIcon />
        }

        if (isNormal) {
            return <CertificateIcon />
        }

        if (isNSFFee) {
            return <BankCardIcon />
        }

        if (isLateFee) {
            return <TimeIcon />
        }

        if (isReversed) {
            return <RefundDollarIcon />
        }
    }

    return (
        <div className="flex items-center">
            <div className="flex justify-center items-center shrink-0 bg-slate-100-kiwi text-regal-blue-kiwi h-10 w-10 rounded-full mr-4">
                <PaymentIcon className="w-6" />
            </div>

            <div className="grow flex flex-col w-full mr-4">
                <p className="text-md ">{getPaymentTitle()}</p>
                <p className="text-sm text-gray-500-kiwi">
                    {formatDate(date, 'MMM DD, YYYY')}
                </p>
            </div>

            <div className="font-semibold">{getPaymentAmount()}</div>
        </div>
    )
}
