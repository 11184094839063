import React, { Fragment, useEffect } from 'react'

import SuccessOnboardingSVG from 'assets/onboarding_v3/email_sent.svg'
import Button from 'components/Button'
import BackwardButton from 'components/Button/Backward'
import { useLocation, useNavigate } from 'react-router-dom'

export default function ChangeEmailSent() {
    const navigate = useNavigate()
    const location = useLocation()
    const new_email = location.state?.new_email

    useEffect(() => {
        if (!new_email) {
            onBackward()
        }
    }, [])

    const onContinue = () => {
        navigate('/personal-information')
    }

    const onBackward = () => {
        navigate('/change-email')
    }

    return (
        <Fragment>
            <BackwardButton onBackward={onBackward} className="mb-0" />
            <div className="flex flex-col justify-center text-center m-auto mb-10">
                <img className="mx-auto mb-8" src={SuccessOnboardingSVG} />
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    Revisa tu correo electrónico
                </h1>
                <p>
                    Te hemos enviado un enlace de verificación al correo{' '}
                    <span className="font-semibold">{new_email}</span> para
                    puedas completar el proceso de cambio de tu correo
                    electrónico
                </p>
            </div>

            <Button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto"
                onClick={onContinue}>
                Aceptar
            </Button>
        </Fragment>
    )
}
