import { STATES_AVAILABLES } from 'constants'
import FL from './FL'
import PR from './PR'

const findStateKey = (state) => {
    return (
        Object.keys(STATES_AVAILABLES).find(
            (key) => STATES_AVAILABLES[key] === state
        ) ?? ''
    )
}

export default function (state) {
    const currentKey = findStateKey(state)
    const stateName =
        STATES_AVAILABLES[currentKey] ?? STATES_AVAILABLES.puertoRico

    const stateConstants = {
        [STATES_AVAILABLES.puertoRico]: PR,
        [STATES_AVAILABLES.florida]: FL
    }

    return stateConstants[stateName]
}
