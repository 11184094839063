import http from 'utils/http'

export const updateLoanPurpose = async ({ loanId, purpose }) => {
    const response = await http.put(`/loans/${loanId}/purpose`, {
        purpose
    })

    return response
}

export const getPayments = async (loanId) => {
    const response = await http.get(`/loan/${loanId}/payments`)

    return response
}

export const getTransaction = async (loanId) => {
    const response = await http.get(`/loan/${loanId}/transaction`)

    return response
}

export const getScheduledPayments = async ({ loanpro_tmp_loan_id }) => {
    const response = await http.get(
        `/loans/scheduled-payments/${loanpro_tmp_loan_id}`
    )

    return response
}

export const getContractPersonalPayment = async ({
    user_id,
    loanpro_tmp_loan_id,
    ip
}) => {
    const response = await http.post(
        `/loans/contract/personal-payments`,
        {
            user_id,
            loanpro_tmp_loan_id,
            user_payload: {
                ip
            }
        },
        {
            responseType: 'blob'
        }
    )

    return response
}

export const getContractAchAuthorization = async ({
    user_id,
    loanpro_tmp_loan_id,
    ip
}) => {
    const response = await http.post(
        `/loans/contract/ach-authorization`,
        {
            user_id,
            loanpro_tmp_loan_id,
            user_payload: {
                ip
            }
        },
        {
            responseType: 'blob'
        }
    )

    return response
}

export const getContractCreditCard = async ({
    user_id,
    loanpro_tmp_loan_id,
    ip,
    card_number,
    expiration_year,
    expiration_month,
    cardholder_name
}) => {
    const response = await http.post(
        `/loans/contract/card-authorization`,
        {
            user_id,
            loanpro_tmp_loan_id,
            user_payload: {
                ip,
                card_number,
                expiration_year,
                expiration_month,
                cardholder_name
            }
        },
        {
            responseType: 'blob'
        }
    )

    return response
}

export const getContractCreditCardCreditBuilder = async ({
    user_id,
    loanpro_tmp_loan_id,
    ip,
    card_number,
    expiration_year,
    expiration_month,
    cardholder_name
}) => {
    const response = await http.post(
        `/loans/contract/cb-card-authorization`,
        {
            user_id,
            loanpro_tmp_loan_id,
            user_payload: {
                ip,
                card_number,
                expiration_year,
                expiration_month,
                cardholder_name
            }
        },
        {
            responseType: 'blob'
        }
    )

    return response
}

export const getContractPaymentsCreditBuilder = async ({
    user_id,
    loanpro_tmp_loan_id,
    ip
}) => {
    const response = await http.post(
        `/loans/contract/cb-payments`,
        {
            user_id,
            loanpro_tmp_loan_id,
            user_payload: {
                ip
            }
        },
        {
            responseType: 'blob'
        }
    )

    return response
}

export const getContractAchAuthorizationCreditBuilder = async ({
    user_id,
    loanpro_tmp_loan_id,
    ip
}) => {
    const response = await http.post(
        `/loans/contract/cb-ach-authorization`,
        {
            user_id,
            loanpro_tmp_loan_id,
            user_payload: {
                ip
            }
        },
        {
            responseType: 'blob'
        }
    )

    return response
}

const makeLoanPayment = async ({
    loanpro_loan_id,
    loanpro_user_id,
    method_type,
    method_id,
    amount
}) => {
    const response = await http.post(`/loans/${loanpro_loan_id}/payments`, {
        loanpro_loan_id,
        loanpro_user_id,
        method_type,
        method_id,
        amount
    })

    return response
}

const createLoan = async ({
    user_id,
    amount,
    loanpro_tmp_loan_id,
    type,
    ip,
    lang,
    disbursement_method,
    disbursement_value,
    repayment_method,
    repayment_value
}) => {
    const response = await http.post('/loans', {
        user_id,
        amount,
        loanpro_tmp_loan_id,
        type,
        ip,
        lang,
        disbursement_method,
        disbursement_value,
        repayment_method,
        repayment_value
    })

    return response
}

const checkContractActive = async ({ tempLoanId }) => {
    const response = await http.get('/loans/contract/check-loan', {
        params: {
            loanpro_tmp_loan_id: tempLoanId
        }
    })

    return response
}

const createTempLoanCreditBuilder = async ({
    user_id,
    product_id,
    price_id,
    preferred_payment_date
}) => {
    const response = await http.post('/loans/cb/quick-quote', {
        user_id,
        product_id,
        price_id,
        preferred_payment_date
    })

    return response
}

const createTempLoan = async ({ user_id, amount, termMonths, paymentDay }) => {
    const response = await http.post('/loans/quick-quote', {
        user_id,
        amount,
        term_months: termMonths,
        preferred_payment_date: paymentDay
    })

    return response
}

const moveLoanPaymentDate = async ({
    loanId,
    newDate,
    oldDate,
    amount,
    source
}) => {
    const response = await http.put(`/loans/${loanId}/payment`, {
        amount,
        new_date: newDate,
        old_date: oldDate,
        source
    })

    return response
}

const getContractByType = async (type) => {
    const response = await http.get(`/contracts?type=${type}`)
    return response
}

const getContractDocument = async ({
    path,
    lang,
    ip,
    loanpro_tmp_loan_id,
    disbursement_method,
    repayment_method,
    product_id
}) => {
    const response = await http.post(
        `/${path}`,
        {
            loanpro_tmp_loan_id,
            user_payload: {
                ip,
                lang
            },
            disbursement_method,
            repayment_method,
            product_id
        },
        {
            responseType: 'blob'
        }
    )

    return response
}

/**
 * Autopay endpoints
 */
export const getACHContractAutopay = async ({
    loanpro_tmp_loan_id,
    ip,
    payment_profile_id
}) => {
    const response = await http.post(
        '/loans/contract/ach-authorization-reactivate',
        {
            loanpro_tmp_loan_id,
            user_payload: {
                ip,
                payment_profile_id
            }
        },
        {
            responseType: 'blob'
        }
    )

    return response
}

export const signACHContractAutopay = async ({
    loanpro_tmp_loan_id,
    ip,
    payment_profile_id
}) => {
    const response = await http.post('/loan/reactive-autopay', {
        loanpro_tmp_loan_id,
        user_payload: {
            ip,
            payment_profile_id
        }
    })

    return response
}

export default {
    updateLoanPurpose,
    getPayments,
    getTransaction,
    getScheduledPayments,
    makeLoanPayment,
    getContractCreditCard,
    getContractPersonalPayment,
    getContractAchAuthorization,
    getContractCreditCardCreditBuilder,
    getContractPaymentsCreditBuilder,
    getContractAchAuthorizationCreditBuilder,
    createLoan,
    checkContractActive,
    createTempLoanCreditBuilder,
    createTempLoan,
    moveLoanPaymentDate,
    getContractByType,
    getContractDocument,

    getACHContractAutopay,
    signACHContractAutopay
}
