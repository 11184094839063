import React, { useState, createContext } from 'react'
export const AutopayContext = createContext()

export const AutopayProvider = ({ children }) => {
    const initialState = {
        paymentProfile: {
            id: '',
            last4: '',
            bankName: '',
            completed: false
        }
    }

    const [state, setState] = useState(initialState)

    const resetState = () => {
        setState(initialState)
    }

    const providerValues = {
        initialState,
        state,
        setState,
        resetState
    }

    return (
        <AutopayContext.Provider value={providerValues}>
            {children}
        </AutopayContext.Provider>
    )
}
