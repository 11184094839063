export default [
    {
        name: 'Adjuntas'
    },
    {
        name: 'Aguada'
    },
    {
        name: 'Aguadilla'
    },
    {
        name: 'Aguas Buenas'
    },
    {
        name: 'Aibonito'
    },
    {
        name: 'Arecibo'
    },
    {
        name: 'Arroyo'
    },
    {
        name: 'Anasco'
    },
    {
        name: 'Barceloneta'
    },
    {
        name: 'Barranquitas'
    },
    {
        name: 'Bayamon'
    },
    {
        name: 'Cabo Rojo'
    },
    {
        name: 'Caguas'
    },
    {
        name: 'Camuy'
    },
    {
        name: 'Canovanas'
    },
    {
        name: 'Carolina'
    },
    {
        name: 'Catano'
    },
    {
        name: 'Cayey'
    },
    {
        name: 'Ceiba'
    },
    {
        name: 'Ciales'
    },
    {
        name: 'Cidra'
    },
    {
        name: 'Coamo'
    },
    {
        name: 'Comerio'
    },
    {
        name: 'Corozal'
    },
    {
        name: 'Culebra'
    },
    {
        name: 'Dorado'
    },
    {
        name: 'Fajardo'
    },
    {
        name: 'Florida'
    },
    {
        name: 'Guayama'
    },
    {
        name: 'Guayanilla'
    },
    {
        name: 'Guaynabo'
    },
    {
        name: 'Gurabo'
    },
    {
        name: 'Guanica'
    },
    {
        name: 'Hatillo'
    },
    {
        name: 'Hormigueros'
    },
    {
        name: 'Humacao'
    },
    {
        name: 'Isabela'
    },
    {
        name: 'Jayuya'
    },
    {
        name: 'Juana Diaz'
    },
    {
        name: 'Juncos'
    },
    {
        name: 'Lajas'
    },
    {
        name: 'Lares'
    },
    {
        name: 'Las Marias'
    },
    {
        name: 'Las Piedras'
    },
    {
        name: 'Loíza'
    },
    {
        name: 'Luquillo'
    },
    {
        name: 'Manati'
    },
    {
        name: 'Maricao'
    },
    {
        name: 'Maunabo'
    },
    {
        name: 'Mayaguez'
    },
    {
        name: 'Moca'
    },
    {
        name: 'Morovis'
    },
    {
        name: 'Naguabo'
    },
    {
        name: 'Naranjito'
    },
    {
        name: 'Orocovis'
    },
    {
        name: 'Patillas'
    },
    {
        name: 'Penuelas'
    },
    {
        name: 'Ponce'
    },
    {
        name: 'Quebradillas'
    },
    {
        name: 'Rincon'
    },
    {
        name: 'Rio Grande'
    },
    {
        name: 'Sabana Grande'
    },
    {
        name: 'Salinas'
    },
    {
        name: 'San German'
    },
    {
        name: 'San Juan'
    },
    {
        name: 'San Lorenzo'
    },
    {
        name: 'San Sebastian'
    },
    {
        name: 'Santa Isabel'
    },
    {
        name: 'Toa Alta'
    },
    {
        name: 'Toa Baja'
    },
    {
        name: 'Trujillo Alto'
    },
    {
        name: 'Utuado'
    },
    {
        name: 'Vega Alta'
    },
    {
        name: 'Vega Baja'
    },
    {
        name: 'Vieques'
    },
    {
        name: 'Villalba'
    },
    {
        name: 'Yabucoa'
    },
    {
        name: 'Yauco'
    }
]
