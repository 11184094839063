import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import BaseModal from './Modal'

const ElectronicTermsModal = forwardRef(({}, ref) => {
    const BaseModalRef = useRef()

    useImperativeHandle(ref, () => ({
        ...BaseModalRef.current
    }))

    return (
        <BaseModal
            title="Consentimiento Comunicación Electrónica 15 U.S.C S700"
            ref={BaseModalRef}>
            <div className="flex flex-col gap-6">
                <p>
                    <strong>Introducción.</strong> Está solicitando servicios
                    del acreedor identificado anteriormente ("nosotros", "nos",
                    "nuestro", "Kiwi Financial Inc." o "Kiwi"). Kiwi ofrece
                    diversos servicios de crédito al consumo y servicios
                    relacionados con el crédito. Para proporcionar estos
                    servicios, necesitamos su consentimiento para usar y aceptar
                    firmas, registros y divulgaciones electrónicas
                    ("Consentimiento Electrónico"). Este Consentimiento
                    Electrónico le informa sobre sus derechos al recibir o dar
                    divulgaciones, avisos e información electrónicos, y
                    comunicaciones. Al hacer clic en "Firmar contrato” o
                    "Continuar" en nuestro sitio web o comunicaciones
                    electrónicas, al enviarnos un correo electrónico o un
                    mensaje de texto, o al hacer clic en otros enlaces que
                    acepten nuestros términos, usted acepta los términos de este
                    Consentimiento Electrónico. Asimismo, al hacerlo da su
                    consentimiento para realizar transacciones electrónicamente
                    y da su consentimiento para que Kiwi utilice firmas,
                    registros, avisos y divulgaciones electrónicas
                    (colectivamente, "Divulgaciones"). Usted reconoce que las
                    Divulgaciones pueden incluir avisos en virtud de las leyes
                    federales o estatales, u otra información.
                </p>

                <p>
                    <strong>
                        Opción Para Registros Físicos o No Electrónicos.
                    </strong>{' '}
                    Puede solicitar una copia impresa de cualquier Divulgación
                    proporcionada electrónicamente por escrito o enviándonos un
                    correo electrónico a legal@kiwicredito.com con el asunto
                    “Una copia impresa”. Proporcionaremos acceso razonable a
                    copias impresas sin cargo. Conservaremos todas las
                    Divulgaciones según lo exija la ley.
                </p>

                <p>
                    <strong>Alcance del Consentimiento.</strong> Este
                    Consentimiento Electrónico se aplica a todas las
                    Divulgaciones. Se aplica a nuestras transacciones e
                    interacciones con usted, incluidas aquellas realizadas desde
                    cualquier computadora, dispositivo electrónico o móvil,
                    incluidos teléfonos, teléfonos inteligentes, máquinas de fax
                    y tabletas. Al utilizar este Consentimiento Electrónico,
                    podemos, a nuestra discreción, procesar su información,
                    interactuar con usted en forma electrónica, almacenar
                    Divulgaciones electrónicamente y enviarle cualquier aviso,
                    incluidas las Divulgaciones, electrónicamente.
                </p>

                <p>
                    <strong>
                        Consentimiento Para Hacer Negocios por Vía Electrónica.
                    </strong>{' '}
                    Antes de decidirse a hacer negocios electrónicamente con
                    nosotros, debe considerar si tiene las capacidades de
                    hardware y software requeridas que se describen a
                    continuación.
                </p>

                <p>
                    <strong>Requisitos de Hardware y Software.</strong> Para
                    acceder y conservar las Divulgaciones electrónicamente,
                    deberá utilizar el software y el hardware informáticos
                    descritos en este párrafo. Su navegador debe ser compatible
                    con el protocolo de cifrado de seguridad Secure Sockets
                    Layer (SSL). Debe utilizar un navegador web actual y de uso
                    común, como Chrome, Safari o Firefox. También necesitará una
                    impresora conectada para imprimir las Divulgaciones. No
                    proporcionamos servicios de Internet. Debe tener su propio
                    proveedor de servicios de Internet. Es posible que también
                    necesite administración y almacenamiento de documentos para
                    Adobe PDF y Microsoft Word para algunas divulgaciones. Si
                    estos requisitos cambian mientras tiene una relación activa
                    con nosotros y el cambio crea un riesgo material de que sea
                    posible que no pueda recibir Divulgaciones electrónicamente,
                    se lo notificaremos. Es posible que necesite su propio
                    Dispositivo de Almacenamiento USB protegido, un Servicio
                    Seguro Basado en la Nube o un dispositivo de almacenamiento
                    a largo plazo para conservar una copia de las Divulgaciones.
                    Necesitará una dirección de correo electrónico válida y
                    acceso seguro al correo electrónico. Usted nos declara que
                    sostiene todo lo anterior y que confiamos en dicha
                    declaración para prestarle servicios electrónicamente.
                </p>

                <p>
                    <strong>Revocación del Consentimiento.</strong> Puede
                    revocar este Consentimiento Electrónico sin cargo. Si revoca
                    este Consentimiento Electrónico antes de recibir nuestros
                    servicios, esto puede impedirle recibir algunos de ellos.
                    Para revocar este Consentimiento Electrónico, debe ponerse
                    en contacto con nosotros como se describe a continuación. Si
                    revoca este Consentimiento Electrónico, el retiro no
                    afectará la eficacia legal, la validez y la aplicabilidad de
                    las Divulgaciones electrónicas anteriores.
                </p>

                <p>
                    <strong>Contáctenos.</strong> Para solicitar copias impresas
                    de las Divulgaciones, hacer preguntas sobre hardware o
                    software, revocar su consentimiento o cambiar su información
                    de contacto, envíenos una carta a 151 Calle de San Francisco
                    Ste 200 PMB 1123, San Juan, PR 00901-1607, llámenos al
                    787-490-2004 o envíenos un correo electrónico a
                    legal@kiwicredito.com. Usted se compromete a notificarnos
                    cualquier nueva información sobre usted con, al menos, 5
                    días de antelación. Si no nos ha notificado un cambio
                    oportunamente, acepta que podemos usar su información además
                    de este Consentimiento, como su información de contacto.
                </p>

                <p>
                    <strong>
                        Consentimiento por Correo Electrónico y Teléfono
                        Celular.
                    </strong>{' '}
                    Usted da su consentimiento para que le enviemos mensajes de
                    texto y correos electrónicos con información, incluidas
                    firmas electrónicas, divulgaciones y registros relacionados
                    con su transacción o en relación con nosotros.
                </p>

                <p>
                    <strong>
                        Su Capacidad para Acceder a las Divulgaciones.
                    </strong>{' '}
                    Usted acepta que puede acceder a las Divulgaciones en los
                    formatos descritos. Una vez que dé su consentimiento, puede
                    iniciar sesión en el sitio web www.kiwicredito.com para
                    acceder a las Divulgaciones.
                </p>

                <p>
                    <strong>Registros Transferibles.</strong> A menos que se
                    disponga expresamente lo contrario en un acuerdo posterior
                    con nosotros, cualquier acuerdo que firme con nosotros no
                    pretende ser un "instrumento negociable", según se define en
                    el Código Comercial Uniforme vigente, y no se rige por el
                    Artículo 3 del mencionado código. Nuestros acuerdos con
                    usted son "registros transferibles" según los alcances y
                    definiciones de la ley vigente relacionada con las
                    transacciones electrónicas. Si un tribunal de justicia
                    interpreta finalmente que un acuerdo con nosotros no es un
                    "registro transferible" según la ley vigente, entonces se
                    aplicarán a este Contrato las disposiciones sobre
                    instrumentos representativos de una obligación monetaria o
                    garantía del Artículo 9 del Código Comercial Uniforme
                    vigente. La copia electrónica del acuerdo en el sistema de
                    gestión de documentos que utilizamos para almacenar el
                    acuerdo será la única copia autorizada. El titular del
                    contrato (tal como se identifica en el sistema de gestión de
                    documentos utilizado para almacenar el contrato) puede ceder
                    y transferir el registro del contrato a un Cesionario, y el
                    Cesionario será el nuevo titular, de conformidad con las
                    disposiciones de registro transferible de la ley vigente o
                    las disposiciones sobre instrumentos representativos de una
                    obligación monetaria o garantía del artículo 9 del Código
                    Comercial Uniforme vigente.
                </p>

                <p>
                    <strong>Ley Aplicable.</strong> La ley de Florida rige su
                    Contrato con nosotros, excepto que la Ley Federal de
                    Arbitraje rige la Cláusula de Renuncia a Juicio por Jurado y
                    Arbitraje.
                </p>

                <p>
                    <strong>Consentimiento.</strong> Con su firma a
                    continuación, incluso haciendo clic en "Firmar contrato” o
                    "Continuar" u otros enlaces, usted acepta estos términos.
                    Usted acepta que ha leído esta información. Usted da su
                    consentimiento para las firmas electrónicas, la divulgación,
                    los registros y las transacciones electrónicas.
                </p>

                <p>
                    <strong>
                        Acceso a Registros Electrónicos en Formato Electrónico.
                    </strong>
                    Usted reconoce que está accediendo a registros electrónicos
                    en el formato electrónico correspondiente.
                </p>

                <p>
                    <strong>
                        Acceso a Un Dispositivo e Internet Para Revisar las
                        Divulgaciones.
                    </strong>{' '}
                    Usted reconoce que puede utilizar su propio dispositivo y
                    servicio de internet.
                </p>

                <p>
                    <strong>
                        Capacidad Para Recibir Divulgaciones por Correo
                        Electrónico y Mensajes de Texto.
                    </strong>{' '}
                    Al proporcionar su dirección de correo electrónico, teléfono
                    celular o ambos, usted acepta recibir divulgaciones de
                    nuestra parte. También está demostrando su capacidad para
                    acceder a la información que enviamos utilizando los
                    formatos de archivo divulgados en los requisitos de hardware
                    y software. Usted acepta que tiene la capacidad de abrir
                    cada uno de estos formatos.
                </p>

                <p>
                    <strong>
                        Aviso Sobre Divulgaciones Importantes Disponibles por
                        Correo Electrónico y Mensaje de Texto.
                    </strong>{' '}
                    Podemos enviarle correos electrónicos y entregarle
                    información importante o poner esa información importante a
                    su disposición en un lugar seguro y alertarlo sobre su
                    disponibilidad. Si le enviamos un mensaje indicando que una
                    divulgación importante está disponible para usted, usted
                    acepta que enviarle dicho mensaje constituye la entrega de
                    esa divulgación.
                </p>

                <p>
                    <strong>
                        Al Confirmar el Consentimiento Electrónicamente, Está
                        Confirmando que Puede Acceder a la Información.
                    </strong>{' '}
                    Al aceptar en forma electrónica a continuación, confirma que
                    puede acceder a las divulgaciones electrónicas en línea.
                </p>

                <p>
                    <strong>
                        No Está Obligado a Realizar Transacciones Electrónicas.
                    </strong>{' '}
                    No está obligado a aceptar divulgaciones electrónicas u
                    otros registros. Si no puede acceder a las divulgaciones por
                    vía electrónica o si no está de acuerdo con estos términos,
                    comuníquese con nosotros a legal@kiwicredito.com para
                    analizar alternativas.
                </p>

                <p>
                    Lea atentamente esta información e imprima o guarde una
                    copia.
                </p>
            </div>
        </BaseModal>
    )
})

export default ElectronicTermsModal
