import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowRightUpIcon } from 'assets/icons_v3/fonts'
import TachometerSVG from 'assets/icons_v3/tachometer.svg'

export default function HomeStatusCreditMonitoring() {
    const navigate = useNavigate()

    const onSelect = () => {
        navigate('/credit-monitoring')
    }

    return (
        <div
            className="rounded-xl bg-regal-blue-kiwi text-white p-4 cursor-pointer"
            onClick={onSelect}>
            <div className="flex items-start justify-between gap-4 mb-4">
                <div className="w-10 h-10 bg-yellow-50-kiwi rounded-full flex justify-center items-center">
                    <img src={TachometerSVG} className="w-6" />
                </div>
                <div className="font-medium shrink-0 grow-0 px-3 py-1 bg-white rounded-2xl text-xs text-regal-blue-kiwi flex items-center gap-1">
                    Ver puntaje <ArrowRightUpIcon className="w-4 h-4" />
                </div>
            </div>

            <div>
                <div className="text-sm font-semibold mb-2">
                    Credit Monitoring
                </div>
                <div className="text-xs opacity-80 mb-2">
                    Conoce tu puntaje crediticio y accede a un informe de
                    crédito detallado.
                </div>
            </div>
        </div>
    )
}
