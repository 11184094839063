import React from 'react'

import BaseModal from '../BaseModal'
import whatsappIcon from 'assets/icons/whatsapp.svg'
import mobileIcon from 'assets/icons/mobile.svg'
import { MailIcon, MobileIcon, WhatsappIcon } from 'assets/icons_v3/fonts'

export default function ResendCodeModal({
    cellphone,
    isVisible,
    closeModal,
    resendCode
}) {
    return (
        <BaseModal
            title="Verifica tu número de teléfono"
            description={`Te enviaremos un mensaje al número ${cellphone}`}
            isVisible={isVisible}
            closeModal={closeModal}
            resendCode={resendCode}>
            <div
                className={`flex items-center mb-4 cursor-pointer text-blue-kiwi`}
                onClick={() => resendCode('sms')}>
                <MailIcon className="w-6 flex justify-center" />
                <p className="ml-4">Enviar código por SMS</p>
            </div>

            <div
                className={`flex items-center mb-4 cursor-pointer text-blue-kiwi`}
                onClick={() => resendCode('whatsapp')}>
                <WhatsappIcon className="w-6 flex justify-center" />
                <p className="ml-4">Enviar código por Whatsapp</p>
            </div>

            <div
                className={`flex items-center cursor-pointer text-blue-kiwi`}
                onClick={() => resendCode('phone')}>
                <MobileIcon className="w-6 flex justify-center" />
                <p className="ml-4">Editar número de teléfono</p>
            </div>
        </BaseModal>
    )
}
