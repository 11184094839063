import React from 'react'
import { Route } from 'react-router-dom'

/**
 * Context Providers
 */
import { RequestLoanProvider } from 'context/RequestLoanContext'

/**
 * Middlewwares
 */
import {
    RequestLoanSurveyMiddleware,
    RequestLoanMiddleware
} from './middlewares/RequestLoanMiddleware'
import { BlackListMiddleware } from './middlewares/AuthMiddleware'

/**
 * Routes
 */
import RequestLoan from 'views/mobile/RequestLoan'
import RequestLoanAddress from 'views/mobile/RequestLoan/Address'
import RequestLoanSSN from 'views/mobile/RequestLoan/SSN'
import RequestLoanVeritec from 'views/mobile/RequestLoan/Veritec'
import RequestLoanIncome from 'views/mobile/RequestLoan/Income'
import RequestLoanEmployment from 'views/mobile/RequestLoan/Employment'
import RequestLoanMilitary from 'views/mobile/RequestLoan/Military'
import RequestLoanBankruptcy from 'views/mobile/RequestLoan/Bankruptcy'
import RequestLoanCurrentLoan from 'views/mobile/RequestLoan/CurrentLoan'
import RequestLoanEmployer from 'views/mobile/RequestLoan/Employer'
import RequestLoanSeniority from 'views/mobile/RequestLoan/Seniority'
import RequestLoanResidence from 'views/mobile/RequestLoan/Residence'
import RequestLoanValidation from 'views/mobile/RequestLoan/Validation'
import RequestLoanPlaid from 'views/mobile/RequestLoan/Plaid'
import RequestLoanBankStatements from 'views/mobile/RequestLoan/BankStatements'
import RequestLoanBankStatementsDocuments from 'views/mobile/RequestLoan/BankStatements/Documents'
import RequestLoanBankStatementsOnboarding from 'views/mobile/RequestLoan/BankStatements/Onboarding'
import RequestLoanReviewing from 'views/mobile/RequestLoan/Reviewing'
import RequestLoanReviewingMLA from 'views/mobile/RequestLoan/ReviewingMLA'

const RequestLoanRoutes = (
    <Route element={<BlackListMiddleware />}>
        <Route element={<RequestLoanMiddleware />}>
            <Route
                exact
                path="request-loan"
                element={
                    <RequestLoanProvider>
                        <RequestLoan />
                    </RequestLoanProvider>
                }>
                <Route element={<RequestLoanSurveyMiddleware />}>
                    <Route
                        exact
                        path="address"
                        element={<RequestLoanAddress />}
                    />
                    <Route exact path="ssn" element={<RequestLoanSSN />} />
                    <Route
                        exact
                        path="veritec"
                        element={<RequestLoanVeritec />}
                    />
                    <Route
                        exact
                        path="income"
                        element={<RequestLoanIncome />}
                    />
                    <Route
                        exact
                        path="military"
                        element={<RequestLoanMilitary />}
                    />
                    <Route
                        exact
                        path="bankruptcy"
                        element={<RequestLoanBankruptcy />}
                    />
                    <Route
                        exact
                        path="current-loan"
                        element={<RequestLoanCurrentLoan />}
                    />
                    <Route
                        exact
                        path="employment"
                        element={<RequestLoanEmployment />}
                    />
                    <Route
                        exact
                        path="employer"
                        element={<RequestLoanEmployer />}
                    />
                    <Route
                        exact
                        path="seniority"
                        element={<RequestLoanSeniority />}
                    />
                    <Route
                        exact
                        path="residence"
                        element={<RequestLoanResidence />}
                    />
                </Route>

                <Route exact path="plaid" element={<RequestLoanPlaid />} />

                <Route
                    exact
                    path="validation"
                    element={<RequestLoanValidation />}
                />

                <Route
                    exact
                    path="bank-statements"
                    element={<RequestLoanBankStatements />}>
                    <Route
                        exact
                        path=""
                        element={<RequestLoanBankStatementsOnboarding />}
                    />

                    <Route
                        exact
                        path="documents"
                        element={<RequestLoanBankStatementsDocuments />}
                    />
                </Route>

                <Route
                    exact
                    path="reviewing"
                    element={<RequestLoanReviewing />}
                />
                <Route
                    exact
                    path="reviewing-mla"
                    element={<RequestLoanReviewingMLA />}
                />
            </Route>
        </Route>
    </Route>
)

export default RequestLoanRoutes
