import React, { useState, Fragment, useRef } from 'react'
import dayjs from 'dayjs'
import { useLocation } from 'react-router-dom'
import { Calendar2Icon, RightIcon } from 'assets/icons_v3/fonts'

import Button from 'components/Button'
import FirstPaymentDayModal from 'components/Modals/FirstPaymentDay'
import Advisement from 'components/Advisement'
import capitalizeText from 'utils/capitalizeText'

export default function PaymentDay({ onSuccess, selectedPaymentDate }) {
    const location = useLocation()
    const CalendarRef = useRef()

    const [selectedDate, setSelectedDate] = useState('')
    const [error, setError] = useState('')
    const [isLastDaySelected, setIsLastDaySelected] = useState(false)

    const onSelectDate = async (date, lastDayChecked) => {
        setError('')
        setIsLastDaySelected(lastDayChecked)
        setSelectedDate(dayjs(date))
    }

    const onChangeDate = () => {
        onSuccess(selectedDate.format('YYYY-MM-DD'))
    }

    return (
        <Fragment>
            <FirstPaymentDayModal
                ref={CalendarRef}
                onSelectDate={onSelectDate}
                selectedPaymentDate={selectedPaymentDate}
            />

            <div className="mb-auto">
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    Escoge el primer día de pago de tu préstamo
                </h1>
                <div className="flex flex-col gap-4 mb-8">
                    Tu primer pago será procesado en la fecha seleccionada.
                    Consulta el contrato del préstamo para ver el calendario de
                    pagos
                </div>

                <div>
                    <div className="text-sm text-gray-600-kiwi mb-2">
                        Selecciona el primer día de pago
                    </div>

                    <div
                        className={`border rounded-xl p-4 flex items-center gap-4 cursor-pointer ${
                            error
                                ? 'border-red-kiwi bg-red-kiwi/5 text-red-kiwi'
                                : 'border-gray-200-kiwi  bg-gray-100-kiwi'
                        }`}
                        onClick={() => CalendarRef.current.openModal()}>
                        <Calendar2Icon
                            className={`${
                                error ? 'text-red-kiwi' : 'text-blue-kiwi'
                            }`}
                        />
                        <div>
                            <div>
                                {selectedDate
                                    ? capitalizeText(
                                          selectedDate.format('MMMM DD, YYYY')
                                      )
                                    : 'Seleccionar fecha'}
                            </div>
                            {isLastDaySelected && (
                                <div className="text-xs text-gray-500-kiwi">
                                    Último día del mes
                                </div>
                            )}
                        </div>
                        <RightIcon
                            className={`ml-auto ${
                                error ? 'text-red-kiwi' : 'text-slate-300-kiwi'
                            }`}
                        />
                    </div>

                    <div className="text-red-kiwi inline-block text-xs w-full mt-2">
                        {error}
                    </div>
                </div>
            </div>

            {location.state?.showWarning && (
                <Advisement icon="shield_check">
                    <div className="font-bold">Detalles de préstamo</div>
                    Es necesario vovler a calcular tu fecha de pago y los
                    detalles de tu préstamo para recalcular tu contrato
                </Advisement>
            )}

            <Button
                disabled={!selectedDate}
                onClick={onChangeDate}
                className={`rounded-xl p-3 mt-10 ${
                    selectedDate
                        ? 'bg-blue-kiwi text-white'
                        : 'bg-gray-200-kiwi text-gray-500-kiwi'
                }`}>
                Continuar
            </Button>
        </Fragment>
    )
}
