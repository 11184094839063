import dayjs from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'
import formatDate from 'utils/formatDate'
import moneyFormat from 'utils/moneyFormat'
import STATE_CONSTANTS from 'constants/states'
import { UserContext } from 'context/UserContext'
import { LoanAPI } from 'api'
import { Calendar2Icon } from 'assets/icons_v3/fonts'
import ToggleInput from 'components/ToggleInput'

export default function RepaymentTermsTemplate({ loan, loading = false }) {
    const { state: userState } = useContext(UserContext)

    const [schedulesPayments, setSchedulesPayments] = useState([])
    const {
        id,
        totalLoanAmount,
        monthlyPayment,
        totalAmountIncludingFee,
        firstPaymentDate,
        interestRate,
        apr,
        term
    } = loan

    useEffect(() => {
        if (id) onGetSchedulesPayments()
    }, [id])

    const onGetSchedulesPayments = async () => {
        const { data } = await LoanAPI.getScheduledPayments({
            loanpro_tmp_loan_id: id
        })

        setSchedulesPayments(data.data)
    }

    const paymentFrequency = STATE_CONSTANTS(userState.user.state).approved
        .paymentFrequency

    const formatPaymentDate = (date) => {
        return dayjs(date).add(1, 'day').format('MMMM DD, YYYY')
    }

    const transformDate = (date) => {
        const [month, day, year] = date.split('/')
        return formatDate(year + '/' + day + '/' + month, 'MMMM DD, YYYY')
    }

    return (
        <div className="text-sm relative mb-10 flex flex-col items-center">
            <div className="text-gray-500-kiwi text-xs mb-1">
                Monto de préstamo
            </div>

            <div className="text-dark-kiwi font-semibold mb-6 text-6xl">
                ${moneyFormat(totalLoanAmount)}
            </div>

            <div className="w-full flex flex-col gap-4 mb-6">
                <div className="flex justify-between gap-2">
                    <div>Pago {paymentFrequency}</div>
                    <b className="text-right">
                        {loading ? '...' : `$${monthlyPayment?.toFixed(2)}`}
                    </b>
                </div>
                <div className="flex justify-between gap-2">
                    <div>Monto total a repagar</div>
                    <b className="text-right">
                        {loading
                            ? '...'
                            : `$${moneyFormat(totalAmountIncludingFee)}`}
                    </b>
                </div>
                <div className="flex justify-between gap-2">
                    <div>Pago total de interés</div>
                    <b className="text-right">
                        {loading
                            ? '...'
                            : `$${moneyFormat(
                                (
                                    totalAmountIncludingFee -
                                    Number(totalLoanAmount)
                                )?.toFixed(2)
                            )}`}
                    </b>
                </div>
                <div className="flex justify-between gap-2">
                    <div>Primera fecha de pago</div>
                    <b className="text-right">
                        {loading ? '...' : formatPaymentDate(firstPaymentDate)}
                    </b>
                </div>
                <div className="flex justify-between gap-2">
                    <div>Tasa de interés</div>
                    <b className="text-right">
                        {loading ? '...' : `${interestRate?.toFixed(2)}%`}
                    </b>
                </div>
                <div className="flex justify-between gap-2">
                    <div
                        className="text-blue-kiwi underline cursor-pointer"
                        onClick={() => APRModalRef.current.openModal()}>
                        APR
                    </div>
                    <b className="text-right">
                        {loading ? '...' : `${apr?.toFixed(2)}%`}
                    </b>
                </div>
            </div>

            <ToggleInput
                IconHeader={Calendar2Icon}
                title={`Calendario de ${term} pagos`}
                saveState={false}>
                <div className="flex flex-col gap-4">
                    {schedulesPayments?.map((payment) => (
                        <div
                            className="flex items-center justify-between capitalize"
                            key={payment.date}>
                            <div>{transformDate(payment.date)}</div>
                            <div className="font-semibold">
                                ${Number(payment.chargeAmount).toFixed(2)}
                            </div>
                        </div>
                    ))}
                </div>
            </ToggleInput>
        </div>
    )
}
