import dayjs from 'dayjs'
import React, { useState, useEffect, Fragment, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { CreditBuilderContext } from 'context/CreditBuilderContext'
import mixpanel from 'utils/mixpanel'

import SSNTemplate from 'templates/SSN'
import ProgressBar from 'components/ProgressBar'
import Advidsment from 'components/Advisement'

import ConsentTermsModal from 'components/Modals/ConsentTerms'
import PatriotaTermsModal from 'components/Modals/PatriotaTerms'

export default function CreditBuilderSSN() {
    const navigate = useNavigate()

    const { state: creditState, saveState: saveCreditState } =
        useContext(CreditBuilderContext)

    const [consentTermsVisible, setConsentTermsVisible] = useState(false)
    const [patriotaTermsVisible, setPatriotaTermsVisible] = useState(false)

    useEffect(() => {
        if (creditState.address.completed === false) {
            return navigate('/credit-builder/address')
        }
    }, [])

    const onContinue = async () => {
        /**
         * Agregar la fecha del día de hoy como día de pago...
         */
        const paymentDay = dayjs().date()

        /**
         * TODO: Revisar porque no se está actualizando ssn completed: true en esta llamada....
         */
        saveCreditState({
            ...creditState,
            paymentDay: {
                value: paymentDay,
                completed: true
            },
            ssn: {
                completed: true
            }
        })

        mixpanel.track('Select Loan Payment Date - CB', {
            'Loan Payment Date': paymentDay
        })

        navigate('/credit-builder/repayment-terms')
    }

    const onBackward = () => {
        navigate('/credit-builder/address')
    }

    const WarningSlot = (
        <Advidsment className="mt-10 items-center" icon="shield_check">
            No hacemos indagación de crédito por lo que{' '}
            <strong>no afectará</strong> tu puntaje.
        </Advidsment>
    )

    const TermsSlot = (
        <p>
            Al hacer clic en “Continuar”, confirmo que he leído y estoy de
            acuerdo con el{' '}
            <span
                className="text-blue-kiwi underline cursor-pointer"
                onClick={() => setConsentTermsVisible(true)}>
                Consentimiento de verificación de identidad
            </span>
            . También la verificación de identidad nos garantizar el
            cumplimiento de requisitos de la{' '}
            <span
                className="text-blue-kiwi underline cursor-pointer"
                onClick={() => setPatriotaTermsVisible(true)}>
                Ley PATRIOTA de los EE.UU.
            </span>
        </p>
    )

    return (
        <Fragment>
            <ProgressBar progress="3/8" onBackward={onBackward} />

            <SSNTemplate
                context={CreditBuilderContext}
                onContinue={onContinue}
                warningSlot={WarningSlot}
                termsSlot={TermsSlot}
            />

            <ConsentTermsModal
                isVisible={consentTermsVisible}
                closeModal={() => setConsentTermsVisible(false)}
            />

            <PatriotaTermsModal
                isVisible={patriotaTermsVisible}
                closeModal={() => setPatriotaTermsVisible(false)}
            />
        </Fragment>
    )
}
