import http from 'utils/httpGptApi';

const createToken = async payload => {
    const response = await http.post('/webhook/zendesk/create-token', payload);

    return response;
};

const updateUserZendesk = async (payload) => {
    const response = await http.post('/webhook/zendesk/update-user', payload);

    return response;
};

export default {
    createToken,
    updateUserZendesk,
};
