import React, { useState, Fragment, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import backIcon from 'assets/icons/back.svg'
import cardIcon from 'assets/icons/card.svg'
import bankIcon from 'assets/icons/bank.svg'
import rightArrowIcon from 'assets/icons/right_arrow.svg'
import plusIcon from 'assets/icons/plus.svg'
import { UserContext } from 'context/UserContext'

export default function CreditBuilderActivePaymentMethodList() {
    const navigate = useNavigate()
    const { getPaymentProfiles } = useContext(UserContext)
    const [loading, setLoading] = useState(true)
    const [paymentMethods, setPaymentMethods] = useState([])

    useEffect(() => {
        getPaymentMethods()
    }, [])

    const getPaymentMethods = async () => {
        const {
            data: {
                data: { results: methods }
            }
        } = await getPaymentProfiles()

        setPaymentMethods(methods)
        setLoading(false)
    }

    const onBackward = () => {
        navigate('/credit-builder/active')
    }

    const onShowDetails = (method) => {
        navigate('/credit-builder/active/payment-method/details', {
            state: {
                ...method
            }
        })
    }

    const onAddCreditCard = () => {
        navigate('/credit-builder/active/payment-method/card')
    }

    const getMethodTitle = (item) => {
        if (item.checkingAccountId) {
            return `Cuenta **** ${String(
                item.CheckingAccount.accountNumber
            ).slice(-4)}`
        } else {
            return `Tarjeta **** ${String(item.CreditCard.cardNumber).slice(
                -4
            )}`
        }
    }

    return (
        <Fragment>
            <img
                className="ml-2 mb-12 cursor-pointer"
                width="8"
                src={backIcon}
                onClick={onBackward}
            />

            <div className="mb-10">
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    Métodos de pago
                </h1>
                <p className="mb-10">
                    Los días de pago se ajustarán al próximo día laboral en caso
                    de ser fin de semana o día feriado
                </p>

                <div className="flex flex-col gap-6 mb-10">
                    {loading === false ? (
                        <Fragment>
                            {paymentMethods.map((item, index) => (
                                <div
                                    key={index}
                                    className="flex items-center rounded-lg shadow-lg shadow-dark-kiwi/5 p-4 cursor-pointer"
                                    onClick={() => {
                                        onShowDetails(item)
                                    }}>
                                    <div className="h-10 w-10 bg-blue-kiwi/10 mr-4 grow-0 shrink-0 rounded-full flex">
                                        <img
                                            className="m-auto"
                                            alt="icon_card"
                                            src={
                                                item.checkingAccountId
                                                    ? bankIcon
                                                    : cardIcon
                                            }
                                        />
                                    </div>
                                    <div className="mr-auto">
                                        <div className="leading-4 font-semibold">
                                            {getMethodTitle(item)}
                                        </div>
                                        {item.isPrimary === 1 ? (
                                            <p className="text-xs text-gray-500-kiwi mt-1">
                                                Método de pago principal
                                            </p>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <img src={rightArrowIcon} />
                                </div>
                            ))}
                        </Fragment>
                    ) : (
                        <Fragment>
                            {[...Array(3)].map((item, index) => (
                                <div
                                    key={index}
                                    className="flex items-center rounded-lg shadow-lg shadow-dark-kiwi/5 p-4">
                                    <div className="h-10 w-10 bg-gray-50-kiwi mr-4 grow-0 shrink-0 rounded-full"></div>
                                    <div className="w-2/3">
                                        <div className="bg-gray-50-kiwi mb-2 h-3 w-full rounded-lg"></div>
                                        <div className="bg-gray-50-kiwi h-2 w-2/3 rounded-lg"></div>
                                    </div>
                                </div>
                            ))}
                        </Fragment>
                    )}
                </div>

                <button
                    className="w-full bg-blue-kiwi/10 text-blue-kiwi rounded-xl p-4 flex items-center justify-center border border-dashed border-blue-kiwi"
                    onClick={onAddCreditCard}>
                    <img className="mr-4" src={plusIcon} />
                    Agregar nueva tarjeta
                </button>
            </div>

            <p className="text-xs text-gray-500-kiwi mt-auto">
                Si quieres cambiar tu tarjeta puedes comunicarte con nosotros al
                correo <strong>support@kiwicredito.com</strong>
            </p>
        </Fragment>
    )
}
