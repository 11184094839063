import React, { useState, useEffect, useContext, Fragment } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import ResetApprovedTracking from 'components/Modals/ResetApprovedTracking'
import { ApprovedLoanContext } from 'context/ApprovedLoanContext'
import dateDifference from 'utils/dateDifference'
import { UserContext } from 'context/UserContext'
import { TRACKING_EXPIRATION_DAYS } from 'constants'

export default function ApprovedLoan() {
    const location = useLocation()
    const navigate = useNavigate()
    const { state, resetState } = useContext(ApprovedLoanContext)
    const { setState: setUserState } = useContext(UserContext)
    const { repaymentTerms } = state
    const [openModalResetApprovedTracking, setOpenModalResetApprovedTracking] =
        useState(false)

    useEffect(() => {
        if (['/approved-loan/amount'].includes(location.pathname) === false) {
            checkTempLoanCreatedDate()
        }
    }, [])

    const checkTempLoanCreatedDate = () => {
        if (repaymentTerms.completed && repaymentTerms.created_at) {
            const diffDays = dateDifference(repaymentTerms.created_at)
            if (diffDays >= TRACKING_EXPIRATION_DAYS) {
                setOpenModalResetApprovedTracking(true)
            }
        }
    }

    const openModalResetTracking = () => {
        // Se reinicia el tracking a null cuando se reinicia el state de approved context
        resetState()
        setUserState((prev) => ({
            ...prev,
            products: {
                ...prev.products,
                personal_loan: {
                    ...prev.products.personal_loan,
                    tracking: null
                }
            }
        }))
        setOpenModalResetApprovedTracking(!openModalResetApprovedTracking)
        navigate('/approved-loan/amount')
    }

    const redirectHome = () => {
        navigate('/home')
    }

    return (
        <div className="w-full min-h-full flex flex-col px-6 py-10">
            <Outlet />
            <ResetApprovedTracking
                isVisible={openModalResetApprovedTracking}
                resetTracking={openModalResetTracking}
                redirectHome={redirectHome}
            />
        </div>
    )
}
