import {UserContext} from 'context/UserContext';
import React, {useContext, useEffect} from 'react';
import {Outlet} from 'react-router-dom';

export default function ApprovedLoanRepaymentOptions() {
    const {state: userState} = useContext(UserContext);

    useEffect(() => {
        dataLayer.push({
            event: 'new_application',
            eventCategory: 'loan_process',
            eventAction: 'loan_process_repayment_options',
            dl_user_id: userState.user.id,
        });
    }, []);

    return <Outlet />;
}
