import http from 'utils/http'

const fingerprintEvaluate = async () => {
    if (process.env.REACT_APP_FINGERPRINT_EVALUATE === 'enable') {
        const response = await http.post('/fingerprint/evaluate')
        return response
    }
    return true
}

/** 
 * NOTA:
 * Cuidado al usar este método...
 * No esta trayendo toda la información a comparación de /users/firebase
 * que es el que se usa al inicio de la aplicación...
 */
const getUser = async (id) => {
    const response = await http.get(`/users/${id}`)

    return response
}

const update = async (userId, body) => {
    const response = await http.post(`/users/${userId}`, body)
    return response
}

const mlaCheckSurvey = async () => {
    const response = await http.post(`/check_mla_survey`)
    return response
}

const checkEligibilityEvaluateVeritec = async (user_id) => {
    const response = await http.get(`/check-eligibility/${user_id}/evaluate`)
    return response
}

const creditReport = async () => {
    const response = await http.post('/credit-reports')
    return response
}

const verifyAddress = async (data) => {
    const response = await http.post('/verify-address', data)
    return response
}

const changeEmail = async (new_email) => {
    const response = await http.post('/change-email', {
        new_email
    })

    return response
}

const verifyChangeEmailToken = async (token) => {
    const response = await http.post('/change-email/verify', {
        token
    })

    return response
}

export default {
    getUser,
    update,
    checkEligibilityEvaluateVeritec,
    creditReport,
    fingerprintEvaluate,
    mlaCheckSurvey,
    verifyAddress,
    changeEmail,
    verifyChangeEmailToken
}
