import React from 'react'
import kiwiLogoWhite from 'assets/logo_white.svg'

const SplashComponent = () => {
    return (
        <div
            className={`relative w-full min-h-full flex flex-col p-10 bg-blue-kiwi`}>
            <img
                id="logo_kiwi"
                width="230"
                className="m-auto"
                src={kiwiLogoWhite}
            />
            <div className="absolute bottom-20 left-[calc(50%-24px)] loader_solo"></div>
        </div>
    )
}

export default SplashComponent
