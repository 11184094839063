import React, { useContext } from 'react'
import { Outlet, Navigate, useLocation } from 'react-router-dom'
import { AuthContext } from 'context/AuthContext'
import { STATES_AVAILABLES } from 'constants'

export const UnauthMiddleware = () => {
    const { state } = useContext(AuthContext)

    return state.logged ? <Navigate to="/home" /> : <Outlet />
}

export const LocationAllowed = () => {
    /**
     * Evitar que se cicle redireccionando a la misma
     * ruta de not available, por eso el isAvailableRoute...
     */
    const { pathname } = useLocation()
    const notAvailableRoute = '/location/not-available'
    const isAvailableRoute = pathname === notAvailableRoute

    const location = localStorage.getItem('selectedLocation')
    const isLocationAllowed = [
        null,
        STATES_AVAILABLES.puertoRico,
        STATES_AVAILABLES.florida
    ].includes(location)

    return isLocationAllowed || isAvailableRoute ? (
        <Outlet />
    ) : (
        <Navigate to={notAvailableRoute} />
    )
}
