import React, { useEffect, useContext, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

import MagnifyingSVG from 'assets/onboarding_v3/magnifying.svg'
import { UserContext } from 'context/UserContext'
import { ProductsAPI } from 'api'
import BackwardButton from 'components/Button/Backward'

export default function RequestLoanReviewingMLA() {
    const navigate = useNavigate()
    const { state: userState, setState: setUserState } = useContext(UserContext)

    useEffect(() => {
        getProductsLastStatus()
    }, [])

    const getProductsLastStatus = async () => {
        const {
            data: { data: personal_loan }
        } = await ProductsAPI.getPersonalLoan()

        setTimeout(() => {
            setUserState({
                ...userState,
                products: {
                    ...userState.products,
                    personal_loan
                }
            })
        }, 3000)
    }

    const onContinue = () => {
        navigate('/home')
    }

    return (
        <Fragment>
            <BackwardButton onBackward={onContinue} />

            <div className="flex flex-col justify-center h-full text-center m-auto">
                <img className="mx-auto mb-8" src={MagnifyingSVG} />
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    Revisando solicitud
                </h1>
                <p>
                    Estamos analizando tu solicitud de préstamo, recibirás
                    respuesta en segundos.
                </p>
            </div>

            <button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                onClick={onContinue}>
                Continuar
            </button>
        </Fragment>
    )
}
