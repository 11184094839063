import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import BaseModal from './Modal'

const SMSTermsModal = forwardRef(({}, ref) => {
    const BaseModalRef = useRef()

    useImperativeHandle(ref, () => ({
        ...BaseModalRef.current
    }))

    return (
        <BaseModal
            title="TCPA, Servicio de Marcación Automática, Llamadas de Marketing, Consentimiento y Privacidad de Mensajes de Texto SMS"
            ref={BaseModalRef}>
            <div className="flex flex-col gap-6">
                <p>
                    Sin perjuicio de cualquier elección actual o anterior para
                    aceptar o no recibir llamadas o mensajes SMS (incluidos los
                    mensajes de texto) de la Compañía, nuestros agentes,
                    representantes, afiliados, cesionarios, sucesores, abogados,
                    compañías de cobro, proveedores de servicios o cualquier
                    persona que llame o le contacte en nuestro nombre
                    (colectivamente, las "Partes de la Compañía" o
                    individualmente "Parte de la Compañía"), como consideración
                    buena y valiosa por la Membresía y los Servicios que se le
                    brindan, Usted acepta expresamente recibir llamadas y
                    mensajes (incluidos mensajes de texto) a través de un
                    sistema de marcación telefónica automatizada (“ATDS”) de las
                    Partes de la Compañía en cualquier número de teléfono
                    asociado con Su cuenta que haya proporcionado o pueda
                    proporcionar en el futuro (incluido cualquier número de
                    celular o móvil) con avisos de servicio de cuenta; con
                    avisos de cobro, mensajes de marketing, promocionales o de
                    marketing mixto, utilizando mensajes de voz
                    pregrabados/artificiales a través de un ATDS. También otorga
                    permiso a las Partes de la Compañía para comunicarle dicha
                    información mediante notificación automática o correo
                    electrónico. Los mensajes automatizados pueden ser
                    reproducidos por una máquina automáticamente cuando se
                    contesta un teléfono, ya sea que usted o alguien más
                    responda. En caso de que un agente o representante llame, él
                    o ella puede dejar un mensaje en Su contestador automático,
                    correo de voz o enviar uno por mensaje de texto. No puede
                    revocar su consentimiento para recibir notificaciones de
                    cobro o servicio de cuenta si tiene una obligación pendiente
                    con las Partes de la Compañía o una de sus afiliadas o si
                    está utilizando los servicios de una de las Partes de la
                    Compañía o de sus afiliadas. Sin embargo, puede comunicarse
                    con la Compañía en cualquier momento para revocar su
                    consentimiento para recibir mensajes promocionales o de
                    marketing enviando un correo electrónico a
                    support@kiwicredito.com con el asunto "Detener mensajes de
                    marketing" e incluyendo su nombre, dirección postal, número
                    de teléfono y dirección de correo electrónico e indicando
                    que no desea recibir tales llamadas, mensajes de texto o
                    correos electrónicos de; llamando a un representante de
                    atención al cliente al 787-490-2004 y proporcionando el
                    número de teléfono y/o correo electrónico del que no desea
                    recibir dichas llamadas, mensajes de texto o correos
                    electrónicos.
                </p>

                <p>
                    Usted certifica, garantiza y declara que los números de
                    teléfono y/o correos electrónicos que ha proporcionado a las
                    Partes de la Compañía son Sus teléfonos y correos
                    electrónicos personales y acepta que las Partes de la
                    Compañía no serán responsables ante Usted por ningún
                    reclamo, daño, tarifa, inconveniente o molestia, o pérdida
                    de privacidad en relación con dichas llamadas, mensajes de
                    texto o correos electrónicos. Usted comprende que cualquier
                    persona con acceso a Su teléfono o correo electrónico puede
                    escuchar o leer los mensajes, sin perjuicio de que las
                    Partes de la Compañía se comuniquen únicamente con Usted. Si
                    cambia un número de teléfono o correo electrónico de las
                    Partes Prestamistas, o si usted deja de ser el propietario,
                    suscriptor o usuario principal de dicho número de teléfono o
                    correo electrónico, acepta notificar inmediatamente a las
                    Partes de la Compañía tales hechos para que actualicen sus
                    registros. Su proveedor de telefonía celular o móvil,
                    proveedor de Internet o proveedor de correo electrónico le
                    cobrará según el tipo de plan que tenga.
                </p>

                <p>
                    Usted comprende y acepta que, sujeto a la ley aplicable, las
                    Partes de la Compañía pueden monitorear y/o grabar
                    cualquiera de Sus conversaciones telefónicas con cualquiera
                    de sus representantes para capacitación, control de calidad,
                    evidencia y cualquier otro propósito. Sin embargo, las
                    Partes de la Compañía no tienen ninguna obligación de
                    monitorear, retener o reproducir dichas grabaciones, a menos
                    que lo exija la ley aplicable.
                </p>
            </div>
        </BaseModal>
    )
})

export default SMSTermsModal
