import React, { useState, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import backIcon from './../../../assets/icons/back.svg'
import badgeImage from './../../../assets/no-service.svg'
export default function Maintenance() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const onBackward = () => {
        navigate('/home')
    }
    return (
        <div className="px-6 pt-10 h-full overflow-hidden">
            <div className="flex items-center">
                <img
                    className="ml-2 cursor-pointer"
                    width="8"
                    src={backIcon}
                    onClick={onBackward}
                />
            </div>

            <div className="flex flex-col justify-center min-h-full text-center m-auto">
                <img className="mx-auto mb-8" width="218" src={badgeImage} />
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    ¡Estamos en mantenimiento!
                </h1>
                <p>
                    En este momento estamos haciendo mejoras a nuestra app para
                    brindarte una mejor experiencia. Te notificaremos tan pronto
                    el servicio sea restablecido.
                </p>
                <p className="mt-5">¡Gracias por tu comprensión!</p>
            </div>
        </div>
    )
}
