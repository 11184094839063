export default size => {
    if (typeof size === 'string') {
        size = Number(size)
    }

    let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let i = 0

    while (size >= 1024) {
        size /= 1024
        ++i
    }

    return size.toFixed(1) + '' + units[i]
}