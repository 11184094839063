import http from 'utils/http'

export const generateToken = async () => {
    const response = await http.post('/plaid/token')

    return response
}

export const publicTokenExchange = async ({ public_token }) => {
    const response = await http.post('/plaid/public-token-exchange', {
        public_token
    })

    return response
}

export default { generateToken, publicTokenExchange }
