import React from 'react'
import { Route } from 'react-router-dom'
/**
 * Context Providers
 */
import { CreditLimitProvider } from 'context/CreditLimitContext'

/**
 * Middlewares
 */
import {
    CreditLimitApplication,
    CreditLimitOpen,
    CreditLimitShow
} from './middlewares/CreditLimitMiddleware'

/**
 * Routes
 */
import CreditLimit from 'views/mobile/CreditLimit'
import CreditLimitSurvey from 'views/mobile/CreditLimit/Survey'
import CreditLimitBankStatements from 'views/mobile/CreditLimit/BankStatements'

import CreditLimitReviewing from 'views/mobile/CreditLimit/Reviewing'
import CreditLimitApproved from 'views/mobile/CreditLimit/Approved'

const CreditLimitRoutes = (
    <Route
        element={
            <CreditLimitProvider>
                <CreditLimitApplication />
            </CreditLimitProvider>
        }>
        <Route exact path="credit-limit" element={<CreditLimit />}>
            <Route element={<CreditLimitShow />}>
                <Route exact path="survey" element={<CreditLimitSurvey />} />
                <Route
                    exact
                    path="bank-statements"
                    element={<CreditLimitBankStatements />}
                />
            </Route>

            <Route element={<CreditLimitOpen />}>
                <Route
                    exact
                    path="reviewing"
                    element={<CreditLimitReviewing />}
                />
                <Route
                    exact
                    path="approved"
                    element={<CreditLimitApproved />}
                />
            </Route>
        </Route>
    </Route>
)

export default CreditLimitRoutes
