import React from 'react'
import { Route } from 'react-router-dom'

import { CreditLimitProvider } from 'context/CreditLimitContext'
import { AutopayProvider } from 'context/AutopayContext'

import BlackList from 'views/mobile/BlackList'
import Home from 'views/mobile/Home'
import Products from 'views/mobile/Products'
import RejectedLoan from 'views/mobile/RejectedLoan'
import ShareReferralCode from 'views/mobile/ShareReferralCode'
import Settings from 'views/mobile/Settings'
import PersonalInformation from 'views/mobile/PersonalInformation'
import DuplicatedBankAccount from 'views/mobile/DuplicatedBankAccount'
import LiveChat from 'views/mobile/LiveChat'
import MoneysOnTheWay from 'views/mobile/MoneysOnTheWay'
import MesalveCode from 'views/mobile/MesalveCode'
import ChangeEmail from 'views/mobile/ChangeEmail'
import ChangeEmailForm from 'views/mobile/ChangeEmail/Form'
import ChangeEmailSent from 'views/mobile/ChangeEmail/Sent'
import ChangeEmailSuccess from 'views/mobile/ChangeEmail/Success'

import Autopay from 'views/mobile/Autopay'
import AutopayOnboarding from 'views/mobile/Autopay/Onboarding'
import AutopayPaymentProfile from 'views/mobile/Autopay/PaymentProfile'
import AutopayContract from 'views/mobile/Autopay/Contract'
import AutopayPlaid from 'views/mobile/Autopay/Plaid'
import AutopaySuccess from 'views/mobile/Autopay/Success'
import AutopayError from 'views/mobile/Autopay/Error'
import AutopayReviewing from 'views/mobile/Autopay/Reviewing'
import AutopayPlaidPaymentProfile from 'views/mobile/Autopay/PlaidPaymentProfile'
import { AutopayMiddleware } from './middlewares/AutopayMiddleware'

const AuthRoutes = (
    <Route>
        <Route exact path="home" element={<Home />} />
        <Route exact path="products" element={<Products />} />
        <Route exact path="black-list" element={<BlackList />} />
        <Route exact path="referrals" element={<ShareReferralCode />} />
        <Route exact path="rejected-loan" element={<RejectedLoan />} />
        <Route
            exact
            path="settings"
            element={
                <CreditLimitProvider>
                    <Settings />
                </CreditLimitProvider>
            }
        />
        <Route
            exact
            path="personal-information"
            element={<PersonalInformation />}
        />
        <Route
            exact
            path="duplicated-bank-account"
            element={<DuplicatedBankAccount />}
        />
        <Route exact path="live-chat" element={<LiveChat />} />

        <Route exact path="change-email" element={<ChangeEmail />}>
            <Route exact path="" element={<ChangeEmailForm />} />
            <Route exact path="sent" element={<ChangeEmailSent />} />
            <Route
                exact
                path="success/:tokenNewEmail"
                element={<ChangeEmailSuccess />}
            />
        </Route>
        <Route exact path="moneys-on-the-way" element={<MoneysOnTheWay />} />
        <Route exact path="mesalve-code" element={<MesalveCode />} />
        <Route element={<AutopayMiddleware />}>
            <Route
                exact
                path="autopay"
                element={
                    <AutopayProvider>
                        <Autopay />
                    </AutopayProvider>
                }>
                <Route exact path="" element={<AutopayOnboarding />} />
                <Route
                    exact
                    path="payment-profile"
                    element={<AutopayPaymentProfile />}
                />
                <Route exact path="contract" element={<AutopayContract />} />
                <Route exact path="plaid" element={<AutopayPlaid />} />
                <Route exact path="success" element={<AutopaySuccess />} />
                <Route exact path="error" element={<AutopayError />} />
                <Route exact path="reviewing" element={<AutopayReviewing />} />
                <Route
                    exact
                    path="plaid/payment-profile"
                    element={<AutopayPlaidPaymentProfile />}
                />
            </Route>
        </Route>
    </Route>
)

export default AuthRoutes
