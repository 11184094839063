import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from 'context/UserContext'
import ChartLineSVG from 'assets/icons_v3/chart_line.svg'

import { ChevronLeftIcon, ArrowRightIcon } from 'assets/icons_v3/fonts'
import InformationCircleSVG from 'assets/onboarding_v3/information_circle.svg'
import Button from 'components/Button'
import RejectedLoanReasonModal from 'components/Modals/RejectedLoanReason'
import dayjs from 'dayjs'
import { STATES_AVAILABLES } from 'constants'

export default function RejectedLoan() {
    const navigate = useNavigate()

    const [rejectedReason, setRejectedReason] = useState({})
    const [showRejectedLoanReasonModal, setShowRejectedLoanModal] =
        useState(false)

    const { state: userState } = useContext(UserContext)
    const {
        user,
        products: {
            personal_loan: { status, rejected_application }
        }
    } = userState

    useEffect(() => {
        if (status !== 'reject') {
            return navigate('/home')
        }

        getRejectedReason()
    }, [])

    const rejection_type = [
        {
            title: 'Sin ingresos (menos de $750.00)',
            value: 'no_income',
            reasons: [
                {
                    title: 'Incapacidad para verificar ingresos',
                    value: 'unable_to_verify_income',
                    description:
                        'Hemos revisado tu solicitud y lamentamos informarte que en este momento no podemos aprobar el préstamo debido a la dificultad para verificar sus ingresos a través de los documentos proporcionados.'
                },
                {
                    title: 'Empleo temporal o irregular',
                    value: 'temporary_or_irregular_employment',
                    description:
                        'Hemos revisado tu solicitud y lamentamos informarte que en este momento no podemos aprobar el préstamo debido a variaciones e inestabilidades en tus ingresos mensuales.'
                },
                {
                    title: 'Incapacidad para verificar empleo',
                    value: 'unable_to_verify_employment',
                    description:
                        'Hemos revisado tu solicitud y lamentamos informarte que en este momento no podemos aprobar el préstamo debido al no poder verificar tu fuente de ingresos a través del empleador.'
                },
                {
                    title: 'Duración del empleo',
                    value: 'length_of_employment',
                    description:
                        'Hemos revisado tu solicitud y lamentamos informarte que en este momento no podemos aprobar el préstamo debido a la ausencia de estabilidad laboral, un criterio esencial que requerimos.'
                }
            ]
        },
        {
            title: 'Saldos mínimos negativos',
            value: 'negative_minimum_balances',
            reasons: [
                {
                    title: 'Plaid',
                    value: 'plaid',
                    description:
                        'Hemos revisado tu solicitud y lamentamos informarte que en este momento no podemos aprobar el préstamo debido a un historial de balances negativos en la cuenta asociada a tu solicitud.'
                },
                {
                    title: 'Extractos bancarios',
                    value: 'bank_statements',
                    description:
                        'Hemos revisado tu solicitud y lamentamos informarte que en este momento no podemos aprobar el préstamo debido a un historial de balances negativos en los estados de cuenta proporcionados.'
                }
            ]
        },
        {
            title: 'Obligaciones mensuales',
            value: 'monthly_obligations',
            reasons: [
                {
                    title: 'Obligaciones excesivas en relación con los ingresos',
                    value: 'excessive_obligations_relative_to_income',
                    description:
                        'Hemos revisado tu solicitud y lamentamos informarte que en este momento no podemos aprobar el préstamo debido a sus ingresos actuales y las obligaciones financieras existentes ya que podría generar un desequilibrio financiero.'
                },
                {
                    title: 'Ingresos insuficientes para la cantidad de crédito solicitada',
                    value: 'income_insufficient_for_amount_of_credit_requested',
                    description:
                        'Hemos revisado tu solicitud y lamentamos informarte que en este momento no podemos aprobar el préstamo debido a tus ingresos actuales son insuficientes en comparación con el monto de crédito solicitado.'
                }
            ]
        },
        {
            title: 'Tarifas por fondos insuficientes',
            value: 'nsf_fees',
            reasons: [
                {
                    title: 'Plaid',
                    value: 'plaid',
                    description:
                        'Hemos revisado tu solicitud y lamentamos informarte que en este momento no podemos aprobar el préstamo debido al historial de transacciones devueltas por fondos insuficientes en la cuenta bancaria asociada a tu solicitud.'
                },
                {
                    title: 'Extractos bancarios',
                    value: 'bank_statements',
                    description:
                        'Hemos revisado tu solicitud y lamentamos informarte que en este momento no podemos aprobar el préstamo debido al historial de transacciones devueltas por fondos insuficientes en los estados de cuenta proporcionados.'
                }
            ]
        },
        {
            title: 'Historial crediticio negativo',
            value: 'bad_credit_history',
            reasons: [
                {
                    title: 'Obligaciones crediticias pasadas o presentes morosas',
                    value: 'delinquent_past_or_present_credit_obligations',
                    description:
                        'Hemos revisado tu solicitud y lamentamos informarte que en este momento no podemos aprobar el préstamo debido a la identificación de productos financieros en su historial crediticio con pagos atrasados en relación con otras entidades financieras.'
                },
                {
                    title: 'Experiencia crediticia limitada',
                    value: 'limited_credit_experience',
                    description:
                        'Hemos revisado tu solicitud y lamentamos informarte que en este momento no podemos aprobar el préstamo debido a que carece de la variedad de productos financieros necesarios para cumplir con nuestros criterios de aprobación.'
                },
                {
                    title: 'Quiebra',
                    value: 'bankruptcy',
                    description:
                        'Hemos revisado tu solicitud y lamentamos informarte que en este momento no podemos aprobar el préstamo debido a que se identificó la existencia de un informe de bancarrota activa o reciente.'
                },
                {
                    title: 'Sin historial crediticio',
                    value: 'no_credit_file',
                    description:
                        'Hemos revisado tu solicitud y lamentamos informarte que en este momento no podemos aprobar el préstamo debido a que carece de antecedentes o se encuentra sin información.'
                }
            ]
        },
        {
            title: 'Tarifas de sobregiro',
            value: 'overdraft_fees',
            reasons: [
                {
                    title: 'Plaid',
                    value: 'plaid',
                    description:
                        'Hemos revisado tu solicitud y lamentamos informarte que en este momento no podemos aprobar el préstamo debido por sobregiro en la cuenta bancaria asociada a tu solicitud.'
                },
                {
                    title: 'Extractos bancarios',
                    value: 'bank_statements',
                    description:
                        'Hemos revisado tu solicitud y lamentamos informarte que en este momento no podemos aprobar el préstamo debido por sobregiro en los estados de cuenta proporcionados.'
                }
            ]
        },
        {
            title: 'Estado de cuenta bancaria sospechoso/manipulado',
            value: 'suspicious/manipulated_bank_statements',
            description:
                'Hemos revisado tu solicitud y lamentamos informarte que en este momento no podemos aprobar el préstamo debido a discrepancias en los documentos presentados como la información bancaria.'
        },

        /** DQ Reasons */
        {
            title: 'Puntaje de crédito insuficiente',
            value: 'dq_credit_score',
            description:
                'Hemos revisado tu solicitud y lamentamos informarte que en este momento no podemos aprobar el préstamo debido a que podría generar un desequilibrio financiero.'
        },
        {
            title: 'Problemas con la dirección',
            value: 'dq_address_status',
            description:
                'No hemos podido verificar tu estado de residencia. Por favor, envíanos los siguientes documentos a nuestro correo <strong>support@kiwicredito.com</strong>: </br> 1. Estado bancario más reciente </br> 2. Foto de tu identificación de Puerto Rico'
        },
        {
            title: 'Problemas con operaciones comerciales',
            value: 'dq_revolving_trade',
            description:
                'Hemos revisado tu solicitud y lamentamos informarte que en este momento no podemos aprobar el préstamo debido a la existencia de tarjetas de crédito con saldos que exceden el límite establecido.'
        },
        {
            title: 'Montos pendientes en cuentas abiertas',
            value: 'dq_open_amount_past_due',
            description:
                'Hemos revisado tu solicitud y lamentamos informarte que en este momento no podemos aprobar el préstamo debido a pagos atrasados en relación con otras entidades financieras.'
        },
        {
            title: 'Montos pendientes por pagar',
            value: 'dq_amount_past_due',
            description:
                'Hemos revisado tu solicitud y lamentamos informarte que en este momento no podemos aprobar el préstamo debido a un historial de balances negativos en los estados de cuenta proporcionados.'
        },
        {
            title: 'Fallo en la verificación de la edad',
            value: 'dq_age_check_failed',
            description:
                'Hemos revisado tu solicitud y lamentamos informarte que en este momento no podemos aprobar el préstamo, ya que la edad legal para solicitarlo es de 21 años. En caso de ser emancipado, por favor, envíanos un documento legal que confirme esta información a nuestro correo support@kiwicredito.com para evaluar tu solicitud.'
        },
        {
            title: 'Estado militar no verificado',
            value: 'dq_military_status',
            description:
                'Lamentablemente, su solicitud de préstamo ha sido rechazada debido a que no pudimos verificar su estado militar. Esto puede ser necesario para determinar su elegibilidad para ciertos beneficios o programas especiales. Le recomendamos proporcionar la información necesaria y verificar su estado militar antes de volver a solicitar.'
        },
        {
            title: 'Estado militar no verificado',
            value: 'no_address_in_pr_dq',
            description:
                'Necesitamos validar su estado de residencia para poder continuar con su solicitud. Por favor, envíenos los siguientes dos (2) documentos: </br> 1. Estado bancario más reciente </br> 2. Foto de tu identificación de Puerto Rico'
        },
        {
            title: 'Veritec Validation',
            value: 'veritec_validation',
            description:
                'Hemos revisado tu solicitud y lamentamos informarte que en este momento no podemos aprobar el préstamo debido a posibles prestamos activos en relación con otras entidades financieras.'
        },

        /** Default Reason */
        {
            title: 'Rechazo de la solicitud',
            value: 'default',
            description:
                'Lamentablemente, hemos revisado su solicitud de préstamo y no podemos aprobarla en este momento. Después de una cuidadosa evaluación de su situación financiera, hemos determinado que no cumplimos con nuestros criterios de aprobación en este momento.'
        }
    ]

    const getRejectedReason = () => {
        const { reason, sub_reason } = rejected_application

        let rejectedReason = rejection_type.find((r) => r.value === reason)
        let rejectedDefaultReason = rejection_type.find(
            (r) => r.value === 'default'
        )

        const currentRejectedReason = rejectedReason ?? rejectedDefaultReason
        const rejectedSubreason = currentRejectedReason?.reasons?.find(
            (r) => r.value === sub_reason
        )

        delete currentRejectedReason.reasons

        setRejectedReason({
            ...currentRejectedReason,
            subreason: rejectedSubreason
        })
    }

    const onContinue = () => {
        navigate('/credit-builder')
    }

    const onBackward = () => {
        navigate('/home')
    }

    const onShowReason = () => {
        toggleRejectedLoanReasonModal()
    }

    const toggleRejectedLoanReasonModal = () => {
        setShowRejectedLoanModal(!showRejectedLoanReasonModal)
    }

    return (
        <div className="w-full min-h-full flex flex-col px-6 py-10">
            <ChevronLeftIcon
                className="cursor-pointer w-2 mb-12"
                onClick={onBackward}
            />

            <div className="m-auto flex flex-col justify-center h-full text-center">
                <img className="mx-auto mb-8" src={InformationCircleSVG} />
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    Tu solicitud no ha sido aprobada
                </h1>
                <p>
                    Enviamos a tu correo electrónico{' '}
                    <strong>{userState.user.email}</strong> los detalles de tu
                    solicitud. Puedes solicitar nuevamente a partir de{' '}
                    <strong>
                        {dayjs(rejected_application.created_at)
                            .add(65, 'day')
                            .format('MMMM DD, YYYY')}
                    </strong>
                    .
                </p>
            </div>

            {user.state !== STATES_AVAILABLES.florida && (
                <div className="flex items-start p-4 rounded-2xl bg-blue-kiwi/10 mt-10">
                    <img src={ChartLineSVG} className="mr-4" />
                    <div>
                        <div className="font-semibold text-sm mb-1">
                            ¿Deseas construir crédito?
                        </div>
                        <div className="text-xs">
                            Te ayudamos a construir tu puntaje por $5 al mes.
                        </div>

                        <button
                            className="text-xs mt-2 text-blue-kiwi font-medium flex items-center"
                            onClick={onContinue}>
                            <span className="underline">
                                Construir historial
                            </span>
                            <ArrowRightIcon className="ml-2 w-4" />
                        </button>
                    </div>
                </div>
            )}

            <Button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                onClick={onShowReason}>
                Conocer la razón
            </Button>

            <RejectedLoanReasonModal
                email={userState.user.email}
                reason={rejectedReason}
                isVisible={showRejectedLoanReasonModal}
                closeModal={toggleRejectedLoanReasonModal}
            />
        </div>
    )
}
