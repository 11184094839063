import { RouletteContext } from 'context/RouletteContext'
import React, { useContext, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
const contentful = require('contentful')
const client = contentful.createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE,
    accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN,
    host: 'api.contentful.com'
})

export default function Roulette() {
    const { state: rouletteState, setState: setRouletteState } =
        useContext(RouletteContext)

    useEffect(() => {
        getEntries()
    }, [])

    const getEntries = async () => {
        const { items } = await client.getEntries({
            content_type: 'event'
        })

        const events = items
            .map(({ fields }) => {
                return {
                    name: fields.name['en-US'],
                    start: fields.start['en-US'],
                    end: fields.end['en-US'],
                    winners: [...fields.winners['en-US']]
                }
            })
            .sort((a, b) => new Date(b.start) - new Date(a.start))

        const lastEvent = events.length ? events[0] : rouletteState.lastEvent

        setRouletteState({
            events,
            lastEvent
        })
    }

    return (
        <div className="flex flex-col w-full min-h-full px-6 pb-10 pt-10">
            <Outlet />
        </div>
    )
}
