import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

import badgeImage from 'assets/badge_check.svg'

export default function CreditBuilderActivePaymentMethodSuccess() {
    const navigate = useNavigate()

    const onContinue = () => {
        navigate('/credit-builder/active/payment-method')
    }

    return (
        <Fragment>
            <div className="flex flex-col justify-center min-h-full text-center m-auto">
                <img className="mx-auto mb-8" width="218" src={badgeImage} />
                <h1 className="text-dark-kiwi font-semibold text-2xl">
                    Tu nuevo método de pago se agregó exitosamente
                </h1>
            </div>

            <button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                onClick={onContinue}>
                Continuar
            </button>
        </Fragment>
    )
}
