import React, { useEffect, useState, Fragment } from 'react'
import { LoanAPI } from 'api'

import EmptyState from './EmptyState'
import PaymentList from './PaymentList'

import PaymentSkeleton from 'components/Skeleton/Payment'

export default function HistoryPayments({ loan, onContinue }) {
    const [payments, setPayments] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getPayments()
    }, [])

    const getPayments = async () => {
        try {
            const {
                data: { data }
            } = await LoanAPI.getTransaction(loan.id)

            setPayments(data)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Fragment>
            {loading === false ? (
                <Fragment>
                    {payments?.length > 0 ? (
                        <PaymentList payments={payments} />
                    ) : (
                        <EmptyState onContinue={onContinue} />
                    )}
                </Fragment>
            ) : (
                <div className="flex flex-col gap-8">
                    {[...Array(10)].map((item, index) => (
                        <PaymentSkeleton key={index} />
                    ))}
                </div>
            )}
        </Fragment>
    )
}
