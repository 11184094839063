import RouletteHowWorksComponent from 'components/Roulette/HowWorks'
import React from 'react'
import BaseModal from '../BaseModal'

const RouletteHowWorks = ({ isVisible, closeModal }) => {
    return (
        <BaseModal
            title="¿Cómo participar?"
            description="Sigue estos pasos para participar:"
            isVisible={isVisible}
            closeModal={closeModal}>
            <RouletteHowWorksComponent />
        </BaseModal>
    )
}

export default RouletteHowWorks
