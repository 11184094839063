import axios from 'axios'

const VALID_BANK_MESSAGE = 'is valid bank routing number'
const INVALID_BANK_MESSAGE = 'is not a valid bank routing number'
const VALID_CLASS = 'ublcrnright'
const INVALID_CLASS = 'ublcrnwrong'
const DEFAULT_VALIDITY = true

const validateRoutingNumber = async (routingNumber) => {
    try {
        const response = await axios.get(
            `https://www.usbanklocations.com/crn.php?q=${routingNumber}`
        )

        const { data } = response

        if (data.includes(VALID_CLASS) && data.includes(VALID_BANK_MESSAGE)) {
            return true
        } else if (
            data.includes(INVALID_CLASS) &&
            data.includes(INVALID_BANK_MESSAGE)
        ) {
            return false
        }

        return DEFAULT_VALIDITY
    } catch (error) {
        console.error('Error validating routing number:', error)
        return DEFAULT_VALIDITY
    }
}

export default validateRoutingNumber
