import React from 'react'
import BaseModal from '../BaseModal'
import stores_list from 'data/stores_list'

export default function StoresModal({
    isVisible,
    selectedStore,
    closeModal,
    selectStore
}) {
    const onSelectStore = async (store) => {
        selectStore(store)
        closeModal()
    }

    return (
        <BaseModal
            title="Lugares donde recoger tu efectivo"
            description="Puedes ir a recoger el efectivo en cualquiera de nuestros comercios participantes"
            isVisible={isVisible}
            closeModal={closeModal}>
            <div className="flex flex-col gap-6">
                {stores_list.map((store) => (
                    <div key={store.store} className="relative">
                        <input
                            className="peer hidden"
                            type="radio"
                            id={store.store}
                            name="store"
                            onChange={() => onSelectStore(store)}
                            checked={store.store === selectedStore.store}
                        />
                        <div className='pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:bg-[url("./assets/icons/check.svg")] after:bg-no-repeat after:bg-center after:bg-auto after:rounded-full absolute right-4 top-1/2 -translate-y-1/2 rounded-full w-5 h-5 peer-checked:border-blue-kiwi border border-solid border-gray-400-kiwi'></div>
                        <label
                            htmlFor={store.store}
                            className="cursor-pointer peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 block rounded-lg border border-solid border-gray-200-kiwi p-4 pr-12">
                            <div className="leading-5 mb-1">{store.store}</div>
                            <p className="text-xs text-gray-500-kiwi">
                                {store.address}
                            </p>
                        </label>
                    </div>
                ))}
            </div>
        </BaseModal>
    )
}
