import React, { Fragment } from 'react'

import BankWarningSVG from 'assets/onboarding_v3/bank_warning.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import HTMLParse from 'html-react-parser'
export default function AutopayError() {
    const navigate = useNavigate()
    const location = useLocation()

    const { state: errorType } = location

    const messages = {
        noAccounts:
            'Parece que no tienes cuentas de banco asociadas. Puedes contactarte con nuestro equipo de soporte enviándonos un correo a <span className="font-semibold">support@kiwicredito.com</span>',
        noMatch:
            'Lamentablemente, no pudimos verificar tu cuenta de banco. El nombre de tu cuenta bancaria no coincide con el registrado con nosotros.',
        default:
            'Lamentablemente, no pudimos verificar tu cuenta de banco. Puedes contactarte con nuestro equipo de soporte enviándonos un correo a <span className="font-semibold">support@kiwicredito.com</span>'
    }

    const onContinue = () => {
        navigate('/autopay')
    }

    return (
        <Fragment>
            <div className="flex flex-col justify-center min-h-full text-center m-auto">
                <img className="mx-auto mb-8" src={BankWarningSVG} />
                <h1 className="text-dark-kiwi font-semibold text-2xl mb-4">
                    No pudimos verificar tu cuenta de banco
                </h1>
                <p>{HTMLParse(messages[errorType || 'default'])}</p>
            </div>

            <button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                onClick={onContinue}>
                Volver a intentarlo
            </button>
        </Fragment>
    )
}
