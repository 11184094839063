import React, { useEffect } from 'react'

export default function LoadingModal({ isVisible }) {
    useEffect(() => {
        if (isVisible === true) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [isVisible])

    useEffect(() => {
        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [])

    if (isVisible) {
        return (
            <div className="flex items-center justify-center fixed top-0 left-0 bottom-0 right-0 z-50 bg-dark-kiwi/50 overflow-auto sm:w-[450px] mx-auto sm:bottom-6 sm:top-6 sm:left-[none] sm:right-[none]  sm:rounded-2xl">
                <span className="loader"></span>
            </div>
        )
    }
}
