import axios from 'axios';

let http = axios.create({
    baseURL: process.env.REACT_APP_GPT_API,
});

http.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        return Promise.reject(error);
    },
);

export default http;
