import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import React, { useState, useEffect, createContext, useContext } from 'react'
import http from 'utils/http'
import { UserContext } from './UserContext'

dayjs.extend(relativeTime)

export const CreditLimitContext = createContext()

export const CreditLimitProvider = ({ children }) => {
    const { state: userState } = useContext(UserContext)

    const initialState = {
        prevPath: undefined,
        survey: {
            annual: '',
            additional: '',
            mortgage: '',
            completed: false
        },
        bankStatements: {
            completed: false
        }
    }

    const [state, setState] = useState(() => {
        const state = JSON.parse(sessionStorage.getItem('CreditLimitContext'))

        if (state === null) {
            return initialState
        }

        return state
    })

    useEffect(() => {
        sessionStorage.setItem('CreditLimitContext', JSON.stringify(state))
    }, [state])

    const resetState = () => {
        sessionStorage.removeItem('CreditLimitContext')
        setState(initialState)
    }

    const showCreditLimit = () => {
        const {
            products: {
                personal_loan: { loan, status, credit_limit, application }
            }
        } = userState

        const monthsLastCreditLimit = dayjs().diff(
            dayjs(credit_limit.last_date_credit_limit),
            'months',
            true
        )

        const monthsLastPlaidConnection = dayjs().diff(
            dayjs(credit_limit.last_date_plaid_connection),
            'months',
            true
        )

        /** Mostrar solo en el flujo de approved, antes de que se firme el contrato y se cree el loan */
        const isApprovedLoan = status === 'approved' && loan === null

        /** Si no hay fecha, mostrar card porque no hay credit limit anterior, si si hay verificar que hayan pasado 6 meses */
        const isCreditLimitMore6Months = isNaN(monthsLastCreditLimit)
            ? true
            : monthsLastCreditLimit >= 6

        /** Mostrar si no se ha conectado plaid o la fecha de la última conexión es mayor a 6 meses */
        const isPlaidConnectedMore6Months = isNaN(monthsLastPlaidConnection)
            ? true
            : monthsLastPlaidConnection >= 6

        /** Mostrar si el credit limit actual es menor a 1000 */
        const isCurrentLimitLess1000 = application?.credit_limit < 1000

        /** Mostrar si el credit limit actual es mayor a 100, porque hay usuarios viejos con 1 o 0 de credit limit */
        const isCurrentLimitMore100 = application?.credit_limit >= 100

        const showCreditLimit = isApprovedLoan &&
            isCreditLimitMore6Months &&
            isPlaidConnectedMore6Months &&
            isCurrentLimitLess1000 &&
            isCurrentLimitMore100

        /**
         * Ocultar botones de credit limit...
         */
        return {
            value: false,
            isCreditLimitMore6Months,
            isPlaidConnectedMore6Months,
            isCurrentLimitLess1000,
            isCurrentLimitMore100
        }
    }

    const creditLimitErrorMessage = () => {
        const {
            products: {
                personal_loan: { credit_limit }
            }
        } = userState

        const { isCreditLimitMore6Months, isCurrentLimitLess1000, isCurrentLimitMore100, isPlaidConnectedMore6Months } = showCreditLimit()

        const rejectedMessage = credit_limit.rejected_by
        const recentlyLimitMessage = isCreditLimitMore6Months === false ? 'recently_limit_increase' : null
        const maxLimitMessage = isCurrentLimitLess1000 === false ? 'max_credit_limit' : null
        const minLimitMessage = isCurrentLimitMore100 === false ? 'default' : null
        const plaidConnectedMessage = isPlaidConnectedMore6Months === false ? 'default' : null

        const message = rejectedMessage || recentlyLimitMessage || maxLimitMessage || minLimitMessage || plaidConnectedMessage

        return message
    }

    const createSurvey = async ({
        annual_income,
        annual_additional_income,
        monthly_payment,
        plaid_connected
    }) => {
        const response = await http.post('/credit-limit/new-increase', {
            annual_income,
            annual_additional_income,
            monthly_payment,
            plaid_connected
        })

        return response
    }

    const updateSurvey = async (id, payload) => {
        const response = await http.put(`/credit-limit/${id}`, payload)
        return response
    }

    const providerValues = {
        /** State */
        state,
        setState,
        resetState,

        /** Handlers */
        createSurvey,
        updateSurvey,
        showCreditLimit,
        creditLimitErrorMessage
    }

    return (
        <CreditLimitContext.Provider value={providerValues}>
            {children}
        </CreditLimitContext.Provider>
    )
}
