import React, { useContext } from 'react'
import { UserContext } from 'context/UserContext'
import { useNavigate } from 'react-router-dom'
import MoneySVG from 'assets/icons_v3/money.svg'
import { ArrowRightIcon } from 'assets/icons_v3/fonts'
import { PersonalLoanApplyRedirect } from 'utils/redirectPersonalLoan'

export default function HomeStatusApply() {
    const navigate = useNavigate()
    const { state: userState } = useContext(UserContext)
    const { products } = userState
    const {
        personal_loan: { tracking }
    } = products

    const hasRequestLoanTracking = tracking !== null
    const onContinue = async () => {
        const route = await PersonalLoanApplyRedirect(tracking?.context ?? {})
        navigate(route)
    }

    return (
        <div className="p-4 rounded-xl bg-slate-100-kiwi flex items-start gap-4">
            <img src={MoneySVG} className="w-10 shrink-0" />
            <div className="flex flex-col">
                <h3 className="font-semibold text-dark-kiwi mb-1">
                    ¿Necesitas dinero?
                </h3>
                <p className="text-xs mb-4 text-slate-700-kiwi">
                    Solicita un préstamo personal de hasta $1,000 ¡Ahora mismo!
                </p>
                <div
                    onClick={onContinue}
                    className="rounded-xl px-4 py-1 bg-regal-blue-kiwi text-xs text-white font-medium flex items-center self-start cursor-pointer">
                    {hasRequestLoanTracking
                        ? 'Continuar solicitud'
                        : 'Solicitar ahora'}{' '}
                    <ArrowRightIcon className="w-5 ml-1" />
                </div>
            </div>
        </div>
    )
}
