import React from 'react'
import MovePaymentDateTemplate from 'templates/MovePaymentDate'
import { useNavigate } from 'react-router-dom'

export default function CreditBuilderMovePaymentDate() {
    const navigate = useNavigate()

    const onBackward = () => {
        navigate('/credit-builder/active')
    }

    return (
        <MovePaymentDateTemplate
            onBackward={onBackward}
            onContinue={onBackward}
            product="credit_builder"
        />
    )
}
