import * as Yup from 'yup'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/es'
import Emitter from 'utils/emitter'
import React, { useState, useEffect, useContext, Fragment, useRef } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { UserContext } from 'context/UserContext'
import { CalendarIcon } from '@heroicons/react/outline'
import { LoanAPI } from 'api'

import daysBetweenTwoDates from 'utils/daysBetweenTwoDates'

import Button from 'components/Button'
import LoadingModal from 'components/Modals/Loading'
import Advisement from 'components/Advisement'
import BackwardButton from 'components/Button/Backward'

import { STATES_AVAILABLES } from 'constants'
import InformationCircleSVG from 'assets/onboarding_v3/information_circle.svg'

dayjs.locale('es')
dayjs.extend(timezone)

export default function MovePaymentDateTemplate({
    onBackward,
    onContinue,
    product
}) {
    const form = useRef()
    const {
        state: userState,
        setState: setUserState,
        showMovePaymentDate
    } = useContext(UserContext)

    const {
        products: {
            [product]: {
                loan: { id: loanId, nextPaymentDate, nextPaymentAmount }
            }
        }
    } = userState

    const MAX_TOTAL_DAYS = 10
    const [dayList, setDayList] = useState([])
    const [loading, setLoading] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [movePaymentError, setMovePaymentError] = useState('')
    const [movePaymentValidation] = useState(showMovePaymentDate(product))

    useEffect(() => {
        initValidation()
    }, [])

    const initValidation = () => {
        const startDate = dayjs().hour(0).minute(0).second(0)
        const endDate = dayjs(nextPaymentDate.replace(/Z/g, ''))

        checkMovePaymentValidation()
        diffDateValidation(startDate, endDate)
        generateDayList(endDate)
    }

    const checkMovePaymentValidation = () => {
        const {
            validPastDue,
            validContractDate,
            validLastPaymentChanged,
            validAmountDue,
            validLastPaymentDate
        } = movePaymentValidation

        if (validPastDue === false) {
            return setMovePaymentError(
                'No es posible mover la fecha ya que cuentas con días de atraso en tu pago'
            )
        }

        if (validContractDate === false) {
            return setMovePaymentError(
                'No es posible mover la fecha de pago ya que firmaste tu contrato antes del 11-01-2023'
            )
        }

        if (validLastPaymentChanged === false) {
            return setMovePaymentError(
                'No es posible mover la fecha de pago. Haz solicitado un cambio de fecha recientemente'
            )
        }

        if (validAmountDue === false) {
            return setMovePaymentError(
                'No es posible mover la fecha el mismo día de tu próximo pago'
            )
        }

        if (validLastPaymentDate === false) {
            return setMovePaymentError(
                'No es posible mover la fecha de pago hasta después de 7 días del último pago realizado'
            )
        }
    }

    const generateDayList = (endDate) => {
        const get10CalendarDays = getNext10CalendarDays(endDate)
        setDayList(get10CalendarDays)
    }

    const diffDateValidation = (startDate, endDate) => {
        const diffPaymentDays = daysBetweenTwoDates(startDate, endDate)

        /** Si la fecha de pago es hoy o mañana... */
        if (diffPaymentDays <= 1) {
            /** Si es hoy, desactivar... */
            if (diffPaymentDays === 0) {
                setMovePaymentError(
                    'No es posible mover la fecha el mismo día de tu próximo pago'
                )
            } else if (diffPaymentDays === 1) {
                /** Si es mañana y el cambio es despues de las 5PM de PR, desactivar... */
                const currentDatePR = dayjs().tz('America/Puerto_Rico')
                const maxDate5PM = currentDatePR.hour(17).minute(0).second(0)
                const isAfterWorkingHours = currentDatePR.isAfter(maxDate5PM)

                if (isAfterWorkingHours) {
                    setMovePaymentError(
                        'Solo es posible mover la fecha un día antes de la fecha de pago y antes de las 5:00 pm (AST) '
                    )
                }
            }
        }
    }

    const getNext10CalendarDays = (date) => {
        const dates = []
        let currentDate = dayjs(date).add(1, 'day')

        while (dates.length < MAX_TOTAL_DAYS) {
            dates.push(currentDate.format())
            currentDate = currentDate.add(1, 'day')
        }

        return dates
    }

    const onSubmit = async ({ value }) => {
        try {
            if (loading === true) {
                return
            }

            setLoading(true)
            setShowLoader(true)

            const newDate = dayjs(value)
            const oldDate = dayjs(nextPaymentDate.replace(/Z/g, ''))

            const { data } = await LoanAPI.moveLoanPaymentDate({
                loanId,
                oldDate: oldDate.format('YYYY-MM-DD'),
                newDate: newDate.format('YYYY-MM-DD'),
                amount: nextPaymentAmount,
                source: 'app'
            })

            setUserState({
                ...userState,
                products: {
                    ...userState.products,
                    [product]: {
                        ...userState.products[product],
                        loan: {
                            ...userState.products[product].loan,
                            nextPaymentDate: newDate.format(),
                            last_payment_changed: data
                        }
                    }
                }
            })

            Emitter.emit('onOpenNotification', {
                type: 'success',
                title: 'Fecha de pago',
                message: `Tu nueva fecha de pago se cambio correctamente a <strong>${newDate.format(
                    'MMMM DD, YYYY'
                )}</strong>`
            })

            onContinue(value)
        } catch (error) {
            const { data } = error.response

            if (data.messages === 'PAYMENT_IN_PROGRESS') {
                return form.current.setFieldError(
                    'value',
                    'No es posible cambiar tu fecha de pago hasta después de 7 días del último cambio'
                )
            }

            form.current.setFieldError(
                'value',
                'Problemas al cambiar tu fecha de pago, por favor contacta a support@kiwicredito.com'
            )
        } finally {
            setLoading(false)
            setShowLoader(false)
        }
    }

    const formatPaymentDate = (date) => {
        return dayjs(date?.replace(/Z/g, '')).format('MMM DD, YYYY')
    }

    const initialValues = {
        value: ''
    }

    const validationSchema = Yup.object().shape({
        value: Yup.string().required('Este campo es requerido')
    })

    /**
     * Retornar vista de estado no disponible
     * si el estado es diferente a Puerto Rico...
     */
    if (userState.user.state !== STATES_AVAILABLES.puertoRico) {
        return (
            <Fragment>
                <BackwardButton onBackward={onBackward} className="mb-0" />
                <div className="text-center m-auto">
                    <img className="mx-auto mb-8" src={InformationCircleSVG} />
                    <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                        No es posible realizar el cambio de fecha
                    </h1>
                    <p>
                        Esta función aún no esta habilitada para el estado de{' '}
                        {userState.user.state}.
                    </p>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <LoadingModal isVisible={showLoader} />
            <BackwardButton onBackward={onBackward} />

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                innerRef={form}>
                {({ errors, touched }) => (
                    <Form className="flex flex-col grow" autoComplete="off">
                        <div className="mb-auto">
                            <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                                Cambiar fecha de pago
                            </h1>
                            <div className="flex flex-col gap-4 mb-10">
                                <p>
                                    Solo se podrá generar cambios hasta un día
                                    antes de tu fecha de pago{' '}
                                    <strong>5:00 pm (AST)</strong>
                                </p>
                                <p>
                                    Se permite mover la fecha de pago un máximo
                                    de {MAX_TOTAL_DAYS} días.
                                </p>
                            </div>

                            <div className="flex items-center p-4 rounded-xl border border-gray-200-kiwi">
                                <div className="flex justify-center items-center shrink-0 bg-white h-10 w-10 rounded-full mr-4 bg-slate-100-kiwi">
                                    <CalendarIcon className="w-6 h-6 text-regal-blue-kiwi" />
                                </div>
                                <div className="grow flex flex-col mr-4">
                                    <p className="text-md font-semibold">
                                        Fecha del próximo pago
                                    </p>
                                    <p className="text-sm text-gray-500-kiwi">
                                        {formatPaymentDate(nextPaymentDate)}
                                    </p>
                                </div>
                            </div>

                            {movePaymentError === '' && (
                                <div className="mt-10">
                                    <label
                                        htmlFor="value"
                                        className="inline-block text-sm mb-2">
                                        ¿Para qué día desea cambiar su fecha de
                                        pago?
                                    </label>
                                    <Field
                                        as="select"
                                        name="value"
                                        id="value"
                                        className={`w-full rounded-lg py-2 px-4 border ${
                                            errors.value && touched.value
                                                ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                                : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                        }`}>
                                        <option defaultValue hidden></option>
                                        {dayList.map((item, index) => (
                                            <option key={index} value={item}>
                                                {dayjs(item).format(
                                                    'MMMM DD, YYYY'
                                                )}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage
                                        name="value"
                                        component="small"
                                        className="text-red-kiwi inline-block text-xs w-full mt-2"
                                    />
                                </div>
                            )}
                        </div>

                        <Advisement className="mt-10">
                            {movePaymentError === '' ? (
                                <Fragment>
                                    <strong className="block">
                                        Mover próximas fechas de pago
                                    </strong>
                                    Para cambiar todas las proximas fechas de
                                    pago contacta a{' '}
                                    <strong>support@kiwicredito.com</strong>.
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <strong className="block">
                                        No puedes mover tu fecha de pago
                                    </strong>
                                    {movePaymentError}
                                </Fragment>
                            )}
                        </Advisement>

                        <Button
                            className={`rounded-xl p-3 mt-10 ${
                                movePaymentError === ''
                                    ? 'bg-blue-kiwi text-white'
                                    : 'bg-gray-200-kiwi text-gray-500-kiwi'
                            }`}
                            loading={loading}
                            type="submit">
                            Continuar
                        </Button>
                    </Form>
                )}
            </Formik>
        </Fragment>
    )
}
