import http from 'utils/http'

const createTempLoan = async ({ user_id, amount, termMonths, paymentDay }) => {
    const response = await http.post('/loans/quick-quote', {
        user_id,
        amount,
        term_months: termMonths,
        preferred_payment_date: paymentDay
    })

    return response
}

const createLoanproCustomer = async (user_id) => {
    const response = await http.post('/users/loanpro', {
        user_id
    })

    return response
}

export default {
    createTempLoan,
    createLoanproCustomer
}
