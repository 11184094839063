import React, { Fragment, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import AddCreditCardTemplate from 'templates/AddCreditCard'
import BackwardButton from 'components/Button/Backward'

export default function CreditBuilderActivePaymentMethodCard() {
    const creditCardRef = useRef()
    const navigate = useNavigate()

    const onBackward = () => {
        navigate(-1)
    }

    return (
        <Fragment>
            <AddCreditCardTemplate
                product="credit_builder"
                onBackward={onBackward}
                ref={creditCardRef}
            />
        </Fragment>
    )
}
