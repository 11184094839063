import React from 'react'
import BaseModal from '../BaseModal'

const NSFFee = ({ isVisible, closeModal }) => {
    return (
        <BaseModal
            title="Cargo por falta de fondos"
            isVisible={isVisible}
            closeModal={closeModal}>
            <div className="flex flex-col gap-6 mt-6">
                <p>
                    El "cargo por falta de fondos" o "NSF Fee" se activa cuando
                    una cuenta no dispone de los fondos necesarios para cubrir
                    una transacción, y la entidad financiera aplica una tarifa
                    como compensación por la insuficiencia de fondos.
                </p>
            </div>
        </BaseModal>
    )
}

export default NSFFee
