export default [
    { name: 'Alachua' },
    { name: 'Altamonte Springs' },
    { name: 'Andover' },
    { name: 'Apollo Beach' },
    { name: 'Apopka' },
    { name: 'Arcadia' },
    { name: 'Atlantic Beach' },
    { name: 'Auburndale' },
    { name: 'Aventura' },
    { name: 'Avon Park' },
    { name: 'Azalea Park' },
    { name: 'Bartow' },
    { name: 'Bayonet Point' },
    { name: 'Bayshore Gardens' },
    { name: 'Beacon Square' },
    { name: 'Bee Ridge' },
    { name: 'Bellair-Meadowbrook Terrace' },
    { name: 'Belle Glade' },
    { name: 'Bellview' },
    { name: 'Beverly Hills' },
    { name: 'Bloomingdale' },
    { name: 'Boca Del Mar' },
    { name: 'Boca Raton' },
    { name: 'Bonita Springs' },
    { name: 'Boynton Beach' },
    { name: 'Bradenton' },
    { name: 'Brandon' },
    { name: 'Brent' },
    { name: 'Broadview Park' },
    { name: 'Brooksville' },
    { name: 'Brownsville' },
    { name: 'Callaway' },
    { name: 'Cape Canaveral' },
    { name: 'Cape Coral' },
    { name: 'Carol City' },
    { name: 'Casselberry' },
    { name: 'Century Village' },
    { name: 'Cheval' },
    { name: 'Citrus Park' },
    { name: 'Citrus Ridge' },
    { name: 'Clearwater' },
    { name: 'Clermont' },
    { name: 'Clewiston' },
    { name: 'Cocoa' },
    { name: 'Cocoa Beach' },
    { name: 'Coconut Creek' },
    { name: 'Collier Manor-Cresthaven' },
    { name: 'Conway' },
    { name: 'Cooper City' },
    { name: 'Coral Gables' },
    { name: 'Coral Springs' },
    { name: 'Coral Terrace' },
    { name: 'Country Club' },
    { name: 'Country Walk' },
    { name: 'Crestview' },
    { name: 'Cutler' },
    { name: 'Cutler Ridge' },
    { name: 'Cypress Gardens' },
    { name: 'Cypress Lake' },
    { name: 'Dade City' },
    { name: 'Dania Beach' },
    { name: 'Davie' },
    { name: 'Daytona Beach' },
    { name: 'De Bary' },
    { name: 'De Land' },
    { name: 'Deerfield Beach' },
    { name: 'Delray Beach' },
    { name: 'Deltona' },
    { name: 'Destin' },
    { name: 'Doctor Phillips' },
    { name: 'Doral' },
    { name: 'Dunedin' },
    { name: 'East Lake' },
    { name: 'East Perrine' },
    { name: 'Edgewater' },
    { name: 'Eglin AFB' },
    { name: 'Egypt Lake-Leto' },
    { name: 'Elfers' },
    { name: 'Englewood' },
    { name: 'Ensley' },
    { name: 'Estero' },
    { name: 'Eustis' },
    { name: 'Fairview Shores' },
    { name: 'Fern Park' },
    { name: 'Fernandina Beach' },
    { name: 'Ferry Pass' },
    { name: 'Florida City' },
    { name: 'Florida Ridge' },
    { name: 'Forest City' },
    { name: 'Fort Lauderdale' },
    { name: 'Fort Myers' },
    { name: 'Fort Myers Beach' },
    { name: 'Fort Pierce' },
    { name: 'Fort Pierce North' },
    { name: 'Fort Walton Beach' },
    { name: 'Fountainbleau' },
    { name: 'Fruit Cove' },
    { name: 'Fruitville' },
    { name: 'Gainesville' },
    { name: 'Gibsonton' },
    { name: 'Gifford' },
    { name: 'Gladeview' },
    { name: 'Glenvar Heights' },
    { name: 'Golden Gate' },
    { name: 'Golden Glades' },
    { name: 'Golden Lakes' },
    { name: 'Goldenrod' },
    { name: 'Gonzalez' },
    { name: 'Goulds' },
    { name: 'Greater Carrollwood' },
    { name: 'Greater Northdale' },
    { name: 'Greater Sun Center' },
    { name: 'Greenacres' },
    { name: 'Gulf Gate Estates' },
    { name: 'Gulfport' },
    { name: 'Haines City' },
    { name: 'Hallandale' },
    { name: 'Hamptons at Boca Raton' },
    { name: 'Hernando' },
    { name: 'Hialeah' },
    { name: 'Hialeah Gardens' },
    { name: 'Hobe Sound' },
    { name: 'Holiday' },
    { name: 'Holly Hill' },
    { name: 'Hollywood' },
    { name: 'Homestead' },
    { name: 'Homosassa Springs' },
    { name: 'Hudson' },
    { name: 'Hunters Creek' },
    { name: 'Immokalee' },
    { name: 'Indian Harbour Beach' },
    { name: 'Inverness' },
    { name: 'Inwood' },
    { name: 'Iona' },
    { name: 'Islamorada' },
    { name: 'Ives Estates' },
    { name: 'Jacksonville' },
    { name: 'Jacksonville Beach' },
    { name: 'Jasmine Estates' },
    { name: 'Jensen Beach' },
    { name: 'Jupiter' },
    { name: 'Kendale Lakes' },
    { name: 'Kendall' },
    { name: 'Kendall West' },
    { name: 'Key Biscayne' },
    { name: 'Key Largo' },
    { name: 'Key West' },
    { name: 'Keystone' },
    { name: 'Kings Point' },
    { name: 'Kissimmee' },
    { name: 'Lady Lake' },
    { name: 'Lake Butter' },
    { name: 'Lake City' },
    { name: 'Lake Lorraine' },
    { name: 'Lake Lucerne' },
    { name: 'Lake Magdalene' },
    { name: 'Lake Mary' },
    { name: 'Lake Park' },
    { name: 'Lake Wales' },
    { name: 'Lake Worth' },
    { name: 'Lake Worth Corridor' },
    { name: 'Lakeland' },
    { name: 'Lakeland Highlands' },
    { name: 'Lakes by the Bay' },
    { name: 'Lakeside' },
    { name: 'Lakewood Park' },
    { name: 'Land O’ Lakes' },
    { name: 'Lantana' },
    { name: 'Largo' },
    { name: 'Lauderdale Lakes' },
    { name: 'Lauderhill' },
    { name: 'Laurel' },
    { name: 'Leesburg' },
    { name: 'Lehigh Acres' },
    { name: 'Leisure City' },
    { name: 'Lighthouse Point' },
    { name: 'Live Oak' },
    { name: 'Lockhart' },
    { name: 'Longboat Key' },
    { name: 'Longwood' },
    { name: 'Lutz' },
    { name: 'Lynn Haven' },
    { name: 'Maitland' },
    { name: 'Mango' },
    { name: 'Marathon' },
    { name: 'Marco Island' },
    { name: 'Margate' },
    { name: 'Marianna' },
    { name: 'McGregor' },
    { name: 'Meadow Woods' },
    { name: 'Medulla' },
    { name: 'Melbourne' },
    { name: 'Melrose Park' },
    { name: 'Memphis' },
    { name: 'Merritt Island' },
    { name: 'Miami' },
    { name: 'Miami Beach' },
    { name: 'Miami Lakes' },
    { name: 'Miami Shores' },
    { name: 'Miami Springs' },
    { name: 'Micco' },
    { name: 'Middleburg' },
    { name: 'Milton' },
    { name: 'Mims' },
    { name: 'Miramar' },
    { name: 'Mount Dora' },
    { name: 'Myrtle Grove' },
    { name: 'Naples' },
    { name: 'Naples Park' },
    { name: 'Neptune Beach' },
    { name: 'New Port Richey' },
    { name: 'New Port Richey East' },
    { name: 'New Smyrna Beach' },
    { name: 'Niceville' },
    { name: 'Norland' },
    { name: 'North Andrews Gardens' },
    { name: 'North Bay Village' },
    { name: 'North Fort Myers' },
    { name: 'North Lauderdale' },
    { name: 'North Miami' },
    { name: 'North Miami Beach' },
    { name: 'North Palm Beach' },
    { name: 'North Port' },
    { name: 'North Sarasota' },
    { name: 'Oak Ridge' },
    { name: 'Oakland Park' },
    { name: 'Ocala' },
    { name: 'Ocoee' },
    { name: 'Ojus' },
    { name: 'Oldsmar' },
    { name: 'Olympia Heights' },
    { name: 'Opa-locka' },
    { name: 'Opa-locka North' },
    { name: 'Orange City' },
    { name: 'Orange Park' },
    { name: 'Orlando' },
    { name: 'Orlovista' },
    { name: 'Ormond Beach' },
    { name: 'Ormond-By-The-Sea' },
    { name: 'Oviedo' },
    { name: 'Pace' },
    { name: 'Palatka' },
    { name: 'Palm Bay' },
    { name: 'Palm Beach' },
    { name: 'Palm Beach Gardens' },
    { name: 'Palm City' },
    { name: 'Palm Coast' },
    { name: 'Palm Harbor' },
    { name: 'Palm River-Clair Mel' },
    { name: 'Palm Springs' },
    { name: 'Palm Valley' },
    { name: 'Palmetto' },
    { name: 'Palmetto Estates' },
    { name: 'Panama City' },
    { name: 'Panama City Beach' },
    { name: 'Parkland' },
    { name: 'Pembroke Park' },
    { name: 'Pembroke Pines' },
    { name: 'Pensacola' },
    { name: 'Perry' },
    { name: 'Pine Castle' },
    { name: 'Pine Hills' },
    { name: 'Pinecrest' },
    { name: 'Pinellas Park' },
    { name: 'Pinewood' },
    { name: 'Plant City' },
    { name: 'Plantation' },
    { name: 'Poinciana' },
    { name: 'Pompano Beach' },
    { name: 'Pompano Beach Highlands' },
    { name: 'Port Charlotte' },
    { name: 'Port Orange' },
    { name: 'Port Salerno' },
    { name: 'Port St. John' },
    { name: 'Port St. Lucie' },
    { name: 'Princeton' },
    { name: 'Punta Gorda' },
    { name: 'Quincy' },
    { name: 'Richmond Heights' },
    { name: 'Richmond West' },
    { name: 'Riverview' },
    { name: 'Riviera Beach' },
    { name: 'Rockledge' },
    { name: 'Rotonda' },
    { name: 'Royal Palm Beach' },
    { name: 'Ruskin' },
    { name: 'Safety Harbor' },
    { name: 'San Carlos Park' },
    { name: 'Sandalfoot Cove' },
    { name: 'Sanford' },
    { name: 'Sanibel' },
    { name: 'Sarasota' },
    { name: 'Sarasota Springs' },
    { name: 'Satellite Beach' },
    { name: 'Scott Lake' },
    { name: 'Sebastian' },
    { name: 'Sebring' },
    { name: 'Seminole' },
    { name: 'Shady Hills' },
    { name: 'Siesta Key' },
    { name: 'Silver Springs Shores' },
    { name: 'South Bradenton' },
    { name: 'South Daytona' },
    { name: 'South Highpoint' },
    { name: 'South Miami' },
    { name: 'South Miami Heights' },
    { name: 'South Patrick Shores' },
    { name: 'South Venice' },
    { name: 'Southeast Arcadia' },
    { name: 'Southgate' },
    { name: 'Spring Hill' },
    { name: 'Springfield' },
    { name: 'St. Augustine' },
    { name: 'St. Cloud' },
    { name: 'St. Pete Beach' },
    { name: 'St. Petersburg' },
    { name: 'Stuart' },
    { name: 'Sugarmill Woods' },
    { name: 'Sunny Isles Beach' },
    { name: 'Sunrise' },
    { name: 'Sunset' },
    { name: 'Sweetwater' },
    { name: 'Tallahassee' },
    { name: 'Tamarac' },
    { name: 'Tamiami' },
    { name: 'Tampa' },
    { name: 'Tarpon Springs' },
    { name: 'Tavares' },
    { name: 'Temple Terrace' },
    { name: 'The Crossings' },
    { name: 'The Hammocks' },
    { name: 'The Villages' },
    { name: 'Thonotosassa' },
    { name: 'Three Lakes' },
    { name: 'Titusville' },
    { name: "Town 'n' Country" },
    { name: 'Treasure Island' },
    { name: 'Union Park' },
    { name: 'University' },
    { name: 'University Park' },
    { name: 'Upper Grand Lagoon' },
    { name: 'Valparaiso' },
    { name: 'Valrico' },
    { name: 'Venice' },
    { name: 'Venice Gardens' },
    { name: 'Vero Beach' },
    { name: 'Vero Beach South' },
    { name: 'Villas' },
    { name: 'Warrington' },
    { name: 'Wekiwa Springs' },
    { name: 'Wellington' },
    { name: 'West and East Lealman' },
    { name: 'West Little River' },
    { name: 'West Melbourne' },
    { name: 'West Palm Beach' },
    { name: 'West Pensacola' },
    { name: 'West Perrine' },
    { name: 'West Vero Corridor' },
    { name: 'Westchase' },
    { name: 'Westchester' },
    { name: 'Westgate-Belvedere Homes' },
    { name: 'Weston' },
    { name: 'Westview' },
    { name: 'Westwood Lakes' },
    { name: 'Williamsburg' },
    { name: 'Wilton Manors' },
    { name: 'Winston' },
    { name: 'Winter Garden' },
    { name: 'Winter Haven' },
    { name: 'Winter Park' },
    { name: 'Winter Springs' },
    { name: 'Wright' },
    { name: 'Yeehaw Junction' },
    { name: 'Yulee' },
    { name: 'Zephyrhills' }
]
