export default [
    {
        name: 'Banco Popular',
        routingNumber: '021502011',
        maxDigitsAccount: 9
    },
    {
        name: 'Oriental',
        routingNumber: '221571415',
        maxDigitsAccount: 10
    },
    {
        name: 'Firstbank',
        routingNumber: '221571473',
        maxDigitsAccount: 10
    },
    {
        name: 'Santander',
        routingNumber: '021502341',
        maxDigitsAccount: 10
    },
    {
        name: 'Coop. A/C  Las Piedras',
        routingNumber: '021582853',
        maxDigitsAccount: ''
    },
    {
        name: 'Coopaca',
        routingNumber: '221581641',
        maxDigitsAccount: ''
    },
    {
        name: 'Coop. A/C  Oriental',
        routingNumber: '221581748',
        maxDigitsAccount: ''
    },
    {
        name: 'Coop. A/C  Roosevelt Roads',
        routingNumber: '221581670',
        maxDigitsAccount: ''
    },
    {
        name: 'Coop. A/C  La Sagrada Familia',
        routingNumber: '221582569',
        maxDigitsAccount: ''
    },
    {
        name: 'Coop. A/C San Jose of Puerto Rico',
        routingNumber: '021582743',
        maxDigitsAccount: ''
    },
    {
        name: 'PenFed',
        routingNumber: '256078446',
        maxDigitsAccount: ''
    },
    {
        name: 'Otro',
        routingNumber: '',
        maxDigitsAccount: ''
    }
]
