import React from 'react'
import { useNavigate } from 'react-router-dom'
import { GiftIcon } from 'assets/icons_v3/fonts'

import ReferralSVG from 'assets/onboarding_v3/recommended/referral.png'

export default function HomeRecommendedReferral({ className }) {
    const navigate = useNavigate()

    const onReferral = () => {
        navigate('/referrals')
    }

    return (
        <div
            className={`px-4 pt-4 bg-slate-100-kiwi rounded-2xl flex justify-between ${className}`}
            onClick={onReferral}>
            <div className="mb-4">
                <p className="text-blue-kiwi mb-2 text-xs font-medium flex items-center">
                    <GiftIcon className="w-4 mr-2" /> Plan de referidos
                </p>
                <p className="font-bold mb-1 text-sm">
                    Gana hasta $500 dólares
                </p>
                <p className="text-xs">
                    Inscribe a tus amigos para que sean parte de Kiwi
                </p>
            </div>

            <img src={ReferralSVG} className="shrink-0 self-end" />
        </div>
    )
}
