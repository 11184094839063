import React, { useEffect, useContext, useState, Fragment } from 'react'
import { UserContext } from 'context/UserContext'

import LoadingModal from 'components/Modals/Loading'
import SelectProfile from './Select'
import AddProfile from './Add'

export default function PaymentProfileTemplate({
    onAddProfile,
    onSelectProfile,
    selectedProfile,
    selectProfileTitle,
    selectProfileDescription,
    showSelectProfileView = true
}) {
    const { paymentProfiles } = useContext(UserContext)

    const [showLoader, setShowLoader] = useState(true)
    const [showSelectProfile, setShowSelectProfile] = useState(false)
    const [showAddProfile, setShowAddProfile] = useState(false)
    const [primaryProfile, setPrimaryProfile] = useState({})
    const [checkingAccounts, setCheckingAccounts] = useState([])

    useEffect(() => {
        getPaymentProfiles()
    }, [])

    const getPaymentProfiles = async () => {
        try {
            const { data } = await paymentProfiles()

            /** Generar array solo con las cuentas de banco */
            if (data.success) {
                const checkingAccounts = data.data?.results.filter(
                    (profile) => profile.checkingAccountId !== null
                )

                let primaryProfile = data.data?.results.find(
                    (profile) => profile.isPrimary === 1
                )

                const filterDisabledAccounts = checkingAccounts.filter(
                    (profile) => profile.active === 0 && profile.visible === 0
                )

                const filterActiveAccounts = checkingAccounts.filter(
                    (profile) => profile.active === 1 && profile.visible === 1
                )

                setPrimaryProfile(primaryProfile)
                setCheckingAccounts(checkingAccounts)

                /**
                 * NOTE: Esta condicion es para ocultar la vista de selección
                 * de perfil de pago... Sirve para mostrar el formulario
                 * de perfil si es necesario.
                 */
                const oneAccount = filterActiveAccounts.find(
                    (account) => account.type === 'paymentAccount.type.checking'
                )

                if (filterActiveAccounts.length > 0 && !showSelectProfileView) {
                    return onSelectProfile({
                        id: oneAccount.id,
                        bankName: oneAccount.CheckingAccount.bankName,
                        last4: oneAccount.CheckingAccount.accountNumber.slice(
                            -4
                        )
                    })
                }

                if (
                    filterDisabledAccounts.length > 0 ||
                    filterActiveAccounts.length > 0
                ) {
                    return setShowSelectProfile(true)
                }

                setShowAddProfile(true)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setShowLoader(false)
        }
    }

    const onAddProfileSuccess = async (profile) => {
        onAddProfile(profile)
    }

    const onSelectProfileSuccess = async (profile) => {
        onSelectProfile(profile)
    }

    return (
        <Fragment>
            <LoadingModal isVisible={showLoader} />

            {showSelectProfile === true && (
                <SelectProfile
                    selectProfileTitle={selectProfileTitle}
                    selectProfileDescription={selectProfileDescription}
                    selectedProfile={selectedProfile}
                    primaryProfile={primaryProfile}
                    checkingAccounts={checkingAccounts}
                    onSelectProfile={onSelectProfileSuccess}
                    setShowLoader={setShowLoader}
                />
            )}

            {showAddProfile === true && (
                <AddProfile
                    onAddProfile={onAddProfileSuccess}
                    setShowLoader={setShowLoader}
                />
            )}
        </Fragment>
    )
}
