import axios from 'axios'

let httpFirebase = axios.create({
    baseURL: process.env.REACT_APP_API_KIWI_FIREBASE
})

export const setBearerTokenFirebase = token => {
    httpFirebase.defaults.headers.common['Authorization'] = token
}

httpFirebase.interceptors.response.use(
    response => {
        return response
    },
    error => {
        const { response } = error
        const { data } = response

        if (data.success === false) {
            if (data.data && data.data.message === 'Unauthorized') {
                window.localStorage.clear()
                window.location.reload()
            }
        }

        return Promise.reject(error)
    }
)

export default httpFirebase
