import React, { useState, useContext, useEffect, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { CreditBuilderContext } from 'context/CreditBuilderContext'
import { UserContext } from 'context/UserContext'
import mixpanel from 'utils/mixpanel'
import LoadingModal from 'components/Modals/Loading'
import { ChevronLeftIcon } from 'assets/icons_v3/fonts'
import CourseComponent from 'components/Course'
import CourseSkeleton from 'components/Skeleton/Course'
import BackwardButton from 'components/Button/Backward'

export default function CreditBuilderBuyCourse() {
    const navigate = useNavigate()

    const { state: userContext } = useContext(UserContext)

    const [courses, setCourses] = useState([])
    const [loading, setLoading] = useState(true)
    const [showLoader, setShowLoader] = useState(false)

    const {
        state: creditState,
        saveState: saveCreditState,
        initialState: initialCreditState,
        getCourses
    } = useContext(CreditBuilderContext)

    useEffect(() => {
        getUserCourses()
    }, [])

    const getUserCourses = async () => {
        try {
            let { data } = await getCourses()

            setCourses(data)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onBackward = () => {
        const {
            credit_builder: {
                courses: { total }
            }
        } = userContext.products

        if (total === 0) {
            return navigate('/credit-builder/build-score')
        }

        navigate('/credit-builder')
    }

    const onSelectCourse = async ({
        id,
        financial_months,
        name,
        cb_prices
    }) => {
        try {
            setShowLoader(true)

            const price = getCoursePrice('us', cb_prices)

            /**
             * Si seleccionamos un curso que sea diferente al que ya
             * hayamos seleccionado, se reinicia el tracking con los
             * valores iniciales del state, por dos razones:
             *
             * 1.- Recalcular los detalles de la compra.
             * 2.- No redireccionar a la última vista a la que se avanzó
             *     antes de cambiar el curso.
             */
            if (creditState.course.id !== id) {
                await saveCreditState({
                    ...initialCreditState,
                    build: {
                        completed: true
                    },
                    course: {
                        id,
                        financial_months,
                        name,
                        price_id: price.id,
                        price: price.value,
                        completed: true
                    }
                })
            }

            mixpanel.track('Select Course', {
                Course: name
            })

            navigate('/credit-builder/selected-course')
        } catch (error) {
            console.error(error)
        } finally {
            setShowLoader(false)
        }
    }

    const getCoursePrice = (country, prices) => {
        const price = prices.find((p) => p.country_code === country)
        return price
    }

    return (
        <Fragment>
            <LoadingModal isVisible={showLoader} />
            <BackwardButton onBackward={onBackward} />

            <div className="flex flex-col justify-center text-left mb-auto">
                <h1 className="text-dark-kiwi font-semibold text-2xl mb-2">
                    Compra un curso financiero y obtén financiamiento
                </h1>
                <p className="mb-10">
                    Los pagos a plazo y a tiempo son la clave del éxito en la
                    construcción de tu historial de crédito.
                </p>

                <div className="flex flex-col gap-10">
                    {loading ? (
                        <Fragment>
                            {[...Array(3)].map((item, index) => (
                                <CourseSkeleton key={index} />
                            ))}
                        </Fragment>
                    ) : (
                        <Fragment>
                            {courses?.map((course) => (
                                <CourseComponent
                                    key={course.id}
                                    course={course}
                                    onSelectCourse={onSelectCourse}
                                />
                            ))}
                        </Fragment>
                    )}
                </div>
            </div>
        </Fragment>
    )
}
