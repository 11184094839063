import React, { useEffect, useRef, useState } from 'react'
import { DownIcon, UpIcon } from 'assets/icons_v3/fonts'
import tailwindConfig from 'utils/tailwindConfig'

export default function ToggleInput({
    img,
    IconHeader,
    title,
    saveState = true,
    children,
    className
}) {
    const cardContent = useRef()
    const cardHeader = useRef()

    const [isOpen, setIsOpen] = useState(() => {
        if (saveState === true) {
            const isSelected = sessionStorage.getItem(`${title} card`)
            return ['true', null].includes(isSelected)
        }

        return false
    })

    useEffect(() => {
        onExpandCard(!isOpen)
    }, [])

    const onExpandCard = (isOpen) => {
        if (isOpen) {
            cardContent.current.style.maxHeight = '0px'
            cardContent.current.style.padding = '0px 16px'
            cardContent.current.style.opacity = '0'
            cardHeader.current.style.backgroundColor = 'transparent'
        } else {
            cardContent.current.style.maxHeight = 'max-content'
            cardContent.current.style.padding = '16px'
            cardContent.current.style.opacity = '1'
            cardHeader.current.style.backgroundColor =
                tailwindConfig.theme.extend.colors['blue-50-kiwi']
        }
    }

    const onToggleCard = () => {
        onExpandCard(isOpen)
        setIsOpen(!isOpen)

        if (saveState === true) {
            sessionStorage.setItem(`${title} card`, !isOpen)
        }
    }

    return (
        <div
            className={`rounded-xl border border-slate-200-kiwi w-full ${className}}`}>
            <div
                ref={cardHeader}
                onClick={onToggleCard}
                className="cursor-pointer p-4 bg-blue-50-kiwi rounded-xl -mt-[1px] -ml-[1px] w-[calc(100%+2px)] flex items-center">
                {img && <img src={img} className="w-6 mr-4" />}
                {IconHeader && <IconHeader />}

                <div className="font-semibold text-sm mr-auto ml-4">
                    {title}
                </div>

                {isOpen ? <UpIcon /> : <DownIcon />}
            </div>
            <div className="p-4 max-h-max overflow-hidden" ref={cardContent}>
                {children}
            </div>
        </div>
    )
}
