import React, { Fragment, useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import PlaidLogoSVG from 'assets/logos/plaid.svg'
import ProgressBar from 'components/ProgressBar'
import Button from 'components/Button'
import Advisement from 'components/Advisement'
import LoadingModal from 'components/Modals/Loading'
import {
    BankIcon,
    Wallet3Icon,
    BalanceIcon,
    TimeIcon
} from 'assets/icons_v3/fonts'
import { usePlaidLink } from 'react-plaid-link'
import { PlaidAPI, VerificationsAPI } from 'api'
import { ApprovedLoanContext } from 'context/ApprovedLoanContext'

export default function ApprovedLoanPaperCheckPlaid() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [linkTokenPlaid, setLinkTokenPlaid] = useState('')
    const { state: approvedState, saveState: saveApprovedState } =
        useContext(ApprovedLoanContext)

    useEffect(() => {
        if (approvedState.paperCheckPlaid.completed === true) {
            return navigate('/approved-loan/paper-check/reviewing')
        }

        createTokenPlaid()
    }, [])

    const createTokenPlaid = async () => {
        try {
            setLoading(true)
            setShowLoader(true)

            const {
                data: {
                    data: { link_token }
                }
            } = await PlaidAPI.generateToken()

            setLinkTokenPlaid(link_token)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
            setShowLoader(false)
        }
    }

    const { open: openPlaid } = usePlaidLink({
        token: linkTokenPlaid,
        onSuccess: async (public_token) => {
            try {
                setShowLoader(true)

                await PlaidAPI.publicTokenExchange({
                    public_token
                })

                await VerificationsAPI.postDisbursementCheck()

                saveApprovedState({
                    ...approvedState,
                    paperCheckPlaid: {
                        completed: true
                    }
                })

                navigate('/approved-loan/paper-check/reviewing')
            } catch (error) {
                console.error(error)
            } finally {
                setShowLoader(false)
            }
        },
        onExit: async () => {
            setShowLoader(false)
        }
    })

    const onContinue = async () => {
        await openPlaid()
        setShowLoader(true)
    }

    const onBackward = () => {
        navigate('/approved-loan/disbursement')
    }

    return (
        <Fragment>
            <ProgressBar progress="8/13" onBackward={onBackward} />
            <LoadingModal isVisible={showLoader} />
            <div className="mb-10">
                <h1 className="font-semibold mb-4 text-2xl">
                    Enlazar cuenta de banco
                </h1>
                <div className="mb-8">
                    Para poder validar tu cuenta bancaria, asegúrate que:
                </div>

                <div className="flex flex-col gap-6 ">
                    <div className="flex items-center">
                        <div className="flex h-10 w-10 bg-slate-100-kiwi rounded-full mr-4 flex-none">
                            <Wallet3Icon className="m-auto text-regal-blue-kiwi" />
                        </div>
                        <div className="text-sm">
                            Tu cuenta bancaria está a tu nombre
                        </div>
                    </div>
                    <div className="flex items-center">
                        <div className="flex h-10 w-10 bg-slate-100-kiwi rounded-full mr-4 flex-none">
                            <BalanceIcon className="m-auto text-regal-blue-kiwi" />
                        </div>
                        <div className="text-sm">
                            Actualmente no está sobregirada
                        </div>
                    </div>
                    <div className="flex items-center">
                        <div className="flex h-10 w-10 bg-slate-100-kiwi rounded-full mr-4 flex-none">
                            <TimeIcon className="m-auto text-regal-blue-kiwi" />
                        </div>
                        <div className="text-sm">
                            Tiene al menos tres meses de transacciones
                        </div>
                    </div>
                    <div className="flex items-center">
                        <div className="flex h-10 w-10 bg-slate-100-kiwi rounded-full mr-4 flex-none">
                            <BankIcon className="m-auto text-regal-blue-kiwi" />
                        </div>
                        <div className="text-sm">
                            Aquí es donde obtienes tu principal fuente de
                            ingresos
                        </div>
                    </div>
                </div>

                <img className="mx-auto mt-14" src={PlaidLogoSVG} />
            </div>

            <div className="flex flex-col mt-auto">
                <Advisement icon="shield_check">
                    Utilizamos Plaid para vincular tu cuenta bancaria de manera
                    segura, empleando protocolos de cifrado avanzados para
                    garantizar la protección de tus datos.
                </Advisement>

                <Button
                    className="bg-blue-kiwi text-white rounded-xl p-3 flex items-center justify-center mt-10"
                    onClick={onContinue}
                    loading={loading}>
                    Conectar cuenta de banco
                </Button>
            </div>
        </Fragment>
    )
}
