export default ({ className, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={className}
        {...props}>
        <path
            fill="currentColor"
            d="M15.764 4a3 3 0 0 1 2.683 1.658l1.383 2.765c.244-.1.487-.201.723-.318a1 1 0 0 1 .894 1.79c-.494.246-.72.322-.72.322l.956 1.913c.209.417.317.876.317 1.342V16c0 .889-.386 1.687-1 2.236V19.5a1.5 1.5 0 0 1-3 0V19H6v.5a1.5 1.5 0 0 1-3 0v-1.264c-.614-.55-1-1.348-1-2.236v-2.528a3 3 0 0 1 .317-1.341l.956-1.914c-.242-.101-.482-.207-.718-.321a1 1 0 0 1-.45-1.343 1.011 1.011 0 0 1 1.347-.445c.235.114.476.217.718.315l1.383-2.765A3 3 0 0 1 8.236 4Zm3.07 6.904C17.134 11.441 14.715 12 12 12s-5.134-.56-6.834-1.096l-1.06 2.12a1 1 0 0 0-.106.448V16a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-2.528a1 1 0 0 0-.106-.447l-1.06-2.12ZM7.5 13a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm9 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm-.736-7H8.236a1 1 0 0 0-.894.553L6.072 9.09C7.62 9.555 9.706 10 12 10s4.38-.445 5.927-.91l-1.269-2.537A1 1 0 0 0 15.764 6Z"
        />
    </svg>
)
