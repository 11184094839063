import React, { useContext } from 'react'
import { UserContext } from 'context/UserContext'
import { PERSONAL_LOAN_STATUS, CREDIT_BUILDER_STATUS } from 'constants'

import PersonalLoan from './PersonalLoan'
import CreditBuilder from './CreditBuilder'
import CreditMonitoring from './CreditMonitoring'

export default function HomeStatusActive() {
    const { state: userState } = useContext(UserContext)

    const {
        products: {
            personal_loan: { status: personalLoanStatus },
            credit_builder: { status: creditBuilderStatus }
        }
    } = userState

    const isPersonalLoanActive =
        personalLoanStatus === PERSONAL_LOAN_STATUS.current

    const isCreditBuilderActive =
        creditBuilderStatus === CREDIT_BUILDER_STATUS.current

    const isCreditMonitoringActive =
        isPersonalLoanActive || isCreditBuilderActive

    return (
        <div className="flex flex-col gap-6">
            {isPersonalLoanActive && <PersonalLoan />}
            {isCreditBuilderActive && <CreditBuilder />}
            {isCreditMonitoringActive && <CreditMonitoring />}
        </div>
    )
}
