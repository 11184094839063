export default ({ className, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={className}
        {...props}>
        <path
            fill="currentColor"
            d="M4 6.414C2.74 5.154 3.632 3 5.414 3h13.172C20.368 3 21.26 5.154 20 6.414l-7 7V19h2a1 1 0 1 1 0 2H9a1 1 0 1 1 0-2h2v-5.586l-7-7ZM5.414 5 12 11.586 18.586 5H5.414Z"
        />
    </svg>
)
