import * as Yup from 'yup';
import delay from 'delay';
import React, {useState, useEffect, Fragment, useContext} from 'react';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {useNavigate} from 'react-router-dom';
import {RequestLoanContext} from 'context/RequestLoanContext';
import mixpanel from 'utils/mixpanel';

import ProgressBar from 'components/ProgressBar';
import Button from 'components/Button';
import {UserContext} from 'context/UserContext';

export default function RequestLoanEmployer() {
    const navigate = useNavigate();

    const {state: userState} = useContext(UserContext);
    const {state: requestState, saveState} = useContext(RequestLoanContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dataLayer.push({
            event: 'new_application',
            eventCategory: 'loan_process',
            eventAction: 'loan_process_enterprise',
            dl_user_id: userState.user.id,
        });

        if (requestState.military.completed === false) {
            return navigate('/request-loan/military');
        }
    }, []);

    const onContinue = async employer => {
        if (loading === true) {
            return;
        }

        try {
            setLoading(true);

            saveState({
                ...requestState,
                employer: {
                    value: employer.value,
                    completed: true,
                },
            });

            mixpanel.track('Employer Status Survey', {
                Employer: employer.value,
            });

            navigate('/request-loan/seniority');
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onBackward = () => {
        navigate('/request-loan/military');
    };

    const validationSchema = Yup.object().shape({
        value: Yup.string().required('Este campo es requerido'),
    });

    return (
        <Formik
            initialValues={requestState.employer}
            validationSchema={validationSchema}
            onSubmit={onContinue}>
            {({errors, touched}) => (
                <Fragment>
                    <ProgressBar progress="7/12" onBackward={onBackward} />

                    <Form className="flex flex-col grow" autoComplete="off">
                        <h1 className="text-dark-kiwi font-semibold text-2xl">
                            ¿Cuál es el nombre del empleador o fuente de
                            ingresos?
                        </h1>

                        <div className="mb-10 mt-8">
                            <Field
                                name="value"
                                id="value"
                                type="text"
                                placeholder="Ej. Walmart, Seguro Social, Uber"
                                className={`focus:ring-0 outline-none w-full rounded-lg py-2 px-4 border border-solid ${
                                    errors.value && touched.value
                                        ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                        : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                }`}
                            />
                            <ErrorMessage
                                name="value"
                                component="small"
                                className="text-red-kiwi inline-block text-xs w-full mt-2"
                            />
                        </div>

                        <Button
                            className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto"
                            loading={loading}
                            type="submit">
                            Continuar
                        </Button>
                    </Form>
                </Fragment>
            )}
        </Formik>
    );
}
