import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import BaseModal from './Modal'

const EmailTermsModal = forwardRef(({}, ref) => {
    const BaseModalRef = useRef()

    useImperativeHandle(ref, () => ({
        ...BaseModalRef.current
    }))

    return (
        <BaseModal
            title="Consentimiento de Comunicación Email 12 CFR S1041"
            ref={BaseModalRef}>
            <p>
                Con su firma a continuación, incluso haciendo clic en "Firmar
                contrato” o "Continuar" u otros enlaces, usted acepta
                explícitamente recibir divulgaciones requeridas por 12 CFR §
                1041 a través de correo electrónico a la dirección vinculada a
                su cuenta con nosotros. Si desea retirar este consentimiento
                para correo electrónico, puede comunicarse con nosotros enviando
                una carta a 151 Calle de San Francisco Ste 200 PMB 1123, San
                Juan, PR 00901-1607, llamándonos al 787-490-2004, o enviando un
                correo electrónico a legal@kiwicredito.com. Tras la revocación
                de este consentimiento, enviaremos estas divulgaciones a la
                dirección postal asociada a su cuenta. Por favor, tenga en
                cuenta que, si se nos informa que los correos electrónicos no
                pueden ser entregados a la dirección de correo electrónico
                vinculada a su cuenta, no podremos enviar divulgaciones
                requeridas por 12 CFR § 1041 electrónicamente.
            </p>
        </BaseModal>
    )
})

export default EmailTermsModal
