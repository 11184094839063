import React from 'react'
import { LightBulbIcon } from '@heroicons/react/solid'

export default function WarningComponent({ children, className }) {
    return (
        <div className={`flex p-4 bg-yellow-50-kiwi rounded-lg ${className}`}>
            <div
                className={`flex items-center justify-center rounded-full h-10 w-10 shrink-0 bg-[#FFE9C7] mr-4`}>
                <LightBulbIcon className="text-yellow-kiwi w-6" />
            </div>
            <small className="text-xs">{children}</small>
        </div>
    )
}
