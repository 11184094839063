export default ({ className, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={className}
        {...props}>
        <path
            fill="currentColor"
            d="M9 2a3.99 3.99 0 0 1 3 1.354A3.99 3.99 0 0 1 15 2h.5A1.5 1.5 0 0 1 17 3.5V4c0 .729-.195 1.412-.535 2H19a2 2 0 0 1 2 2v3a2 2 0 0 1-1 1.732V18a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3v-5.268A2 2 0 0 1 3 11V8a2 2 0 0 1 2-2h2.535A3.982 3.982 0 0 1 7 4v-.5A1.5 1.5 0 0 1 8.5 2zm9 11h-5v6h4l.117-.007a1 1 0 0 0 .876-.876L18 18zm-7 0H6v5a1 1 0 0 0 .883.993L7 19h4zm8-5h-6v3h6zm-8 0H5v3h6zM9 4a2 2 0 0 0 2 2 2 2 0 0 0-2-2m6 0a2 2 0 0 0-2 2 2 2 0 0 0 2-2"
        />
    </svg>
)
