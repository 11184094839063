import React from 'react'
import { useNavigate } from 'react-router-dom'

import NotFoundPNG from 'assets/onboarding_v3/not_found.svg'

export default function NotFound() {
    const navigate = useNavigate()

    const onBackward = () => {
        navigate(-1)
    }

    return (
        <div className="w-full min-h-full flex flex-col px-6 py-10">
            <div className="flex flex-col justify-center text-center m-auto">
                <img className="mx-auto mb-10" src={NotFoundPNG} />
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    ¡Oops!
                </h1>
                <p>
                    Parece que no podemos encontrar la página que está buscando
                </p>
            </div>

            <button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                onClick={onBackward}>
                Volver a intentar
            </button>
        </div>
    )
}
