import banks from 'data/banks/PR'
import CITIES from 'data/cities'

export default {
    create: {
        allowedAge: {
            text: 'Debes tener 21 años para poder registrarte',
            number: 21
        }
    },
    home: {
        creditBuilderAvailable: true,
        creditMonitoringAvailable: true
    },
    address: {
        cities: CITIES.PR
    },
    approved: {
        banks,
        termsMonths(amount) {
            if (amount <= 499) return 6
            if (amount >= 500 && amount <= 599) return 8
            if (amount >= 600 && amount <= 699) return 9
            if (amount >= 700 && amount <= 850) return 10
            if (amount >= 851 && amount <= 999) return 11
            if (amount >= 1000 && amount <= 1099) return 12
            if (amount >= 1100 && amount <= 1250) return 15
            if (amount >= 1251 && amount <= 1500) return 18
            if (amount >= 1501) return 24
        },
        daysOptionsList: [
            15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
            32, 33, 34, 35
        ],
        lastDayOption: true,
        onCalculateInstallment(loanAmount, interestRate, months) {
            const monthlyInterestRate = interestRate / 100 / 12

            const monthlyPayment =
                (loanAmount * monthlyInterestRate) /
                (1 - Math.pow(1 + monthlyInterestRate, -months))

            return Number(monthlyPayment.toFixed(2))
        },
        paymentFrequency: 'mensual',
        personaTemplate: 'itmpl_J3L1kSS65gYtxCT5Meo914fY'
    }
}
