import React, { Fragment, useContext, useEffect, useState } from 'react'

import SuccessOnboardingSVG from 'assets/onboarding_v3/email_success.svg'
import Button from 'components/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { UsersAPI } from 'api'
import Emitter from 'utils/emitter'
import { UserContext } from 'context/UserContext'
import LoadingModal from 'components/Modals/Loading'

export default function ChangeEmailSuccess() {
    const navigate = useNavigate()
    const { tokenNewEmail } = useParams()

    const { setState: setUserState } = useContext(UserContext)
    const [showLoader, setShowLoader] = useState(true)

    useEffect(() => {
        verifyToken()
    }, [])

    const verifyToken = async () => {
        try {
            const { data } = await UsersAPI.verifyChangeEmailToken(
                tokenNewEmail
            )

            setUserState((prev) => ({
                ...prev,
                user: {
                    ...prev.user,
                    email: data.data.new_email,
                    new_email: null
                }
            }))

            setShowLoader(false)
        } catch (error) {
            const {
                response: {
                    data: { messages }
                }
            } = error

            if (messages === 'EMAIL_ALREADY_EXISTS') {
                Emitter.emit('onOpenNotification', {
                    type: 'error',
                    title: 'Correo electrónico ya existe',
                    message: `El correo electrónico que intentas cambiar ya fue tomado previamente, contacta a nuestro soporte <strong>support@kiwicredito.com</strong>`
                })
            } else if (messages === 'INVALID_TOKEN') {
                Emitter.emit('onOpenNotification', {
                    type: 'error',
                    title: 'Token de acceso expiró',
                    message: `El token para actualizar tu correo electrónico a expirado, intentalo nuevamente.`
                })
            } else {
                Emitter.emit('onOpenNotification', {
                    type: 'error',
                    title: 'Algo salió mal...',
                    message: `No pudimos cambiar tu correo electrónico, intentalo de nuevo o contacta a nuestro soporte <strong>support@kiwicredito.com</strong>`
                })
            }

            setUserState((prev) => ({
                ...prev,
                user: {
                    ...prev.user,
                    new_email: null
                }
            }))

            onContinue()
        }
    }

    const onContinue = () => {
        navigate('/personal-information')
    }

    if (showLoader) {
        return <LoadingModal isVisible={showLoader} />
    }

    return (
        <Fragment>
            <div className="flex flex-col justify-center text-center m-auto mb-10">
                <img className="mx-auto mb-8" src={SuccessOnboardingSVG} />
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    ¡Felicitaciones!
                </h1>
                <p>
                    ¡Felicidades! El cambio de tu correo electrónico se ha
                    realizado con éxito
                </p>
            </div>

            <Button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto"
                onClick={onContinue}>
                Aceptar
            </Button>
        </Fragment>
    )
}
