export default ({ className, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={className}
        {...props}>
        <path
            fill="currentColor"
            d="m12.67 2.217 8.5 4.75A1.5 1.5 0 0 1 22 8.31V9.75c0 .69-.56 1.25-1.25 1.25H20v8h1a1 1 0 1 1 0 2H3a1 1 0 1 1 0-2h1v-8h-.75C2.56 11 2 10.44 2 9.75V8.31c0-.522.27-1.002.706-1.274l8.623-4.819a1.5 1.5 0 0 1 1.342 0ZM18 11H6v8h3v-6h2v6h2v-6h2v6h3v-8Zm-6-6.882-8 4.5V9h16v-.382l-8-4.5ZM12 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2Z"
        />
    </svg>
)
