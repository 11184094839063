import React from 'react'
import InformationCircleSVG from 'assets/icons_v3/information_circle.svg'
import ShieldCheckSVG from 'assets/icons_v3/shield_check.svg'
import BulbMoneySVG from 'assets/icons_v3/bulb_money.svg'
import MoneyShopSVG from 'assets/icons_v3/money_shop.svg'
import LockedUserSVG from 'assets/icons_v3/locked_user.svg'
import EmailSVG from 'assets/icons_v3/email.svg'

export default function Advisement({ children, className, icon = '' }) {
    return (
        <div
            className={`flex items-start p-4 rounded-lg border border-slate-200-kiwi ${className}`}>
            {icon === '' && (
                <img className="mr-4 shrink-0" src={InformationCircleSVG} />
            )}

            {icon === 'bulb_money' && (
                <img className="mr-4 shrink-0" src={BulbMoneySVG} />
            )}

            {icon === 'shield_check' && (
                <img className="mr-4 shrink-0" src={ShieldCheckSVG} />
            )}

            {icon === 'money_shop' && (
                <img className="mr-4 shrink-0" src={MoneyShopSVG} />
            )}

            {icon === 'locked_user' && (
                <img className="mr-4 shrink-0" src={LockedUserSVG} />
            )}

            {icon === 'email' && (
                <img className="mr-4 shrink-0" src={EmailSVG} />
            )}

            <small className="text-xs">{children}</small>
        </div>
    )
}
