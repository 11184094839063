export default [
    {
        id: 178,
        name: 'ADJUNTAS PLAZA',
        address: 'Adjuntas Plaza Shopping Center, Local 13',
        city: 'Adjuntas',
        city_1: 'Adjuntas, PR 00601'
    },
    {
        id: 86,
        name: 'AGUADA MALL',
        address: 'Aguada Shopping/Road 115 KM. 22.4',
        city: 'Aguada',
        city_1: 'Aguada, PR 00602'
    },
    {
        id: 112,
        name: 'PLZ FERRAM',
        address: 'Road 2 Km 1.25 Plz. Ferran Shopping',
        city: 'Aguadilla',
        city_1: 'Aguadilla, PR 00603'
    },
    {
        id: 132,
        name: 'AGUADILLA MALL',
        address: 'Carr. #2 Km. 126.5 Ave. Corazones Local 5',
        city: 'Aguadilla',
        city_1: 'Aguadilla, PR 00605'
    },
    {
        id: 57,
        name: 'AGUAS BUENAS',
        address: '30 Rafael Lasa Street',
        city: 'Aguas Buenas',
        city_1: 'Aguas Buenas, PR 00703'
    },
    {
        id: 156,
        name: 'AIBONITO SHOPPI',
        address: 'Plaza Las Flores Carr. #14 KM. 52.7 Bo. Robles',
        city: 'Aibonito',
        city_1: 'Aibonito, PR 00705'
    },
    {
        id: 172,
        name: 'ANASCO SHOPPING',
        address: 'La Hacienda Town Center',
        city: 'Anasco',
        city_1: 'Anasco, PR 00610'
    },
    {
        id: 82,
        name: 'ARECIBO NORTE',
        address: 'Arecibo Shop/Luis Llorens Torr',
        city: 'Arecibo',
        city_1: 'Arecibo, PR 00612'
    },
    {
        id: 144,
        name: 'PLZ ATLANTICO',
        address: 'Plaza Atlantico Mall Loc. 27 A Road 2 Km. 80.0',
        city: 'Arecibo',
        city_1: 'Arecibo, PR 00612'
    },
    {
        id: 190,
        name: 'OUTLETS BARCELO',
        address: 'Premium Outlets, Barceloneta',
        city: 'Barceloneta',
        city_1: 'Barceloneta,PR 00617'
    },
    {
        id: 89,
        name: 'BARCELONETA',
        address:
            'Plaza Norbith, 1-T Davila Martinez Ave.,Carretera Estatal #140 Kilómetro 675',
        city: 'Barceloneta',
        city_1: 'Barceloneta, 00617'
    },
    {
        id: 73,
        name: 'BARRANQUITAS',
        address: 'Carr. 156 km. 17.1 Plaza San Cristobal Local #108',
        city: 'Barranquitas',
        city_1: 'Barranquitas, PR 00794'
    },
    {
        id: 165,
        name: 'PLAZA RIO HONDO',
        address: 'Ave. Comerio Local 55',
        city: 'Bayamon',
        city_1: 'Bayamon, PR 00961'
    },
    {
        id: 52,
        name: 'BAYAMON OESTE',
        address:
            'Bayamon Oeste Shopping Carr. 2 km. 14.7, Bo. Hato Tejas, Local 8A',
        city: 'Bayamon',
        city_1: 'Bayamon, P.R. 00959'
    },
    {
        id: 60,
        name: 'CANTON MALL',
        address: 'Canton Mall/Local F8 & F10',
        city: 'Bayamon',
        city_1: 'Bayamón, PR 00960'
    },
    {
        id: 137,
        name: 'STA. ROSA MALL',
        address: 'Carr. # 1 Km. 7.1 Local 39',
        city: 'Bayamon',
        city_1: 'Bayamón,PR 00959'
    },
    {
        id: 167,
        name: 'CABO ROJO PLAZA',
        address: 'Cabo Rojo Plaza Shopping Center',
        city: 'Cabo Rojo',
        city_1: 'Cabo Rojo, PR 00623'
    },
    {
        id: 134,
        name: 'LAS CATALINAS M',
        address: '#400 Calle Betances ID space #010025 Suite. 0025',
        city: 'Caguas',
        city_1: 'Caguas, PR 00726'
    },
    {
        id: 98,
        name: 'PLZ CARMEN',
        address: 'Carr. 3 Villa del Carmen, Loc. #29',
        city: 'Caguas',
        city_1: 'Caguas, PR 00726'
    },
    {
        id: 33,
        name: 'CAMUY',
        address: '16 Munoz Rivera Corner Adames St.',
        city: 'Camuy',
        city_1: 'Camuy, PR 00627'
    },
    {
        id: 131,
        name: 'SHOPPING COURT',
        address: 'Ave. Roberto Clemente, Esq. 65 Infanteria Suite 107',
        city: 'Carolina',
        city_1: 'Carolina, PR 00984'
    },
    {
        id: 8,
        name: 'CATANO',
        address: '137 Barbosa St',
        city: 'Catano',
        city_1: 'Catano, PR 00962'
    },
    {
        id: 153,
        name: 'MONTELLANOS SHO',
        address: 'Plaza Cayey Centro,Carr. #1 KM 55.2',
        city: 'Cayey',
        city_1: 'Cayey, PR 00736'
    },
    {
        id: 149,
        name: 'CIDRA SHOPPING',
        address: 'Cidra Shopping Center',
        city: 'Cidra',
        city_1: 'Cidra, PR 00739'
    },
    {
        id: 150,
        name: 'COAMO SHOPPING',
        address: 'Carr. #153, km. 13.7, local #9, Bo. San Idefonso',
        city: 'Coamo',
        city_1: 'Coamo, PR 00769'
    },
    {
        id: 164,
        name: 'COMERIO II',
        address: 'Calle Georgetti #67',
        city: 'Comerio',
        city_1: 'Comerio, PR 00782'
    },
    {
        id: 102,
        name: 'DORADO',
        address: 'Dorado Shop. Road 693 Km. 8.0',
        city: 'Dorado',
        city_1: 'Dorado, PR 00646'
    },
    {
        id: 103,
        name: 'FAJARDO MALL',
        address: 'Plz. Fajardo Shop. Rd. 3 Km. 44.6',
        city: 'Fajardo',
        city_1: 'Fajardo, PR 00738'
    },
    {
        id: 109,
        name: 'PLZ GUAYAMA',
        address: 'Carr. 3. km. 134.7 local #43 Ave. Los Veteranos',
        city: 'Guayama',
        city_1: 'Guayama, PR 00784'
    },
    {
        id: 128,
        name: 'SAN PATRICIO',
        address: 'San Patricio Shopping F-10',
        city: 'Guaynabo',
        city_1: 'Guaynabo, PR 00968'
    },
    {
        id: 184,
        name: 'HATILLO TOWN CE',
        address: 'Int. State Roads PR-2 KM.89.9',
        city: 'Hatillo',
        city_1: 'Hatillo, PR 00659'
    },
    {
        id: 129,
        name: 'PALMA REAL',
        address: 'Plaza Palma Real 350 Road 3',
        city: 'Humacao',
        city_1: 'Humacao, PR 00791'
    },
    {
        id: 163,
        name: 'HUMACAO CENTRO',
        address: 'Calle Font  Martelo #10A',
        city: 'Humacao',
        city_1: 'Humacao, PR 00792'
    },
    {
        id: 183,
        name: 'ORIENTAL PLAZA',
        address: 'Carretera Estatal #3 y Carretera #908',
        city: 'Humacao',
        city_1: 'Humacao, PR 00791'
    },
    {
        id: 124,
        name: 'PLZ ISABELA',
        address: 'Plaza Isabela Shop. Road 2',
        city: 'Isabela',
        city_1: 'Isabela, PR 00662'
    },
    {
        id: 34,
        name: 'ISABELA',
        address: '48 Barbosa Street',
        city: 'Isabela',
        city_1: 'Isabela, PR 00662'
    },
    {
        id: 68,
        name: 'JAYUYA',
        address: 'Calle Guillermo Esteves',
        city: 'Jayuya',
        city_1: 'Jayuya, PR 00664'
    },
    {
        id: 99,
        name: 'JUANA DIAZ MALL',
        address: 'Plz. Juana Diaz Shop. Road 149',
        city: 'Juana Diaz',
        city_1: 'Juana Diaz, PR 00795'
    },
    {
        id: 40,
        name: 'LAJAS',
        address: 'Centro Comercial Municipal',
        city: 'Lajas',
        city_1: 'Lajas, PR 00667'
    },
    {
        id: 90,
        name: 'LARES MALL',
        address: 'Lares Mall Shop. Road 111 KM. 3.1',
        city: 'Lares',
        city_1: 'Lares,PR  00669'
    },
    {
        id: 147,
        name: 'LAS PIEDRAS SHO',
        address: 'Caribbean Shopping Center,Carretera 198 Km. 20.1',
        city: 'Las Piedras',
        city_1: 'Las Piedras, PR 00771'
    },
    {
        id: 162,
        name: 'MONTE REAL PLAZ',
        address: 'Loc. #20 State Road #2, KM 45.6',
        city: 'Manati',
        city_1: 'Manati, PR 00674'
    },
    {
        id: 154,
        name: 'ATENAS PLAZA',
        address: 'Carr. 2 km. 50.0, Barrio Bajura',
        city: 'Manati',
        city_1: 'Manati, PR 00674'
    },
    {
        id: 133,
        name: 'MAYAGUEZ MALL',
        address: 'Ave. Hostos 975 Suite 415',
        city: 'Mayaguez',
        city_1: 'Mayaguez, PR 00680'
    },
    {
        id: 69,
        name: 'POST',
        address: '10 Munoz Rivera & Post Street',
        city: 'Mayaguez',
        city_1: 'Mayaguez, PR 00681'
    },
    {
        id: 106,
        name: 'MOCA',
        address: '184 Don Chemary Street',
        city: 'Moca',
        city_1: 'Moca, PR 00676'
    },
    {
        id: 91,
        name: 'NAGUABO',
        address: 'Space #3 & #4 Plaza Naguabo',
        city: 'Naguabo',
        city_1: 'Naguabo, PR  00718'
    },
    {
        id: 157,
        name: 'NARANJITO SHOPP',
        address: 'El Mercado Plaza,Carr #152 KM 16.00 Bo. Cedro Abajo',
        city: 'Naranjito',
        city_1: 'Naranjito, PR 00719'
    },
    {
        id: 93,
        name: 'OROCOVIS',
        address: '5 4 de Julio Street',
        city: 'Orocovis',
        city_1: 'Orocovis, PR  00720'
    },
    {
        id: 61,
        name: 'PATILLAS',
        address: 'Calle Riefkol Carr. 3 km. 123.1',
        city: 'Patillas',
        city_1: 'Patillas, PR 00723'
    },
    {
        id: 171,
        name: 'PLAZA PENUELAS',
        address: 'Carr.385, Km 0.7, Local C-20 Bo. Cuevas',
        city: 'Penuelas',
        city_1: 'Penuelas, PR 00624'
    },
    {
        id: 111,
        name: 'EUROPONCE',
        address: 'Road 22 Km. 227.9 Loc. 129-130',
        city: 'Plaza Del Caribe',
        city_1: 'Plaza Del Caribe'
    },
    {
        id: 84,
        name: 'CENTRO SUR',
        address: 'Centro Del Sur Shopping Center',
        city: 'Ponce',
        city_1: 'Ponce, PR 00717'
    },
    {
        id: 168,
        name: 'EL MONTE TOWN C',
        address: 'Store #102 ST Road PR-14',
        city: 'Ponce',
        city_1: 'Ponce, PR 00731'
    },
    {
        id: 10,
        name: 'PONCE',
        address: 'Santa María Shopping Center',
        city: 'Ponce',
        city_1: 'Ponce, PR 00733'
    },
    {
        id: 49,
        name: 'QUEBRADILLA',
        address: '116 San Carlos Street',
        city: 'Quebradilla',
        city_1: 'Quebradilla, PR 00678'
    },
    {
        id: 166,
        name: 'RINCON',
        address: 'Carr. #115 K.m. 11.4',
        city: 'Rincon',
        city_1: 'Rincon, PR 00677'
    },
    {
        id: 140,
        name: 'EL SENORIAL',
        address: 'Señorial Plaza, Loc. E003  Expo Las Américas & PR 177',
        city: 'Rio Piedras',
        city_1: 'Rio Piedras, PR 00926'
    },
    {
        id: 44,
        name: 'SABANA GRANDE',
        address: '4 Angel Gregorio Martinez Street',
        city: 'Sabana Grande',
        city_1: 'Sabana Grande, PR 00637'
    },
    {
        id: 100,
        name: 'S. GERMAN MALL',
        address: 'San German Shop/L.5 Road 2 & 122, Km. 174.4',
        city: 'San German',
        city_1: 'San German, PR 00683'
    },
    {
        id: 4,
        name: 'RIO PIEDRAS',
        address: '108 Calle José De Diego, Rio Piedras',
        city: 'San Juan',
        city_1: 'San Juan, PR 00925'
    },
    {
        id: 136,
        name: 'PLZ S.SEBASTIAN',
        address: 'Carr. 111 km. 17.6, Bo. Guatemala',
        city: 'San Sebastian',
        city_1: 'San Sebastian, PR 00685'
    },
    {
        id: 182,
        name: 'GALERIA PASEOS',
        address: 'Avenida Las Cumbres',
        city: 'San Juan',
        city_1: 'San Juan, PR 00926'
    },
    {
        id: 152,
        name: 'S LORENZO SHOPP',
        address: 'Carr. #183 Km. 1.0',
        city: 'San Lorenzo',
        city_1: 'San Lorenzo, PR 00754'
    },
    {
        id: 177,
        name: 'SANTA ISABEL PL',
        address: 'Plaza Los Prados del Sur,Local B-13',
        city: 'Santa Isabel',
        city_1: 'Santa Isabel, PR 00757'
    },
    {
        id: 15,
        name: 'BARRIO OBRERO',
        address: '2270 Borinquen Ave, Bo. Obrero',
        city: 'Santurce',
        city_1: 'Santurce, PR 00915'
    },
    {
        id: 158,
        name: 'PLAZA AQUARIUM',
        address: 'Carretera #165 Int. PR -159, Bo. Quebrada Cruz,',
        city: 'Toa Alta',
        city_1: 'Toa Alta, PR 00953'
    },
    {
        id: 55,
        name: 'UTUADO',
        address: 'Centro Comercial Santa Rosa (Plaza Utuado)',
        city: 'Utuado',
        city_1: 'Utuado, PR 00641'
    },
    {
        id: 67,
        name: 'PLZ CARIBE',
        address: 'Plaza Caribe Mall  140 Carr. 678  Local S-005',
        city: 'Vega Alta',
        city_1: 'Vega Alta, PR 00692'
    },
    {
        id: 64,
        name: 'VILLALBA',
        address: '30 Munoz Rivera Street',
        city: 'Villalba',
        city_1: 'Villalba, PR 00766'
    },
    {
        id: 161,
        name: 'YABUCOA PLAZA',
        address: 'Yabucoa Plaza Shopping Center',
        city: 'Yabucoa',
        city_1: 'Yabucoa, PR 00767'
    },
    {
        id: 66,
        name: 'YAUCO PLAZA',
        address: 'Yauco Plz/Ground Floor-Road 128',
        city: 'Yauco',
        city_1: 'Yauco, PR 00698'
    }
]
