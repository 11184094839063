import http from 'utils/http'

const createRating = async ({ score, description }) => {
    const response = await http.post('/rating', {
        score,
        description
    })

    return response
}

export default {
    createRating
}
