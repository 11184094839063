import ProgressBar from 'components/ProgressBar'
import { UserContext } from 'context/UserContext'
import React, { Fragment, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import phoneFormatter from 'phone-formatter'
import Button from 'components/Button'

export default function ApprovedLoanATHVerification() {
    const { state: userState } = useContext(UserContext)
    const navigate = useNavigate()

    const onBackward = () => {
        navigate('/approved-loan/disbursement')
    }

    const onContinue = () => {
        navigate('/approved-loan/repayment-options')
    }

    return (
        <Fragment>
            <ProgressBar progress="6/11" onBackward={onBackward} />

            <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                Confirma tu cuenta ATH Móvil para recibir tu desembolso
            </h1>

            <p>
                Para recibir los fondos de manera segura, confirma que el número
                asociado a tu cuenta ATH Móvil es el correcto:{' '}
                <span className="font-semibold">
                    {phoneFormatter.format(
                        userState.user.phone_number,
                        '+1 (NNN) NNN-NNNN'
                    )}
                </span>
            </p>

            <p className="text-xs text-gray-500-kiwi mt-auto">
                Si este no es tu número de ATH Móvil, puedes contactar a nuestro
                equipo de soporte enviando un correo a{' '}
                <strong>support@kiwicredito.com</strong>
            </p>

            <Button
                className="bg-blue-kiwi text-white rounded-xl p-3 flex items-center justify-center mt-10"
                onClick={onContinue}>
                Confirmar ATH Móvil
            </Button>
        </Fragment>
    )
}
