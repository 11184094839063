import React from 'react'

export default function CourseSkeleton() {
    return (
        <div>
            <div className="h-40 bg-gray-200-kiwi rounded-t-lg"></div>
            <div className="h-8 bg-gray-300-kiwi"></div>
            <div className="flex justify-between items-center mt-4">
                <div className="h-3 w-1/2 bg-gray-300-kiwi rounded-full"></div>
                <div className="h-3 w-3 bg-gray-300-kiwi rounded-full"></div>
            </div>
        </div>
    )
}
