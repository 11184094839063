import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { useContext, useEffect, useState, Fragment } from 'react'
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'

import OnePayMethodModal from 'components/Modals/OnePayMethod'
import Button from 'components/Button'
import InformationIcon from 'assets/onboarding_v3/information_circle.svg'

import { useLocation, useNavigate } from 'react-router-dom'
import moneyFormat from 'utils/moneyFormat'
import { UserContext } from 'context/UserContext'
import { BankCardIcon, BankIcon, ChevronLeftIcon } from 'assets/icons_v3/fonts'
import formatDate from 'utils/formatDate'
import { LoanAPI, PaymentAPI, ProductsAPI } from 'api'
import Advisement from 'components/Advisement'
import getLastProfileDigits from 'utils/getLastProfileDigits'
import LoadingModal from 'components/Modals/Loading'
import PaymentTermsModal from 'components/Modals/PaymentTerms'
import AlertInformationModal from 'components/Modals/AlertInformation'

dayjs.extend(utc)

export default function ActiveLoanNextPayment() {
    const navigate = useNavigate()
    const location = useLocation()

    const { getData: getDataFingerprint } = useVisitorData(
        { extendedResult: true },
        { immediate: true }
    )

    const { state: userState, setState: setUserState } = useContext(UserContext)
    const {
        products: {
            personal_loan: {
                loan: {
                    id,
                    loanpro_user_id,
                    nextPaymentDate,
                    has_collection_management,
                    paymentCollectionManagement
                }
            }
        }
    } = userState

    const [loading, setLoading] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [paymentAmount, setPaymentAmount] = useState(0)
    const [primaryProfile, setPrimaryProfile] = useState({})
    const [paymentProfiles, setPaymentProfiles] = useState([])
    const [visibleProfilesModal, setVisibleProfilesModal] = useState(false)
    const [visibleTermsModal, setVisibleTermsModal] = useState(false)
    const [hasMoreCreditCards, setHasMoreCreditCards] = useState(false)
    const [showModalAlertInformation, setShowModalAlertInformation] =
        useState(false)

    useEffect(() => {
        const { state } = location

        if (state === null) {
            return navigate('/active-loan/pay-fee')
        }

        const { paymentAmount } = state

        setPaymentAmount(paymentAmount)
        getPaymentProfiles()
    }, [])

    useEffect(() => {
        countingCreditCards()
    }, [paymentProfiles])

    const getPaymentProfiles = async () => {
        try {
            setLoading(true)

            const {
                data: {
                    data: { results: profiles }
                }
            } = await PaymentAPI.getProfiles()

            const primaryProfile = profiles.find(
                (profile) => profile.isPrimary === 1
            )

            const activeProfiles = profiles.filter((profile) => profile.active === 1 && profile.visible === 1)

            setPrimaryProfile(primaryProfile)
            setPaymentProfiles(activeProfiles)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onBackward = () => {
        navigate('/active-loan/pay-fee')
    }

    const fingerprintSetData = async () => {
        try {
            await getDataFingerprint({
                ignoreCache: true,
                tag: {
                    env: process.env.REACT_APP_FINGERPRINT_ENV,
                    user_id: userState.user.id,
                    place: 'payment_log_success'
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    const onContinue = async () => {
        try {
            setShowLoader(true)

            if (has_collection_management && primaryProfile.checkingAccountId) {
                return setShowModalAlertInformation(true)
            }

            const {
                data: { success }
            } = await LoanAPI.makeLoanPayment({
                amount: paymentAmount,
                loanpro_loan_id: id,
                loanpro_user_id: loanpro_user_id,
                method_type: primaryProfile.type,
                method_id: primaryProfile.id
            })

            if (success) {
                const {
                    data: { data: personal_loan }
                } = await ProductsAPI.getPersonalLoan()

                setUserState({
                    ...userState,
                    products: {
                        ...userState.products,
                        personal_loan
                    }
                })

                fingerprintSetData()

                navigate('/active-loan/payment-success')
            }
        } catch (error) {
            const {
                response: { data }
            } = error

            if (data.error) {
                const message = data.error.includes('fund')
                    ? 'no_balance'
                    : data.error

                navigate('/active-loan/payment-failed', {
                    state: {
                        message
                    }
                })
            }
        } finally {
            setShowLoader(false)
        }
    }

    const onToggleProfileModal = () => {
        setVisibleProfilesModal(!visibleProfilesModal)
    }

    const onToggleTermsModal = () => {
        setVisibleTermsModal(!visibleTermsModal)
    }

    const sendCard = (selectedProfile) => {
        setPrimaryProfile(selectedProfile)
        onToggleProfileModal()
    }

    const onAddNewCard = () => {
        navigate('/active-loan/payment-method/card')
    }

    const closeModalAlertInformation = () => {
        setShowModalAlertInformation(false)
        setLoading(false)
    }

    const countingCreditCards = () => {
        const count = paymentProfiles.filter((profile) => profile.checkingAccountId === null).length

        if (count >= 2) {
            return setHasMoreCreditCards(true)
        }

        setHasMoreCreditCards(false)
    }

    const description = paymentCollectionManagement
        ? `Pago procesado el
    <span class="font-semibold">
        ${dayjs(paymentCollectionManagement?.createDate).format('MM/DD/YY')}
    </span>
    a tu cuenta que termina en *<span class="font-semibold">${String(
            paymentCollectionManagement?.accountNumber?.slice(-4)
        ).slice(-4)}
    </span>
    está pendiente a confirmación de tu banco. Favor de contactar a nuestro equipo de soporte si prefieres pagar con otro método de pago o deseas consultar el estado del pago actual.`
        : 'El último pago procesado a tu cuenta está pendiente a confirmación de su banco. Favor de contactar a nuestro equipo de soporte si prefieres pagar con otro método de pago o deseas consultar el estado del pago actual.'

    return (
        <Fragment>
            <ChevronLeftIcon
                className="w-2 cursor-pointer mb-12"
                onClick={onBackward}
            />

            <h1 className="text-dark-kiwi font-semibold text-2xl mb-10">
                Realizar pago
            </h1>

            <div className="flex flex-col gap-6 mb-10">
                <div className="text-center">
                    <p className="text-gray-600-kiwi text-sm mb-1">
                        Monto a pagar
                    </p>
                    <div className="text-dark-kiwi font-semibold text-6xl">
                        ${moneyFormat(paymentAmount)}
                    </div>
                </div>

                <div className="text-sm flex flex-col gap-4">
                    <div className="flex items-center justify-between">
                        <div>Paga desde</div>
                        <strong>
                            **** {getLastProfileDigits(primaryProfile)}
                        </strong>
                    </div>
                    <div className="flex items-center justify-between">
                        <div>Fecha pago</div>
                        <strong>
                            {formatDate(nextPaymentDate, 'MMMM DD, YYYY')}
                        </strong>
                    </div>
                </div>

                <div className="bg-gray-50-kiwi p-4 rounded-xl">
                    {loading === true ? (
                        <div className="flex items-center">
                            <div className="h-10 w-10 bg-white mr-4 grow-0 shrink-0 rounded-full"></div>
                            <div className="w-2/3">
                                <div className="bg-white mb-2 h-3 w-full rounded-lg"></div>
                                <div className="bg-white h-2 w-2/3 rounded-lg"></div>
                            </div>
                        </div>
                    ) : (
                        <div
                            className="flex items-center rounded-lg cursor-pointer"
                            onClick={onToggleProfileModal}>
                            <div className="h-10 w-10 bg-white mr-4 grow-0 shrink-0 rounded-full flex">
                                {primaryProfile.checkingAccountId ? (
                                    <BankIcon className="w-6 m-auto text-regal-blue-kiwi" />
                                ) : (
                                    <BankCardIcon className="w-6 m-auto text-regal-blue-kiwi" />
                                )}
                            </div>
                            <div className="mr-auto">
                                <div className="leading-4 font-semibold mb-1">
                                    {primaryProfile.checkingAccountId
                                        ? 'Cuenta'
                                        : 'Tarjeta'}{' '}
                                    **** ****{' '}
                                    {getLastProfileDigits(primaryProfile)}
                                </div>
                                <p className="text-xs text-gray-500-kiwi">
                                    Cuenta registrada
                                </p>
                            </div>
                        </div>
                    )}
                </div>

                <Advisement>
                    Puede tardar hasta <strong>3 días laborables</strong> para
                    que su pago se refleje en su cuenta de banco.
                </Advisement>
            </div>

            <div className="mt-auto flex flex-col items-center">
                <div className="mb-6 text-xs text-gray-600">
                    Al elegir "Pagar", autorizo a Kiwi Financial Inc para
                    iniciar un pago electrónico desde mi cuenta bancaria
                    indicada, y autorizo ​​a mi banco a procesar el retiro.{' '}
                    <span
                        className="text-blue-kiwi"
                        onClick={onToggleTermsModal}>
                        Ver términos y condiciones.
                    </span>
                </div>

                <Button
                    loading={loading}
                    className="bg-blue-kiwi text-white rounded-xl p-3 w-full"
                    onClick={onContinue}>
                    Pagar
                </Button>

                <div
                    className="text-blue-kiwi mt-6 mx-auto cursor-pointer"
                    onClick={onToggleProfileModal}>
                    Usar otra cuenta o tarjeta
                </div>
            </div>

            <AlertInformationModal
                isVisible={showModalAlertInformation}
                icon={InformationIcon}
                title="Tienes una transacción pendiente"
                description={description}
                closeModal={closeModalAlertInformation}
            />

            <OnePayMethodModal
                sendCard={sendCard}
                paymentMethods={paymentProfiles}
                isVisible={visibleProfilesModal}
                closeModal={onToggleProfileModal}
                onAddNewCard={onAddNewCard}
                hasMoreCreditCards={hasMoreCreditCards}
            />

            <PaymentTermsModal
                isVisible={visibleTermsModal}
                closeModal={onToggleTermsModal}
            />

            <LoadingModal isVisible={showLoader} />
        </Fragment>
    )
}
