import React from 'react'
import { Route } from 'react-router-dom'

/**
 * Middlewares
 */
import { LocationAllowed } from './middlewares/UnauthMiddleware'

/**
 * Routes
 */
import Splash from 'views/mobile/Splash'
import Tour from 'views/mobile/Tour'

import Location from 'views/mobile/Location'
import LocationSelectState from 'views/mobile/Location/SelectState'
import LocationNoAvailable from 'views/mobile/Location/NoAvailable'

import Login from 'views/mobile/Login'
import CodeVerification from 'views/mobile/CodeVerification'

import CreateAccountLayout from 'views/mobile/CreateAccount'
import CreateAccount from 'views/mobile/CreateAccount/Create'
import CreateAccountAttribution from 'views/mobile/CreateAccount/Attribution'
import CreateAccountSuccess from 'views/mobile/CreateAccount/Success'

const UnauthRoutes = (
    <Route element={<LocationAllowed />}>
        <Route exact path="" element={<Splash />} />
        <Route exact path="tour" element={<Tour />} />
        <Route exact path="login" element={<Login />} />
        <Route exact path="code-verification" element={<CodeVerification />} />

        <Route exact path="location">
            <Route exact path="" element={<Location />} />
            <Route
                exact
                path="select-state"
                element={<LocationSelectState />}
            />
            <Route
                exact
                path="not-available"
                element={<LocationNoAvailable />}
            />
        </Route>

        <Route exact path="create-account" element={<CreateAccountLayout />}>
            <Route exact path="" element={<CreateAccount />} />
            <Route
                exact
                path="attribution"
                element={<CreateAccountAttribution />}
            />
            <Route exact path="success" element={<CreateAccountSuccess />} />
        </Route>
    </Route>
)

export default UnauthRoutes
