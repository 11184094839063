import React, { Fragment } from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import BaseModal from '../BaseModal'
import Button from 'components/Button'
import NumberFormat from 'react-number-format'
import moneyToNumber from 'utils/moneyToNumber'

const AmountHomeOwnershipModal = ({
    isVisible,
    closeModal,
    changeAmount,
    type
}) => {
    const initialValues = {
        amount: ''
    }

    const onContinue = ({ amount }) => {
        const moneyFormatted = moneyToNumber(amount, true)

        changeAmount(moneyFormatted)
        closeModal()
    }

    const validationSchema = Yup.object().shape({
        amount: Yup.string()
            .required('Este campo es requerido')
            .test(
                'max-amount',
                'El monto debe ser igual o menor a $9999',
                (money) => {
                    if (money) {
                        let amount = Number(
                            money.replaceAll(',', '').replace('$ ', '')
                        )

                        if (amount <= 9999) {
                            return true
                        }
                    }
                }
            )
    })

    return (
        <BaseModal
            title={`¿Cuánto pagas de ${type.toLowerCase()} mensual?`}
            isVisible={isVisible}
            closeModal={closeModal}>
            <div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onContinue}>
                    {({ errors, touched }) => (
                        <Fragment>
                            <Form
                                className="flex flex-col grow"
                                autoComplete="off">
                                <div className="mb-auto mt-5">
                                    <label
                                        htmlFor="amount"
                                        className="inline-block text-sm mb-2">
                                        Ingresa el monto
                                    </label>
                                    <div className="mb-2">
                                        <Field name="amount" id="amount">
                                            {({ field, ...props }) => (
                                                <NumberFormat
                                                    id="amount"
                                                    {...field}
                                                    {...props}
                                                    thousandSeparator={true}
                                                    prefix={'$ '}
                                                    allowNegative={false}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    placeholder="$ 0.00"
                                                    className={`w-full rounded-lg py-2 px-4 border ${
                                                        errors.amount &&
                                                        touched.amount
                                                            ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                                            : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                                    }`}
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage
                                            name="amount"
                                            component="small"
                                            className="text-red-kiwi inline-block text-xs w-full mt-2"
                                        />
                                    </div>
                                </div>

                                <Button
                                    className="bg-blue-kiwi text-white rounded-xl p-3 mt-5 text-base"
                                    type="submit">
                                    Continuar
                                </Button>
                            </Form>
                        </Fragment>
                    )}
                </Formik>
            </div>
        </BaseModal>
    )
}

export default AmountHomeOwnershipModal
