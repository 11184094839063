import http from 'utils/http'

export const getUserReferrals = async (user_id) => {
    const response = await http.get(`/referrals/my-referrals/${user_id}`)

    return response
}

const validateReferralCode = async ({ code, to }) => {
    const response = await http.post('/referrals', {
        code,
        to
    })

    return response
}

export default {
    getUserReferrals,
    validateReferralCode
}
