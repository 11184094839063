import React, { useEffect, useContext, useState, Fragment } from 'react'
import { useNavigate, Outlet, Navigate } from 'react-router-dom'
import { ApprovedLoanContext } from 'context/ApprovedLoanContext'
import { VerificationsAPI } from 'api'
import { DISBURSEMENT_CHECK_STATUS } from 'constants'

export default function ApprovedLoanPaperCheck() {
    const navigate = useNavigate()
    const {
        state: approvedState,
        saveState: saveApprovedState,
        resetState: resetApprovedState
    } = useContext(ApprovedLoanContext)
    const [showOutlet, setShowOutlet] = useState(false)

    useEffect(() => {
        disbursementCheckStatus()
    }, [])

    const disbursementCheckStatus = async () => {
        const { data } = await VerificationsAPI.getDisbursementCheck()
        if (data?.status === DISBURSEMENT_CHECK_STATUS.rejected) {
            await resetApprovedState()
            return navigate('/approved-loan/amount')
        }
        if (data?.status === DISBURSEMENT_CHECK_STATUS.resolved) {
            saveApprovedState({
                ...approvedState,
                paperCheckPlaid: {
                    completed: true
                },
                paperCheckReviewing: {
                    completed: true
                }
            })

            return navigate('/approved-loan/repayment-options')
        }

        setShowOutlet(true)
    }

    if (showOutlet) {
        return <Outlet />
    }

    return <Fragment />
}
