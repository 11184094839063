import * as Yup from 'yup'
import React, { useState, useRef, useContext } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useNavigate } from 'react-router-dom'
import { UsersAPI } from 'api'

import EmailOnboardingSVG from 'assets/onboarding_v3/email_plus.svg'
import Button from 'components/Button'
import BackwardButton from 'components/Button/Backward'
import Advisement from 'components/Advisement'
import { UserContext } from 'context/UserContext'

export default function ChangeEmailForm() {
    const navigate = useNavigate()
    const form = useRef()
    const { setState: setUserState } = useContext(UserContext)

    const [loading, setLoading] = useState(false)

    const onSendEmail = async ({ email }) => {
        try {
            setLoading(true)

            await UsersAPI.changeEmail(email)

            setUserState((prev) => ({
                ...prev,
                user: {
                    ...prev.user,
                    new_email: email
                }
            }))

            navigate('/change-email/sent', {
                state: {
                    new_email: email
                }
            })
        } catch (error) {
            const {
                response: {
                    data: { messages }
                }
            } = error

            if (messages === 'EMAIL_ALREADY_EXISTS') {
                return form.current.setFieldError(
                    'email',
                    'Problemas al cambiar tu correo electrónico, parece que ya existe.'
                )
            }

            form.current.setFieldError(
                'email',
                'Problemas al cambiar tu correo electrónico, intentalo de nuevo.'
            )
        } finally {
            setLoading(false)
        }
    }

    const onBackward = () => {
        navigate('/personal-information')
    }

    const initialValues = {
        email: ''
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Este campo es requerido')
            .email('Correo electrónico inválido')
    })

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSendEmail}
            validationSchema={validationSchema}
            innerRef={form}>
            {({ errors, touched }) => (
                <Form className="flex flex-col grow" autoComplete="off">
                    <BackwardButton onBackward={onBackward} />

                    <div className="flex flex-col text-center">
                        <img
                            className="mx-auto mb-8"
                            src={EmailOnboardingSVG}
                        />
                        <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                            ¿Quieres cambiar tu correo electrónico?
                        </h1>
                        <p>
                            Ingresa el nuevo correo electrónico y te enviaremos
                            enlace de verificación
                        </p>
                    </div>

                    <div className="flex flex-col gap-6 my-10">
                        <div>
                            <label
                                htmlFor="email"
                                className="inline-block text-sm mb-2">
                                Nuevo correo electrónico
                            </label>
                            <Field
                                id="email"
                                type="email"
                                placeholder="example@gmail.com"
                                name="email"
                                disabled={loading}
                                className={`w-full rounded-lg py-2 px-4 border ${
                                    errors.email && touched.email
                                        ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                        : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                }`}
                            />
                            <ErrorMessage
                                name="email"
                                component="small"
                                className="text-red-kiwi inline-block text-xs w-full mt-2"
                            />
                        </div>
                    </div>

                    <div className="mt-auto">
                        <Advisement className="mb-6" icon="email">
                            En caso de no poder validar el correo electrónico
                            puede comunicarse con nuestro equipo de soporte o
                            enviar un correo a{' '}
                            <span className="font-semibold">
                                support@kiwicredito.com
                            </span>
                        </Advisement>

                        <Button
                            id="create_account_success-gtm"
                            className="bg-blue-kiwi w-full text-white rounded-xl p-3"
                            type="submit"
                            loading={loading}>
                            Cambiar correo
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
