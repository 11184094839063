import React from 'react'
import BaseModal from './BaseModal'

const PrivacyTermsModal = ({ isVisible, closeModal }) => {
    return (
        <BaseModal
            title="Consentimiento de Firma Electrónica (ESIGN)"
            description="DIVULGACIÓN Y CONSENTIMIENTO PARA ELECTRÓNICA RECEPCIÓN DE COMUNICACIONES Y FIRMAS ELECTRÓNICAS"
            isVisible={isVisible}
            closeModal={closeModal}>
            <div className="flex flex-col gap-6">
                <div>
                    Este Consentimiento de Firma Electrónica (este
                    “Consentimiento”) es proporcionado por Kiwi Financial, Inc.
                    (&quot;Kiwi&quot; o &quot;nosotros&quot;) y, en la medida en
                    que solicite, use u obtenga cualquier servicio a través de
                    la plataforma en línea de Kiwi.
                </div>
                <div>
                    Usted comprende que para solicitar, solicitar u obtener uno
                    o más productos financieros y participar en transacciones
                    relacionadas a través de nosotros, y para ver y conservar
                    una copia de los avisos, divulgaciones, acuerdos,
                    autorizaciones, reconocimientos, términos y condiciones,
                    declaraciones, recibos y otros documentos y registros
                    (colectivamente, &quot;Comunicaciones&quot;), o firmar o
                    enviarnos cualquier Comunicación a petición nuestra, durante
                    el curso de nuestra relación con usted, debe dar su
                    consentimiento para recibir Comunicaciones electrónicamente
                    y proporcionar su firma electrónicamente.
                </div>
                <div>
                    Por favor lea este Consentimiento cuidadosa y completamente.
                    Al aceptar este Consentimiento, usted acepta afirmativamente
                    el uso de Comunicaciones electrónicas, registros
                    electrónicos y firmas electrónicas al realizar transacciones
                    comerciales con nosotros.
                </div>

                <ol className="pl-10">
                    <div id="1">
                        <li className="list-decimal pb-5">
                            ALCANCE DE SU CONSENTIMIENTO.
                        </li>
                        <div>
                            <p className="pb-5">
                                Su consentimiento se aplica a las Comunicaciones
                                relacionadas con todas las cuentas, productos o
                                servicios que ofrecemos, que ponemos a su
                                disposición, o que solicita, posee, usa,
                                administra o accede, ya sea ahora o en el futuro
                                (los &quot;Productos&quot;). Su consentimiento
                                incluye, pero no se limita a, Comunicaciones
                                relacionadas con:
                            </p>
                            <ul className="pl-10">
                                <li className="list-disc pb-5">
                                    Solicitar, abrir u obtener de otro modo un
                                    Producto.
                                </li>
                                <li className="list-disc pb-5">
                                    Servicio, mantenimiento, uso, operación o
                                    cierre de un Producto.
                                </li>
                                <li className="list-disc pb-5">
                                    Realizar transacciones u obtener información
                                    en relación con un Producto.
                                </li>
                            </ul>
                            <p className="pb-5">
                                Su consentimiento se aplica a las Comunicaciones
                                relacionadas con todas las cuentas, productos o
                                servicios que ofrecemos, que ponemos a su
                                disposición, o que solicita, posee, usa,
                                administra o accede, ya sea ahora o en el futuro
                                (los &quot;Productos&quot;). Su consentimiento
                                incluye, pero no se limita a, Comunicaciones
                                relacionadas con:
                            </p>
                        </div>
                    </div>
                    <div id="2">
                        <li className="list-decimal pb-5">
                            SU CONSENTIMIENTO PARA UTILIZAR FIRMAS Y REGISTROS
                            ELECTRÓNICOS.
                        </li>
                        <div>
                            <p className="pb-5">
                                A nuestro exclusivo criterio, las Comunicaciones
                                que le proporcionamos, o que usted firma o
                                acepta a petición nuestra, pueden estar en
                                formato electrónico (&quot;Registros
                                electrónicos&quot;). También podemos usar firmas
                                electrónicas y obtenerlas de usted como parte de
                                nuestras transacciones con usted.
                            </p>
                            <p className="pb-5">
                                Los registros electrónicos se le pueden entregar
                                de varias maneras. En algunos casos, los
                                registros electrónicos pueden enviarse por
                                correo electrónico o a través de la plataforma
                                en línea de Kiwi. Debe imprimir o descargar una
                                copia de cualquier Comunicación electrónica para
                                sus registros, incluido este Consentimiento.
                            </p>
                            <p className="pb-5">
                                Siempre podemos, a nuestro exclusivo criterio,
                                proporcionarle cualquier Comunicación por
                                escrito o enviársela por correo de los EE. UU. u
                                otros medios de entrega, incluso si ha elegido
                                recibirla electrónicamente. Es posible que
                                solicitemos que se nos entregue por escrito
                                cualquier información que nos proporcione, o
                                cualquier documento que firme.
                            </p>
                        </div>
                    </div>
                    <div id="3">
                        <li className="list-decimal pb-5">
                            OBTENCIÓN DE COPIAS EN PAPEL.
                        </li>
                        <div>
                            <p className="pb-5">
                                Puede solicitarnos una copia en papel de
                                cualquier Comunicación que le hayamos
                                proporcionado o puesto a su disposición
                                electrónicamente sin cargo, siempre que dicha
                                solicitud se realice dentro de un tiempo
                                razonable después de que le hayamos
                                proporcionado la Comunicación por primera vez.
                            </p>
                            <p className="pb-5">
                                Para solicitar copias en papel, debe enviar un
                                correo electrónico a support@kiwicredito.com con
                                el asunto &quot;Solicitud de documento en
                                papel&quot; y en el cuerpo del correo
                                electrónico debe indicar su dirección de correo
                                electrónico, nombre completo, dirección y número
                                de teléfono. Especifique también (en el cuerpo
                                del correo electrónico) qué Comunicación está
                                solicitando en copia impresa. Alternativamente,
                                si desea una copia en papel de todos los
                                documentos de la cuenta que están disponibles en
                                su panel de control en línea de Kiwi, solicite
                                &quot;Todos los documentos de la cuenta&quot; en
                                copia en papel.
                            </p>
                            <p className="pb-5">
                                Solicitar copias en papel, como se especifica
                                anteriormente, no elimina su consentimiento
                                proporcionado previamente para recibir
                                Comunicaciones electrónicas. Para retirar su
                                consentimiento para recibir cualquier
                                Comunicación futura en formato electrónico, siga
                                las instrucciones a continuación en
                                &quot;Retirar su Consentimiento&quot;.
                            </p>
                        </div>
                    </div>
                    <div id="4">
                        <li className="list-decimal pb-5">
                            RETIRAR SU CONSENTIMIENTO.
                        </li>
                        <div>
                            <p className="pb-5">
                                Puede retirar su consentimiento para recibir
                                Comunicaciones electrónicamente siguiendo las
                                instrucciones a continuación. Tenga en cuenta
                                que para ciertos Productos, retirar su
                                consentimiento puede resultar en la terminación
                                de su participación en dichos Productos o su
                                solicitud para dichos Productos como se describe
                                a continuación.
                            </p>
                            <p className="pb-5">
                                <span className="underline">
                                    Producto de Préstamo Personal:
                                </span>
                                Si tiene una solicitud de préstamo pendiente o
                                una solicitud en proceso con nosotros, o un
                                préstamo que ha sido aprobado y está en proceso
                                de financiamiento, retirar su consentimiento
                                cancelará respectivamente su solicitud o
                                solicitud de préstamo, o resultará en la
                                cancelación de cualquier fondo en proceso de
                                desembolso (excepto donde lo prohíba la ley).
                                Después de que su préstamo haya sido originado y
                                financiado, puede retirar su consentimiento para
                                recibir cualquier Comunicación futura en formato
                                electrónico. Retirar su consentimiento no se
                                aplica a las divulgaciones de originación que se
                                le proporcionaron previamente (electrónicamente)
                                antes de la financiación de su préstamo (o en el
                                momento de la financiación). Retirar su
                                consentimiento puede ralentizar la velocidad a
                                la que podemos brindarle los servicios.
                            </p>
                            <p className="pb-5">
                                <span className="underline">
                                    Cómo retirar su consentimiento:
                                </span>
                                Puede retirar su consentimiento para recibir
                                Comunicaciones electrónicas en el futuro
                                enviando un correo electrónico a
                                support@kiwicredito.com con el asunto
                                &quot;Retirar el consentimiento
                                electrónico&quot; y en el cuerpo del correo
                                electrónico, indique (i) su nombre completo,
                                Dirección postal de EE. UU., dirección de correo
                                electrónico y número de teléfono y (ii) a qué
                                Producto(s) se refiere su solicitud.
                            </p>
                            <p className="pb-5">
                                Su retiro de consentimiento será efectivo solo
                                después de que hayamos tenido un período de
                                tiempo razonable para procesar su solicitud.
                            </p>
                        </div>
                    </div>
                    <div id="5">
                        <li className="list-decimal pb-5">
                            CAMBIO EN SU DIRECCIÓN DE CORREO ELECTRÓNICO U OTRA
                            INFORMACIÓN DE CONTACTO.
                        </li>
                        <p className="pb-5">
                            Para asegurarse de recibir todas las Comunicaciones
                            de manera oportuna, acepta notificarnos de inmediato
                            cualquier cambio en su dirección de correo
                            electrónico o dirección. Para informarnos sobre un
                            cambio en su dirección de correo electrónico,
                            también puede llamar a nuestro equipo de Atención al
                            Cliente al (787) 490-2004. Hasta que nos notifique
                            un cambio en su dirección de correo electrónico, se
                            considerará que se le ha entregado cualquier
                            Comunicación enviada a su dirección de correo
                            electrónico en nuestros registros.
                        </p>
                    </div>
                    <div id="6">
                        <li className="list-decimal pb-5">
                            HARDWARE Y SOFTWARE REQUERIDOS.
                        </li>
                        <p className="pb-5">
                            Para acceder y retener Comunicaciones
                            electrónicamente, debe cumplir con los siguientes
                            requisitos de hardware y software de computadora:
                            acceso a Internet; una cuenta de correo electrónico
                            y software relacionado capaz de recibir correo
                            electrónico a través de Internet; software de
                            navegación web compatible (Chrome versión 32.0 o
                            superior, Firefox versión 26.0 o superior, Internet
                            Explorer versión 8.0 o superior, o Safari versión
                            7.0 o superior); software que le permite ver e
                            imprimir o guardar documentos PDF, como Adobe Reader
                            o software similar; hardware capaz de ejecutar el
                            software descrito anteriormente; y una impresora o
                            dispositivo de almacenamiento a largo plazo que le
                            permita imprimir o guardar Comunicaciones
                            electrónicas para referencia futura. Para garantizar
                            el acceso y la impresión óptima de sus documentos en
                            formato PDF, debe tener Adobe Reader. Para instalar
                            la versión gratuita de Adobe Reader, haga clic aquí
                            (http://get.adobe.com/reader/otherversions/).
                        </p>
                        <p className="pb-5">
                            Requisitos adicionales de tecnología móvil. Si
                            accede a la plataforma en línea de Kiwi y a las
                            Comunicaciones electrónicamente a través de un
                            dispositivo móvil (como un teléfono inteligente, una
                            tableta y similares), además de los requisitos
                            anteriores, debe asegurarse de tener un software en
                            su dispositivo móvil que permita imprima y guarde
                            las Comunicaciones que se le presenten. Estas
                            aplicaciones se pueden encontrar para la mayoría de
                            los dispositivos móviles en la &quot;tienda de
                            aplicaciones&quot; respectiva del dispositivo. Si no
                            tiene estas capacidades en su dispositivo móvil,
                            acceda a la plataforma en línea de Kiwi a través de
                            un dispositivo que proporcione estas capacidades.
                        </p>
                        <p className="pb-5">
                            Comunicaciones telefónicas y consentimiento. Si
                            accede a la plataforma en línea de Kiwi y a las
                            Comunicaciones a través de un dispositivo móvil, o
                            nos proporciona un número de teléfono que es un
                            número de teléfono móvil, al acceder o proporcionar
                            su número de teléfono móvil, acepta recibir las
                            llamadas de información y los mensajes de texto
                            realizados por Kiwi o cualquiera de sus proveedores
                            de servicios para brindarle servicios relacionados
                            con su aplicación o uso de nuestros Productos. Usted
                            acepta que podemos usar sistemas de marcación
                            automática y llamadas pregrabadas a este número y a
                            cualquier número que nos proporcione. Su proveedor
                            de telefonía móvil puede cobrar tarifas de mensajes
                            y datos.
                        </p>
                    </div>
                    <div id="7">
                        <li className="list-decimal pb-5">LEY FEDERAL.</li>
                        <p className="pb-5">
                            Todas las Comunicaciones que le enviemos en formato
                            electrónico o en papel se considerarán &quot;por
                            escrito&quot;. Debe imprimir o descargar para sus
                            registros una copia de este Consentimiento y
                            cualquier otra Comunicación. Usted acepta y ambos
                            tenemos la intención de que la La Ley de Firma
                            Electrónica en el Comercio Nacional y Global se
                            aplique a (i) este Consentimiento y (ii) nuestra
                            capacidad para realizar negocios con usted por
                            medios electrónicos.
                        </p>
                    </div>
                    <div id="8">
                        <li className="list-decimal pb-5">
                            TERMINACIÓN/CAMBIOS.
                        </li>
                        <p className="pb-5">
                            Nos reservamos el derecho, a nuestro exclusivo
                            criterio, de suspender la provisión de
                            Comunicaciones electrónicas en cualquier momento o
                            de cancelar o cambiar los términos y condiciones en
                            los que se proporcionan las Comunicaciones
                            electrónicas. Le enviaremos un aviso de dicha
                            terminación o cambio según lo exija la ley.
                        </p>
                    </div>
                    <div id="9">
                        <li className="list-decimal pb-5">
                            RECONOCIMIENTO DE SU ACCESO Y CONSENTIMIENTO PARA
                            RECIBIR MATERIALES ELECTRÓNICAMENTE Y REALIZAR
                            NEGOCIOS ELECTRÓNICAMENTE.
                        </li>
                        <p className="pb-5">
                            Al aceptar este Consentimiento, usted acepta el uso
                            de Comunicaciones electrónicas, registros
                            electrónicos y firmas electrónicas en conexión con
                            su relación con nosotros, y también confirma: (i) su
                            capacidad para acceder y leer, y también imprimir o
                            guardar, este Consentimiento y las Comunicaciones o
                            cualquier documento y comunicación relacionada; (ii)
                            ha proporcionado una dirección de correo electrónico
                            válida a la que podemos enviarle Comunicaciones
                            electrónicas; (iii) tiene acceso a software y
                            hardware que cumple con los requisitos anteriores
                            para recibir y revisar registros electrónicos y
                            Comunicaciones; (iv) acepta los términos y
                            condiciones de este Consentimiento, incluido el
                            consentimiento telefónico anterior; y (vi) al hacer
                            clic en la casilla que hace referencia a este
                            Consentimiento, usted coloca su firma electrónica en
                            este Consentimiento.
                        </p>
                    </div>
                </ol>
            </div>
        </BaseModal>
    )
}

export default PrivacyTermsModal
