import React, { useContext, useState, Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { CreditBuilderContext } from 'context/CreditBuilderContext'

import CourseLessonsModal from 'components/Modals/CourseLessons'
import Button from 'components/Button'
import ProgressBar from 'components/ProgressBar'

export default function CreditBuilderSelectedCourse() {
    const navigate = useNavigate()

    const {
        state: creditState,
        saveState: saveCreditState,
        getCourse
    } = useContext(CreditBuilderContext)
    const { course } = creditState
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [lessons, setLessons] = useState([])

    useEffect(() => {
        getLessons()
    }, [])

    const getLessons = async () => {
        try {
            setLoading(true)

            const courseId = creditState.course.id
            const {
                data: [course]
            } = await getCourse(courseId)

            setLessons(course.cb_product_content)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onBackward = () => {
        navigate('/credit-builder/buy-course')
    }

    const onContinue = async () => {
        try {
            setLoading(true)

            await saveCreditState({
                ...creditState,
                selectedCourse: {
                    completed: true
                }
            })

            navigate('/credit-builder/address')
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onOpenModal = () => {
        setVisible(true)
    }

    const onCloseModal = () => {
        setVisible(false)
    }

    const monthlyPayment = () => {
        return (course.price / course.financial_months).toFixed(0)
    }

    return (
        <Fragment>
            <CourseLessonsModal
                isVisible={visible}
                closeModal={onCloseModal}
                lessons={lessons}
                description={course.name}
            />

            <ProgressBar progress="1/8" onBackward={onBackward} />

            <div className="flex flex-col grow" autoComplete="off">
                <h1 className="text-dark-kiwi font-semibold text-2xl mb-4">
                    Ya casi empiezas a construir tu puntaje
                </h1>

                <p>
                    Con esta compra, estarás construyendo{' '}
                    {course.financial_months} meses de historial de crédito por
                    tan solo ${monthlyPayment()} al mes.
                </p>

                <div className="my-10 flex flex-col gap-y-8">
                    <div className="border border-slate-100-kiwi border-t-0 rounded-xl">
                        <div className="font-bold p-4 bg-slate-100-kiwi rounded-t-xl text-dark-kiwi -ml-[1px] w-[calc(100%+2px)]">
                            Resumen de Crédito
                        </div>
                        <div className="text-sm p-4">
                            <div className="flex justify-between mb-2">
                                <div className="mr-2">
                                    Monto de financiamiento
                                </div>
                                <div className="font-semibold">
                                    ${Number(course.price).toFixed(2)}
                                </div>
                            </div>
                            <div className="flex justify-between mb-2">
                                <div className="mr-2">Total de interés</div>
                                <div className="font-semibold">$0.00</div>
                            </div>
                            <div className="flex justify-between mb-6">
                                <div className="mr-2">
                                    Meses de financiamiento
                                </div>
                                <div className="font-semibold">
                                    {course.financial_months}
                                </div>
                            </div>
                            <div className="flex justify-between text-blue-kiwi">
                                <div className="mr-2">
                                    Monto de pago mensual
                                </div>
                                <div className="font-semibold">
                                    ${monthlyPayment()} al mes
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="border border-slate-100-kiwi rounded-xl">
                        <div className="font-bold p-4 bg-slate-100-kiwi rounded-t-xl text-dark-kiwi flex justify-between items-center w-[calc(100%+2px)]">
                            Resumen de Compra
                            <div
                                className="text-blue-kiwi text-sm font-normal underline cursor-pointer"
                                onClick={onBackward}>
                                Cambiar artículo
                            </div>
                        </div>
                        <div className="text-sm p-4">
                            <div className="mb-2 text-gray-500-kiwi">
                                Construye {course.financial_months} Meses de
                                Historial Crediticio
                            </div>
                            <div className="flex justify-between mb-2">
                                <div className="mr-2">{course.name}</div>
                                <div className="font-semibold">
                                    ${Number(course.price).toFixed(2)}
                                </div>
                            </div>
                            <button
                                className="text-blue-kiwi rounded-xl p-3 border border-blue-kiwi w-full mt-6"
                                onClick={onOpenModal}>
                                Ver contenido del curso
                            </button>
                        </div>
                    </div>
                </div>

                <Button
                    className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto"
                    loading={loading}
                    onClick={onContinue}>
                    Comprar producto
                </Button>
            </div>
        </Fragment>
    )
}
