import ReactPlayer from 'react-player'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CreditBuilderContext } from 'context/CreditBuilderContext'
import backIcon from 'assets/icons/back.svg'
import playIcon from 'assets/icons/play.svg'
import { ChevronLeftIcon } from 'assets/icons_v3/fonts'
import BackwardButton from 'components/Button/Backward'

export default function CreditBuilderActiveLesson() {
    const navigate = useNavigate()
    const { courseId, lessonId } = useParams()

    const { getCourse, getLesson } = useContext(CreditBuilderContext)

    const [lesson, setLesson] = useState({})
    const [course, setCourse] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        lessonDetails()
        document.body.scrollTop = 0
    }, [lessonId])

    const lessonDetails = async () => {
        const {
            data: [course]
        } = await getCourse(courseId)
        const { data: lesson } = await getLesson(lessonId)

        setLesson(lesson)
        setCourse(course)

        setLoading(false)
    }

    const onBackward = () => {
        navigate(`/credit-builder/courses/${courseId}`)
    }

    const onLessonDetails = (lessonId) => {
        navigate(`/credit-builder/courses/${courseId}/${lessonId}`)
    }

    return (
        <Fragment>
            <BackwardButton onBackward={onBackward} />

            <div className="react-player-wrapper bg-gray-200-kiwi rounded-lg mb-6">
                <ReactPlayer
                    url={lesson.video_url}
                    className="react-player"
                    playing
                    width="100%"
                    height="100%"
                    controls={true}
                />
            </div>

            <div className="mb-10">
                <h1 className="text-dark-kiwi font-semibold mb-1 text-xl">
                    {lesson.name}
                </h1>
                <h2 className="text-gray-500-kiwi text-sm">{course.name}</h2>
            </div>

            <div className="flex flex-col gap-4">
                {course.cb_product_content?.map((l) => (
                    <div
                        key={l.id}
                        className={`cursor-pointer rounded-md flex justify-between items-center p-4 border-l-4 ${
                            l.id === lesson.id
                                ? 'border-green-kiwi'
                                : 'border-blue-kiwi'
                        } text-sm`}
                        onClick={() =>
                            l.id === lesson.id ? '' : onLessonDetails(l.id)
                        }>
                        <div className="mr-2">
                            <div>{l.name}</div>
                            <div className="text-gray-500-kiwi text-xs mt-1">
                                Duración {l.duration}
                            </div>
                        </div>
                        <img src={playIcon} alt="icon" />
                    </div>
                ))}
            </div>
        </Fragment>
    )
}
