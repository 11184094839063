import Button from 'components/Button'
import BaseModal from '../BaseModal'

export default function AlertInformation({
    isVisible,
    icon,
    title,
    description,
    closeModal
}) {
    return (
        <BaseModal isVisible={isVisible} closeModal={closeModal}>
            <div className="flex flex-col gap-10">
                <div className="flex flex-col items-center text-center gap-8">
                    <img src={icon} className="w-44" />
                    <div className="flex flex-col gap-6">
                        <h2 className="text-dark-kiwi font-semibold text-xl">
                            {title}
                        </h2>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: description
                            }}></p>
                    </div>
                </div>
                <Button
                    onClick={closeModal}
                    className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto w-full">
                    Aceptar
                </Button>
            </div>
        </BaseModal>
    )
}
