import { UserContext } from 'context/UserContext'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PERSONAL_LOAN_STATUS } from 'constants'
import { ArrowRightIcon } from 'assets/icons_v3/fonts'
import { PersonalLoanApprovedRedirect } from 'utils/redirectPersonalLoan'
import {
    DISBURSEMENT_METHODS,
    DISBURSMENT_FUND_DAYS,
    PAPER_CHECK_STATUS
} from 'constants'

export default function HomeStatusProgress() {
    const navigate = useNavigate()
    const {
        state: userState,
        isFirstMoneyOnTheWay,
        isSecondMoneyOnTheWay,
        getLoanFundingDate
    } = useContext(UserContext)
    const { products } = userState
    const {
        personal_loan: { status, tracking, loan, application }
    } = products
    const context = tracking?.context ?? {}

    const need_verification_call = application?.need_verification_call ? application?.need_verification_call : null;

    const [isApplyStatus] = useState(status === PERSONAL_LOAN_STATUS.apply)
    const [isCurrentStatus] = useState(status === PERSONAL_LOAN_STATUS.current)
    const [isApprovedStatus] = useState(
        status === PERSONAL_LOAN_STATUS.approved && loan === null
    )
    const [isMoneyOnWayStatus] = useState(
        isFirstMoneyOnTheWay() || isSecondMoneyOnTheWay()
    )
    const [isMesalveStatus] = useState(
        status === PERSONAL_LOAN_STATUS.approved &&
            loan &&
            loan.type === 'mesalve'
    )
    // const [isPaperCheckRepaymentReviewing] = useState(
    //     status === PERSONAL_LOAN_STATUS.approved &&
    //         application &&
    //         application.bucket === PAPER_CHECK_STATUS.reviewing
    // )

    const [needVerificationCall] = useState(
        status === PERSONAL_LOAN_STATUS.approved
        && need_verification_call
    )

    const [statusDescription] = useState(() => {
        if (isApplyStatus)
            return {
                title: 'Revisando tu solicitud',
                description:
                    'Estamos analizando tu solicitud de préstamo. ¡Ya casi!'
            }

        if (isMesalveStatus)
            return {
                title: '¡Recoge tu efectivo!',
                description:
                    'Te invitamos a recoger tu efectivo en los comercios participantes'
            }
        
        if (needVerificationCall)
            return {
                title: 'Llamada de verificación',
                description: '¡Te llamaremos pronto! Queremos verificar tu información dentro de las próximas 12 horas'
            }

        if (isMoneyOnWayStatus)
            return {
                title: 'Dinero en camino',
                description: `Recibirás tu dinero en tu cuenta, se espera que los fondos lleguen el día <strong>${getLoanFundingDate()}</strong>`
            }

        // if (isPaperCheckRepaymentReviewing)
        //     return {
        //         title: 'Revisando solicitud cheque',
        //         description:
        //             'Nuestro equipo te estará contactando durante las próximas 48 y 72 horas '
        //     }

        if (isApprovedStatus)
            return {
                title: 'Préstamo aprobado',
                description:
                    '¡Felicitaciones! puedes seguir con el proceso para obtener tu dinero'
            }

        if (isCurrentStatus)
            return {
                title: 'Préstamo activo',
                description: ''
            }
    })

    const [progressBarWidth] = useState(() => {
        if (isApplyStatus) return 'w-1/4'
        if (needVerificationCall) return 'w-3/4'
        if (isMoneyOnWayStatus || isMesalveStatus) return 'w-3/4'
        if (isApprovedStatus) return 'w-1/2'
        if (isCurrentStatus) return 'w-full'
    })

    const onApprovedRedirect = async () => {
        const route = await PersonalLoanApprovedRedirect(context)
        navigate(route)
    }

    const onNeedVerificationCall = () => {
        return navigate('/approved-loan/need-verification-call')
    }

    const onMesalveRedirect = () => {
        return navigate('/mesalve-code')
    }

    const RenderCTAButton = () => {
        if (isMesalveStatus)
            return (
                <div
                    onClick={onMesalveRedirect}
                    className="cursor-pointer mt-6 rounded-xl px-4 py-1 bg-regal-blue-kiwi text-xs text-white font-medium flex justify-center items-center">
                    Obtener efectivo <ArrowRightIcon className="w-5 ml-1" />
                </div>
            )
        
        if (needVerificationCall)
        return (
            <div
                onClick={onNeedVerificationCall}
                className="cursor-pointer mt-6 rounded-xl px-4 py-1 bg-regal-blue-kiwi text-xs text-white font-medium flex justify-center items-center">
                Validar llamada <ArrowRightIcon className="w-5 ml-1" />
            </div>
        )

        if (isApprovedStatus)
            return (
                <div
                    onClick={onApprovedRedirect}
                    className="cursor-pointer mt-6 rounded-xl px-4 py-1 bg-regal-blue-kiwi text-xs text-white font-medium flex justify-center items-center">
                    Continuar proceso <ArrowRightIcon className="w-5 ml-1" />
                </div>
            )
    }

    return (
        <div className="p-4 rounded-xl bg-slate-100-kiwi">
            <h3 className="font-semibold text-dark-kiwi mb-1">
                {statusDescription.title}
            </h3>
            <p
                className="text-xs mb-4 text-slate-700-kiwi"
                dangerouslySetInnerHTML={{
                    __html: statusDescription.description
                }}></p>

            <div className="mb-2">
                <div className="h-1 bg-slate-200-kiwi rounded-xl flex justify-between relative">
                    <div
                        className={`-mt-0.5 w-2 h-2 rounded-full z-10 bg-blue-kiwi ${
                            isApplyStatus
                                ? '[&~:not(:last-child)]:bg-slate-200-kiwi'
                                : null
                        }`}></div>
                    <div
                        className={`-mt-0.5 w-2 h-2 rounded-full z-10 bg-blue-kiwi ${
                            isApprovedStatus ||
                            isMoneyOnWayStatus ||
                            isMesalveStatus
                                ? '[&~:not(:last-child)]:bg-slate-200-kiwi'
                                : null
                        }`}></div>
                    <div
                        className={`-mt-0.5 w-2 h-2 rounded-full z-10 bg-blue-kiwi ${
                            isCurrentStatus
                                ? '[&~:not(:last-child)]:bg-slate-200-kiwi'
                                : null
                        }`}></div>
                    <div
                        className={`h-1 rounded-xl bg-blue-kiwi absolute ${progressBarWidth}`}></div>
                </div>
            </div>

            <div className="text-xs flex justify-between text-slate-700-kiwi">
                <div
                    className={`w-1/3 text-left ${
                        isApplyStatus ? '[&~div]:text-slate-400-kiwi' : null
                    }`}>
                    Revisión
                </div>
                <div
                    className={`w-1/3 text-center ${
                        isApprovedStatus ||
                        isMoneyOnWayStatus ||
                        isMesalveStatus
                            ? '[&~div]:text-slate-400-kiwi'
                            : null
                    }`}>
                    Aprobación
                </div>
                <div
                    className={`w-1/3 text-right ${
                        isCurrentStatus ? '[&~div]:text-slate-400-kiwi' : null
                    }`}>
                    Desembolso
                </div>
            </div>

            <RenderCTAButton />
        </div>
    )
}
