import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import BlackListImage from 'assets/black_list.svg'
import backIcon from 'assets/icons/back.svg'

export default function BlackList() {
    const navigate = useNavigate()

    const onBackward = () => {
        navigate('/home')
    }

    return (
        <div className="w-full min-h-full flex flex-col px-6 py-10">
            <img
                className="ml-2 mb-12 cursor-pointer"
                width="8"
                src={backIcon}
                onClick={onBackward}
                alt="kiwi-image"
            />

            <div className="flex flex-col justify-center text-center m-auto">
                <img
                    className="mx-auto mb-10"
                    width="200"
                    src={BlackListImage}
                    alt="kiwi-image"
                />
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    Su cuenta está inhabilitada
                </h1>
                <p>
                    Para más información se puede comunicar con nuestro equipo
                    de soporte o enviar un correo a{' '}
                    <strong>support@kiwicredito.com</strong>
                </p>
            </div>

            <button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                onClick={onBackward}>
                Volver
            </button>
        </div>
    )
}
