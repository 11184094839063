import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import BaseModal from './Modal'

const ECBSVTerms = forwardRef(({}, ref) => {
    const BaseModalRef = useRef()

    useImperativeHandle(ref, () => ({
        ...BaseModalRef.current
    }))

    return (
        <BaseModal
            title="Consentimiento de Verificación eCBSV"
            ref={BaseModalRef}>
            <p>
                Autorizo a la Administración del Seguro Social (SSA) a verificar
                y divulgar a Persona Identities, a través de SentiLink
                Verification Services Corp, su proveedor de servicios, con el
                propósito de esta transacción, si el nombre, el Número de Seguro
                Social (SSN) y la fecha de nacimiento que he presentado
                coinciden con la información en los registros de la SSA. Mi
                consentimiento es para una validación única dentro de los
                próximos 30 días.
            </p>
        </BaseModal>
    )
})

export default ECBSVTerms
