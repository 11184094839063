import React, {
    useState,
    Fragment,
    useImperativeHandle,
    forwardRef
} from 'react'

import RepaymentTerms from './RepaymentTerms'
import PaymentDay from './PaymentDay'

export default forwardRef(
    ({ onPaymentDaySuccess, onRepaymentTermsSuccess, selectedPaymentDate }, ref) => {
        const [showPaymentDay, setShowPaymentDay] = useState(true)
        const [showRepaymentTerms, setShowRepaymentTerms] = useState(false)

        useImperativeHandle(ref, () => ({
            showPaymentDay,
            setShowPaymentDay,
            showRepaymentTerms,
            setShowRepaymentTerms
        }))

        const handlePaymentDaySuccess = (value) => {
            onPaymentDaySuccess(value)
            setShowPaymentDay(false)
            setShowRepaymentTerms(true)
        }

        const handleRepaymentTermsSuccess = () => {
            onRepaymentTermsSuccess()
        }

        return (
            <Fragment>
                {showPaymentDay === true && (
                    <PaymentDay onSuccess={handlePaymentDaySuccess} selectedPaymentDate={selectedPaymentDate} />
                )}

                {showRepaymentTerms === true && (
                    <RepaymentTerms onSuccess={handleRepaymentTermsSuccess} />
                )}
            </Fragment>
        )
    }
)
