import React, { useState, createContext, useContext } from 'react'
import { UserContext } from './UserContext'
import http from '../utils/http'
import {
    refreshFinicity,
    saveFinicityConnection,
    createLinkFinicity
} from 'utils/finicity'

export const RequestLoanContext = createContext()

export const RequestLoanProvider = ({ children }) => {
    const {
        state: userState,
        userTracking,
        deleteUserTracking
    } = useContext(UserContext)

    const initialState = {
        address: {
            value: '',
            suite: '',
            state: 'Puerto Rico',
            city: '',
            zipcode: '',
            completed: false
        },
        ssn: {
            value: '',
            confirmValue: '',
            completed: false
        },
        veritec: {
            completed: false
        },
        income: {
            value: '',
            direct: false,
            completed: false
        },
        employment: {
            value: '',
            completed: false
        },
        military: {
            value: '',
            completed: false
        },
        employer: {
            value: '',
            completed: false
        },
        seniority: {
            value: '',
            completed: false
        },
        bankruptcy: {
            value: '',
            completed: false
        },
        currentLoan: {
            value: '',
            completed: false
        },
        residence: {
            value: '',
            completed: false
        },
        validation: {
            value: 'plaid',
            completed: false
        },
        reviewing: {
            completed: false
        }
    }

    const [state, setState] = useState(() => {
        const {
            personal_loan: { tracking }
        } = userState.products

        const state = tracking?.context

        if (state === undefined) {
            return initialState
        }

        return state
    })

    const saveState = (state) => {
        setState(state)
        userTracking({
            status: 'apply',
            context: state
        })
    }

    const resetState = () => {
        setState(initialState)
        deleteUserTracking({
            status: 'apply'
        })
    }

    const createSurvey = async (survey) => {
        const response = await http.post('/survey', survey)
        return response
    }

    const providerValues = {
        /** State */
        state,
        resetState,
        setState,
        saveState,

        /** Handlers */
        createSurvey,
        createLinkFinicity,
        saveFinicityConnection,
        refreshFinicity
    }

    return (
        <RequestLoanContext.Provider value={providerValues}>
            {children}
        </RequestLoanContext.Provider>
    )
}
