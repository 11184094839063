import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'components/Button'
import Advisement from 'components/Advisement'
import { Wallet3Icon, TimeIcon, CertificateIcon } from 'assets/icons_v3/fonts'
import BackwardButton from 'components/Button/Backward'

export default function RequestLoanPlaid() {
    const navigate = useNavigate()

    const onContinue = async () => {
        navigate('/autopay/payment-profile')
    }

    const onBackward = () => {
        navigate('/active-loan/disbursement')
    }

    return (
        <Fragment>
            <BackwardButton onBackward={onBackward} />

            <div className="mb-10">
                <h1 className="font-semibold mb-4 text-2xl">
                    ¿Cómo funcionan los pagos automáticos?
                </h1>
                <div className="mb-8">
                    Reactiva tus pagos automáticos para que evites olvidos y
                    retrasos
                </div>

                <div className="flex flex-col gap-6 ">
                    <div className="flex items-center">
                        <div className="flex h-10 w-10 bg-slate-100-kiwi rounded-full mr-4 flex-none">
                            <TimeIcon className="m-auto text-regal-blue-kiwi" />
                        </div>
                        <div className="text-sm">
                            Tu pago será procesado un día antes de la fecha
                            límite.
                        </div>
                    </div>
                    <div className="flex items-center">
                        <div className="flex h-10 w-10 bg-slate-100-kiwi rounded-full mr-4 flex-none">
                            <Wallet3Icon className="m-auto text-regal-blue-kiwi" />
                        </div>
                        <div className="text-sm">
                            El monto se retirará automáticamente de tu perfil de
                            pago seleccionado.
                        </div>
                    </div>
                    <div className="flex items-center">
                        <div className="flex h-10 w-10 bg-slate-100-kiwi rounded-full mr-4 flex-none">
                            <CertificateIcon className="m-auto text-regal-blue-kiwi" />
                        </div>
                        <div className="text-sm">
                            Tu pago se reflejará hasta cuatro días hábiles en tu
                            cuenta bancaria.
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col mt-auto">
                <Advisement icon="bulb_money">
                    Recuerda contar con fondos en tu perfil de pago seleccionado
                    antes de la fecha límite para hacer el pago automático
                </Advisement>

                <Button
                    className="bg-blue-kiwi text-white rounded-xl p-3 flex items-center justify-center mt-10"
                    onClick={onContinue}>
                    Reactivar pago automático
                </Button>
            </div>
        </Fragment>
    )
}
