import React, { useContext, useEffect, useState, Fragment } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import LogRocket from 'logrocket'
import { AuthContext } from 'context/AuthContext'
import { UserContext } from 'context/UserContext'
import { setBearerToken } from 'utils/http'
import { setBearerTokenFirebase } from 'utils/httpFirebase'
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'

import SplashComponent from 'components/Splash'

/**
 * Middleware para prevenir entrar a rutas cuando
 * el usuario se encuentra en el 'Black List'....
 */
export const BlackListMiddleware = () => {
    const {
        state: { user }
    } = useContext(UserContext)

    return user.black_list === true ? <Navigate to="/black-list" /> : <Outlet />
}

export const AuthMiddleware = () => {
    const [renderView, setRenderView] = useState(false)
    const [initialDataInterval, setInitialDataInterval] = useState(0)

    const {
        state: authState,
        userVerification,
        resetState: resetAuthState,
        fingerprintUtmUpdate
    } = useContext(AuthContext)
    const {
        setState: setUserState,
        state: userState,
        resetState: resetUserState,
        getProducts
    } = useContext(UserContext)

    const { getData: getDataFingerprint } = useVisitorData(
        { extendedResult: true },
        { immediate: true }
    )

    useEffect(() => {
        let token = authState.accessTokenApp

        if (token) {
            setBearerToken(token)
            setBearerTokenFirebase(token)

            refreshInitialData()
        } else {
            setRenderView(true)
        }

        return () => clearInterval(initialDataInterval)
    }, [authState.accessTokenApp])

    const getInitialData = () => {
        return new Promise(async (resolve) => {
            try {
                const { data } = await userVerification()
                const { success, user } = data

                if (success) {
                    const {
                        data: { data: products }
                    } = await getProducts()

                    const payload = {
                        products,
                        user
                    }

                    setUserState((prev) => ({
                        ...prev,
                        ...payload
                    }))

                    setRenderView(true)

                    resolve()

                    LogRocket.identify(user.id, {
                        name: `${user.first_name} ${user.first_surname}`,
                        email: user.email,
                        state: user.state
                    })

                    const dataFPJS = await getDataFingerprint({
                        ignoreCache: true
                    })

                    await fingerprintUtmUpdate({
                        visitor_id: dataFPJS?.visitorId,
                        user_id: user.id
                    })
                }
            } catch (error) {
                console.error(error)
            }
        })
    }

    const refreshInitialData = async () => {
        await getInitialData()
        const userInterval = setInterval(getInitialData, 300000)
        setInitialDataInterval(userInterval)
    }

    const renderOutlet = () => {
        if (
            authState.logged &&
            !userState.user.is_deleted &&
            authState.accessTokenApp
        ) {
            return <Outlet />
        }

        resetAuthState()
        resetUserState()

        return <Navigate to="/login" />
    }

    return (
        <Fragment>{renderView ? renderOutlet() : <SplashComponent />}</Fragment>
    )
}
