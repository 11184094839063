import React, { useState, useEffect, useContext, Fragment } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { UserContext } from 'context/UserContext'
import { RequestLoanContext } from 'context/RequestLoanContext'

/**
 * Prevenir que el usuario regrese al flujo del survey, si ya se
 * envió el survey...
 */
export const RequestLoanSurveyMiddleware = () => {
    const { state: requestState } = useContext(RequestLoanContext)
    const createdSurvey = requestState.residence.completed

    return createdSurvey === true ? (
        <Navigate to="/request-loan/validation" />
    ) : (
        <Outlet />
    )
}

/**
 * Middleware para prevenir que el usuario entre a esta vista
 * sin que su status sea igual a apply...
 */
export const RequestLoanMiddleware = () => {
    const { state: userState } = useContext(UserContext)
    const {
        products: {
            personal_loan: { status }
        }
    } = userState

    return status === 'apply' ? <Outlet /> : <Navigate to="/home" />
}
