import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import BaseModal from '../Modal'
import InformationCircleSVG from 'assets/onboarding_v3/information_circle.svg'
import Advisement from 'components/Advisement'

const PaperCheckConfirmation = forwardRef(
    ({ onChangeACH, onContinuePaperCheck }, ref) => {
        const BaseModalRef = useRef()

        useImperativeHandle(ref, () => ({
            ...BaseModalRef.current
        }))

        return (
            <BaseModal showDetails={true} ref={BaseModalRef}>
                <div className="mb-10">
                    <img
                        className="shrink-0 grow-0 mx-auto mb-10 grid justify-center"
                        src={InformationCircleSVG}
                        alt="icon-icon"
                    />
                    <h2 className="font-semibold text-xl text-dark-kiwi text-center">
                        Pagos manuales con Cheque de Papel
                    </h2>
                    <p className="mt-5 text-left">
                        Optar por el repago mediante cheques personales requiere
                        verificación adicional. Antes de que puedas continuar y
                        recibir fondos, tendrás que proporcionar más información
                        para verificar tu cuenta bancaria. Recibirás un correo
                        electrónico con instrucciones para verificar tu cuenta.
                    </p>
                </div>

                <Advisement icon="bulb_money" className="mt-auto">
                    <strong>
                        Puedes evitar este requerimiento eligiendo repagar a
                        través de pagos automáticos mediante ACH
                    </strong>
                </Advisement>
                <div className="flex flex-col mt-10">
                    <button
                        className="w-full bg-blue-kiwi text-white rounded-xl py-3 px-8"
                        onClick={() => {
                            onChangeACH()
                            BaseModalRef.current.closeModal()
                        }}>
                        Cambiar a ACH
                    </button>
                    <div
                        className="cursor-pointer mx-auto text-blue-kiwi rounded-xl mt-6"
                        onClick={onContinuePaperCheck}>
                        Mantener pagos con cheque
                    </div>
                </div>
            </BaseModal>
        )
    }
)

export default PaperCheckConfirmation
