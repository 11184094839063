import Star from 'assets/icons_v3/fonts/star'
import BaseModal from '../BaseModal'
import { useState } from 'react'
import Button from 'components/Button'
import { RatingAPI } from 'api'

const RatingModal = ({ isVisible, closeModal, ratingStatus }) => {
    const [score, setScore] = useState(0)
    const [description, setDescription] = useState(null)
    const [loading, setLoading] = useState(false)

    const createRating = async () => {
        setLoading(true)
        try {
            await RatingAPI.createRating({
                score,
                description: description ?? ''
            })
            ratingStatus(score)
        } finally {
            setLoading(false)
        }
    }

    const statusRating = () => {
        switch (score) {
            case 1:
                return 'Muy malo'
            case 2:
                return 'Malo'
            case 3:
                return 'Regular'
            case 4:
                return 'Bueno'
            case 5:
                return 'Excelente'
            default:
                return null
        }
    }

    return (
        <BaseModal
            isVisible={isVisible}
            closeModal={() => {
                closeModal()
                createRating()
            }}
            title=" ">
            <div className="flex flex-col items-center justify-center">
                <p className="text-slate-900-kiwi text-xl text-center font-semibold pb-2">
                    ¿Cómo calificarías tu experiencia con Kiwi?
                </p>
                <p className="text-center text-gray-700-kiwi mb-8">
                    Tu calificación y comentarios nos ayudan a mejorar
                </p>
                <div className="flex items-center  w-max mb-2">
                    {Array.from({ length: 5 }).map((_, index) => (
                        <div
                            key={index}
                            className="mx-2"
                            onClick={() => setScore(index + 1)}>
                            <Star
                                className={`${
                                    index + 1 <= score
                                        ? 'text-[#F99307]'
                                        : 'text-slate-200'
                                }  w-10 h-10`}
                            />
                        </div>
                    ))}
                </div>
                {score > 0 && (
                    <p
                        className={`${
                            score > 0 && score < 5 && 'mb-10'
                        } text-xs text-slate-500`}>
                        {statusRating()}
                    </p>
                )}
                {score > 0 && score < 5 && (
                    <textarea
                        rows="3"
                        className="w-full border border-gray-200 bg-gray-100 rounded-lg placeholder:text-gray-400 focus: outline-none focus-visible:outline-none"
                        placeholder="Escribe aquí tus comentarios..."
                        onChange={(e) => setDescription(e.target.value)}
                    />
                )}
                <Button
                    className={`${
                        score > 0 ? 'bg-blue-kiwi' : 'bg-slate-300'
                    }  text-white rounded-xl p-3 mt-10 text-base w-full`}
                    type="button"
                    loading={loading}
                    disabled={score === 0}
                    onClick={createRating}>
                    Enviar calificación
                </Button>
            </div>
        </BaseModal>
    )
}

export default RatingModal
