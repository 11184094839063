import React from 'react'
import BaseModal from './../BaseModal'

const BalanceModal = ({ isVisible, closeModal }) => {
    return (
        <BaseModal
            title="Balance restante"
            isVisible={isVisible}
            closeModal={closeModal}>
            <p className="text-black">
                El “balance de saldo” es el valor completo al día de hoy que
                debes de pagar si quisieras saldar en su totalidad el préstamo.
                Este valor incluye los intereses devengados diariamente y
                cualquier cargo por pago devuelto. Si realizas todos tus pagos a
                tiempo y sin pagos devueltos, nunca pagarás más de lo que
                aparece en el contrato.
            </p>
        </BaseModal>
    )
}

export default BalanceModal
