import React, { useContext, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from 'context/UserContext'

import BackIcon from 'assets/icons/back.svg'
import HistoryPayments from 'templates/HistoryPayments'
import BackwardButton from 'components/Button/Backward'

export default function CreditBuilderActiveHistoryPayment() {
    const navigate = useNavigate()

    const { state: userState } = useContext(UserContext)
    const {
        products: { credit_builder }
    } = userState

    const onBackward = () => {
        navigate('/credit-builder/active')
    }

    const onContinue = () => {
        navigate('/credit-builder/active/pay-fee')
    }

    return (
        <Fragment>
            <BackwardButton onBackward={onBackward} />

            <HistoryPayments
                loan={credit_builder.loan}
                onContinue={onContinue}
            />
        </Fragment>
    )
}
