import React from 'react'
import BaseModal from './../BaseModal'

const PrivacyTermsModal = ({ isVisible, closeModal }) => {
    return (
        <BaseModal
            title="POLÍTICA DE PRIVACIDAD"
            description="Última actualización Mayo 27, 2020"
            isVisible={isVisible}
            closeModal={closeModal}>
            <div className="flex flex-col gap-6 text-justify">
                <div>
                    Kiwi Financial, Inc (&quot;Compañía&quot;,
                    &quot;nosotros&quot;, &quot;nuestros&quot; o
                    &quot;nuestra&quot;) respeta su privacidad y se compromete a
                    proteger su información de identificación personal mediante
                    el cumplimiento de esta política. Esta política describe los
                    tipos de información que podemos recopilar de usted o que
                    puede proporcionar cuando visita nuestro sitio web y/o
                    nuestra aplicación móvil (en conjunto, las &quot;Plataformas
                    en Línea&quot;), o al solicitar o recibir cualquiera de
                    nuestros productos y servicios, y nuestras prácticas para
                    recopilar, usar, mantener, proteger, y divulgar esa
                    información.
                    <p className="py-3">
                        Esta política se aplica a la información que
                        recopilamos:
                    </p>
                    <ul className="list-disc pl-10 pb-6">
                        <li>En nuestras Plataformas en Línea</li>
                        <li>
                            En correos electrónicos, mensajes de texto, y otros
                            mensajes electrónicos entre usted y la Compañía
                        </li>
                        <li>
                            Cuando interactúa con nuestra publicidad y
                            aplicaciones en sitios web y servicios de terceros
                            si esas aplicaciones o publicidad incluyen enlaces a
                            esta política
                        </li>
                    </ul>
                    <p className="py-3">
                        No se aplica a la información recopilada por:
                    </p>
                    <ul className="list-disc pl-10 pb-6">
                        <li>
                            Cualquier tercero no afiliado, incluso a través de
                            cualquier aplicación o contenido que pueda
                            vincularse o ser accesible desde o en el sitio web.
                        </li>
                    </ul>
                    Por favor lea esta política detenidamente para comprender
                    nuestras políticas y prácticas con respecto a su información
                    de identificación personal y cómo la trataremos. Si no está
                    de acuerdo con nuestras políticas y prácticas, su elección
                    es no utilizar nuestras Plataformas en Línea. Al acceder o
                    utilizar nuestras Plataformas en Línea usted acepta que
                    recopilemos, usemos, y compartamos su información de
                    identificación personal de acuerdo con esta política de
                    privacidad. Esta política puede cambiar ocasionalmente
                    (consulte la sección Cambios a Nuestra Política de
                    Privacidad). El uso continuo de nuestras Plataformas en
                    Línea después de que realicemos los cambios se considerará
                    como aceptación de dichos cambios, por lo tanto, consulte la
                    política periódicamente para obtener actualizaciones.
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        Individuos Menores de 21 Años
                    </h5>
                    <p>
                        Nuestro sitio web no está destinado a personas menores
                        de 21 años dentro del Estado Libre Asociado de Puerto
                        Rico, que se consideran menores de edad y no son mayores
                        de edad a menos que estén debidamente emancipados.
                        Ningún menor de 21 años puede proveer información
                        personal en nuestras Plataformas en Línea. No
                        recopilamos deliberadamente información personal de
                        personas menores de 21 años. Si tiene menos de 21 años
                        no use ni brinde información en nuestras plataformas en
                        Línea, ni se registre, aplique a nuestros productos, use
                        ninguna de las funciones interactivas o proporcione
                        cualquier información sobre usted, incluyendo su nombre,
                        dirección, número de teléfono, dirección de correo
                        electrónico o cualquier nombre de usuario que pueda usar
                        en nuestras Plataformas en Línea. Si descubrimos que
                        hemos recopilado o recibido información personal de una
                        persona menor de 21 años sin verificación de
                        emancipación legal, eliminaremos esa información. Si
                        cree que podríamos tener información de o sobre una
                        persona menor de 21 años, contáctenos en
                        support@kiwicredito.com.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        Información que Recopilamos Sobre Usted y Cómo la
                        Recopilamos
                    </h5>
                    <p className="py-3">
                        Recopilamos varios tipos de información de y sobre los
                        usuarios de nuestras Plataformas en Línea y los clientes
                        de nuestros servicios, incluyendo la información:
                    </p>
                    <ul className="list-disc pl-10 pb-6">
                        <li>
                            Por la cual puede ser identificado personalmente,
                            que es información que identifica, se relaciona,
                            describe, puede asociarse con o podría estar
                            razonablemente vinculado directa o indirectamente
                            con un consumidor específico. Esta información puede
                            incluir: nombre y apellido, direcciones físicas y
                            postales, dirección de correo electrónico, número de
                            teléfono, Seguridad Social, fecha de nacimiento,
                            información de empleo, información de cuenta
                            bancaria, historial de transacciones de cuenta
                            bancaria, estados de cuenta bancaria, recibos de
                            pago del empleador, facturas telefónicas y datos de
                            servicios públicos, información de fuentes de
                            ingresos, puntaje de crédito o cualquier otra
                            información de identificación personal por la cual
                            pueda ser contactado en Línea o fuera de Línea
                            (&quot;PII&quot;);
                        </li>
                        <li>
                            Que se trate de usted, pero individualmente no lo
                            identifica; y/o
                        </li>
                        <li>
                            Acerca de su conexión al Internet, el equipo que
                            utiliza para acceder a nuestras Plataformas en Línea
                            y detalles de uso.
                        </li>
                    </ul>
                    <p className="py-3">Recopilamos esta información:</p>
                    <ul className="list-disc pl-10 pb-6">
                        <li>
                            Directamente de usted cuando nos la proporciona.
                        </li>
                        <li>
                            Automáticamente a medida que utiliza nuestras
                            Plataformas en Línea. La información recopilada
                            automáticamente puede incluir detalles de uso,
                            direcciones IP, e información recopilada a través de
                            cookies, balizas web, y otras tecnologías de
                            recopilación y seguimiento.
                        </li>
                        <li>
                            De terceros, por ejemplo, nuestros socios
                            comerciales.
                        </li>
                    </ul>
                    <p className="py-3 italic">
                        Información que Nos Proporciona
                    </p>
                    <p className="py-3">
                        La información que recopilamos en o a través de nuestras
                        Plataformas en Línea, tal como la proporcionó usted,
                        puede incluir:
                    </p>
                    <ul className="list-disc pl-10 pb-6">
                        <li>
                            Información que proporciona al completar formularios
                            en nuestras Plataformas en Línea. Esto incluye
                            información proporcionada al momento de registrarse
                            para usar nuestras Plataformas en Línea, cuando
                            solicita uno de nuestros productos o cuando solicita
                            cualquier otro servicio que ofrecemos o mediante
                            consultas generales sobre nuestros productos, que
                            utilizaremos para contactarlo. También podemos
                            solicitar y recopilar su PII para verificar su
                            identidad, con fines de seguridad y administrativos
                            o cuando informe un problema con nuestras
                            Plataformas en Línea.
                        </li>
                        <li>
                            Registros y copias de su correspondencia (incluidas
                            las direcciones de correo electrónico) e
                            interacciones con nosotros. Si nos contacta por
                            correo, teléfono o correo electrónico y nos
                            proporciona información sobre usted, mantendremos
                            registros de su correspondencia e interacciones.
                            Esto puede incluir cualquier pregunta, comentario o
                            sugerencia que haga durante estas interacciones.
                        </li>
                        <li>
                            Sus respuestas a encuestas que podríamos pedirle que
                            complete para fines de investigación.
                        </li>
                        <li>
                            Detalles de las transacciones que realiza a través
                            de nuestras Plataformas en Línea y del cumplimiento
                            de nuestros acuerdos con usted.
                        </li>
                    </ul>
                    <p className="py-3 italic">
                        Información que Recopilamos a Través de Tecnologías de
                        Recolección Automática de Datos
                    </p>
                    <p className="py-3">
                        A medida que navega e interactúa con nuestras
                        Plataformas en Línea, podemos utilizar tecnologías de
                        recopilación automática de datos para recopilar cierta
                        información sobre su acceso web, dispositivos, equipos,
                        acciones de navegación y patrones, que incluyen:
                    </p>
                    <ul className="list-disc pl-10 pb-6">
                        <li>
                            Detalles de su uso de nuestras Plataformas en Línea,
                            incluida la información de uso, datos de tráfico,
                            datos de ubicación, registros y otros datos de
                            comunicación y los recursos a los que accede y
                            utiliza en nuestras Plataformas en Línea.
                        </li>
                        <li>
                            Información sobre su computadora y conexión a
                            Internet, incluida su dirección IP, sistema
                            operativo y tipo de navegador.
                        </li>
                        <li>
                            Información sobre los dispositivos y equipos, como
                            el modelo de hardware, y sobre dispositivos móviles,
                            también podemos recopilar el identificador de
                            dispositivo único asignado a ese dispositivo,
                            sistema operativo y otros atributos del dispositivo
                            con fines de seguridad.
                        </li>
                    </ul>
                    <p className="py-3">
                        La información que recopilamos automáticamente es solo
                        información estadística y no incluye PII, pero podemos
                        mantenerla o asociarla con información personal que
                        recopilamos de otras maneras o que recibimos de
                        terceros. Nos ayuda a mejorar nuestras Plataformas en
                        Línea y a ofrecer un servicio mejor y más personalizado,
                        incluso nos permite estimar el tamaño de nuestra
                        audiencia y los patrones de uso, almacenar información
                        sobre sus preferencias, lo que nos permite personalizar
                        nuestras Plataformas en Línea de acuerdo con sus
                        intereses o incrementar la velocidad de sus búsquedas.
                    </p>
                    <p className="py-3">
                        Las tecnologías que utilizamos para esta recopilación
                        automática de datos pueden incluir:
                    </p>
                    <ul className="list-disc pl-10 pb-6">
                        <li>
                            Cookies (o cookies del navegador). Una cookie es un
                            pequeño archivo colocado en el disco duro de su
                            computadora. Puede negarse a aceptar las cookies del
                            navegador activando la configuración adecuada en su
                            navegador. Sin embargo, si selecciona esta
                            configuración es posible que no pueda acceder a
                            ciertas partes de nuestras Plataformas en Línea. Por
                            ejemplo, no puede crear una cuenta con nosotros o
                            solicitar un préstamo u otro producto sin aceptar
                            cookies. A menos que haya ajustado la configuración
                            de su navegador para que rechace las cookies,
                            nuestro sistema emitirá cookies cuando dirija su
                            navegador a nuestras Plataformas en Línea.
                        </li>
                        <li>
                            Flash Cookies. Ciertas características de nuestras
                            Plataformas en Línea pueden usar objetos locales
                            almacenados (o Flash cookies) para recopilar y
                            almacenar información sobre sus preferencias y
                            navegación hacia, desde, y en nuestras Plataformas
                            en Línea. Las Flash cookies no son administradas por
                            la misma configuración del navegador que se usa para
                            las cookies del navegador. Para obtener información
                            sobre cómo administrar su configuración de
                            privacidad y seguridad para las Flash cookies,
                            consulte la sección Opciones Sobre Cómo Usamos y
                            Divulgamos su Información.
                        </li>
                        <li>
                            Balizas Web. Partes de nuestras Plataformas en Línea
                            y nuestros correos electrónicos pueden contener
                            pequeños archivos electrónicos conocidos como
                            balizas web (también conocidos como gifs
                            transparentes, etiquetas de píxeles, y gifs de un
                            solo píxel) que permiten a la Compañía, por ejemplo,
                            contar usuarios quienes visitaron esas páginas o
                            abrieron un correo electrónico y otras estadísticas
                            relacionadas con el sitio web (por ejemplo,
                            registrar la popularidad de cierto contenido del
                            sitio web y verificar la integridad del sistema y
                            del servidor).
                        </li>
                    </ul>
                    <p className="py-3">
                        La información que recopilamos automáticamente no
                        incluye PII, pero podemos mantenerla o asociarla con PII
                        lo recopilamos de otras formas o lo recibimos de
                        terceros.
                    </p>
                    <p className="py-3">
                        También podemos usar estas tecnologías para recopilar
                        información sobre sus actividades en línea a lo largo
                        del tiempo y en sitios web de terceros u otros servicios
                        en línea (seguimiento del comportamiento).
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        Uso de Cookies y Recopilación de Datos de Terceros
                    </h5>
                    <p className="py-3">
                        Algunos contenidos o aplicaciones, incluidos los
                        anuncios, en las Plataformas en Línea, son provistos por
                        terceros, incluyendo anunciantes, redes y servidores de
                        anuncios, proveedores de contenido, y proveedores de
                        aplicaciones. Estos terceros pueden usar cookies, solos
                        o en conjunto con balizas web u otras tecnologías de
                        rastreo, para recopilar información sobre usted cuando
                        utiliza nuestro sitio web o aplicación móvil. La
                        información que recopilan puede estar asociada con su
                        información personal o pueden recopilar información,
                        incluida información personal, sobre sus actividades en
                        línea a lo largo del tiempo y en diferentes sitios web y
                        otros servicios en línea. Pueden usar esta información
                        para proporcionarle publicidad basada en sus intereses
                        (conductal) u otro contenido específico.
                    </p>
                    <p className="py-3">
                        No controlamos las tecnologías de seguimiento de estos
                        terceros ni cómo se pudieran usar. Si tiene alguna
                        pregunta sobre un anuncio u otro contenido específico,
                        debe comunicarse directamente con el proveedor
                        responsable. Para obtener información sobre cómo puede
                        optar por no recibir publicidad dirigida de muchos
                        proveedores, consulte la sección Opciones Sobre Cómo
                        Usamos y Divulgamos Su Información.
                    </p>
                    <p className="py-3">
                        Para proporcionarle nuestros productos y servicios, en
                        ocasiones podemos complementar la PII que nos envíe con
                        información de fuentes terceras, como información de
                        informes de consumidores e información crediticia.
                    </p>
                    <p className="py-3 italic">
                        Información de Cuenta y Transacción
                    </p>
                    <p className="py-3">
                        Recopilamos información sobre su uso de nuestros
                        productos y servicios. Por ejemplo, al procesar un pago
                        con usted. Otra información que recopilamos durante su
                        uso de nuestros productos y servicios incluye su
                        historial de transacciones, historial de pagos, y saldo
                        de cuenta. Para la seguridad de su información, también
                        recopilamos tokens de servicios en línea y sistemas de
                        pago para ayudarnos a procesar las transacciones de su
                        cuenta.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        Cómo Usamos Su Información
                    </h5>
                    <p className="py-3">
                        Usamos la información que recopilamos sobre usted o que
                        nos proporciona, incluida cualquier información
                        personal:
                    </p>
                    <ul className="list-disc pl-10 pb-6">
                        <li>
                            Para presentarle nuestras Plataformas en Línea y sus
                            contenidos.
                        </li>
                        <li>
                            Para proporcionarle información, productos, o
                            servicios que nos solicite.
                        </li>
                        <li>
                            Para cumplir con cualquier otro propósito para el
                            que lo proporcione.
                        </li>
                        <li>
                            Para proporcionarle avisos sobre sus cuentas,
                            incluidos, entre otros, el estado de la solicitud y
                            detalles del plazo de repago y notificaciones.
                        </li>
                        <li>
                            Para cumplir con nuestras obligaciones y hacer
                            cumplir nuestros derechos derivados de los contratos
                            celebrados entre usted y nosotros, incluida la
                            facturación y el cobro.
                        </li>
                        <li>
                            Para notificarle sobre cambios en nuestras
                            Plataformas en Línea o cualquier producto o servicio
                            que ofrezcamos o proporcionamos a través de las
                            mismas.
                        </li>
                        <li>
                            Para permitirle participar en funciones interactivas
                            en nuestras Plataformas en Línea.
                        </li>
                        <li>
                            De cualquier otra manera, podemos describir cuándo
                            proporciona la información.
                        </li>
                        <li>
                            Para cualquier otro propósito con su consentimiento.
                        </li>
                    </ul>
                    <p className="py-3">
                        También podemos usar su información para contactarlo
                        sobre nuestros productos y servicios que puedan ser de
                        su interés. Si no desea que usemos su información de
                        esta manera, envíenos un correo electrónico a
                        support@kiwicredito.com. Para obtener más información,
                        consulte la sección Opciones Sobre Cómo Usamos y
                        Divulgamos Su Información.
                    </p>
                    <p>
                        Podemos utilizar la información que hemos recopilado de
                        usted para que podamos mostrar anuncios a los públicos
                        objetivo de nuestros anunciantes. Aunque no divulguemos
                        su información personal para estos fines sin su
                        consentimiento, si hace clic o interactúa con un
                        anuncio, el anunciante puede asumir que cumple con los
                        criterios de su objetivo.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        Divulgación de Su Información
                    </h5>
                    <p className="py-3">
                        Podemos divulgar la PII que recopilamos, o que usted
                        proporciona tal como se describe en esta política de
                        privacidad:
                    </p>
                    <ul className="list-disc pl-10 pb-6">
                        <li>
                            A los contratistas, proveedores de servicios, y
                            otros terceros que utilizamos para respaldar nuestro
                            negocio y que están obligados por las obligaciones
                            contractuales a mantener la confidencialidad de la
                            información personal y usarla solo para los fines
                            para los que se la revelamos.
                        </li>
                        <li>
                            A un comprador u otro sucesor en caso de fusión,
                            desinversión, reestructuración, reorganización,
                            disolución, u otra venta o transferencia de algunos
                            o todos los activos de Kiwi Financial, Inc, ya sea
                            como empresa en marcha o como parte de la quiebra,
                            liquidación, o un procedimiento similar, en el que
                            la información personal que tenemos sobre nuestros
                            usuarios de Plataformas en Línea se encuentra entre
                            los activos transferidos.
                        </li>
                        <li>
                            Para cumplir con el propósito para el que usted lo
                            proporciona.
                        </li>
                        <li>
                            Para cualquier otro propósito revelado por nosotros
                            cuando proporciona la información.
                        </li>
                        <li>Con su consentimiento.</li>
                    </ul>
                    <p className="py-3">
                        También podemos divulgar su información personal:
                    </p>
                    <ul className="list-disc pl-10 pb-6">
                        <li>
                            Para cumplir con cualquier orden judicial, ley, o
                            proceso legal, incluso para responder a cualquier
                            solicitud gubernamental o reglamentaria.
                        </li>
                        <li>
                            Hacer cumplir o aplicar nuestros términos de uso y
                            otros acuerdos, incluso para fines de facturación y
                            cobro.
                        </li>
                    </ul>
                    <p className="py-3">
                        Si creemos que la divulgación es necesaria o apropiada
                        para proteger los derechos, la propiedad o la seguridad
                        de la Compañía, nuestros clientes u otros. Esto incluye
                        el intercambio de información con otras compañías y
                        organizaciones con fines de protección contra el fraude
                        y reducción del riesgo de crédito.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        Cómo Usamos Su Información
                    </h5>
                    <p className="py-3">
                        Usamos la información que recopilamos sobre usted o que
                        nos proporciona, incluida cualquier información
                        personal:
                    </p>
                    <ul className="list-disc pl-10 pb-6">
                        <li>
                            Para presentarle nuestras Plataformas en Línea y sus
                            contenidos.
                        </li>
                        <li>
                            Para proporcionarle información, productos, o
                            servicios que nos solicite.
                        </li>
                        <li>
                            Para cumplir con cualquier otro propósito para el
                            que lo proporcione.
                        </li>
                        <li>
                            Para proporcionarle avisos sobre sus cuentas,
                            incluidos, entre otros, el estado de la solicitud y
                            detalles del plazo de repago y notificaciones.
                        </li>
                        <li>
                            Para cumplir con nuestras obligaciones y hacer
                            cumplir nuestros derechos derivados de los contratos
                            celebrados entre usted y nosotros, incluida la
                            facturación y el cobro.
                        </li>
                        <li>
                            Para notificarle sobre cambios en nuestras
                            Plataformas en Línea o cualquier producto o servicio
                            que ofrezcamos o proporcionamos a través de las
                            mismas.
                        </li>
                        <li>
                            Para permitirle participar en funciones interactivas
                            en nuestras Plataformas en Línea.
                        </li>
                        <li>
                            De cualquier otra manera, podemos describir cuándo
                            proporciona la información.
                        </li>
                        <li>
                            Para cualquier otro propósito con su consentimiento.
                        </li>
                    </ul>
                    <p className="py-3">
                        También podemos usar su información para contactarlo
                        sobre nuestros productos y servicios que puedan ser de
                        su interés. Si no desea que usemos su información de
                        esta manera, envíenos un correo electrónico a
                        support@kiwicredito.com. Para obtener más información,
                        consulte la sección Opciones Sobre Cómo Usamos y
                        Divulgamos Su Información.
                    </p>
                    <p className="py-3">
                        Podemos utilizar la información que hemos recopilado de
                        usted para que podamos mostrar anuncios a los públicos
                        objetivo de nuestros anunciantes. Aunque no divulguemos
                        su información personal para estos fines sin su
                        consentimiento, si hace clic o interactúa con un
                        anuncio, el anunciante puede asumir que cumple con los
                        criterios de su objetivo.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        Divulgación de Su Información
                    </h5>
                    <p className="py-3">
                        Podemos divulgar la PII que recopilamos, o que usted
                        proporciona tal como se describe en esta política de
                        privacidad:
                    </p>
                    <ul className="list-disc pl-10 pb-6">
                        <li>
                            A los contratistas, proveedores de servicios, y
                            otros terceros que utilizamos para respaldar nuestro
                            negocio y que están obligados por las obligaciones
                            contractuales a mantener la confidencialidad de la
                            información personal y usarla solo para los fines
                            para los que se la revelamos.
                        </li>
                        <li>
                            A un comprador u otro sucesor en caso de fusión,
                            desinversión, reestructuración, reorganización,
                            disolución, u otra venta o transferencia de algunos
                            o todos los activos de Kiwi Financial, Inc, ya sea
                            como empresa en marcha o como parte de la quiebra,
                            liquidación, o un procedimiento similar, en el que
                            la información personal que tenemos sobre nuestros
                            usuarios de Plataformas en Línea se encuentra entre
                            los activos transferidos.
                        </li>
                        <li>
                            Para cumplir con el propósito para el que usted lo
                            proporciona.
                        </li>
                        <li>
                            Para cualquier otro propósito revelado por nosotros
                            cuando proporciona la información.
                        </li>
                        <li>Con su consentimiento.</li>
                    </ul>
                    <p className="py-3">
                        También podemos divulgar su información persona
                    </p>
                    <ul className="list-disc pl-10 pb-6">
                        <li>
                            Para cumplir con cualquier orden judicial, ley, o
                            proceso legal, incluso para responder a cualquier
                            solicitud gubernamental o reglamentaria.
                        </li>
                        <li>
                            Hacer cumplir o aplicar nuestros términos de uso y
                            otros acuerdos, incluso para fines de facturación y
                            cobro.
                        </li>
                    </ul>
                    <p className="py-3">
                        Si creemos que la divulgación es necesaria o apropiada
                        para proteger los derechos, la propiedad o la seguridad
                        de la Compañía, nuestros clientes u otros. Esto incluye
                        el intercambio de información con otras compañías y
                        organizaciones con fines de protección contra el fraude
                        y reducción del riesgo de crédito.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        Opciones Sobre Cómo Usamos y Divulgamos Su Información,
                        y los Límites para Compartir
                    </h5>
                    <p className="py-3">
                        Nos esforzamos por brindarle opciones con respecto a la
                        información personal que nos proporciona. Hemos creado
                        mecanismos para proporcionarle el siguiente control
                        sobre su información:
                    </p>
                    <ul className="list-disc pl-10 pb-6">
                        <li>
                            Tecnologías de seguimiento y publicidad. Puede
                            configurar su navegador para rechazar todas o
                            algunas cookies del navegador o para que le avise
                            cuando se envíen cookies. Para saber cómo puede
                            administrar su configuración de Flash cookies,
                            visite la página de configuración del reproductor
                            Flash en el sitio web de Adobe. Si deshabilita o
                            rechaza las cookies, tenga en cuenta que algunas
                            partes de este sitio pueden ser inaccesibles o no
                            funcionar correctamente.
                        </li>
                        <li>
                            Ofertas promocionales de la empresa. Si no desea que
                            la Compañía utilice su dirección de correo
                            electrónico/información de contacto para promocionar
                            productos o servicios propios o de terceros, puede
                            optar por no participar enviándonos un correo
                            electrónico a support@kiwicredito.com. Si le hemos
                            enviado un correo electrónico promocional, puede
                            enviarnos un correo electrónico de devolución
                            solicitando que se lo omita de futuras
                            distribuciones de correo electrónico. Esta exclusión
                            no se aplica a la información proporcionada a la
                            Compañía como resultado de la compra de un producto,
                            la experiencia del servicio del producto u otras
                            transacciones.
                        </li>
                        <li>
                            Publicidad dirigida. Si no desea que usemos la
                            información que recopilamos o que nos proporciona
                            para entregar anuncios de acuerdo con las
                            preferencias del público objetivo de nuestros
                            anunciantes, puede darse de baja enviándonos un
                            correo electrónico a support@kiwicredito.com.
                        </li>
                    </ul>
                    <p className="py-3">
                        Consulte nuestra sección de Prácticas de privacidad para
                        obtener más información sobre cómo usamos su PII.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        Accediendo y Corrigiendo Su Información
                    </h5>
                    <p className="py-3">
                        Puede revisar y cambiar su información personal
                        iniciando sesión en la página de perfil de su cuenta.
                        También puede enviarnos un correo electrónico a para
                        solicitar acceso, corregir, o eliminar cualquier
                        información personal que nos haya proporcionado. No
                        podemos eliminar su información personal, excepto si
                        también eliminamos su cuenta de usuario. Es posible que
                        no aceptemos una solicitud de cambio de información si
                        creemos que el cambio violaría cualquier ley o requisito
                        legal o haría que la información sea incorrecta.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        Seguridad de Datos
                    </h5>
                    <p className="py-3">
                        Hemos implementado medidas diseñadas para proteger su
                        información personal de pérdidas accidentales y de
                        acceso, uso, alteración y divulgación no autorizada.
                        Toda la información que nos proporciona se almacena en
                        nuestros servidores seguros detrás de firewalls. Todas
                        las transacciones serán encriptadas. Además, aplicamos
                        controles de acceso físico a nuestras instalaciones y su
                        información personal solo está disponible para empleados
                        autorizados, ya que se requiere responsabilidad laboral.
                        La seguridad de su información también depende de usted.
                        Cuando tiene una cuenta de cliente activa en línea y le
                        hemos dado (o donde ha elegido) una contraseña, usted es
                        responsable de mantener la confidencialidad de esta
                        contraseña. Le pedimos que no comparta su contraseña con
                        nadie.
                    </p>
                    <p className="py-3">
                        Lamentablemente, la transmisión de información a través
                        del Internet no es completamente segura. Aunque hacemos
                        todo lo posible para proteger su información personal,
                        no podemos garantizar la seguridad de su información
                        personal transmitida a nuestras Plataformas en Línea.
                        Cualquier transmisión de información personal es bajo su
                        propio riesgo. No somos responsables de eludir ninguna
                        configuración de privacidad o medidas de seguridad
                        contenidas en las Plataformas en Línea. Si tiene alguna
                        pregunta o inquietud sobre la seguridad de su PII, o
                        cree que se ha visto comprometida, envíenos un correo
                        electrónico a support@kiwicredito.com.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        Cambios a Nuestra Política de Privacidad
                    </h5>
                    <p className="py-3">
                        Es nuestra política publicar cualquier cambio que
                        hagamos en nuestra política de privacidad. Si hacemos
                        cambios materiales en la forma en que tratamos la
                        información personal de nuestros usuarios, le
                        notificaremos por correo electrónico a la última
                        dirección de correo electrónico notificada a la
                        Compañía, tal como puede aparecer en nuestros registros.
                        La fecha de la última revisión de la política de
                        privacidad se identifica en la parte superior de la
                        página. Usted es responsable de garantizar que tengamos
                        una dirección de correo electrónico activa y disponible
                        actualizada para usted, y de visitar periódicamente
                        nuestro sitio web y esta política de privacidad para
                        verificar cualquier cambio.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        Prácticas de Privacidad
                    </h5>
                    <p className="py-3 italic">
                        ¿Qué hace Kiwi Financial, Inc con su información
                        personal?
                    </p>
                    <ul className="list-decimal pl-10 pb-6">
                        <li>
                            ¿Por qué? Las compañías financieras eligen cómo
                            comparten su información personal. La ley federal
                            otorga a los consumidores el derecho de limitar
                            parte, pero no todo, el intercambio. La ley federal
                            también nos exige que le informemos cómo
                            recopilamos, compartimos, y protegemos su
                            información personal. Lea este aviso detenidamente
                            para comprender lo que hacemos.
                        </li>
                        <li>
                            ¿Qué? Los tipos de información personal que
                            recopilamos y compartimos dependen del producto o
                            servicio que tenga con nosotros. Esta información
                            puede incluir:
                            <ul className="list-disc">
                                <li>Número de Seguro Social e ingresos</li>
                                <li>
                                    Número de teléfono, dirección de correo
                                    electrónico, direcciones físicas y postales,
                                    fecha de nacimiento
                                </li>
                                <li>
                                    Saldos de cuenta Kiwi e historial de
                                    transacciones, pagos, o historial de
                                    pérdidas
                                </li>
                                <li>
                                    Saldos de cuentas bancarias e historial de
                                    transacciones, extractos y fuentes de
                                    ingresos
                                </li>
                                <li>
                                    Facturas telefónicas e historial de pagos de
                                    servicios públicos
                                </li>
                                <li>
                                    Información del empleador y recibos de pago
                                </li>
                                <li>
                                    Historial de crédito, puntajes de crédito, y
                                    tarjeta de crédito y/u otra deuda
                                </li>
                            </ul>
                        </li>
                        <li>
                            ¿Cómo? Todas las compañías financieras necesitan
                            compartir la información personal de los clientes
                            para administrar sus negocios cotidianos. En la
                            sección a continuación, enumeramos las razones por
                            las cuales las compañías financieras pueden
                            compartir la información personal de sus clientes,
                            las razones por las que Kiwi Financial, Inc elige
                            compartir; y si puedes limitar este intercambio.
                        </li>
                    </ul>
                    <p className="py-3 italic">
                        Motivos por los que podemos compartir su información
                        personal:
                    </p>
                    <ul className="list-decimal pl-10 pb-6">
                        <li>
                            Para nuestros fines comerciales cotidianos—como
                            procesar sus transacciones, mantener su(s)
                            cuenta(s), responder a órdenes judiciales e
                            investigaciones legales, o informar a los burós de
                            crédito
                            <ul className="list-disc pl-10">
                                <li>¿Kiwi Financial, Inc comparte? Si</li>
                                <li>¿Puedes limitar este intercambio? No</li>
                            </ul>
                        </li>
                        <li>
                            Para nuestros fines de marketing—ofrecerle nuestros
                            productos y servicios
                            <ul className="list-disc pl-10">
                                <li>¿Kiwi Financial, Inc comparte? Si</li>
                                <li>¿Puedes limitar este intercambio? No</li>
                            </ul>
                        </li>
                        <li>
                            Para mercadeo conjunto con otras compañías
                            financieras
                            <ul className="list-disc pl-10">
                                <li>¿Kiwi Financial, Inc comparte? Si</li>
                                <li>¿Puedes limitar este intercambio? No</li>
                            </ul>
                        </li>
                        <li>
                            Para los fines comerciales cotidianos de nuestros
                            afiliados—información sobre sus transacciones y
                            experiencias
                            <ul className="list-disc pl-10">
                                <li>¿Kiwi Financial, Inc comparte? Si</li>
                                <li>¿Puedes limitar este intercambio? No</li>
                            </ul>
                        </li>
                        <li>
                            Para los fines comerciales cotidianos de nuestros
                            afiliados—información sobre su solvencia
                            <ul className="list-disc pl-10">
                                <li>¿Kiwi Financial, Inc comparte? Si</li>
                                <li>¿Puedes limitar este intercambio? No</li>
                            </ul>
                        </li>
                        <li>
                            Para que los no afiliados la comercialicen a usted
                            <ul className="list-disc pl-10">
                                <li>¿Kiwi Financial, Inc comparte? Si</li>
                                <li>¿Puedes limitar este intercambio? No</li>
                            </ul>
                        </li>
                    </ul>
                    <p className="py-3">
                        Para limitar nuestro intercambio: Envíenos un correo
                        electrónico a support@kiwicredito.com
                    </p>
                    <p className="py-3">
                        Tenga en cuenta: Si es un cliente nuevo, podemos
                        comenzar a compartir su información 30 días después de
                        la fecha en que enviamos este aviso. Cuando ya no sea
                        nuestro cliente, continuaremos compartiendo su
                        información como se describe en este aviso
                    </p>
                    <p className="py-3">
                        Sin embargo, puede contactarnos en cualquier momento
                        para limitar nuestro intercambio.
                    </p>
                    <p className="py-3">
                        ¿Quién está proporcionando este aviso? Kiwi Financial,
                        Inc
                    </p>
                    <p className="py-3">
                        ¿Cómo protege Kiwi Financial, Inc mi información
                        personal? Para proteger su información personal del
                        acceso y uso no autorizados, utilizamos medidas de
                        seguridad que cumplen con la ley federal. Estas medidas
                        incluyen protecciones informáticas y archivos y
                        edificios seguros.
                    </p>
                    <p className="py-3">
                        Nuestros sistemas de información tienen controles, tales
                        como: firewalls, antivirus, contraseñas, pruebas
                        periódicas de nuestro acceso a la red, y encriptación de
                        computadoras y correos electrónicos.
                    </p>
                    <p className="py-3">
                        ¿Cómo recopila Kiwi Financial, Inc mi información
                        personal? Recopilamos su información personal, por
                        ejemplo, cuando usted:
                    </p>
                    <ul className="list-disc pl-10 pb-6">
                        <li>abre una cuenta o solicita un préstamo</li>
                        <li>solicita financiación</li>
                        <li>paga sus cuentas</li>
                    </ul>
                    <p className="py-3">
                        Las leyes estatales y compañías individuales pueden
                        otorgar derechos adicionales para limitar el
                        intercambio.
                    </p>
                    <p className="py-3">
                        ¿Qué sucede cuando limito el intercambio de una cuenta y
                        la mantengo en conjunto con otra persona? Sus opciones
                        se aplicarán a todos en su cuenta a menos que usted nos
                        indique lo contrario.
                    </p>
                    <p className="py-3">Definiciones:</p>
                    <ul className="list-disc pl-10 pb-6">
                        <li>
                            Afiliados: Empresas relacionadas por propiedad o
                            control común. Pueden ser empresas financieras y no
                            financieras.
                            <ul className="list-disc">
                                <li>Kiwi Financial, Inc no tiene afiliados.</li>
                            </ul>
                        </li>
                        <li>
                            No afiliados: Empresas no relacionadas por propiedad
                            o control común. Pueden ser empresas financieras y
                            no financieras.
                            <ul className="list-disc">
                                <li>
                                    Kiwi Financial, Inc no comparte con no
                                    afiliados para que puedan comercializarse a
                                    usted.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Marketing conjunto: Un acuerdo formal entre
                            compañías financieras no afiliadas para que juntas
                            puedan comercializar productos o servicios
                            financieros.
                            <ul className="list-disc">
                                <li>
                                    Nuestros socios de marketing conjunto pueden
                                    incluir marketing directo, telemarketing,
                                    seguros, u otros proveedores de marketing.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        Información de Contacto
                    </h5>
                    <p className="py-3">
                        Para hacer preguntas o comentar sobre esta política de
                        privacidad y nuestras prácticas de privacidad,
                        contáctenos en support@kiwicredito.com
                    </p>
                </div>
            </div>
        </BaseModal>
    )
}

export default PrivacyTermsModal
