export default ({ className, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={className}
        {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="currentColor"
            d="M12 2.065c.992 0 1.899.443 2.678 1.163 1.46 1.349 2.772 3.697 3.843 6.043 1.089 2.385 1.994 4.914 2.6 6.749.672 2.033-.207 4.198-2.004 5.276a4.634 4.634 0 0 1-5.876-.914c-.393-.45-.817-.937-1.241-1.427-.424.49-.848.977-1.242 1.427a4.634 4.634 0 0 1-5.875.914c-1.797-1.078-2.676-3.243-2.004-5.276.606-1.835 1.511-4.364 2.6-6.749C6.55 6.925 7.86 4.577 9.32 3.228c.78-.72 1.687-1.163 2.679-1.163Zm1.316 15.354c.63-.745 1.17-1.401 1.484-1.82.94-1.253 1.181-2.85.714-4.18-.486-1.384-1.727-2.42-3.514-2.42-1.787 0-3.028 1.036-3.514 2.42-.467 1.33-.227 2.927.714 4.18.313.419.853 1.075 1.484 1.82-.48.556-.972 1.12-1.43 1.646a2.634 2.634 0 0 1-3.342.516c-1.034-.62-1.498-1.832-1.134-2.934.595-1.8 1.474-4.254 2.52-6.545 1.063-2.33 2.238-4.35 3.38-5.405.516-.476.961-.632 1.322-.632.36 0 .805.156 1.322.632 1.142 1.055 2.316 3.076 3.38 5.405 1.046 2.291 1.925 4.745 2.52 6.545.364 1.102-.1 2.313-1.134 2.934a2.634 2.634 0 0 1-3.341-.516c-.46-.525-.951-1.09-1.431-1.646ZM12 15.877a39.115 39.115 0 0 1-1.2-1.477c-.56-.746-.663-1.649-.427-2.318C10.59 11.464 11.099 11 12 11c.9 0 1.41.464 1.627 1.082.235.67.132 1.572-.427 2.318-.254.339-.683.863-1.2 1.477Z"
        />
    </svg>
)
