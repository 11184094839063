import http from "./http"

const createLinkFinicity = () => {
  return new Promise(async (resolve, reject) => {
      try {
          const response = await http.post('/users/finicity/connect')

          resolve(response)
      } catch (error) {
          reject(error)
      }
  })
}

const saveFinicityConnection = payload => {
  return new Promise(async (resolve, reject) => {
      try {
          const response = await http.post('/users/finicity/save-connection', {
              ...payload
          })

          resolve(response)
      } catch (error) {
          reject(error)
      }
  })
}

const refreshFinicity = () => {
  return new Promise(async (resolve, reject) => {
      try {
          const response = await http.post('/users/finicity/refresh')

          resolve(response)
      } catch (error) {
          reject(error)
      }
  })
}

export { refreshFinicity, saveFinicityConnection, createLinkFinicity }