import { ChevronLeftIcon } from 'assets/icons_v3/fonts'

const BackwardButton = ({ onBackward, className }) => {
    return (
        <ChevronLeftIcon
            className={`shrink-0 grow-0 cursor-pointer w-2 mb-12 ${className}`}
            onClick={onBackward}
        />
    )
}

export default BackwardButton
