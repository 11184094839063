import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

import PaymentProfile from 'templates/PaymentProfile'
import BackwardButton from 'components/Button/Backward'

export default function AutopayPlaidPaymentProfile() {
    const navigate = useNavigate()

    const onBackward = () => {
        navigate('/autopay')
    }

    const onSelectProfile = ({ id, last4, bankName }) => {
        console.log(id, last4, bankName)
    }

    return (
        <Fragment>
            <BackwardButton onBackward={onBackward} />

            <PaymentProfile
                onSelectProfile={onSelectProfile}
                selectedProfile=""
                selectProfileTitle="Selecciona tu cuenta"
                selectProfileDescription="Elige la cuenta de <span className='font-semibold'>Banco Popular</span> que prefieres usar para los pagos automáticos"
            />
        </Fragment>
    )
}
