import ReactGA from 'react-ga4'
export const initGA = () => {
    ReactGA.initialize([
        {
            trackingId: 'G-FH4GD2G5QE',
        }
    ]) // put your tracking id here
}

export const GApageView = (pageview, path) => {
    ReactGA.send({ hitType: pageview, page: path })
}

export const GAevent = (category, eventName, value) => {
    let eventForm = {
        category, // Required
        action: eventName, // Required
    }
    if(value) {
        eventForm.value = parseInt(value) || 0
    }
    ReactGA.event(eventForm)
}
