import React from 'react'

export default function PaidoutCourseSkeleton() {
    return (
        <div>
            <div className="h-20 bg-gray-200-kiwi rounded-t-lg flex gap-6 items-center">
                <div className="w-20 h-20 bg-gray-300-kiwi rounded-t-lg shrink-0"></div>
                <div className="w-full pr-6">
                    <div className="h-3 w-2/3 bg-gray-300-kiwi rounded-full mb-2"></div>
                    <div className="h-3 w-3/3 bg-gray-300-kiwi rounded-full"></div>
                </div>
            </div>
        </div>
    )
}
