import React from 'react'
import BaseModal from '../BaseModal'

const LateFee = ({ isVisible, closeModal }) => {
    return (
        <BaseModal
            title="Cargo por mora"
            isVisible={isVisible}
            closeModal={closeModal}>
            <div className="flex flex-col gap-6 mt-6">
                <p>
                    El "cargo por mora" o "late fee" es una tarifa que se aplica
                    cuando el pago se retrasa más de 11 días respecto a la fecha
                    de pago acordada, equivalente al 2.50% del monto en mora.
                </p>
            </div>
        </BaseModal>
    )
}

export default LateFee
