import delay from 'delay';
import React, {useState, useEffect, Fragment, useContext} from 'react';
import {Formik, Form, Field} from 'formik';
import {useNavigate} from 'react-router-dom';
import {RequestLoanContext} from 'context/RequestLoanContext';
import mixpanel from 'utils/mixpanel';

import LoadingModal from 'components/Modals/Loading';
import ProgressBar from 'components/ProgressBar';
import {UserContext} from 'context/UserContext';

export default function RequestLoanEmployment() {
    const navigate = useNavigate();

    const {state: userState} = useContext(UserContext);
    const {state: requestState, saveState} = useContext(RequestLoanContext);
    const [loading, setLoading] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        dataLayer.push({
            event: 'new_application',
            eventCategory: 'loan_process',
            eventAction: 'loan_process_job_tittle',
            dl_user_id: userState.user.id,
            dl_income:
                userState.products.personal_loan.tracking.context.income.value,
        });

        if (requestState.income.completed === false) {
            return navigate('/request-loan/income');
        }
    }, []);

    const onContinue = async $event => {
        if (loading === true) {
            return;
        }

        try {
            setLoading(true);
            setShowLoader(true);

            await delay(300);
            saveState({
                ...requestState,
                employment: {
                    value: $event.target.value,
                    completed: true,
                },
            });

            mixpanel.track('Employment Status Survey', {
                'Job type': $event.target.value,
            });

            navigate('/request-loan/military');
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            setShowLoader(false);
        }
    };

    const onBackward = () => {
        navigate('/request-loan/income');
    };

    const LABOUR_LIST = [
        'Desempleado',
        'Empleado',
        'Independiente',
        'Jubilado',
        'Militar',
        'Otro',
    ];

    const initialState = {
        value: '',
    };

    return (
        <Formik initialValues={initialState}>
            {() => (
                <Fragment>
                    <LoadingModal isVisible={showLoader} />

                    <ProgressBar progress="5/12" onBackward={onBackward} />

                    <Form className="flex flex-col grow" autoComplete="off">
                        <h1 className="text-dark-kiwi font-semibold text-2xl">
                            Cuéntanos sobre tu situación laboral actual
                        </h1>

                        <div className="flex flex-col gap-6 mb-10 mt-8">
                            {LABOUR_LIST.map(item => (
                                <div className="relative" key={item}>
                                    <Field
                                        className="peer hidden"
                                        type="radio"
                                        id={item}
                                        name="value"
                                        value={item}
                                        onClick={onContinue}
                                    />
                                    <div className='pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:bg-[url("./assets/icons/check.svg")] after:bg-no-repeat after:bg-center after:bg-auto after:rounded-full absolute right-4 top-1/2 -translate-y-1/2 rounded-full w-5 h-5 peer-checked:border-blue-kiwi border border-solid border-gray-400-kiwi'></div>
                                    <label
                                        htmlFor={item}
                                        className="cursor-pointer peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 block rounded-lg border border-solid border-gray-200-kiwi p-4">
                                        {item}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </Form>
                </Fragment>
            )}
        </Formik>
    );
}
