import React, { Fragment, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import BankReviewingSVG from 'assets/onboarding_v3/bank_reviewing.svg'
import { VerificationsAPI } from 'api'
import { DISBURSEMENT_CHECK_STATUS } from 'constants'
import { ApprovedLoanContext } from 'context/ApprovedLoanContext'
export default function ApprovedLoanPaperCheckReviewing() {
    const { resetState: resetApprovedState } = useContext(ApprovedLoanContext)
    const navigate = useNavigate()

    const onContinue = () => {
        navigate('/home')
    }
    useEffect(() => {
        disbursementCheckStatus()
    }, [])

    const disbursementCheckStatus = async () => {
        const { data } = await VerificationsAPI.getDisbursementCheck()
        if (data?.status === DISBURSEMENT_CHECK_STATUS.rejected) {
            await resetApprovedState()
            return navigate('/approved-loan/amount')
        }
    }

    return (
        <Fragment>
            <div className="flex flex-col justify-center min-h-full text-center m-auto">
                <img className="mx-auto mb-8" src={BankReviewingSVG} />

                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    Verificando tu cuenta bancaria
                </h1>
                <p>
                    Estamos verificando tu información, esto puede tardar entre
                    12 a 24 horas laborales.
                </p>
            </div>

            <button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                onClick={onContinue}>
                Continuar
            </button>
        </Fragment>
    )
}
