import dayjs from 'dayjs'
import React from 'react'
import BaseModal from '../BaseModal'
import CreditLimitRejected from 'assets/onboarding/rejected_money.svg'
import Warning from 'components/Warning'

const ReejctedCreditLimitModal = ({
    isVisible,
    closeModal,
    data = {},
    messageType = 'default'
}) => {
    const add6Months = (date) => {
        return dayjs(date?.replace(/Z/g, ''))
            .add(6, 'month')
            .format('MM/DD/YYYY')
    }

    const formatDate = (date) => {
        return dayjs(date?.replace(/Z/g, '')).format('MM/DD/YYYY')
    }

    const messages = {
        recently_limit_increase: {
            title: 'Lo sentimos, no es posible el aumento de límite',
            description: `Esta cuenta no es elegible para una solicitud de aumento de límite de crédito porque su <strong>último cambio de límite de crédito fue el ${formatDate(
                data.last_date_credit_limit
            )}</strong>`,
            warning: `Deber haber 6 meses entre cambios en su límite de crédito. Su próxima solicitud podrá realizarse <strong>a partir del ${add6Months(
                data.last_date_credit_limit
            )}</strong>`
        },
        no_applicable: {
            title: 'Lo sentimos, no es posible el aumento de límite',
            description:
                'Por ahora no cumples con los requisitos para solicitar un aumento del límite de crédito. Si desea mayor información, favor de contactarnos a <strong>support@kiwicredito.com</strong>'
        },
        max_credit_limit: {
            title: 'Límite de crédito máximo',
            description:
                'Por ahora no es posible solicitar un aumento de límite de crédito. Actualmente tienes el máximo de límite de crédito permitido de <strong>$1,000</strong>'
        },
        default: {
            title: 'Lo sentimos, no es posible el aumento de límite',
            description:
                'Esta cuenta no reúne los requisitos necesarios para solicitar un aumento en el límite de crédito. Para obtener más información, no dudes en contactarnos a <strong>support@kiwicredito.com</strong>'
        }
    }

    return (
        <BaseModal isVisible={isVisible} closeModal={closeModal}>
            <div className="flex flex-col gap-6 mt-6 text-center">
                <img
                    className="w-40 mx-auto mb-6"
                    src={CreditLimitRejected}
                    alt="kiwi-image"
                />
                <h1 className="text-dark-kiwi font-semibold text-2xl">
                    {messages[messageType]?.title}
                </h1>
                <p
                    dangerouslySetInnerHTML={{
                        __html: messages[messageType]?.description
                    }}></p>

                {messages[messageType]?.warning && (
                    <Warning className="items-center text-left">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: messages[messageType]?.warning
                            }}></span>
                    </Warning>
                )}
            </div>
            <button
                className="w-full rounded-xl p-3 mt-10 bg-blue-kiwi text-white"
                onClick={closeModal}>
                Aceptar
            </button>
        </BaseModal>
    )
}

export default ReejctedCreditLimitModal
