export default [
    {
        name: 'Wells Fargo Bank',
        routingNumber: '',
        maxDigitsAccount: 13
    },
    {
        name: 'Truist Bank',
        routingNumber: '',
        maxDigitsAccount: 12
    },
    {
        name: 'Bank of America',
        routingNumber: '',
        maxDigitsAccount: 12
    },
    {
        name: 'Chase Bank',
        routingNumber: '',
        maxDigitsAccount: 10
    },
    {
        name: 'Otro',
        routingNumber: '',
        maxDigitsAccount: ''
    }
]
