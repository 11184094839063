import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import MagnifyingSVG from 'assets/onboarding_v3/magnifying.svg'
import { ChevronLeftIcon } from 'assets/icons_v3/fonts'
import Advisement from 'components/Advisement'
import { ApprovedLoanContext } from 'context/ApprovedLoanContext'
import { REPAYMENT_OPTIONS } from 'constants'
import { VerificationsAPI } from 'api'
import { REPAYMENT_CHECK_STATUS } from 'constants'

export default function ApprovedLoanPaperCheckReviewing() {
    const navigate = useNavigate()
    const {
        state: approvedState,
        saveState: saveApprovedState,
        resetState: resetApprovedState
    } = useContext(ApprovedLoanContext)
    const [showOutlet, setShowOutlet] = useState(false)
    const [repaymentCheckId, setRepaymentCheckId] = useState('')

    useEffect(() => {
        repaymentCheckStatus()
    }, [])

    const repaymentCheckStatus = async () => {
        const { data } = await VerificationsAPI.getRepaymentCheck()
        setRepaymentCheckId(data?.id)
        if (data?.status === REPAYMENT_CHECK_STATUS.rejected) {
            await resetApprovedState()
            return navigate('/approved-loan/amount')
        }

        if (data?.status === REPAYMENT_CHECK_STATUS.resolved) {
            saveApprovedState({
                ...approvedState,

                repaymentOptionReviewing: {
                    completed: true
                }
            })

            return navigate('/approved-loan/persona')
        }

        setShowOutlet(true)
    }

    const onContinue = () => {
        navigate('/home')
    }

    const redirectAch = async () => {
        await VerificationsAPI.cancelRepaymentCheck(repaymentCheckId)
        saveApprovedState({
            ...approvedState,
            repaymentOption: {
                value: REPAYMENT_OPTIONS.ACH,
                completed: false
            }
        })

        navigate('/approved-loan/repayment-options')
    }
    if (showOutlet) {
        return (
            <Fragment>
                <ChevronLeftIcon
                    className="cursor-pointer w-2 mb-12"
                    onClick={onContinue}
                />

                <div className="flex flex-col justify-center h-full text-center m-auto">
                    <img className="mx-auto mb-8" src={MagnifyingSVG} />
                    <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                        Revisando solicitud
                    </h1>
                    <p>
                        Nuestro equipo te estará contactando durante las
                        próximas 48 y 72 horas laborables para completar el
                        proceso de verificación.
                    </p>
                </div>

                <Advisement icon="bulb_money" className="mt-10">
                    <strong>
                        Puedes evitar este requerimiento eligiendo repagar a
                        través de pagos automáticos mediante ACH
                    </strong>
                </Advisement>

                <div className="flex flex-col mt-10">
                    <button
                        className="w-full bg-blue-kiwi text-white rounded-xl py-3 px-8"
                        onClick={onContinue}>
                        Aceptar
                    </button>
                    <div
                        className="cursor-pointer mx-auto text-blue-kiwi rounded-xl mt-6"
                        onClick={redirectAch}>
                        Cambiar a ACH
                    </div>
                </div>
            </Fragment>
        )
    }

    return <Fragment />
}
