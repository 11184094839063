import { CurrencyDollarIcon } from '@heroicons/react/outline'
import {
    ChevronLeftIcon,
    Document2Icon,
    InformationIcon,
    NotificationIcon,
    QuestionIcon,
    Refresh3Icon
} from 'assets/icons_v3/fonts'
import BalanceModal from 'components/Modals/Balance'
import { Fragment, useState } from 'react'
import formatDate from 'utils/formatDate'
import moneyFormat from 'utils/moneyFormat'

export default function DisbursmentTemplate({
    loan,
    title,
    children,
    menu,
    onPayFee,
    onBackward
}) {
    const {
        balance,
        daysPastDue,
        amountDue,
        nextPaymentDate,
        nextPaymentAmount
    } = loan

    const isLoanPaid = balance === 0
    const [balanceModalVisible, setBalanceModalVisible] = useState(false)

    const onToggleBalanceModal = () => {
        setBalanceModalVisible((visble) => !visble)
    }

    return (
        <div className="absolute z-10 top-0 left-0 right-0 flex flex-col">
            <BalanceModal
                isVisible={balanceModalVisible}
                closeModal={onToggleBalanceModal}
            />

            <div>
                <div className="px-6 pt-10 pb-6 text-white bg-regal-blue-kiwi rounded-b-2xl">
                    <div className="flex items-center justify-between mb-10">
                        <ChevronLeftIcon
                            className="w-2 cursor-pointer"
                            onClick={onBackward}
                        />
                    </div>

                    <div className="font-semibold text-2xl mb-6">{title}</div>

                    <div className="mb-8">
                        <div className="flex items-center justify-between mb-2 ">
                            <div className="opacity-50">Balance de saldo</div>
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="font-semibold text-4xl">
                                ${moneyFormat(balance)}
                            </div>
                            <QuestionIcon
                                className="opacity-50 w-5 h-5 cursor-pointer"
                                onClick={onToggleBalanceModal}
                            />
                        </div>
                    </div>

                    <div className="flex justify-around">
                        {menu.map((item) => (
                            <div
                                key={item.title}
                                onClick={item.action}
                                className="flex flex-col items-center cursor-pointer">
                                <div className="flex items-center justify-center h-12 w-12 rounded-xl bg-white/20">
                                    {item.icon}
                                </div>
                                <div className="text-xs mt-2 w-20 text-center">
                                    {item.title}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {isLoanPaid === false && (
                    <div
                        className={`absolute flex items-center z-[-1] -mt-4 left-0 right-0 rounded-b-2xl px-6 pb-3 pt-7  text-xs font-medium ${
                            daysPastDue === 0
                                ? 'bg-slate-100-kiwi text-regal-blue-kiwi'
                                : 'bg-yellow-50-kiwi text-yellow-900-kiwi'
                        }`}>
                        {daysPastDue === 0 ? (
                            <Fragment>
                                <NotificationIcon className="w-4 mr-2" />
                                Próximo pago:
                                <strong className="ml-1">
                                    {formatDate(
                                        nextPaymentDate,
                                        'MMMM DD, YYYY'
                                    )}{' '}
                                    | ${nextPaymentAmount}
                                </strong>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <InformationIcon className="w-4 mr-2" />
                                Días de mora:
                                <strong className="ml-1">
                                    {daysPastDue}{' '}
                                    {daysPastDue > 1 ? 'días' : 'día'} | $
                                    {amountDue}
                                </strong>
                                <div
                                    className="cursor-pointer ml-auto font-semibold text-yellow-kiwi"
                                    onClick={onPayFee}>
                                    Pagar ahora
                                </div>
                            </Fragment>
                        )}
                    </div>
                )}
            </div>

            <div
                className={`mb-6 mx-6 flex flex-col gap-8 flex-auto ${
                    isLoanPaid ? 'mt-6' : 'mt-[72px]'
                }`}>
                {children}
            </div>
        </div>
    )
}
