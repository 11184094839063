import React, { useEffect, useRef, useState } from 'react'
import { DownIcon, UpIcon } from 'assets/icons_v3/fonts'
import tailwindConfig from 'utils/tailwindConfig'

export default function HomeStatusToggleProduct({ img, title, children }) {
    const cardContent = useRef()
    const cardHeader = useRef()

    const [isOpen, setIsOpen] = useState(() => {
        const isSelected = sessionStorage.getItem(`${title} card`)
        return ['true', null].includes(isSelected)
    })

    useEffect(() => {
        onExpandCard(!isOpen)
    }, [])

    const onExpandCard = (isOpen) => {
        if (isOpen) {
            cardContent.current.style.maxHeight = '0px'
            cardContent.current.style.opacity = '0'
            cardHeader.current.style.backgroundColor = 'transparent'
        } else {
            cardContent.current.style.maxHeight = 'max-content'
            cardContent.current.style.opacity = '1'
            cardHeader.current.style.backgroundColor =
                tailwindConfig.theme.extend.colors['blue-50-kiwi']
        }
    }

    const onToggleCard = () => {
        onExpandCard(isOpen)
        setIsOpen(!isOpen)
        sessionStorage.setItem(`${title} card`, !isOpen)
    }

    return (
        <div className="rounded-xl border border-slate-200-kiwi">
            <div
                ref={cardHeader}
                onClick={onToggleCard}
                className="p-4 bg-blue-50-kiwi rounded-xl -mt-[1px] -ml-[1px] w-[calc(100%+2px)] flex items-center cursor-pointer">
                <img src={img} className="w-6 mr-4" />
                <div className="font-semibold text-sm mr-auto">{title}</div>
                {isOpen ? <UpIcon /> : <DownIcon />}
            </div>
            <div
                className="max-h-max overflow-hidden relative"
                ref={cardContent}>
                {children}
            </div>
        </div>
    )
}
