import React from 'react'
import BaseModal from './../BaseModal'

import { BankCardIcon, BankIcon } from 'assets/icons_v3/fonts'

const OnePayMethodModal = ({
    isVisible,
    closeModal,
    sendCard,
    paymentMethods,
    onAddNewCard,
    hasMoreCreditCards
}) => {
    const titleItemMethod = (item, index) => {
        return (
            <div
                onClick={() => sendCard(item)}
                key={index}
                className="cursor-pointer w-full flex items-center py-4 px-4 bg-gray-50-kiwi rounded-lg">
                <div className="w-10 h-10 grow-0 shrink-0 rounded-full mr-4 flex justify-center items-center bg-white text-regal-blue-kiwi">
                    {item.checkingAccountId ? (
                        <BankIcon className="w-6" />
                    ) : (
                        <BankCardIcon className="w-6" />
                    )}
                </div>
                <div>
                    <p className="font-semibold">
                        {item?.checkingAccountId ? 'Cuenta' : 'Tarjeta'} ****
                        ****{' '}
                        {item?.checkingAccountId
                            ? String(item.CheckingAccount?.accountNumber).slice(
                                  -4
                              )
                            : String(item.CreditCard?.cardNumber).slice(-4)}
                    </p>
                    <p className="text-gray-600-kiwi font-normal text-sm">
                        Cuenta registrada
                    </p>
                </div>
            </div>
        )
    }

    return (
        <BaseModal
            title="Selecciona tarjeta"
            isVisible={isVisible}
            closeModal={closeModal}>
            <div className="flex flex-col mt-4">
                <div className="flex flex-col gap-4 ">
                    {paymentMethods?.map((item, index) =>
                        titleItemMethod(item, index)
                    )}
                </div>

                {!hasMoreCreditCards && (
                    <div
                        onClick={onAddNewCard}
                        className="mt-10 mx-auto cursor-pointer text-blue-kiwi inline-block">
                        Agrega nueva tarjeta
                    </div>
                )}
            </div>
        </BaseModal>
    )
}

export default OnePayMethodModal
