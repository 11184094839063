import React from 'react'
import BaseModal from '../BaseModal'

const PrivacyTermsModal = ({ isVisible, closeModal }) => {
    return (
        <BaseModal
            title="TÉRMINOS Y CONDICIONES DE USO"
            description="Última actualización Mayo 27, 2020"
            isVisible={isVisible}
            closeModal={closeModal}>
            <div className="flex flex-col gap-6 text-justify">
                <p>
                    POR FAVOR LEER CON CUIDADO ESTOS TÉRMINOS Y CONDICIONES DE
                    USO PARA LOS SERVICIOS EN LÍNEA DE KIWI FINANCIAL.
                </p>

                <div>
                    <h5 className="title text-xl underline py-2">
                        1. Introducción y Aceptación de los Términos de Uso
                    </h5>
                    <p className="py-3">
                        Estos términos de uso serán llevados a cabo por y entre
                        el usuario (“Usted,” “usted,” o “su”) y Kiwi Financial,
                        Inc (“Compañía,” “nosotros,” “nuestros,” o “nuestra”).
                        Los siguientes términos y condiciones, junto con
                        cualquier documento que incorporen expresamente por
                        referencia (colectivamente, “Términos de Uso”), gobierna
                        su acceso y uso de nuestros servicios en línea,
                        incluyendo cualquier contenido, funcionalidad, y
                        servicios ofrecidos ya sea dentro o a través de nuestra
                        página en línea (www.kiwicredito.com) (la “Página Web” o
                        “Sitio Web”), aplicación móvil y/o cualquier otro medio
                        electrónico (Kiwi Crédito, aquí referido como la
                        “Aplicación Móvil”) (conjuntas las “Plataformas en
                        Línea”), ya sea como un usuario invitado o usuario
                        registrado.
                    </p>
                    <p className="py-3">
                        Por favor de leer estos Términos de Uso cuidadosamente
                        antes de utilizar nuestras Plataformas en Línea. Al
                        utilizar nuestras Plataformas en Línea o al aplicar para
                        un producto de la Compañía o servicio a través de la
                        misma, Usted acepta y acuerda con estar atado y el
                        cumplimiento de estos Términos de Uso y nuestra Política
                        de Privacidad, accesible en
                        www.kiwicredito.com/privacy-policy, incorporada aquí por
                        referencia.
                    </p>
                    <p className="py-3">
                        NOSOTROS OFRECEMOS NUESTRAS PLATAFORMAS EN LÍNEA A USTED
                        CONDICIONADO A SU ACEPTACIÓN A ESTOS TÉRMINOS DE USO SIN
                        MODIFICACIÓN ALGUNA. SU USO DEL SITIO WEB CONSTITUYE SU
                        ACEPTACIÓN DE ESTOS TÉRMINOS DE USO. ESTOS TÉRMINOS DE
                        USO CONTIENEN RENUNCIAS A GARANTÍAS Y RESPONSABILIDAD
                        (consulte las Secciones 14 y 15).
                    </p>
                    <p className="py-3">
                        ISi usted no desea aceptar estos Términos de Uso o la
                        Política de Privacidad, usted no debe acceder ni usar la
                        Página Web ni la Aplicación Móvil.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        2. Uso autorizado de nuestra Página Web y Aplicación
                        Móvil
                    </h5>
                    <p className="py-3">
                        Nuestras Plataformas en Línea se ofrecen y son
                        accesibles para usuarios con veinte y un (21) años o más
                        y que residen en el Estado Libre Asociado de Puerto Rico
                        o Estados Unidos de América y cualquiera de sus
                        territorios o posesiones, sea por visa u otro modo. Al
                        utilizar nuestras Plataformas en Línea, usted representa
                        y garantiza que tiene la edad legal para formar un
                        contrato vinculante con la Compañía y cumple con todos
                        los requisitos de elegibilidad. Si no cumple con todos
                        los requisitos, usted no debe acceder ni utilizar
                        nuestras Plataformas en Línea.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        2. Uso autorizado de nuestra Página Web y Aplicación
                        Móvil
                    </h5>
                    <p className="py-3">
                        Nuestras Plataformas en Línea se ofrecen y son
                        accesibles para usuarios con veinte y un (21) años o más
                        y que residen en el Estado Libre Asociado de Puerto Rico
                        o Estados Unidos de América y cualquiera de sus
                        territorios o posesiones, sea por visa u otro modo. Al
                        utilizar nuestras Plataformas en Línea, usted representa
                        y garantiza que tiene la edad legal para formar un
                        contrato vinculante con la Compañía y cumple con todos
                        los requisitos de elegibilidad. Si no cumple con todos
                        los requisitos, usted no debe acceder ni utilizar
                        nuestras Plataformas en Línea.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        3. Cambios a los Términos de Uso
                    </h5>
                    <p className="py-3">
                        Nosotros podremos revisar y actualizar los Términos de
                        Uso de vez en cuando bajo discreción exclusiva. Todos
                        los cambios serán efectuados inmediatamente cuando
                        nosotros los publiquemos y serán aplicados al acceso y
                        uso de nuestras Plataformas en Línea después de eso. Su
                        uso continuo de nuestras Plataformas en Línea después de
                        la publicación de los Términos de Uso actualizados
                        significa que usted acepta estos cambios. Se espera que
                        usted revise esta página frecuentemente para así estar
                        informado de cualquier cambio, ya que son vinculantes a
                        usted.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        4. Cambios a las Plataformas en Línea
                    </h5>
                    <p className="py-3">
                        Nosotros podremos actualizar el contenido en nuestras
                        Plataformas en Línea de vez en cuando, pero su contenido
                        no estará necesariamente completo o actualizado. Tenga
                        en cuenta que parte del material en nuestras Plataformas
                        en Línea puede estar desactualizado en cualquier
                        momento.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        5. Acceso y Seguridad de la Cuenta
                    </h5>
                    <p className="py-3">
                        Nosotros nos reservamos el derecho de retirar, enmendar
                        y/o implementar cambios a la Página Web, la Aplicación
                        Móvil y cualquier otro producto, servicio o material
                        provisto en ello, en nuestra exclusiva discreción y sin
                        notificación. No seremos responsables si por alguna
                        razón toda o parte de nuestras Plataformas en Línea no
                        estén disponibles en cualquier momento o período. De vez
                        en cuando podremos restringir el acceso de algunas
                        partes o la totalidad de nuestras Plataformas en Línea a
                        los usuarios, incluyendo usuarios registrados.
                    </p>
                    <div className="py-3">
                        <p className="py-3">Usted es responsable de:</p>
                        <ul className="pl-10 list-disc pb-5">
                            <li>
                                Hacer cualquier arreglo necesario para que tenga
                                acceso a nuestras Plataformas en Línea.
                            </li>
                            <li>
                                Garantizar que toda persona que acceda a
                                nuestras Plataformas en Línea a través de su
                                conexión de internet conozcan estos Términos de
                                Uso y los cumplan.
                            </li>
                            <li>
                                Custodiar y asegurar su información para acceder
                                a nuestras Plataformas en Línea.
                            </li>
                        </ul>
                    </div>
                    <p className="py-3">
                        Para acceder a nuestra Página Web o Aplicación Móvil, y
                        a sus recursos, productos y servicios que ofrecen, a
                        usted se le puede pedir que provea ciertos detalles de
                        registro u otra información. Es una condición de su uso
                        de nuestras Plataformas en Línea que toda la información
                        que proporcione sobre ellas sea correcta, actual y
                        completa. Usted acepta que toda información
                        proporcionada para registrarse en la Página Web o la
                        Aplicación Móvil, incluidos, entre otros, a través del
                        uso de funciones interactivas, se rige por nuestra
                        Política de Privacidad
                        www.kiwicredito.com/privacy-policy, y usted acepta todas
                        las acciones que tomamos con respecto a su información
                        de acuerdo con nuestra Política de Privacidad.
                    </p>
                    <p className="py-3">
                        Si usted escoge o se le provee un, nombre de usuario,
                        contraseña, o cualquier otra pieza de información como
                        parte de nuestros procedimientos de seguridad, usted
                        debe de manejar esa información con suma
                        confidencialidad y no puede revelarla a otra persona o
                        entidad. Usted también reconoce que su cuenta es
                        personal de usted y acepta a no proporcionar a ninguna
                        otra persona acceso a la Página Web, Aplicación Móvil, o
                        cualquier otra porción de las mismas que utilicen su
                        nombre de usuario, contraseña, o cualquier otra
                        información de seguridad. Usted accede a notificarnos
                        inmediatamente de cualquier acceso no autorizado o uso
                        de su nombre de usuario, contraseña o cualquier otra
                        violación de seguridad. Usted también acepta asegurar
                        salir de su cuenta al final de cada sesión. Usted
                        debería usar debida precaución cuando acceda a su cuenta
                        de una computadora pública o compartida de tal manera
                        que otros no puedan ver ni guardar su contraseña o
                        cualquier otra información personal.
                    </p>
                    <p className="py-3">
                        Tenemos el derecho de desactivar cualquier nombre de
                        usuario, contraseña, u otros identificadores, ya sean
                        escogidos por usted o provisto por nosotros, en
                        cualquier momento con nuestra discreción exclusiva por
                        ninguna o cualquier razón, incluyendo si determinamos
                        que usted ha violado alguna de las provisiones en los
                        Términos de Uso.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        6. Usos Prohibidos
                    </h5>
                    <p className="py-3">
                        Usted puede usar nuestras Plataformas en Línea solamente
                        para fines legales y de acuerdo con estos Términos de
                        Uso. Usted acepta no usar nuestras Plataformas en Línea
                        para:
                    </p>
                    <ul className="pl-10 list-disc pb-5">
                        <li>
                            De tal manera que viole cualquier ley o regulación
                            federal, estatal, local o internacional aplicable
                            (incluidas, entre otras, las leyes relacionadas a la
                            exportación de data o software hacia y desde los
                            EE.UU. u otros países).
                        </li>
                        <li>
                            Para transmitir o procurar el envió de cualquier
                            material publicitario o promocional sin nuestro
                            consentimiento previo por escrito, incluyendo
                            cualquier “correo chatarra”, “correo en cadena”,
                            “correo no deseado”, o cualquier otra solicitud
                            similar.
                        </li>
                        <li>
                            Para suplantar o intentar hacerse pasar por la
                            Compañía, un empleado de la Compañía, otro usuario o
                            cualquier otra persona o entidad (incluyendo, entre
                            otros, el uso de direcciones de correo electrónico o
                            nombres de pantalla asociados con cualquiera de los
                            anteriores).
                        </li>
                        <li>
                            Participar en cualquier conducta que restrinja o
                            inhiba el uso o disfrute de nuestras Plataformas en
                            Línea, o que, según lo determinemos, puedan dañar a
                            la Compañía o a sus usuarios de las Plataformas en
                            Línea, o exponerlos a cualquier riesgo.
                        </li>
                    </ul>
                    <div>
                        <p className="py-3">
                            Adicionalmente, usted acepta a no:
                        </p>
                        <ul className="pl-10 list-disc pb-5">
                            <li>
                                Utilizar nuestras Plataformas en Línea de manera
                                que pueda deshabilitarlas, sobrecargarlas,
                                dañarlas, o perjudicarlas o interferir con el
                                uso de cualquier otro usuario, incluyendo su
                                capacidad de ofrecer sus actividades en tiempo
                                real.
                            </li>
                            <li>
                                Utilizar algún robot, araña o cualquier otro
                                dispositivo automático, proceso o medio para
                                acceder a nuestras Plataformas en Línea para
                                cualquier propósito, incluyendo el monitoreo o
                                la copia de cualquier material mostrado en
                                ellas.
                            </li>
                            <li>
                                Utilizar cualquier proceso manual para
                                monitorear o copiar cualquier material
                                disponible en nuestras Plataformas en Línea, o
                                para cualquier otro propósito no autorizado en
                                los Términos de Uso, sin nuestro consentimiento
                                previo.
                            </li>
                            <li>
                                Utilizar cualquier dispositivo, software, o
                                rutina que interfiera con el funcionamiento
                                adecuado de las Plataformas en Línea.
                            </li>
                            <li>
                                Introducir cualquier virus, “Trojan horses”,
                                “worms”, bombas de lógica, o cualquier material
                                maligno o dañino tecnológicamente.
                            </li>
                            <li>
                                Intentar obtener acceso no autorizado para,
                                interferir, dañar, o interrumpir cualquier parte
                                de las Plataformas en Línea, el servidor en el
                                que se almacenan nuestras Plataformas en Línea,
                                o cualquier otro servidor, computadora o base de
                                datos conectada a ellas.
                            </li>
                            <li>
                                Atacar las Plataformas en Línea a través de un
                                ataque de denegación de servicios o un ataque
                                distribuido de denegación de servicios.
                            </li>
                            <li>
                                De cualquier manera, que intente interferir con
                                el funcionamiento adecuado de nuestras
                                Plataformas en Línea.
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        7. Violación de los Términos de Uso
                    </h5>
                    <p className="py-3">
                        Las violaciones de estos Términos de Uso pueden resultar
                        en la divulgación de cualquier información que la
                        Compañía tenga de usted (incluyendo su identidad) si
                        determinamos que dicha revelación es necesaria para la
                        conexión de cualquier investigación o queja (que
                        incluye, entre otros, cualquier gobierno o investigación
                        de entes legales) con respecto a su uso de nuestras
                        Plataformas en Línea, o para identificar, contactar o
                        aplicar acción legal contra alguien que pueda estar
                        causando daños o interfiriendo (ya sea voluntaria o
                        involuntariamente) con los derechos de propiedad de la
                        Compañía, propiedad o derechos de los visitante o
                        usuarios, incluyendo a nuestros clientes.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        8. Dependencia de la Información Publicada
                    </h5>
                    <p className="py-3">
                        La información presentada en o a través de nuestras
                        Plataformas en Línea están disponibles únicamente con
                        fines de información general. Nosotros no garantizamos
                        la exactitud, plenitud o utilidad de esta información.
                        Cualquier confianza que deposite en dicha información es
                        estrictamente bajo su propio riesgo. Renunciamos a toda
                        responsabilidad o daño que surja de la confianza que
                        usted o cualquier otro visitante de nuestras Plataformas
                        en Línea deposite en dichos materiales, o de cualquier
                        persona que pueda ser informada de cualquiera de sus
                        contenidos.
                    </p>
                    <p className="py-3">
                        La Página Web incluye contenido proporcionado por
                        terceros, incluyendo material proporcionado por otros
                        usuarios, blogueros, licenciantes terciarios,
                        sindicadores, agregadores, y/o servicios de reportaje.
                        Todas las declaraciones y/u opiniones expresadas en
                        estos materiales y todos los artículos y respuestas a
                        preguntas y cualquier otro contenido además de el
                        contenido provisto por la Compañía, son únicamente
                        opiniones y la responsabilidad de las personas o entidad
                        proporcionando dichos materiales. Estos materiales no
                        reflejan necesariamente la opinión de la Compañía.
                        Nosotros no somos responsables ante usted ni ante
                        terceros por el contenido o exactitud de estos
                        materiales proporcionados por terceros.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        9. Información Sobre Usted y Sus Visitas a la Página Web
                    </h5>
                    <p className="py-3">
                        Toda información que nosotros recopilamos en nuestras
                        Plataformas en Línea están sujetas a nuestra Política de
                        Privacidad www.kiwicredito.com/privacy-policy. Mediante
                        el uso de nuestras Plataformas en Línea usted acepta a
                        todas las acciones que tomemos respecto a su información
                        en cumplimiento con nuestra Política de Privacidad.
                        Adicionalmente, al utilizar nuestras Plataformas en
                        Línea usted reconoce y acepta que las transmisiones por
                        Internet nunca son completamente privadas o seguras.
                        Usted comprende que cualquier mensaje o información que
                        envía a nuestras Plataformas en Línea puede ser leída o
                        interceptada por otros, incluso si hay un aviso especial
                        de que una transmisión en particular (por ejemplo,
                        información de la cuenta) está encriptada.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        10. Enlaces desde la Página en Web
                    </h5>
                    <p className="py-3">
                        Si nuestras Plataformas en Línea contienen enlaces para
                        otros sitios en línea y recursos provistos por terceros,
                        estos enlaces son proporcionados solamente para su
                        conveniencia. Esto incluye enlaces contenidos en
                        anuncios, como anuncios publicitarios y enlaces
                        patrocinados. Nosotros no tenemos control sobre el
                        contenido de estos sitios en línea o recursos y no
                        aceptamos responsabilidad por ello ni de cualquier
                        pérdida o daño que pueda incurrir por su uso de ellos.
                        Si usted decide acceder a cualquier sitio en línea de
                        terceros enlazados a la Página Web, usted lo hace
                        completamente bajo su propio riesgo y estará sujeto a
                        los términos y condiciones de uso de los respectivos
                        sitios en línea.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        11. Información de Cuenta de Terceros
                    </h5>
                    <p className="py-3">
                        Mediante el uso de cualquier banca en línea aplicable o
                        servicio de enlace de cuentas o cualquier servicio
                        similar aplicable a través nuestras Plataformas en
                        Línea, usted autoriza a la Compañía y a sus agentes a
                        acceder a sitios en línea terceros designados por usted
                        o por su parte, para retirar información solicitada por
                        usted, y usted designa a la Compañía y sus agentes como
                        su agente para este propósito limitado. Cada vez que
                        usted ingresa su información de acceso de cuenta, está
                        permitiendo a la Compañía y a sus agentes a procesar su
                        solicitud y utilizar la información sometida por usted
                        para lograr lo anterior.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        12. Socios Terceros
                    </h5>
                    <p className="py-3">
                        La Compañía utilizará los servicios provistos por Plaid,
                        Inc. (“Plaid”), un proveedor de servicios tercero, para
                        recolectar datos del usuario de instituciones
                        financieras. Al utilizar nuestras Plataformas en Línea y
                        aplicar para obtener nuestros productos financieros u
                        otros servicios ofrecidos mediante nuestras Plataformas
                        en Línea, usted le concede a la Compañía y a Plaid el
                        derecho, poder y autoridad de actuar por su parte para
                        acceder y transmitir su información personal y
                        financiera desde la institución financiera en cuestión,
                        sujeta a nuestra Política de Privacidad
                        www.kiwicredito.com/privacy-policy. Usted también acepta
                        a que su información personal y financiera pueda ser
                        transferida, almacenada y procesada por Plaid de acorde
                        a la Política de Privacidad de Plaid la cual usted puede
                        acceder aquí: https://plaid.com/legal/#privacy-policy.
                        Además, usted acepta eximir de responsabilidad a Plaid y
                        la Compañía y renunciar a cualquier causa de acción que
                        pueda tener contra Plaid y la Compañía respecto a
                        cualquier problema, daño, o causa de acción que pueda
                        surgir en relación con los actos descritos en este
                        documento en la medida máxima permitida bajo las leyes
                        aplicables.
                    </p>
                    <p>
                        Con respecto a las transferencias hacia y desde su
                        cuenta corriente o cuenta de ahorros, no guardamos ni
                        tenemos acceso a ninguna de las credenciales de sus
                        instituciones financieras, sino que utilizamos Plaid
                        para acceder a la información de la cuenta y generar
                        transacciones. Luego de crear una cuenta con nuestras
                        Plataformas en Línea, y una vez sus credenciales estén
                        aseguradas, cualquier comunicación con su institución
                        financiera en relación a su cuenta corriente o de
                        ahorros se hará a través de Plaid, conforme a lo cual la
                        Compañía recibe un token encriptado que le permite a la
                        Compañía a acceder a sus cuentas financieras y realizar
                        toda actividad necesaria relacionada a nuestros
                        productos y servicios a través de Plaid.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        13. Términos Generales Aplicables a Préstamos Personales
                        Pequeños
                    </h5>
                    <p className="py-3">
                        Los términos de repago van desde 2 meses hasta 12 meses.
                        No hay tarifas anuales ni de originación. No hay pago
                        inicial y no hay penalidad por pago adelantado. Los
                        préstamos empiezan desde $50 hasta $500. El monto y tasa
                        del préstamo estará sujeto a la aprobación de crédito.
                        La aprobación final y el momento de la decisión de
                        crédito pueden variar si se solicitan documentos
                        adicionales como parte del proceso de verificación. El
                        proceso de aprobación puede llevar más tiempo si se
                        solicitan documentos adicionales como parte del proceso
                        de verificación. Los fondos generalmente se depositan a
                        través de servicios de Automated Clearing House (“ACH”)
                        para la entrega el día laboral siguiente si la
                        verificación es completada y la aprobación final ocurre
                        antes de las 3:00 pm Atlantic Standard Time
                        Lunes-Viernes. Días laborables son de Lunes a Viernes,
                        excluyendo días festivos federales y locales. Los fondos
                        también pueden ser depositados mediante pagos
                        automáticos a una tarjeta de débito para una
                        transferencia instantánea, con liquidación las 24 horas
                        del día, 7 días de la semana, independientemente de
                        cualquier día festivo federal o local. Las
                        transferencias instantáneas pueden estar sujetas a
                        demoras o rechazo a la discreción de la Compañía,
                        incluyendo, por ejemplo, si la Compañía se enfrenta a
                        cualquier cuestión o problema con la tarjeta de débito
                        correspondiente. Las transferencias inmediatas pueden
                        estar disponibles solamente para clientes con tarjetas
                        de débito emitidas por bancos que participen en los
                        servicios de transferencia instantánea.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        14. Productos y Servicios en Línea
                    </h5>
                    <p className="py-3">
                        Todos los productos financieros y otros servicios
                        ofrecidos a través de nuestras Plataformas en Línea o
                        cualquier otra transacción formada en la misma, o
                        resultante de una visita por usted, estarán gobernadas
                        por otros términos y condiciones que están incorporados
                        en estos Términos de Uso. Términos y condiciones
                        adicionales pueden ser aplicables a porciones
                        específicas, servicios o funciones. Todo estos términos
                        y condiciones adicionales se incorporarán por por medio
                        de referencia en estos Términos de Uso. SU ELEGIBILIDAD
                        PARA PRODUCTOS Y SERVICIOS PARTICULARES ESTÁN SUJETAS A
                        LA DETERMINACIÓN FINAL POR NOSOTROS.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        15. Descargo de Responsabilidad de Garantías
                    </h5>
                    <p className="py-3">
                        La Compañía no promete que la Página Web o cualquier
                        otro contenido, servicio o función de las Plataformas en
                        Línea estarán libre de errores o serán ininterrumpidas,
                        o que cualquier defecto puede ser corregido o que su uso
                        de las Plataformas en Línea le proveerán resultados
                        específicos. La Compañía no garantiza la exactitud,
                        plenitud, calidad, idoneidad o contenido de cualquier
                        información o herramienta en las Plataformas en Línea o
                        cualquier otra página web o sitio web vinculado o
                        referenciado en ellas, ni hacemos ninguna representación
                        o garantía en cuanto a los resultados que puedan
                        obtenerse mediante el uso de las Plataformas en Línea o
                        cualquier otra página web o sitio web vinculado o
                        referenciado en ellas.
                    </p>
                    <p className="py-3">
                        Nosotros no podemos garantizar o asegurar que los
                        archivos disponibles para descarga desde el Internet o
                        la Página Web estén libres de virus u otro código
                        destructivo. Usted es responsable de implementar
                        procedimientos y controles suficientes para satisfacer
                        sus requisitos particulares de protección antivirus y
                        precisión de la entrada y salida de data, y de mantener
                        un medio externo a la Página Web para cualquier
                        reconstrucción de data perdida.
                    </p>
                    <p className="py-3">
                        USTED ACEPTA QUE, EN LA MEDIDA MÁXIMA PROPORCIONADA POR
                        LA LEY, LA COMPAÑÍA NO SERÁ RESPONSABLE DE NINGUNA
                        PÉRDIDA O DAÑO CAUSADO POR UN ATAQUE DE NEGACIÓN DE
                        SERVICIO DISTRIBUIDO, VIRUS U OTRO MATERIAL
                        TECNOLÓGICAMENTE DAÑINO QUE PUEDA INFECTAR SU EQUIPO DE
                        COMPUTADORA, PROGRAMAS DE COMPUTADORA , DATOS U OTRO
                        MATERIAL PROPIETARIO DEBIDO A SU USO DEL SITIO WEB O DE
                        CUALQUIER SERVICIO O ARTÍCULO OBTENIDO A TRAVÉS DEL
                        SITIO WEB O DE SU DESCARGA DE CUALQUIER MATERIAL
                        PUBLICADO EN ELLO, O EN CUALQUIER SITIO WEB VINCULADO A
                        ELLO.
                    </p>
                    <p className="py-3">
                        SU USO DE LAS PLATAFORMAS EN LÍNEA, SU CONTENIDO Y
                        CUALQUIER SERVICIO O ARTÍCULO OBTENIDO A TRAVÉS DE ELLAS
                        ES BAJO SU PROPIO RIESGO. LAS PLATAFORMAS EN LÍNEA, SU
                        CONTENIDO Y CUALQUIER SERVICIO O ARTÍCULO OBTENIDO A
                        TRAVÉS DE ELLAS SE PROPORCIONAN DE MANERA &quot;TAL
                        CUAL&quot; Y &quot;SEGÚN DISPONIBILIDAD&quot;, SIN
                        NINGUNA GARANTÍA DE NINGÚN TIPO, EXPRESA O IMPLÍCITA. NI
                        LA ​​COMPAÑÍA NI NINGUNA PERSONA O ENTIDAD ASOCIADA CON
                        LA COMPAÑÍA OFRECE NINGUNA GARANTÍA O REPRESENTACIÓN CON
                        RESPECTO A LA COMPLETITUD, SEGURIDAD, CONFIABILIDAD,
                        CALIDAD, EXACTITUD O DISPONIBILIDAD DE LAS PLATAFORMAS
                        EN LÍNEA. SIN LIMITAR LO ANTERIOR, NI LA ​​COMPAÑÍA NI
                        NINGUNA PERSONA O ENTIDAD ASOCIADA CON LA COMPAÑÍA
                        REPRESENTA O GARANTIZA QUE LAS PLATAFORMAS EN LÍNEA, SU
                        CONTENIDO, O CUALQUIER SERVICIO O ARTÍCULO OBTENIDO A
                        TRAVÉS DE ELLAS SERÁN EXACTOS, CONFIABLES, SIN ERRORES O
                        ININTERRUMPIDOS, QUE LOS DEFECTOS SERÁN CORREGIDOS, QUE
                        NUESTRAS PLATAFORMAS EN LÍNEA O EL SERVIDOR QUE LAS
                        HACEN DISPONIBLES ESTARÁN LIBRES DE VIRUS U OTROS
                        COMPONENTES DAÑINOS, O QUE EL SITIO WEB O CUALQUIER
                        SERVICIO O ARTÍCULO OBTENIDO A TRAVÉS DEL SITIO WEB
                        CUMPLIRÁ CON SUS NECESIDADES O EXPECTATIVAS.
                    </p>
                    <p className="py-3">
                        EN LA MEDIDA MÁXIMA PROPORCIONADA POR LA LEY, LA
                        COMPAÑÍA RECHAZA TODAS LAS GARANTÍAS DE CUALQUIER TIPO,
                        EXPLÍCITAS O IMPLÍCITAS, ESTATUTARIAS O DE OTRO MODO,
                        INCLUYENDO PERO SIN LIMITARSE A CUALQUIER GARANTÍA DE
                        COMERCIABILIDAD, NO INFRACCIÓN Y FALTA DE TENENCIA.
                    </p>
                    <p className="py-3">
                        LO ANTERIOR NO AFECTA NINGUNA GARANTÍA QUE NO PUEDA SER
                        EXCLUIDA O LIMITADA BAJO LEYES APLICABLES.
                    </p>
                    <p className="py-3">
                        La Compañía se reserva el derecho de hacer cualquiera de
                        los siguientes, en cualquier momento, sin previo aviso:
                        (1) para modificar, suspender o finalizar la operación o
                        el acceso al Sitio Web, o cualquier parte del Sitio Web,
                        por cualquier motivo; (2) para modificar o cambiar el
                        Sitio Web, o cualquier parte del Sitio Web, y las
                        políticas o términos aplicables; y (3) interrumpir el
                        funcionamiento del Sitio Web, o cualquier parte del
                        Sitio Web, según sea necesario para realizar
                        mantenimiento de rutina o imprevisto, corrección de
                        errores, u otros cambios.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        16. Limitaciones de Responsabilidad
                    </h5>
                    <p className="py-3">
                        USTED ACEPTA QUE LA COMPAÑIA NO SERA RESPONSABLE DE
                        NINGÚN MODO POR CUALQUIER DAÑO DIRECTO, INDIRECTO,
                        ESPECIAL, INCIDENTAL, PUNITIVO, CONSECUENTE, O CUALQUIER
                        OTRO DAÑO, INCLUYENDO, SIN LIMITACIONES, PÉRDIDAS DE
                        INGRESOS, PÉRDIDAS DE VENTAJA ECONÓMICA PROSPECTIVA,
                        RESULTANTE DE CUALQUIER MAL USO DE LAS PLATAFORMAS EN
                        LÍNEA, O CUALQUIER OTRA PÁGINA EN LÍNEA ENLACADA O
                        REFERENCIADA EN LAS PLATAFORMAS EN LÍNEA, O DEPENDENCIA
                        EN LA INFORMACIÓN, DOCUMENTOS, SOFTWARE, O CONTENIDO DE
                        ESTO O DE LO OTRO, AUNQUE SE ADVIERTA LA POSIBILIDAD DE
                        TALES DAÑOS QUE SON RASONALMENTE PREVISIBLES.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        17. Divulgaciones y Consentimiento
                    </h5>
                    <p className="py-3">
                        La Compañía es una prestamista en línea que ofrece
                        préstamos a través de nuestras Plataformas en Línea,
                        utilizando tecnología y algoritmos de data para recibir
                        aplicaciones, generar préstamos, y para pagos y repagos
                        para y de nuestros consumidores. Para obtener un
                        préstamo de nosotros, usted debe crearse una cuenta en
                        nuestras Plataformas en Línea mediante un dispositivo
                        electrónico capaz de accesar el Internet, teniendo una
                        cuenta de correo electrónico por la cual pueda recibir
                        correos de nuestra parte y dando su consentimiento a los
                        Términos de Uso de nuestras Plataformas en Línea y el
                        uso de correos electrónicos para brindarle cualquier
                        información escrita que sea requerida enviarle en
                        relación a su cuenta, su aplicación para un préstamo, o
                        su préstamo. Su consentimiento es voluntario, pero no
                        podemos procesar su aplicación o proveer un préstamo si
                        usted no nos brinda su consentimiento. Si usted no está
                        dispuesto a recibir estas divulgaciones y notificaciones
                        de forma electrónica, puede cancelar esta transacción
                        sin ningún costo cerrando el navegador o Aplicación
                        Móvil en cualquier momento antes de presionar “Aceptar
                        Términos de Uso”.
                    </p>
                    <p className="py-3">
                        Al hacer clic en la casilla junto a “Aceptar Términos de
                        Uso” en el momento de crear su cuenta en nuestras
                        Plataformas en Línea usted:
                    </p>
                    <ul className="pb-5 pl-10 list-disc">
                        <li>
                            Reconoce que usted puede acceder y leer la
                            información que publiquemos en nuestras Plataformas
                            en Línea,
                        </li>
                        <li>
                            acepta proveer una dirección de correo electrónico
                            que esté activa y sea efectiva para recibir correos
                            de nosotros
                        </li>
                        <li>
                            da consentimiento para recibir notificaciones y
                            divulgaciones requeridas por ley sobre su cuenta,
                            aplicación de préstamo, o cualquier préstamo que le
                            proveamos, y
                        </li>
                        <li>
                            reconoce haber recibido las siguientes
                            divulgaciones.
                        </li>
                    </ul>
                    <div>
                        <p className="pl-10">
                            A. Consentimiento de Firmas Electrónicas, Registros
                            y Divulgaciones (“Consentimiento E-Sign”)
                        </p>
                        <p className="py-3 underline">
                            Comunicaciones que se Proporcionarán en Forma
                            Electrónica
                        </p>
                        <p className="py-3">
                            Por favor tenga en cuenta que su consentimiento a la
                            entrega electrónica de divulgaciones y firmas
                            electrónicas será requerido al momento de usted
                            crear su cuenta con nosotros en nuestras Plataformas
                            en Línea. Las siguientes divulgaciones son
                            requeridas por la Ley Federal de Firma Electrónica
                            en Comercio Global y Nacional (“ESIGN” por sus
                            siglas en inglés) y la Ley de Transacciones
                            Electrónicas de Puerto Rico (“PRETA”). Si usted no
                            puede o no desea proveer consentimiento, por favor
                            de no inscribirse para una cuenta. Una vez active su
                            cuenta, podrá retirar su consentimiento de ESIGN y
                            solicitar comunicaciones por papel y/o divulgaciones
                            como se denota A continuación. Sugerimos que lea
                            este documento e imprima una copia para su
                            referencia.
                        </p>
                        <p className="py-3 underline">
                            Alcance de las Comunicaciones a ser Provistas de
                            Forma Electrónica
                        </p>
                        <p className="py-3">
                            Esta divulgación de comunicación electrónica
                            (“Consentimiento E-Sign”) aplica a cualquier y a
                            todas las comunicaciones y/o divulgaciones que son
                            requeridas por ley para proveer a usted contenido
                            escrito relacionado a sus cuentas y cualquier
                            producto relacionado o servicio provisto
                            (“Comunicaciones”). Por lo tanto, usted concede su
                            consentimiento y acepta a:
                        </p>
                        <ul className="list-decimal pl-10">
                            <li>
                                Que cualquier o todas las Comunicaciones que le
                                enviemos o proveamos a usted (incluso las
                                Comunicaciones que, de lo contrario, podríamos
                                enviarle o proporcionarle en forma impres),
                                incluyendo toda divulgación legal y regulatoria,
                                y comunicaciones asociadas a su cuenta, su
                                aplicación para cualquier producto o servicio de
                                la Compañía, y cualquier respuesta relacionadas
                                a reclamaciones, notificaciones, productos, y
                                servicios se realizará en forma de
                                comunicaciones electrónicas;
                            </li>
                            <li>
                                Cada comunicación electrónica se considerará
                                recibida por usted en el momento que intentamos
                                enviársela por primera vez, o si es publicada en
                                nuestras Plataformas en Línea, dentro de las 24
                                horas posteriores al momento en que se publica;
                                y
                            </li>
                            <li>
                                Para guardar y/o almacenar electrónicamente una
                                copia de todas las comunicaciones electrónicas
                                que le enviemos a usted.
                            </li>
                        </ul>
                        <p className="py-3 underline">
                            Métodos para Proveerle Comunicaciones de Forma
                            Electrónica
                        </p>
                        <p className="py-3">
                            Toda comunicación que le proporcionemos a usted de
                            forma electrónica se proporcionarán ya sea por (1)
                            correo electrónico, (2) haciéndolas disponible en
                            línea y accesibles a través de la pantalla principal
                            de su cuenta, o (3) solicitando la descarga de un
                            archivo PDF que contenga el comunicado.
                        </p>
                        <p className="py-3 underline">
                            Como Retirar Su Consentimiento
                        </p>
                        <p className="py-3">
                            Usted puede retirar su consentimiento de recibir
                            comunicaciones electrónicas comunicándose con
                            nosotros en cualquier momento. Si retira
                            consentimiento, salvo que se indique expresamente lo
                            contrario en los Términos y Condiciones de Uso de
                            nuestras Plataformas en Línea y su contrato de
                            préstamo, (a) terminaremos su acceso y su capacidad
                            para usar nuestras Plataformas en Línea, y (b)
                            cerraremos cualquier cuenta que tenga con nosotros y
                            le devolveremos cualquier balance que pueda tener en
                            la misma y le enviaremos comunicados y divulgaciones
                            subsiguientemente requeridas de forma escrita a la
                            dirección mas reciente que tengamos disponible.
                        </p>
                        <p className="py-3">
                            No impondremos ningún cargo por procesar el retiro
                            de su consentimiento para recibir comunicaciones
                            electrónicas. Cualquier retiro de consentimiento de
                            recibir comunicaciones electrónicas será efectivo
                            luego de un período razonable de tiempo para
                            nosotros poder procesar su retiro. Mientras tanto,
                            usted continuará recibiendo comunicaciones
                            electrónicas. Si retira su consentimiento, la
                            validez legal y aplicabilidad de las Comunicaciones
                            previas entregadas de forma electrónica no verán
                            afectadas.
                        </p>
                        <p className="py-3 underline">
                            Cómo Actualizar Sus Registros
                        </p>
                        <p className="py-3">
                            Es su responsabilidad proveernos con datos reales y
                            precisos relacionados a su dirección de correo
                            electrónico, contacto, y cualquier otra información
                            relacionada a su cuenta, y de mantener y actualizar
                            prontamente cualquier cambio en esta información.
                            Puede actualizar su información (como su correo
                            electrónico) a través de la página principal de su
                            cuenta la cual puede ingresar con su nombre de
                            usuario y contraseña.
                        </p>
                        <p className="py-3 underline">
                            Requisitos de Hardware y Software
                        </p>
                        <p className="py-3">
                            Para acceder, ver, y retener las Comunicaciones
                            electrónicas que ponemos a su disposición, usted
                            debe de tener una computadora personal u otros
                            dispositivos que sean capaces de acceder al Internet
                            y a una conexión al Internet, un navegador web de
                            Internet que sea capaz de soportar SSL de
                            comunicaciones cifradas de 128 bits (esto garantiza
                            que la información enviada entre nosotros y usted se
                            transmita y almacene de manera segura) y suficiente
                            capacidad de almacenamiento electrónico en el disco
                            duro de su computadora u otra unidad de
                            almacenamiento de datos, y la capacidad de descargar
                            y almacenar archivos, incluyendo archivos PDF.
                        </p>
                        <p className="py-3 underline">
                            Solicitud de Copias en Papel
                        </p>
                        <p className="py-3">
                            Le enviaremos la copia en papel a su dirección
                            postal que tengamos en nuestros archivos.
                        </p>
                        <p className="py-3">
                            No le enviaremos una copia impresa de ninguna
                            Comunicación a menos que lo solicite o que
                            consideremos apropiado hacerlo. Puede obtener una
                            copia en papel de una Comunicación electrónica
                            imprimiendola usted mismo o solicitando que le
                            enviemos una copia en papel, siempre y cuando dicha
                            solicitud se realice dentro de un tiempo razonable
                            después de que le hayamos enviado la Comunicación
                            electrónica por primera vez. Para solicitar una
                            copia en papel, contáctenos enviando su solicitud
                            por correo electrónico a support@kiwicredito.com.
                        </p>
                        <p className="py-3">
                            Nos reservamos el derecho, pero no asumimos ninguna
                            obligación, de proporcionar una copia en papel (en
                            lugar de electrónica) de cualquier Comunicación que
                            usted nos haya autorizado a proporcionar
                            electrónicamente.
                        </p>
                        <p className="py-3 underline">
                            Uso de Firmas Electrónicas
                        </p>
                        <p className="py-3">
                            &apos;Firma Electrónica&apos; significa cualquier
                            sonido, símbolo, o proceso electrónico adjunto o
                            asociado lógicamente a un registro y ejecutado y
                            adoptado por una persona o entidad con la intención
                            de firmar dicho registro, incluidas las firmas
                            electrónicas por fax o correo electrónico, que se
                            utilice para indicar su consentimiento y/o acuerdo
                            por medios electrónicos. Una firma electrónica puede
                            incluir, entre otros, hacer clic en un botón o
                            marcar una casilla o tomar cualquier otra medida
                            para indicar su consentimiento y/o acuerdo en
                            nuestras Plataformas en Línea.
                        </p>
                        <p className="py-3 underline">Aceptación</p>
                        <p className="py-3">
                            Al hacer clic en la casilla junto a &quot;Aceptar
                            los Términos de Uso&quot; en el momento en que crea
                            su cuenta en nuestras Plataformas en Línea, usted
                            acepta usar las Firmas Electrónicas para afirmar que
                            ha leído y entendido, que está de acuerdo con los
                            términos y condiciones de uso de nuestras
                            Plataformas en Línea, nuestra Política de
                            Privacidad, y todos los demás acuerdos y
                            consentimientos que se divulgarán electrónicamente.
                            Además, también nos declara y garantiza que ha leído
                            y entendido este Consentimiento de Firmas
                            Electrónicas, Registros y Divulgaciones.
                        </p>
                        <p className="py-3">
                            También reconoce y acepta que su consentimiento para
                            las Comunicaciones electrónicas se proporciona en
                            relación con una transacción que afecta al comercio
                            interestatal que está sujeta a la ley federal de
                            “Firma Electrónica en Comercio Global y Nacional” y
                            que usted y nosotros pretendemos que la Ley se
                            aplique a la mayor medida posible para validar
                            nuestra capacidad de hacer negocios con usted por
                            medios electrónicos.
                        </p>
                        <p className="py-3">
                            No dude en solicitar información o hacer preguntas
                            poniéndose en contacto con nosotros a través de
                            support@kiwicredito.com.
                        </p>
                    </div>
                    <div>
                        <p className="pl-10">
                            B. Autorización para Debitar Cuenta para Repago de
                            Préstamo
                        </p>
                        <p className="py-3">
                            Como prestamista en línea, la Compañía transferirá
                            fondos electrónicamente y realizará pagos de sus
                            préstamos desde y hacia su cuenta en una institución
                            financiera a través de ACH o mediante pagos
                            automáticos a una tarjeta de débito para una
                            transferencia instantánea. Para transferencias hacia
                            y desde su institución financiera, le solicitaremos
                            el número de ruta de la institución financiera, su
                            número de cuenta, y el tipo de cuenta (ya sea de
                            ahorro o corriente) de su institución financiera.
                        </p>
                        <p className="py-3">
                            Las transferencias a su cuenta mediante ACH son
                            gratuitas y pueden demorar hasta tres días hábiles
                            en completarse. Como alternativa, puede optar por
                            tener una transferencia electrónica instantánea, en
                            la que recibirá los fondos instantáneamente en su
                            tarjeta de débito, con liquidación las 24 horas del
                            día, los 7 días de la semana, independientemente de
                            cualquier día feriado local o federal. Sin embargo,
                            esta opción podría estar sujeta a demoras o ser
                            rechazadas si encontramos algún problema con su
                            tarjeta de débito. Las transferencias instantáneas
                            pueden que solo estén disponibles para clientes con
                            tarjetas de débito emitidas por bancos que
                            participan en servicios de transferencias
                            instantáneas.
                        </p>
                        <p className="py-3">
                            Además, al hacer clic en la casilla junto a
                            &quot;Aceptar Términos de Uso&quot; en el momento en
                            que crea su cuenta en nuestras Plataformas en Línea,
                            autoriza a Kiwi Financial, Inc a debitar los pagos
                            recurrentes de su préstamo en cada período de
                            facturación a través de Repagos por ACH autorizados
                            previamente o Pagos Automáticos (&quot;Débito
                            Directo&quot;) de su cuenta corriente, ahorro, o
                            débito, según lo proporcionado por usted en el
                            proceso de solicitud y aplicación.
                        </p>
                        <p className="py-3">
                            Al usar Débito Directo, usted autoriza a Kiwi a
                            debitar electrónicamente su cuenta corriente,
                            ahorro, o débito a través de ACH o Pagos Automáticos
                            para hacer los pagos de préstamos en relación con el
                            acuerdo de préstamo establecido entre usted y la
                            Compañía. Al crear su cuenta y solicitar nuestros
                            préstamos, certifica que es el usuario autorizado de
                            la cuenta corriente, ahorros, o débito de la
                            institución financiera que proporciona como parte
                            del proceso de solicitud, y que no disputará estas
                            transacciones programadas con su institución
                            financiera. La Compañía debitará de su cuenta
                            corriente o de ahorros, siempre que la transacción
                            corresponda a los términos establecidos en el
                            contrato de préstamo.
                        </p>
                        <div>
                            <p className="py-3">
                                La Compañía sólo revelará información a terceros
                                sobre su cuenta o las transferencias que
                                realice:
                            </p>
                            <ul className="list-decimal pl-10 pb-5">
                                <li>
                                    Cuando sea necesario para completar
                                    transferencias, o
                                </li>
                                <li>
                                    Para verificar la existencia y condición de
                                    su cuenta para un tercero, tal como para una
                                    agencia de crédito o comerciante, o
                                </li>
                                <li>
                                    Para cumplir con la agencia gubernamental u
                                    órdenes judiciales, o
                                </li>
                                <li>Si nos da su permiso por escrito.</li>
                            </ul>
                        </div>
                        <p className="py-3">
                            Derecho a Cancelar – Si nos ha dicho por adelantado
                            que realicemos pagos regulares desde su cuenta,
                            puede suspender cualquiera de estos pagos. Por lo
                            tanto, comprende que esta autorización permanecerá
                            en vigor y efecto hasta que notifique a la Compañía
                            escribiéndonos a support@kiwicredito.com para
                            revocar esta autorización. La solicitud debe de
                            hacerse 3 días hábiles o más, antes de la fecha que
                            el pago esté programado para realizarse, o se
                            aplicará a su próximo ciclo de facturación. Si
                            llama, también podríamos solicitarle que presente su
                            solicitud por escrito y nos la envíe dentro de 14
                            días posteriores a su llamada.
                        </p>
                        <p className="py-3">
                            Si no desea realizar pagos pre-autorizados a través
                            de ACH o pagos automáticos (&quot;Débito
                            Directo&quot;), puede comunicarse con nosotros a
                            support@kiwicredito.com para establecer repagos
                            manuales. Su préstamo no está condicionado a la
                            autorización de Débito Directo.
                        </p>
                        <p className="py-3">
                            Tiene derecho a disputar errores en su transacción.
                            Si cree que hay un error, contáctenos dentro de 180
                            días a support@kiwicredito.com. También puede
                            contactarnos para obtener una explicación por
                            escrito de sus derechos.
                        </p>
                    </div>
                    <div>
                        <p className="pl-10">
                            C. Autorización de Reportes de Crédito e Informes
                        </p>
                        <p className="py-3">
                            Como parte de su solicitud de un producto o servicio
                            de Kiwi Financial, Inc, solicitamos su autorización
                            para extraer su reporte de crédito. Usted comprende
                            que al solicitar un producto o servicio y al hacer
                            clic en la divulgación correspondiente, está
                            proporcionando &quot;instrucciones escritas&quot; a
                            Kiwi Financial, Inc en virtud de la Ley Federal de
                            Informe de Crédito Justo autorizandonos a obtener
                            información de su perfil de crédito personal u otra
                            información de una o más agencias de informes del
                            consumidor. Además, certifica que la información
                            proporcionada en su solicitud para nuestros
                            productos y servicios es verdadera y correcta.
                        </p>
                        <p className="py-3">
                            Por lo tanto, conforme a la Ley Federal de Informe
                            de Crédito Justo, usted autoriza a la Compañía y a
                            sus agentes y representantes designados a obtener un
                            Informe de Crédito del Consumidor sobre usted de una
                            o más de las principales agencias de crédito.
                            También usted nos declara y garantiza que comprende
                            el alcance del informe del consumidor/informe de
                            investigación del consumidor puede incluir, entre
                            otros, las siguientes áreas: verificación del número
                            de Seguro Social; residencias actuales y anteriores;
                            historial de empleo, incluyendo todos los archivos
                            personales; educación; referencias; historial de
                            crédito e informes; antecedentes penales, incluyendo
                            los registros de cualquier agencia de justicia penal
                            en cualquiera o todas las jurisdicciones federales,
                            estatales o del condado; registros de nacimiento;
                            registros de vehículos de motor, incluyendo las
                            citas de tráfico y registro; y cualquier otro
                            registro público.
                        </p>
                        <p className="py-3">
                            Usted comprende que, conforme a la Ley Federal de
                            Informe de Crédito Justo, si se toman medidas
                            adversas en función al informe del consumidor, se le
                            proporcionará una copia del informe y un resumen de
                            los derechos del consumidor. Además, reconoce que la
                            Compañía puede reportar los datos de crédito de
                            su(s) préstamo(s) a una o más agencias de informes
                            de crédito. La Compañía acepta que cualquier
                            información proporcionada será precisa. Usted
                            comprende que, si está solicitando un préstamo con
                            pagos múltiples, la extracción de crédito y el
                            reporte de datos de su préstamo pueden afectar su
                            puntaje de crédito con Experian, Equifax o
                            Transunion.
                        </p>
                    </div>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        18. Derechos de Propiedad Intelectual
                    </h5>
                    <p className="py-3">
                        Nuestras Plataformas en Línea y todo su contenido,
                        características, y funcionalidad (incluyendo, entre
                        otros, toda la información, software, texto, pantallas,
                        imágenes, video y audio, y el diseño, selección y
                        disposición de los mismos) son propiedad de la Compañía,
                        sus licenciantes, u otros proveedores de dicho material
                        y están protegidos por los Estados Unidos de América y
                        las leyes internacionales de derechos de autor, marcas
                        registradas, patentes, secretos comerciales y otras
                        leyes de propiedad intelectual o derechos de propiedad.
                    </p>
                    <div className="py-3">
                        Estos Términos de Uso le permiten utilizar nuestras
                        Plataformas en Línea únicamente para su uso personal y
                        no comercial. No puede reproducir, distribuir,
                        modificar, crear trabajos derivados de ello, mostrar
                        públicamente, realizar públicamente, volver a publicar,
                        descargar, almacenar o transmitir ningún material en
                        nuestra Página Web o Aplicación Móvil, excepto lo
                        siguiente:
                        <ul className="pl-10 pb-5 list-disc">
                            <li>
                                Su computadora puede almacenar temporalmente
                                copias de dichos materiales en la RAM incidental
                                a su acceso y visualización de dichos
                                materiales.
                            </li>
                            <li>
                                Puede almacenar archivos que su navegador web
                                almacena en caché automáticamente para mejorar
                                la visualización.
                            </li>
                            <li>
                                Puede imprimir o descargar una copia de un
                                número razonable de páginas de la Página Web
                                para su uso personal, no comercial y no para su
                                posterior reproducción, publicación o
                                distribución.
                            </li>
                            <li>
                                Las aplicaciones móviles, de computadora, u
                                otras aplicaciones para descarga pueden
                                descargarse como una sola copia en su
                                computadora o dispositivo móvil únicamente para
                                su uso personal y no comercial, siempre que
                                acepte estar sujeto a nuestro acuerdo de
                                licencia de usuario final para dichas
                                aplicaciones.
                            </li>
                            <li>
                                Si proveemos funciones de redes sociales con
                                cierto contenido, puede tomar las acciones que
                                habiliten dichas funciones.
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p className="py-3">Usted no debe:</p>
                        <ul className="pl-10 pb-5 list-disc">
                            <li>
                                Modificar copias de cualquier material de
                                nuestras Plataformas en Línea.
                            </li>
                            <li>
                                Utilizar las ilustraciones, fotografías,
                                secuencias de video o audio, o cualquier gráfico
                                por separado del texto que lo acompaña.
                            </li>
                            <li>
                                Eliminar o alterar cualquier aviso de derechos
                                de autor, marca registrada, u otros derechos de
                                propiedad de las copias de materiales de
                                nuestras Plataformas en Línea.
                            </li>
                        </ul>
                    </div>
                    <p className="py-3">
                        No puede acceder ni utilizar para ningún fin comercial
                        ninguna parte de nuestras Plataformas en Línea ni ningún
                        servicio o material disponible a través de ellas. Si
                        imprime, copia, modifica, descarga, o de otra manera usa
                        o proporciona a cualquier otra persona el acceso a
                        cualquier parte de nuestras Plataformas en Línea, estará
                        en violación de los Términos de Uso, y su derecho a usar
                        nuestras Plataformas en Línea se detendrá de inmediato y
                        debe, en nuestra opción, devolver o destruir cualquier
                        copia de los materiales que haya realizado. No se le
                        transfiere ningún derecho, título o interés sobre las
                        Plataformas en Línea o cualquier contenido en las
                        Plataformas en Línea, y todos los derechos no otorgados
                        expresamente, incluidos todos los derechos de propiedad
                        intelectual, están reservados y retenidos por la
                        Compañía. Cualquier uso de nuestras Plataformas en Línea
                        no permitidas expresamente por estos Términos de Uso
                        constituye un incumplimiento de estos Términos de Uso y
                        puede violar los derechos de autor, marcas comerciales,
                        y otras leyes.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        19. Divulgación de Dwolla
                    </h5>
                    <p className="py-3">
                        Para utilizar la funcionalidad de pago de nuestra
                        aplicación, debe abrir una cuenta de &quot;Plataforma
                        Dwolla&quot; proporcionada por Dwolla, Inc. y debe
                        aceptar los Términos de Servicio y la Política de
                        Privacidad de Dwolla. Los fondos que se mantienen en la
                        cuenta de Dwolla están en manos de las instituciones
                        financieras asociadas con Dwolla, según se establece en
                        los Términos de Servicio de Dwolla. Usted nos autoriza a
                        recopilar y compartir con Dwolla su información
                        personal, incluido el nombre completo, la dirección de
                        correo electrónico y la información financiera, y es
                        responsable de la precisión y la integridad de esos
                        datos. Usted comprende que accederá y administrará su
                        cuenta de Dwolla a través de nuestra aplicación, y las
                        notificaciones de la cuenta de Dwolla se las enviaremos
                        nosotros, no Dwolla. Brindaremos soporte al cliente para
                        la actividad de su cuenta de Dwolla, y podemos ser
                        localizados en www.kiwicredito.com, y/o
                        support@kiwicredito.com.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        20. Marcas Registradas
                    </h5>
                    <p className="py-3">
                        El nombre de la Compañía, el término Kiwi, el logotipo
                        de la Compañía, y todos los nombres, logotipos, nombres
                        de productos y servicios, diseños, y lemas relacionados
                        son marcas comerciales de la Compañía o sus filiales o
                        licenciantes. Usted no debe usar tales marcas sin el
                        permiso previo por escrito de la Compañía. Todos los
                        demás nombres, logotipos, nombres de productos y
                        servicios, diseños y lemas en esta Página Web son marcas
                        comerciales de sus respectivos propietarios.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        21. Protección de la Privacidad Infantil en Internet
                    </h5>
                    <p className="py-3">
                        Nuestras Plataformas en Línea no son para el uso de
                        niños menores de trece (13) años y no promueve que estos
                        menores proporcionen su información personal. Por lo
                        tanto, nuestra política es que no recopilamos
                        intencionalmente información de niños menores de trece
                        (13) años. Si somos conscientes de que hemos recopilado
                        dicha información, cumpliremos con los requisitos de la
                        Ley de Protección de Privacidad en Línea para Niños
                        (COPPA), que incluye la eliminación de dicha información
                        de nuestros sistemas.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        22. Restricciones Geográficas
                    </h5>
                    <p className="py-3">
                        Kiwi Financial, Inc, propietario de la Pagina Web y la
                        Aplicación Móvil, tiene su sede en el Estado Libre
                        Asociado de Puerto Rico, una jurisdicción de los Estados
                        Unidos de América. Brindamos servicios continuos en
                        línea para uso exclusivo de personas ubicadas en Puerto
                        Rico. No afirmamos que la Pagina Web, la Aplicación
                        Móvil, o cualquiera de sus contenidos sea accesible o
                        apropiado fuera de los Estados Unidos. El acceso a ellas
                        puede no ser legal para ciertas personas o en ciertos
                        países. Si accede a la Página Web o la Aplicación Móvil
                        desde fuera de los Estados Unidos, lo hace por
                        iniciativa propia y es responsable del cumplimiento de
                        las leyes locales. NO TODOS LOS PRODUCTOS Y SERVICIOS
                        ESTÁN DISPONIBLES EN TODAS LAS ÁREAS GEOGRÁFICAS. LA
                        EMPRESA SE RESERVA EL DERECHO DE ENMENDAR EL ALCANCE DEL
                        ÁREA GEOGRÁFICA CUBIERTA POR ESTOS TÉRMINOS DE USO
                        SUJETOS AL CUMPLIMIENTO DE LA LEY APLICABLE.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        23. Ley Aplicable y Jurisdicción
                    </h5>
                    <p className="py-3">
                        Todos los asuntos relacionados con nuestras Plataformas
                        en Línea y estos Términos de Uso, y cualquier disputa o
                        reclamo que surja de ellos o esté relacionado con ellos
                        (en cada caso, incluidas las disputas o reclamos no
                        contractuales), se regirán e interpretarán de acuerdo
                        con las leyes internas del Estado Libre Asociado de
                        Puerto Rico sin dar efecto a ninguna disposición o regla
                        de elección o conflicto de leyes (ya sea del Estado
                        Libre Asociado de Puerto Rico o de cualquier otra
                        jurisdicción).
                    </p>
                    <p className="py-3">
                        Cualquier demanda legal, acción, o procedimiento que
                        surja de, o esté relacionado con estos Términos de Uso o
                        la Página Web se instituirá exclusivamente en los
                        tribunales federales de los Estados Unidos o los
                        tribunales del Estado Libre Asociado de Puerto Rico en
                        cada caso ubicado en el ciudad de San Juan, Puerto Rico.
                        Usted renuncia a todas y cada una de las objeciones al
                        ejercicio de la jurisdicción sobre usted por dichos
                        tribunales y al lugar en dichos tribunales.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        24. Renuncia y Divisibilidad
                    </h5>
                    <p className="py-3">
                        Ninguna renuncia por parte de la Compañía de ningún
                        término o condición establecida en estos Términos de Uso
                        se considerará una renuncia adicional o continua de
                        dicho término o condición o una renuncia de cualquier
                        otro término o condición, y cualquier falla de la
                        Compañía en hacer valer un derecho o la disposición bajo
                        estos Términos de Uso no constituirá una renuncia a
                        dicho derecho o disposición.
                    </p>
                    <p className="py-3">
                        Si una corte u otro tribunal de jurisdicción competente
                        considera que alguna disposición de estos Términos de
                        Uso es inválida, ilegal, o inaplicable por cualquier
                        motivo, dicha disposición se eliminará o limitará a la
                        menor medida posible de manera que las disposiciones
                        restantes de los Términos de Uso continuarán en plena
                        vigencia y efecto.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        25. Acuerdo Completo
                    </h5>
                    <p className="py-3">
                        Los Términos de Uso, nuestra Política de Privacidad y
                        cualquier otro documento relevante proporcionado en el
                        proceso de solicitud constituyen el acuerdo único y
                        completo entre usted y Kiwi Financial, Inc con respecto
                        a nuestras Plataformas en Línea, y reemplazan todos los
                        entendimientos contemporáneos, acuerdos,
                        representaciones, y garantías , tanto escritas como
                        orales, con respecto a ellas.
                    </p>
                </div>
                <div>
                    <h5 className="title text-xl underline py-2">
                        Sus Comentarios y Preocupaciones
                    </h5>
                    <p className="py-3">
                        Esta Página Web es operada por Kiwi Financial, Inc,
                        localizada en 273 Ave. Ponce de León 273 Bldg. Suite 707
                        San Juan, PR 00917-1934​.
                    </p>
                    <p className="py-3">
                        Todas las reclamaciones de infracciones de derechos de
                        autor, comentarios, retroalimentación, solicitud de
                        ayuda técnica, u otras comunicaciones relacionadas a
                        nuestras Plataformas en Línea deberán ser dirigidas a
                        support@kiwicredito.com
                    </p>
                </div>
            </div>
        </BaseModal>
    )
}

export default PrivacyTermsModal
