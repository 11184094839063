import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

import KiwiLogo from 'assets/logo.svg'
import TourMenCellphone from 'assets/onboarding_v3/welcome_tour/men_cellphone.png'
import TourWomenCellphone from 'assets/onboarding_v3/welcome_tour/women_cellphone.png'
import TourWomenMoney from 'assets/onboarding_v3/welcome_tour/women_money.png'

export default function Tour() {
    const [step, setStep] = useState('')
    const navigate = useNavigate()

    const onContinue = () => {
        navigate('/location')
    }

    const alreadyAccount = () => {
        navigate('/login')
    }

    const onSwipeSlider = ({ realIndex }) => {
        setStep(realIndex)
    }

    return (
        <div className="w-full min-h-full flex flex-col items-center text-center py-10">
            <img className="mb-12" width="96" src={KiwiLogo} />

            <Swiper
                className="w-full cursor-grab"
                spaceBetween={200}
                centeredSlides={true}
                loop={true}
                slidesPerView={1}
                onSlideChange={onSwipeSlider}>
                <SwiperSlide className="w-full px-6">
                    <img
                        className="w-[235px] mx-auto mb-8"
                        src={TourWomenMoney}
                    />
                    <h1 className="text-dark-kiwi font-semibold mb-4 text-xl">
                        Solicita nuestros préstamos de hasta $1,000
                    </h1>
                    <p>
                        Recibe efectivo el mismo día o al siguiente día laboral.
                        No requieres garantía o aval.
                    </p>
                </SwiperSlide>

                <SwiperSlide className="w-full px-6">
                    <img
                        className="w-[235px] mx-auto mb-8"
                        src={TourMenCellphone}
                    />
                    <h1 className="text-dark-kiwi font-semibold mb-4 text-xl">
                        Construye historial crediticio
                    </h1>
                    <p>
                        No basamos nuestras decisiones exclusivamente en tu
                        historial de crédito. Kiwi solo realiza una verificación
                        'soft-pull', lo que no afectará tu puntaje.
                    </p>
                </SwiperSlide>

                <SwiperSlide className="w-full px-6">
                    <img
                        className="w-[235px] mx-auto mb-8"
                        src={TourWomenCellphone}
                    />
                    <h1 className="text-dark-kiwi font-semibold mb-4 text-xl">
                        Maneja tu cuenta 100% digital
                    </h1>
                    <p>
                        Realiza tus pagos y solicitudes en línea. Obtén soporte
                        100% humano. Adiós al papeleo y las sucursales.
                    </p>
                </SwiperSlide>
            </Swiper>

            <div className="flex gap-2 mb-6 mt-6">
                <div
                    className={`h-2 w-2 rounded-full ${
                        step === 0 ? 'bg-dark-kiwi' : 'bg-gray-300-kiwi'
                    }`}></div>
                <div
                    className={`h-2 w-2 rounded-full ${
                        step === 1 ? 'bg-dark-kiwi' : 'bg-gray-300-kiwi'
                    }`}></div>
                <div
                    className={`h-2 w-2 rounded-full ${
                        step === 2 ? 'bg-dark-kiwi' : 'bg-gray-300-kiwi'
                    }`}></div>
            </div>

            <div className="px-6 mt-auto w-full">
                <button
                    id="create-acoount-initial-gtm"
                    className="w-full bg-blue-kiwi text-white rounded-xl p-3"
                    onClick={onContinue}>
                    Crear cuenta
                </button>
                <button
                    id="already-account-gtm"
                    className="mx-auto text-blue-kiwi mt-6"
                    onClick={alreadyAccount}>
                    Ya tengo una cuenta
                </button>
            </div>
        </div>
    )
}
