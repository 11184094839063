import http from 'utils/http'

const signContract = async ({
    user_id,
    product_id,
    loanpro_tmp_loan_id,
    amount,
    ip
}) => {
    const response = await http.post(
        '/cb/sign-contract',
        {
            user_id,
            product_id,
            loanpro_tmp_loan_id,
            amount,
            ip
        },
        {
            responseType: 'blob'
        }
    )

    return response
}

const getUserCourses = async (user_id) => {
    let { data } = await http.get(`/cb/user-orders?user=${user_id}`)

    return data
}

const getCourses = async (user_id) => {
    let { data } = await http.get(`/cb/products/${user_id}`)
    return data
}

export default {
    signContract,
    getUserCourses,
    getCourses
}
