import mixpanel from 'utils/mixpanel';
import React, {useContext, useEffect} from 'react';
import {UserContext} from 'context/UserContext';
import {PERSONAL_LOAN_STATUS, CREDIT_BUILDER_STATUS} from 'constants';

import Header from './Header';
import Status from './Status';
import StatusActive from './Status/Active';
import Products from './Products';
import Recommended from './Recommended';

import Menu from 'templates/Menu';

export default function HomeStatusCurrentLoan() {
    const {state: userState, isSecondMoneyOnTheWay} = useContext(UserContext);
    const {
        user,
        products: {
            personal_loan: {status: personalLoanStatus},
            credit_builder: {status: creditBuilderStatus},
        },
    } = userState;

    const isPersonalLoanActive =
        personalLoanStatus === PERSONAL_LOAN_STATUS.current &&
        isSecondMoneyOnTheWay() === false;

    const isCreditBuilderActive =
        creditBuilderStatus === CREDIT_BUILDER_STATUS.current;

    useEffect(() => {
        dataLayer.push({
            event: 'new_application',
            eventCategory: 'register_process',
            eventAction: 'register_process_end_panel',
            dl_user_id: userState.user.id,
        });

        mixpanel.people.set({
            $first_name: user.first_name,
            $last_name: user.first_surname,
            $email: user.email,
            Birthdate: user.birthdate,
            $phone: user.phone_number,
        });

        _cio.identify({
            id: user.id,
        });
    }, []);

    return (
        <div className="w-full min-h-full flex flex-col px-6 pt-10 pb-[136px]">
            <Header className="mb-8" />
            <div className="mb-8 flex flex-col gap-6">
                {isPersonalLoanActive === false && <Status />}
                {(isPersonalLoanActive === true ||
                    isCreditBuilderActive === true) && <StatusActive />}
            </div>
            <Products className="mb-8" />
            <Recommended />
            <Menu selected="home" />
        </div>
    );
}
